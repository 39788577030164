import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Graphics, Sprite, Text, useTick } from '@pixi/react'
import { useSpringValue } from '@react-spring/web'
import { Graphics as PixiGraphics, TextStyle } from 'pixi.js'
import { ENABLE_MAP_TILES_DEBUG_OVERLAY } from 'src/config/constants'
import { HIT_AREAS, PLATFORMS_HIT_AREAS } from 'src/config/platforms'
import { ScreenDimensions as Dimensions } from 'src/hooks/useScreenDimensions'
import Tile from 'src/types/Tile'
import { positionToStringifiedPosition } from 'utils/position'

import { useAppDispatch } from 'store'
import { setIsTabbarHidden } from 'store/storage'

import { PopulatedEgg, PopulatedPet } from '@gatto/shared'

import { TILE_UP_HEIGTH } from './constants'
import { getTilePosition } from './utils'
import { SelectedTile, SelectedTilesActions, TileProps } from '.'

export interface TileDimensions extends Dimensions {
  gapX: number
  gapY: number
  offsetX: number
  offsetY: number
}

type TileSpriteDebugOverlayProps = {
  tileDimensions: TileDimensions
  tile: Tile
}
const TileSpriteDebugOverlay: React.FC<TileSpriteDebugOverlayProps> = ({
  tileDimensions,
  tile,
}) => {
  const { width, height } = tileDimensions
  const drawDebugOutline = useCallback(
    (g: PixiGraphics) => {
      g.clear()
      g.lineStyle({
        width: 1,
        alignment: 0,
        color: 0xff0000,
        alpha: 0.5,
      })
      g.drawRect(0, 0, width, height)
    },
    [height, width],
  )

  return (
    <>
      <Graphics draw={drawDebugOutline} />
      <Text
        text={`${tile.position.x}, ${tile.position.y}`}
        anchor={0.5}
        x={width / 2}
        y={height / 2}
        style={
          new TextStyle({
            fontSize: width / 10,
            fill: 0xffffff,
          })
        }
      />
    </>
  )
}

type TileSpriteProps = {
  tile: Tile
  tileDimensions: TileDimensions
  selectionTypes?: SelectedTile['selectionTypes']
  shouldDimBrightness?: boolean
  selectedTilesActions: SelectedTilesActions
  pet: PopulatedPet | undefined | null
  onpointertap?: () => void
  isSelected?: boolean
  tileProp: TileProps
  petObject: { [p: string]: number }
  isPetAlone: boolean
  children?: React.ReactNode
  placing?: boolean
  allowedRegion?: string | null
  egg?: PopulatedEgg | null
}

const TileSprite: React.FC<TileSpriteProps> = ({
  tile,
  tileDimensions,
  selectionTypes,
  shouldDimBrightness = false,
  onpointertap,
  selectedTilesActions,
  tileProp,
  isSelected = false,
  pet,
  petObject,
  isPetAlone,
  children,
  placing,
  allowedRegion,
  egg,
  ...props
}) => {
  const [isPointerDown, setIsPointerDown] = useState<boolean>(false)
  const [pointerDownPosition, setPointerDownPosition] = useState<{
    x: number
    y: number
  }>()
  const dispatch = useAppDispatch()
  const longPressTimerRef = useRef<number | null | undefined>(null)

  const { width, height } = tileDimensions
  const animationOpacity = useSpringValue(0, {
    config: { mass: 10, tension: 1000, friction: 100 },
  })
  const animationOffsetY = useSpringValue(-24, {
    config: { mass: 10, tension: 1000, friction: 100 },
  })
  const [opacity, setOpacity] = useState(animationOpacity.get())
  const [offsetY, setOffsetY] = useState(animationOffsetY.get())

  const allowPlacingHere =
    !!placing && allowedRegion === tile.type && !egg && !pet

  // TODO: заменить 1 на нужный скин из бд
  const image =
    tile.skin !== 0
      ? `platform_${tile.type}_${tile.skin}.png`
      : `platform_${tile.type}.png`

  const hitArea =
    tile.type in PLATFORMS_HIT_AREAS
      ? PLATFORMS_HIT_AREAS[tile.type]
      : HIT_AREAS.regular

  const { x, y } = getTilePosition(tile, tileDimensions)

  const handlePointerHoldDown = async () => {
    if (
      tile.id &&
      !tileProp.deleteBiomRef.current &&
      onpointertap &&
      !tileProp.isEssence &&
      !placing &&
      location.search !== '?mode=cross'
    ) {
      setIsPointerDown(true)

      longPressTimerRef.current = window.setTimeout(() => {
        if (location.pathname === '/' && !tileProp.deleteBiomRef.current) {
          tileProp.deleteBiomRef.current = tile.id
          tileProp.navigate('/?mode=delete')
          dispatch(setIsTabbarHidden(true))
          tileProp.setDeleteBiom(tile.id)
          handlePointerUp()
          onpointertap()
        }
      }, 4000)
    }

    if (tile.id && tileProp.deleteBiomRef.current) {
      tileProp.setDeleteBiom(tile.id)
      tileProp.deleteBiomRef.current = tile.id
      selectedTilesActions.clear()
      selectedTilesActions.set(positionToStringifiedPosition(tile.position), {
        id: tile.id,
        position: tile.position,
        selectionTypes: new Set(['outline', 'brightness']),
      })
    }
  }

  useEffect(() => {
    if (longPressTimerRef.current) {
      longPressTimerRef.current = null
    }
  }, [longPressTimerRef.current])

  const handleEssenceClick = async () => {
    if (pet && pet?.level !== 10) {
      if (onpointertap && tileProp.isEssence) {
        onpointertap()
        selectedTilesActions.clear()
        selectedTilesActions.set(positionToStringifiedPosition(tile.position), {
          id: tile.id,
          position: tile.position,
          selectionTypes: new Set(['outline', 'brightness']),
        })
        tileProp.setFirstPet(tile.pet)
      }
    }
  }

  const handlePlacingClick = async () => {
    if (allowedRegion && allowedRegion === tile.type) {
      setIsPointerDown(true)
      onpointertap?.()
      selectedTilesActions.remove(positionToStringifiedPosition(tile.position))
      selectedTilesActions.set(positionToStringifiedPosition(tile.position), {
        id: tile.id,
        position: tile.position,
        selectionTypes: new Set(['outline', 'brightness']),
      })
    }
  }

  const handlePointerDown = async () => {
    if (!tileProp.deleteBiomRef.current) {
      setIsPointerDown(true)
      onpointertap?.()
    }

    if (
      pet &&
      location.search.indexOf('mode=cross') !== -1 &&
      tile.pet?.level === 10 &&
      petObject[tile.pet.basePet.kind] !== 1
    ) {
      tileProp.setIsPetSelected(true)
      tileProp.setFirstPet(tile.pet)
      tileProp.setIsCleared(false)
      tileProp.setPetIds(tile?.pet?._id.toString())
      selectedTilesActions.clear()
      selectedTilesActions.set(positionToStringifiedPosition(tile.position), {
        id: tile.id,
        position: tile.position,
        selectionTypes: new Set(['outline', 'brightness']),
      })
    }
  }

  const handleSecondClick = () => {
    tileProp.setIsCleared(true)
    tileProp.setFirstPet(undefined)
    setIsPointerDown(true)
    if (pet && tileProp.firstPet) {
      if (
        tileProp.firstPet.basePet.kind === pet?.basePet.kind &&
        tileProp.firstPet._id !== pet?._id
      ) {
        if (onpointertap && tileProp.isPressed) {
          onpointertap()
        }
      } else {
        tileProp.setIsPetSelected(false)
        tileProp.setIsCleared(false)
        tileProp.setFirstPet(undefined)
      }
    } else {
      tileProp.setIsCleared(false)
      tileProp.setIsPetSelected(false)
      tileProp.setFirstPet(undefined)
    }
  }

  const handlePointerUp = () => {
    if (longPressTimerRef.current) {
      window.clearTimeout(longPressTimerRef.current)
      longPressTimerRef.current = null
    }

    if (isPointerDown && !tileProp.isEssence && !placing) {
      handlePointerDown()
    }

    if (isPointerDown && tileProp.isEssence && !placing) {
      handleEssenceClick()
    }

    if (isPointerDown && pet && tileProp.firstPet && !placing) {
      handleSecondClick()
      selectedTilesActions.clear()
      tileProp.setIsPetSelected(false)
      tileProp.setIsCleared(false)
      tileProp.setFirstPet(undefined)
    }

    if (isPointerDown && !!placing && allowPlacingHere) {
      handleSecondClick()
      selectedTilesActions.clear()
    }

    if (isPointerDown && !!placing && allowPlacingHere) {
      handlePlacingClick()
    }

    setIsPointerDown(false)
  }

  // Анимация появления
  useEffect(() => {
    const id = setTimeout(
      () => {
        animationOpacity.start(1)
        animationOffsetY.start(0)
      },
      (tile.position.x + tile.position.y) * 10,
    )

    return () => {
      clearTimeout(id)
    }
  }, [animationOffsetY, animationOpacity, tile.position.x, tile.position.y])

  useTick(() => {
    setOpacity(animationOpacity.get())
    setOffsetY(animationOffsetY.get())
  })

  // Не рисуем тайл, если он относится к большему тайлу (например, если он часть тайла 2x2)
  if (tile.tileFor) return null

  return (
    <Container
      zIndex={tile.position.x + tile.position.y + (tile.isLarge ? 1 : 0)}
      alpha={opacity}
      x={x}
      y={
        tileProp.deleteBiomRef &&
        tileProp.deleteBiomRef.current === tile.id &&
        tile.id
          ? offsetY + y - TILE_UP_HEIGTH
          : offsetY + y
      }
      {...(shouldDimBrightness && { alpha: 0.5 })}
      {...(!!placing && !allowPlacingHere && { alpha: 0.5 })}
      {...(((location.search.indexOf('mode=cross') !== -1 ||
        location.search === '?popout=petsCross') &&
        tile.pet?.level !== 10) ||
      ((location.search.indexOf('mode=cross') !== -1 ||
        location.search === '?popout=petsCross') &&
        tile?.pet &&
        petObject[tile.pet.basePet?.kind] === 1)
        ? { alpha: 0.5 }
        : '')}
      {...(tileProp.isEssence &&
        (tile.pet?.level === 10 || !tile.pet) && { alpha: 0.5 })}
      {...(isSelected && selectionTypes?.has('brightness') && { alpha: 1 })}
      {...props}
    >
      <Sprite
        zIndex={1000}
        image={image}
        width={width}
        height={height}
        anchor={0}
        hitArea={hitArea}
        alpha={1}
        interactive={true}
        cursor={
          (pet?.level !== 10 && tileProp.isEssence && pet) ||
          (!!placing && allowPlacingHere)
            ? 'pointer'
            : ((isPetAlone || pet?.level !== 10) &&
                location.search.indexOf('mode=cross') !== -1) ||
              tileProp.isEssence ||
              (placing && !allowPlacingHere) ||
              (tileProp.deleteBiomRef.current && !tile.id)
            ? 'default'
            : 'pointer'
        }
        onpointerdown={(event) => {
          setPointerDownPosition({ ...event.global })
          setIsPointerDown(true)
          if (!longPressTimerRef.current) {
            handlePointerHoldDown()
          }
        }}
        onpointerup={handlePointerUp}
        onpointermove={(event) => {
          // Засчитываем нажатие только если курсор сдвинулся менее чем на 10 пикселей с момента нажатия
          if (
            pointerDownPosition &&
            Math.abs(event.global.x - pointerDownPosition.x) +
              Math.abs(event.global.y - pointerDownPosition.y) >
              10
          ) {
            setIsPointerDown(false)
          }
        }}
      >
        {children}
      </Sprite>
      {ENABLE_MAP_TILES_DEBUG_OVERLAY && (
        <TileSpriteDebugOverlay tile={tile} tileDimensions={tileDimensions} />
      )}
    </Container>
  )
}

export default TileSprite
