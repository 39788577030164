/* eslint-disable no-console */
import { GamePet, RunGame } from 'src/pixi/classes'
import { RunGameBackgroundSprites } from 'src/pixi/types/rungame'

import {
  CONTAINER_SCALE_COEF,
  TRACK_WIDTH,
  TRACKS_IN_CONTAINER,
} from '../constants'

export function addTrack(this: RunGame, trackIndex: number): void {
  const track = this.getTrackSpriteByNum(this.fullTrack[trackIndex])
  if (track) {
    this.trackContainer.addChild(track)
    track.x = trackIndex * TRACK_WIDTH
  }
}

/**
 * Подвинуть питомца
 * @param petIndex индекс питомца
 * @param xOffset смещение
 */
export function movePet(this: RunGame, pet: GamePet): void {
  const xOffset = pet.getPetXOffset()
  const y = pet.getPetEngineY()

  if (!xOffset || y === undefined) return
  pet.x += xOffset
  this.movePetY(pet, y)

  if (pet.x >= this.fullTrack.length * TRACK_WIDTH) {
    pet.setAnimation('idle', true)
  }
}

export function movePetY(this: RunGame, pet: GamePet, y: number): void {
  pet.y =
    this.getObjectSecondCoord({
      row: pet.row,
      trackName: this.getTrackNameByX(pet.x),
      x: pet.x % TRACK_WIDTH,
      objOffset: -pet.petSpine.height / 5,
    }) - y
}

/**
 * Подвинуть anchor питомца
 */
export function moveAnchorPet(this: RunGame): void {
  const xOffset = this.anchorPet.getPetXOffset()

  if (
    this.currentTrackOffset <
      (this.fullTrack.length - 1) * TRACK_WIDTH +
        (1 - CONTAINER_SCALE_COEF) * TRACK_WIDTH &&
    xOffset
  ) {
    this.moveTrack(xOffset)
  }
  this.movePet(this.anchorPet)
}

export function moveTrack(this: RunGame, xOffset: number): void {
  this.currentTrackOffset += xOffset
  if (Math.floor(this.currentTrackOffset / TRACK_WIDTH) > this.currentTrack) {
    this.trackContainer.removeChildAt(0)
    this.addTrack(this.currentTrack + TRACKS_IN_CONTAINER)
    this.currentTrack++
  }
  this.trackContainer.x = -this.currentTrackOffset
  this.interractiveContainer.x = -this.currentTrackOffset
  this.moveBackground(xOffset)
}

export function moveBackground(this: RunGame, xOffset: number): void {
  Object.entries(this.backgroundSprites).forEach(([name, sprite]) => {
    if (sprite.tilePosition.x >= 0) return
    const width =
      this.backgroundSpritesWidth[name as keyof RunGameBackgroundSprites]
    if (!width) return
    sprite.tilePosition.x += width * 5
  })

  this.backgroundSprites.stadium.tilePosition.x -= xOffset * 0.6
  this.backgroundSprites.stands.tilePosition.x -= xOffset * 0.7
  this.backgroundSprites.stadiumForward.tilePosition.x -= xOffset * 0.7
  this.backgroundSprites.grass.tilePosition.x -= xOffset
  this.backgroundSprites.clouds.tilePosition.x -= xOffset * 0.3
}
