import React from 'react'
import styled from '@emotion/styled'
import designTokens from 'src/config/designTokens'

const Root = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  gap: 10px;

  font-family: Nunito;
  font-style: normal;
`

const Header = styled.h2<{ theme?: typeof designTokens }>`
  color: ${({ theme }) => theme.color['black-100']};

  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
`

const Content = styled.span`
  display: flex;
  flex-direction: row;
  gap: 5px;
`

type ModalGroupProps = {
  /**
   * Заголовок группы
   */
  header?: string

  /**
   * Элементы группы
   */
  children: React.ReactNode
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

/**
 * Компонент-контейнер для группировки элементов в модальном окне
 * @returns Контейнер для группы элементов
 * @see {@link https://www.figma.com/file/jisNzqXBy6sVRfQxqWzK2M/Untitled-(Copy)?type=design&node-id=361-17849&mode=dev | Figma}
 */
function ModalGroup({
  header,
  children,
  ...props
}: ModalGroupProps): React.ReactElement {
  return (
    <Root {...props}>
      {header && <Header>{header}</Header>}
      <Content>{children}</Content>
    </Root>
  )
}

export default ModalGroup
