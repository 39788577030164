import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Types as MongooseTypes } from 'mongoose'
import { PetClothes } from 'src/pixi/types/spine'
import { makeRequest } from 'src/utils/api'

import {
  PetKind,
  PopulatedEgg,
  PopulatedPet,
  PopulatedUserRegion,
} from '@gatto/shared'

import { RootState } from '.'

export interface RegionSlice {
  data: PopulatedUserRegion | null
  petPrize: boolean | null
  isPetCrossed: boolean
  loading: boolean
  regionId: string | null
}

const initialState: RegionSlice = {
  data: null,
  isPetCrossed: false,
  loading: true,
  petPrize: false,
  regionId: null,
}

export const fetchRegion = createAsyncThunk<
  PopulatedUserRegion,
  MongooseTypes.ObjectId | undefined,
  { state: { region: RegionSlice } }
>('region/get', async (id, { getState }) => {
  const { data } = getState().region

  const { region } = await makeRequest('region.get', {
    id: id ? `${id}` : `${data?._id}`,
  })
  return region
})

const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    clearPetState(state) {
      if (state.data) {
        state.data.pet = undefined
      }
    },
    setPetPrize(state, action) {
      state.petPrize = action.payload
    },
    clearEggState(state) {
      if (state.data) {
        state.data.egg = undefined
      }
    },
    clearRegionState(state) {
      state.data = null
      state.loading = true
    },
    updateRegionPetSkins(state, action: PayloadAction<PetClothes[]>) {
      if (state.data?.pet) {
        state.data.pet.skins = action.payload
      }
    },
    updateRegionEgg: (
      state,
      action: PayloadAction<{
        egg: PopulatedEgg | undefined
      }>,
    ) => {
      if (state.data) state.data.egg = action.payload.egg
    },
    setIsPetCrossed: (state, action) => {
      state.isPetCrossed = action.payload
    },
    setRegionId: (state, action) => {
      state.regionId = action.payload
    },
    updateRegionPet: (
      state,
      action: PayloadAction<{
        pet: PopulatedPet | undefined
      }>,
    ) => {
      if (state.data) state.data.pet = action.payload.pet
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegion.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchRegion.fulfilled, (state, action) => {
        state.data = action.payload
        state.loading = false
      })
  },
})

export const {
  clearPetState,
  clearRegionState,
  updateRegionPet,
  updateRegionEgg,
  clearEggState,
  setIsPetCrossed,
  updateRegionPetSkins,
  setPetPrize,
  setRegionId,
} = regionSlice.actions
export default regionSlice.reducer

export const getRegionPetId = (
  state: RootState,
): MongooseTypes.ObjectId | undefined => state.region.data?.pet?._id

export const getPetSkins = (state: RootState): PetClothes[] | undefined =>
  state.region.data?.pet?.skins

export const getRegionPetKind = (state: RootState): PetKind | undefined =>
  state.region.data?.pet?.basePet.kind
