import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Modals } from 'const/router'
import { ModalContext } from 'src/utils/ModalContext'

// Хук для открытия модальных окон
// принимает путь до модального окна
// возвращает функцию открытия модального окна

const useModal = <T extends keyof Modals>(
  path: T,
  replace: boolean,
  props: Modals[T],
): (() => void) => {
  const navigate = useNavigate()
  const { updateActiveModal } = useContext(ModalContext)

  return () => {
    const queryParams = `?modal=${path}`

    navigate(queryParams, { state: props, replace })

    console.info(queryParams)
    updateActiveModal('root')
  }
}

export default useModal
