import { FC, ReactNode } from 'react'
import styled from '@emotion/styled'

export const Container = styled.div<{ tabbarPadding?: boolean }>`
  width: 100%;
  height: 100%;
  gap: 20px;
  background: radial-gradient(
      50% 38.49% at 50% 50%,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(
      180deg,
      rgba(143, 0, 255, 0) 81.77%,
      rgba(189, 0, 255, 0.4) 100%
    ),
    linear-gradient(180deg, #8cc1ff 0%, #3e70f2 100%), #4f3686;
  padding-bottom: ${(props) =>
    props.tabbarPadding ? `var(--page-with-tabbar-bottom-offset)` : ''};
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: Nunito;
  color: white;
  &::-webkit-scrollbar {
    display: none;
  }
`

interface PageProps {
  children?: ReactNode[] | ReactNode
  style?: {}
  tabbarPadding?: boolean
}

const Page: FC<PageProps> = ({ children, style, tabbarPadding }) => {
  return (
    <Container style={style} tabbarPadding={tabbarPadding}>
      {children}
    </Container>
  )
}

export default Page
