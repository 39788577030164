import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import bridge from '@vkontakte/vk-bridge'
import { ButtonGroup } from '@vkontakte/vkui'
import { GROUP_ID } from 'src/config/constants'
import { Popouts } from 'src/const/router'
import { makeRequest } from 'src/utils/api'

import coin from 'assets/svg/coin.svg'
import {
  Button,
  Header,
  Popout,
  type StatusType,
  Subheader,
} from 'components/base'
import { useAppDispatch } from 'store/index'
import { incrementBalance } from 'store/user'

import { LOCALES } from '@gatto/locales'
import { PetFeelsAliases } from '@gatto/shared'

const Wrapper = styled.div`
  display: flex;
  gap: 3px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  padding: 3px 5px;
  align-items: center;
`

const CoinsImage = styled.img`
  width: 12.95px;
  height: 15px;
  margin-top: -2px;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`

const StyledHeader = styled(Header)`
  max-width: 250px;
`

const StyledSubheader = styled(Subheader)`
  line-height: 14px;
`

const StyledButtonGroup = styled(ButtonGroup)`
  padding-top: 15px;
`

type NotificationsAccessProps = Popouts['notificationsAccess']

const NotificationsAccess: React.FC<NotificationsAccessProps> = ({ feel }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [status, setStatus] = useState<StatusType>()

  const handleButtonClick = async (status: boolean | null, type: string) => {
    if (status) {
      try {
        await bridge.send('VKWebAppAllowMessagesFromGroup', {
          group_id: GROUP_ID,
        })

        const response = await makeRequest('user.setNotifications', {
          status,
          type,
        })
        dispatch(incrementBalance({ ...response }))
      } catch {
        return
      }
    }

    navigate(-1)
  }

  return (
    <Popout
      isFullScreen={false}
      type="default"
      background
      width={'340px'}
      status={status}
      setStatus={setStatus}
    >
      <InfoContainer>
        <StyledHeader lineHeight={18}>
          {t(LOCALES.enableNotifyFromBot)}
        </StyledHeader>
        {feel === PetFeelsAliases.Feed && (
          <StyledSubheader>{t(LOCALES.botSayOnPetFeed)}</StyledSubheader>
        )}
        {feel === PetFeelsAliases.Play && (
          <StyledSubheader>{t(LOCALES.botSayOnPetPlay)}</StyledSubheader>
        )}

        {feel === 'prize' && (
          <StyledSubheader>{t(LOCALES.botSayOnPresentDone)}</StyledSubheader>
        )}

        <StyledButtonGroup>
          <Button
            variant="dangerous"
            onClick={() => handleButtonClick(null, '')}
          >
            {t(LOCALES.refuse)}
          </Button>
          <Button
            variant="success"
            onClick={() => handleButtonClick(true, feel)}
          >
            {t(LOCALES.enable)}
          </Button>
        </StyledButtonGroup>
      </InfoContainer>
    </Popout>
  )
}

export default NotificationsAccess
