import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import { useModal } from 'src/hooks'

import { Popout, StatusType, Subheader } from 'components/base/Popout'

import { LOCALES } from '@gatto/locales'

const SvgContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 47.823px;
  height: 47.823px;
  align-self: center;
  flex-shrink: 0;
  border-radius: 7px;
  background: rgba(174, 179, 177, 0.1);
`

const SuccessSVG = () => {
  return (
    <SvgContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="33"
        viewBox="0 0 33 33"
        fill="none"
      >
        <path
          d="M27.5186 9.5H5.51855C4.41399 9.5 3.51855 10.3954 3.51855 11.5V27.5C3.51855 28.6046 4.41399 29.5 5.51855 29.5H27.5186C28.6231 29.5 29.5186 28.6046 29.5186 27.5V11.5C29.5186 10.3954 28.6231 9.5 27.5186 9.5Z"
          fill="#4C5960"
          fillOpacity="0.24"
        />
        <path d="M3.51855 14.5H29.5186V16.5H3.51855V14.5Z" fill="#4C5960" />
        <path
          d="M16.5186 15.5C19.8323 15.5 22.5186 12.8137 22.5186 9.5C22.5186 6.18629 19.8323 3.5 16.5186 3.5C13.2048 3.5 10.5186 6.18629 10.5186 9.5C10.5186 12.8137 13.2048 15.5 16.5186 15.5Z"
          fill="#A9D333"
        />
        <path
          d="M15.8981 12.1898C15.7665 12.1906 15.636 12.1654 15.5142 12.1156C15.3924 12.0658 15.2816 11.9925 15.1881 11.8998L13.4181 10.1298C13.2298 9.94152 13.124 9.68612 13.124 9.41982C13.124 9.15352 13.2298 8.89813 13.4181 8.70982C13.6064 8.52152 13.8618 8.41573 14.1281 8.41573C14.3944 8.41573 14.6498 8.52152 14.8381 8.70982L15.8981 9.76982L17.9981 7.66982C18.0911 7.57609 18.2017 7.5017 18.3235 7.45093C18.4454 7.40016 18.5761 7.37402 18.7081 7.37402C18.8401 7.37402 18.9708 7.40016 19.0927 7.45093C19.2146 7.5017 19.3252 7.57609 19.4181 7.66982C19.6044 7.85718 19.7089 8.11064 19.7089 8.37482C19.7089 8.63901 19.6044 8.89246 19.4181 9.07982L16.5981 11.8998C16.4119 12.0846 16.1605 12.1887 15.8981 12.1898Z"
          fill="#FCFFF3"
        />
        <path
          d="M13.5186 24.5H7.51855C7.25334 24.5 6.99898 24.3946 6.81145 24.2071C6.62391 24.0196 6.51855 23.7652 6.51855 23.5C6.51855 23.2348 6.62391 22.9804 6.81145 22.7929C6.99898 22.6054 7.25334 22.5 7.51855 22.5H13.5186C13.7838 22.5 14.0381 22.6054 14.2257 22.7929C14.4132 22.9804 14.5186 23.2348 14.5186 23.5C14.5186 23.7652 14.4132 24.0196 14.2257 24.2071C14.0381 24.3946 13.7838 24.5 13.5186 24.5Z"
          fill="#4C5960"
        />
        <path
          d="M25.5186 24.5H24.5186C24.2533 24.5 23.999 24.3946 23.8114 24.2071C23.6239 24.0196 23.5186 23.7652 23.5186 23.5C23.5186 23.2348 23.6239 22.9804 23.8114 22.7929C23.999 22.6054 24.2533 22.5 24.5186 22.5H25.5186C25.7838 22.5 26.0381 22.6054 26.2257 22.7929C26.4132 22.9804 26.5186 23.2348 26.5186 23.5C26.5186 23.7652 26.4132 24.0196 26.2257 24.2071C26.0381 24.3946 25.7838 24.5 25.5186 24.5ZM21.5186 24.5H20.5186C20.2533 24.5 19.999 24.3946 19.8114 24.2071C19.6239 24.0196 19.5186 23.7652 19.5186 23.5C19.5186 23.2348 19.6239 22.9804 19.8114 22.7929C19.999 22.6054 20.2533 22.5 20.5186 22.5H21.5186C21.7838 22.5 22.0381 22.6054 22.2257 22.7929C22.4132 22.9804 22.5186 23.2348 22.5186 23.5C22.5186 23.7652 22.4132 24.0196 22.2257 24.2071C22.0381 24.3946 21.7838 24.5 21.5186 24.5Z"
          fill="#F14E41"
        />
      </svg>
    </SvgContainer>
  )
}

const WalletExist: FC = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const Minting = location.pathname !== '/news'
  const [status, setStatus] = useState<StatusType>()
  const openModaltransformPetToNFT = useModal('walletChoose', false, {
    text: `${t(LOCALES.chooseWalletToMint)}`,
    type: 'minting',
  })
  useEffect(() => {
    if (Minting) {
      const timeoutId = setTimeout(() => {
        openModaltransformPetToNFT()
      }, 2000)
      return () => {
        clearTimeout(timeoutId)
      }
    }
    return
  }, [Minting, openModaltransformPetToNFT])

  return (
    <Popout
      background={true}
      isFullScreen={false}
      type="default"
      width="284px"
      status={status}
      setStatus={setStatus}
    >
      <SuccessSVG />
      <Subheader>{t(LOCALES.connected_wallet)}</Subheader>
    </Popout>
  )
}

export default WalletExist
