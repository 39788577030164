import { FC, ReactNode, useEffect } from 'react'
import styled from '@emotion/styled'
import anime from 'animejs'

const Progress = styled.div<{ progressColor?: string }>`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 0%;
  box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.15) inset;
  background: ${({ progressColor }) => progressColor};
  transition: width 0.5s;
  border-radius: 10px;
`

Progress.defaultProps = {
  progressColor: `linear-gradient(
    0deg,
    rgba(36, 156, 77, 0.69) 0%,
    rgba(36, 156, 77, 0.69) 100%
  ),
  linear-gradient(180deg, #32ff6b 0%, #32ff5f 100%),
  linear-gradient(
    180deg,
    rgba(68, 104, 255, 0.2) 0%,
    rgba(59, 91, 224, 0.2) 100%
  ),
  linear-gradient(180deg, #448fff 0%, #3b73e0 100%)`,
}

const MaskContainer = styled.div<{ lineWidth?: number }>`
  display: inline-flex;
  transform: rotate(20.009deg);

  gap: ${({ lineWidth }) => lineWidth}px;
  position: absolute;
  margin-left: -280px;
  margin-top: -60px;
  transition: left linear 0.1s;
`
MaskContainer.defaultProps = {
  lineWidth: 20,
}

const MaskItem = styled.div<{ lineWidth?: number }>`
  background: rgba(255, 255, 255, 0.15);
  width: ${({ lineWidth }) => lineWidth}px;
  height: 400px;
`
MaskItem.defaultProps = {
  lineWidth: 20,
}

const Main = styled.div<{
  width: number | string
  height?: number | string
  backgroundColor?: string
}>`
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  width: ${({ width }) => (typeof width === 'number' ? width + 'px' : width)};
  height: ${({ height }) =>
    typeof height === 'number' ? height + 'px' : height};
  background-color: ${(props) => props.backgroundColor};
  display: flex;
`

Main.defaultProps = {
  backgroundColor: 'gray',
  height: 20,
}

const Info = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MaskItems = ({ lineWidth }: { lineWidth?: number }) => {
  const maskItems = Array.from(Array(14).keys())

  return (
    <MaskContainer className="maskContainer" lineWidth={lineWidth}>
      {maskItems.map((item) => (
        <MaskItem key={item} lineWidth={lineWidth} />
      ))}
    </MaskContainer>
  )
}

type ProgressBarProps = {
  progressPercentage: number
  progressWidth: number | string
  progressHeight?: number | string
  backgroundColor?: string
  progressColor?: string
  children?: ReactNode
  lineWidth?: number
}

function ProgressBar({
  progressPercentage,
  progressWidth,
  progressHeight,
  backgroundColor,
  progressColor,
  children,
  lineWidth,
}: ProgressBarProps): React.ReactElement {
  useEffect(() => {
    anime({
      targets: '.progress',
      width: `${progressPercentage}%`,
      easing: 'linear',
      duration: 200,
    })
  }, [progressPercentage])

  useEffect(() => {
    anime({
      targets: '.maskContainer',
      rotate: 20,
      translateY: '-100px',
      duration: 0,
    })
    anime({
      targets: '.maskContainer',
      translateX: '100px',
      easing: 'linear',
      duration: 200,
    })
  }, [progressWidth])

  return (
    <Main
      width={progressWidth}
      height={progressHeight}
      backgroundColor={backgroundColor}
    >
      <Progress className="progress" progressColor={progressColor}>
        <MaskItems lineWidth={lineWidth} />
      </Progress>
      <Info>{children ?? ''}</Info>
    </Main>
  )
}

export default ProgressBar
