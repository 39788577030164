import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import { API_URL } from 'src/config/constants'

import CoinIcon from 'assets/svg/coin.svg'
import PawIcon from 'assets/svg/diamond_paw.svg'
import DiamondIcon from 'assets/svg/diamond.png'
import { MINI_APP } from '../../../index'
import { Platform } from '@gatto/shared'
const Container = styled.button<{ stretched?: boolean; hasType?: boolean }>`
  display: flex;
  width: 85px;
  height: 42px;
  padding: 10px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
  align-self: center;

  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 11px;

  border: 0px;
  transition: opacity 0.1s;
  user-select: none;

  -webkit-tap-highlight-color: transparent;
  cursor: ${(props) => (props.hasType ? 'default' : 'pointer')};

  ${(props) =>
    !props.hasType &&
    `
    :hover {
      opacity: 0.9;
    }
    :active {
      opacity: 0.8;
    }
  `}

  ${(props) => props.stretched && 'width: 100%;'}
`

const CoinsNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
`

const Coin = styled.img`
  width: 26.703px;
  height: 30px;
`

type CoinsButtonProps = {
  children: ReactNode | ReactNode[]
  stretched?: boolean
  type?: 'hard' | 'soft' | 'essence' | 'egg'
}

function CoinsButton({
  children,
  stretched,
  type,
}: CoinsButtonProps): React.ReactElement {
  let iconSrc
  const hasType = !!type

  switch (type) {
    case 'hard':
      iconSrc = MINI_APP === Platform.VK ? PawIcon : DiamondIcon
      break
    case 'soft':
      iconSrc = CoinIcon
      break
    case 'essence':
      iconSrc = `https://frontend.gattogame.site/uploads/essences/exp_big.png`
      break
    case 'egg':
      iconSrc = `https://frontend.gattogame.site/uploads/eggs/desert_legendary.png`
      break
    default:
      break
  }

  return (
    <Container stretched={stretched} hasType={hasType}>
      <CoinsNumberContainer>
        {children}
        <Coin src={iconSrc} />
      </CoinsNumberContainer>
    </Container>
  )
}

export default CoinsButton
