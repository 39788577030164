import { FC } from 'react'

import { Wallet } from 'components/base'
import { News, PageContainer, PageHeader } from 'components/page'
import { HeaderText } from 'components/typography'

const page = (
  <PageContainer tabbarPadding={true}>
    <PageHeader over={<Wallet mode="horizontal" />}>
      <HeaderText>Новости</HeaderText>
    </PageHeader>
    <News />
  </PageContainer>
)

export const NewsPage: FC = () => {
  return page
}

NewsPage.displayName = 'NewsPage'
