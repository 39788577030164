import styled from '@emotion/styled'

import InfoCircleImage from 'assets/svg/popouts/info-circle.svg'

const Container = styled.div`
  display: flex;
  padding: 7px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  align-self: stretch;
  border-radius: 13px;
  background: rgba(0, 0, 0, 0.02);
`

const Text = styled.div`
  color: #888888;
  leading-trim: both;
  text-edge: cap;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 11.103px; /* 92.523% */
`

const InfoImageContainer = styled.div`
  display: flex;
  padding: 5px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.05);
`

const InfoImage = styled.img`
  width: 18px;
  height: 18px;
`

type InfoBlockProps = {
  text: string
}

const InfoBlock: React.FC<InfoBlockProps> = ({ text }) => {
  return (
    <Container>
      <InfoImageContainer>
        <InfoImage src={InfoCircleImage} />
      </InfoImageContainer>
      <Text>{text}</Text>
    </Container>
  )
}

export default InfoBlock
