import { FC, useEffect, useState } from 'react'
import styled from '@emotion/styled'

import timerIcon from 'assets/svg/lobby/timer.svg'
import { ProgressBar } from 'components/base'

const TimerIcon = styled.img`
  margin-right: 5px;
`

type TimerProps = {
  start: number
  end: number
  reverse?: boolean
  barUpdateTime?: number
  color?: string
}

export const getReadableTimeFromMs = (time: number): string => {
  const minutes = Math.max(Math.floor(time / 60_000), 0)
  const seconds = Math.max(Math.floor(time / 1000 - minutes * 60), 0)

  let secondsText = `${seconds}`
  if (secondsText.length < 2) secondsText = '0' + secondsText

  return `${minutes}:${secondsText}`
}

export const Timer: FC<TimerProps> = ({
  start,
  end,
  reverse,
  barUpdateTime,
  color,
}) => {
  const [percentage, setPercentage] = useState(0)
  const [text, setText] = useState(getReadableTimeFromMs(0))
  const intervalTime = Math.min(Math.max(barUpdateTime ?? 1000, 100), 10000) // >100ms & <10s. Default: 1s

  function updateTimer() {
    const now = Date.now() - +start + 1000
    const fin = +end - +start
    let out = Math.round((now / fin) * 100)

    if (reverse) out = 100 - out

    if (out > 100 || (out as number) < 0) {
      setPercentage(reverse ? 0 : 100)
      setText(
        reverse ? getReadableTimeFromMs(0) : getReadableTimeFromMs(end - start),
      )
    } else {
      setPercentage(out)
      setText(getReadableTimeFromMs(+end - Date.now()))
    }
  }

  useEffect(() => {
    updateTimer()
    const timer = setInterval(() => {
      updateTimer()
    }, intervalTime)

    return () => clearInterval(timer)
  }, [end])

  return (
    <ProgressBar
      progressWidth="auto"
      progressHeight="40px"
      progressPercentage={percentage}
      backgroundColor="rgba(255, 255, 255, 0.1)"
      progressColor={
        color ?? 'linear-gradient(180deg, #FFAA45 0%, #FF7A33 100%)'
      }
      lineWidth={40}
    >
      <TimerIcon src={timerIcon} />
      {text}
    </ProgressBar>
  )
}
