import { LOCATION_SEARCH } from 'src/config/constants'

import { Platform } from '@gatto/shared'

import { MINI_APP } from '../index'

export const getLocationSearch = (): string => {
  try {
    if (!LOCATION_SEARCH?.includes('access_token')) {
      return localStorage.getItem('token') || ''
    }
    localStorage.setItem('token', LOCATION_SEARCH)
    return LOCATION_SEARCH
  } catch (e) {
    return LOCATION_SEARCH || ''
  }
}

export const getVkUserId = (): string => {
  try {
    if (MINI_APP === Platform.VK) {
      if (!LOCATION_SEARCH?.includes('access_token')) {
        return (
          localStorage.getItem('token')?.split('user_id=')[1]?.split('&')[0] ||
          ''
        )
      }

      return LOCATION_SEARCH.split('user_id=')[1]?.split('&')[0] || ''
    }

    if (MINI_APP === Platform.TG) {
      let token = getLocationSearch()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      token = window.Telegram.WebApp.initData

      const params = new URLSearchParams(token)

      const userParam = params.get('user')

      if (userParam) {
        const userObject = JSON.parse(decodeURIComponent(userParam))

        return userObject.id.toString()
      }
    }
    return LOCATION_SEARCH || ''
  } catch (e) {
    return LOCATION_SEARCH || ''
  }
}
