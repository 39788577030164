import { Spine } from 'pixi-spine'

import { AnimatedGiftProps } from '../types/spine'

export class OpenGiftAnimation extends Spine {
  onEnd?: () => void

  constructor({ texture, cardTextures, onEnd }: AnimatedGiftProps) {
    super(texture)
    this.onEnd = onEnd

    setTimeout(() => {
      this.hackTextureBySlotName(`card_1`, cardTextures[0])
      this.hackTextureBySlotName(`card_2`, cardTextures[1])
      this.hackTextureBySlotName(`card_3`, cardTextures[2])
      this.hackTextureBySlotName(`card_4`, cardTextures[3])
    }, 1000)
  }

  run(): void {
    this.state.setAnimation(0, 'open', false)

    setTimeout(() => {
      if (this.onEnd) {
        this.onEnd()
      }
    }, 4000)
  }
}
