import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface OnboardingState {
  welcome: boolean
  cross: boolean
}

export const initialState: OnboardingState = {
  cross: false,
  welcome: false,
}

export const onboardingSlice = createSlice({
  name: 'selectPet',
  initialState,
  reducers: {
    setOnboarding: (
      state,
      action: PayloadAction<[keyof OnboardingState, boolean]>,
    ) => {
      const [key, value] = action.payload
      console.info(key, value)
      state[key] = value
    },
  },
})

export const { setOnboarding } = onboardingSlice.actions

export default onboardingSlice.reducer
