import React, { FC, useState } from 'react'
import { Popouts } from 'const/router'

import { Popout, StatusType } from 'components/base/Popout'

import StatusAlertContent from './StatusAlertContent'

type StatusAlertProps = Popouts['statusAlert']

// компонент для статуса
// используется в том случае, если не надо добавлять уникальную логику в попаут статуса

const StatusAlert: FC<StatusAlertProps> = ({
  type,
  header,
  subheader,
  icon,
  headerFontSize,
  navigatePath,
  width = '330px',
}) => {
  const [status, setStatus] = useState<StatusType>()

  return (
    <Popout
      type="default"
      background={true}
      width={width}
      isFullScreen={false}
      status={status}
      setStatus={setStatus}
      navigatePath={navigatePath}
    >
      <StatusAlertContent
        type={type}
        header={header}
        subheader={subheader}
        icon={icon}
        headerFontSize={headerFontSize}
      />
    </Popout>
  )
}

export default StatusAlert
