import React, { FC, useState } from 'react'
import Modal from 'components/modals/Modal'
import { LOCALES } from '@gatto/locales'
import { useTranslation } from 'react-i18next'
import { Popouts } from 'const/router'
import { useLocation, useNavigate } from 'react-router-dom'
import StatusAlert from 'popouts/Status/StatusAlertContent'
import { Button, Popout, StatusType } from 'components/base'
import { ButtonContainer } from 'popouts/TonExchange/TonAccept'
import { RootState, useAppDispatch, useAppSelector } from 'store'
import { makeRequest } from 'utils/api'
import { decrementBalance, updateUserRegion } from 'store/user'
import { updateRegionEgg } from 'store/region'
import bridge, { EAdsFormats } from '@vkontakte/vk-bridge'
import { MINI_APP } from '../../index'
import { Platform } from '@gatto/shared'
import { useNow } from '../../hooks/useNow'
import { usePopout } from '../../hooks'

type WarnHatchModalProps = Popouts['eggHatching']

const WarnEggHatchingPopout: FC<WarnHatchModalProps> = ({ egg, region }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [status, setStatus] = useState<StatusType>()
  const now = useNow(1000)
  const dispatch = useAppDispatch()

  const successPopout = usePopout('statusAlert', true, {
    type: 'success',
    subheader: t(LOCALES.exchange_success),
  })

  const showAds = async () => {
    try {
      const response = await bridge.send('VKWebAppShowNativeAds', {
        ad_format: EAdsFormats.REWARD,
      })
      return response.result
    } catch (err) {
      return false
    }
  }

  const hatchingTimeout = egg?.hatching?.hatchingTimeout || 0
  const timeLeft = hatchingTimeout - now

  const price = +(
    MINI_APP === Platform.VK
      ? Math.ceil(timeLeft / 60 / 60 / 1000) * 5
      : Math.ceil(timeLeft / 60 / 60 / 1000) * 0.05
  ).toFixed(3)

  const handleSkipClick = async (payed: boolean) => {
    try {
      if (!payed) {
        await showAds()
      }

      const response = await makeRequest('egg.skipHatchingTime', {
        id: egg._id.toString(),
        payed,
      })

      dispatch(
        updateRegionEgg({
          egg: response,
        }),
      )

      if (payed) {
        dispatch(decrementBalance({ hard: price }))
        dispatch(updateUserRegion({ egg: response, regionId: region._id }))
      }
    } catch (err) {
      /* empty */
    }
  }

  return (
    <Popout
      type="default"
      background={true}
      width="330px"
      isFullScreen={false}
      status={status}
      setStatus={setStatus}
      navigatePath={`region/${region?._id}`}
    >
      <StatusAlert
        header={`${t(LOCALES.warn_hatching_egg)}`}
        subheader={t(LOCALES.lobbyKickUserSubheader)}
        type="error"
      >
        <ButtonContainer>
          <Button
            variant={'dangerous'}
            width="155px"
            style={{ margin: '5px' }}
            onClick={() => {
              navigate(`region/${region._id}`, { replace: true })
            }}
          >
            {t(LOCALES.no)}
          </Button>
          <Button
            variant={'success'}
            style={{ margin: '5px' }}
            width="155px"
            onClick={async () => {
              navigate(`region/${region._id}`, { replace: true })
              await handleSkipClick(true)
            }}
          >
            {t(LOCALES.yes)}
          </Button>
        </ButtonContainer>
      </StatusAlert>
    </Popout>
  )
}

export default WarnEggHatchingPopout
