import React from 'react'
import styled from '@emotion/styled'
import designTokens from 'src/config/designTokens'

const Root = styled.span<{
  theme?: typeof designTokens
  color: ModalChanceProps['color']
}>`
  width: fit-content;
  height: auto;
  padding: 10px;

  display: flex;
  align-items: center;
  gap: 5px;

  font-family: Nunito;
  font-size: 13px;
  font-weight: 600;

  border-radius: 10px;
  color: ${({ theme }) => theme.color['black-100']};
  background: linear-gradient(
      180deg,
      ${({ theme }) => theme.color['white-80']} 0%,
      ${({ theme }) => theme.color['white-80']} 100%
    ),
    ${({ theme, color }) => theme.gradient[color]};
`

const Rarity = styled.span<{
  theme?: typeof designTokens
  color: ModalChanceProps['color']
}>`
  aspect-ratio: 1 / 1;
  width: 30px;
  height: 30px;
  padding: 0px 5px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ theme, color }) => theme.gradient[color]};
  color: ${({ theme }) => theme.color['white-100']};
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.color['white-32']} inww;
  font-size: 9px;
`

type ModalChanceProps = {
  color: keyof typeof designTokens.gradient
  percent: number
  children: React.ReactNode
}

/**
 * Компонент шансов в модальном окне
 * @param {ModalChanceProps} props
 * @returns {React.ReactElement} Компонент шансов в модальном окне
 */
function ModalChance({
  color,
  percent,
  children,
}: ModalChanceProps): React.ReactElement {
  return (
    <Root color={color}>
      <Rarity color={color}>{percent}%</Rarity>
      {children}
    </Root>
  )
}

export default ModalChance
