import {
  AgilityIcon,
  FlyIcon,
  StaminaIcon,
  StrengthIcon,
  SwimIcon,
} from 'src/config/assets'

import { PetChars as IPetChars } from '@gatto/shared'

type PetCharacteristic = {
  icon: typeof AgilityIcon
  type: keyof IPetChars
}
export const PET_CHARS: PetCharacteristic[] = [
  { icon: StrengthIcon, type: 'strength' },
  { icon: StaminaIcon, type: 'stamina' },
  { icon: AgilityIcon, type: 'agility' },
  { icon: SwimIcon, type: 'swim' },
  { icon: FlyIcon, type: 'fly' },
]
