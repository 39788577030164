import { FC, useRef } from 'react'
import { _ReactPixi, Sprite, useTick } from '@pixi/react'
import * as PIXI from 'pixi.js'

import rays from 'assets/svg/egg/rays.svg'

interface EntityRaysProps extends _ReactPixi.ISprite {
  isInvert?: boolean
}

/**
 * Компонент лучей для яйца
 * @component
 */
export const EntityRays: FC<EntityRaysProps> = ({ isInvert, ...props }) => {
  const rayRef = useRef<PIXI.Sprite>(null)

  useTick(() => {
    if (rayRef.current) {
      if (isInvert) {
        rayRef.current.rotation -= 0.005
      } else {
        rayRef.current.rotation += 0.005
      }
    }
  })

  return <Sprite {...props} ref={rayRef} anchor={0.5} image={rays} />
}
