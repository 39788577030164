import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { NFT_ARTICLE_LINK } from 'src/config/constants'
import { Popouts } from 'src/const/router'
import { openUrl } from 'utils/openURL'

import { Popout, StatusType } from 'components/base/Popout'

import { LOCALES } from '@gatto/locales'

import StorageContent from './StorageContent'

export type EggPopoutProps = Popouts['nft']

const NFTPopout: FC<EggPopoutProps> = ({ image, name }) => {
  const { t } = useTranslation()
  const [status, setStatus] = useState<StatusType>()
  const navigate = useNavigate()

  const [isBack, setIsBack] = useState(false)

  useEffect(() => {
    const handlePopstate = () => {
      navigate('/storage', { replace: true })
      setIsBack(true)
    }

    window.addEventListener('popstate', handlePopstate)

    return () => {
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [])

  return (
    <Popout
      type="default"
      background={true}
      width="315px"
      isFullScreen={false}
      status={status}
      setStatus={setStatus}
      navigatePath={isBack ? '/storage' : undefined}
    >
      <StorageContent
        photoUrl={image}
        header={name}
        subheader={t(LOCALES.unMintText)}
        buttonText={t(LOCALES.unMint)}
        handleClick={() => openUrl(NFT_ARTICLE_LINK)}
        category={'nft'}
      />
    </Popout>
  )
}

export default NFTPopout
