import { Link } from 'react-router-dom'
import styled from '@emotion/styled'

import { type LinkType } from './Tabbar'
import TabbarIcon, { type IconType } from './TabbarIcon'

const Tab = styled(Link)`
  width: 100%;
  height: auto;
  padding-top: 18px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 7px;

  text-decoration: none;

  :focus,
  :active {
    transition: transform 120ms ease-out;
    outline: none !important;
  }
`

const Text = styled.span<{ color: React.CSSProperties['color'] }>`
  font: 600 13px/22px 'Nunito', sans-serif;
  white-space: nowrap;
  text-align: center;

  color: ${(props) => props.color};
  transition: all 0.1s;
`

type BaseTabProps = {
  /**
   * Путь для перехода
   */
  destination: LinkType

  /**
   * Иконка элемента таббара
   */
  icon: IconType

  /**
   * Цвет элемента таббара
   */
  color: React.CSSProperties['color']

  /**
   * Выбран ли данная вкладка
   */
  isSelected: boolean

  /**
   * Заголовок вкладки таббара
   */
  children: string
}

type TabProps = React.PropsWithRef<
  BaseTabProps &
    Omit<
      React.DetailedHTMLProps<
        React.AnchorHTMLAttributes<HTMLAnchorElement>,
        HTMLAnchorElement
      >,
      keyof BaseTabProps
    >
>

/**
 * Вкладка таббара
 */
function TabbarItem({
  destination,
  icon,
  isSelected,
  color,
  children,
  ...props
}: TabProps): React.ReactElement {
  return (
    <Tab data-link={destination} to={destination} {...props}>
      <TabbarIcon icon={icon} active={isSelected} />
      <Text color={color}>{children}</Text>
    </Tab>
  )
}

export default TabbarItem
