import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import { Popout, StatusType } from 'components/base/Popout'

import { LOCALES } from '@gatto/locales'

const Main = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: white;
`

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

const Text = styled.div`
  > span {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 11.103px; /* 61.682% */
  }
`

const Header = styled.div`
  > span {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 11.103px; /* 61.682% */
  }
`

const Error404 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 68px;
  > h1 {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: Nunito;
    font-size: 96px;
    font-style: normal;
    font-weight: 700;
    line-height: 11.103px; /* 11.565% */
  }
`

const ErrorNetworkPopout: FC = () => {
  const { t } = useTranslation()
  const [status, setStatus] = useState<StatusType>()

  return (
    <Popout
      isFullScreen={true}
      background={true}
      type="default"
      status={status}
      setStatus={setStatus}
    >
      <Main>
        <Container>
          <Header>
            <span>{t(LOCALES.error)}</span>
          </Header>
          <Error404>
            <h1>404</h1>
          </Error404>
          <Text>
            <span>{t(LOCALES.NoInternet)}</span>
          </Text>
        </Container>
      </Main>
    </Popout>
  )
}

export default ErrorNetworkPopout
