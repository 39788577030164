import { BaseOlympicGame } from 'src/pixi/classes'

import { GameEndedEvent } from '@gatto/engine'

export function startRun(this: BaseOlympicGame): void {
  console.info(
    'START RUN ',
    Date.now(),
    this.startGameAt,
    (this.startGameAt || 0) - Date.now(),
  )
  this.renderFunction()
}

export function finishRun(this: BaseOlympicGame, data: GameEndedEvent): void {
  this.cancelRenderLoop()
  this.finishGame(data)
}
