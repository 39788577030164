import { useLocation } from 'react-router-dom'
import { Params, Popouts } from 'const/router'

// хук для извлечения GET параметров из URL страницы
type useGetPopoutParamResponse = {
  popoutName: keyof Popouts | null
}

const useGetPopoutParam = (name: Params): useGetPopoutParamResponse => {
  const { search } = useLocation()
  const query = new URLSearchParams(search)

  const popoutName = query.get(name) as keyof Popouts | null

  return {
    popoutName,
  }
}

export default useGetPopoutParam
