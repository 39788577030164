import React from 'react'

/**
 * Функция для создания дуги
 *
 * @param center - координаты центра
 * @param radius - радиус дуги
 * @param startAngle - начальный угол
 * @param endAngle - конечный угол
 * @returns SVG path
 */

function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number,
) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  }
}

function describeArc(
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number,
) {
  const start = polarToCartesian(x, y, radius, endAngle)
  const end = polarToCartesian(x, y, radius, startAngle)

  const arcSweep = endAngle - startAngle <= 180 ? '0' : '1'

  const d = [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    arcSweep,
    0,
    end.x,
    end.y,
    'L',
    x,
    y,
    'L',
    start.x,
    start.y,
  ].join(' ')

  return d
}

type Props = {
  width: number
  height: number

  /**
   * ID для clipPath
   */
  clipPathID: string

  /**
   * Прогресс заполнения от 0 до 1
   */
  progress: number

  /**
   * Начальный прогресс
   * @default 0
   */
  startProgress?: number

  /**
   * Начальный угол
   * @default 180
   */
  startAngle?: number
}

/**
 * Компонент для отображения прогресса в виде полукруга
 * @returns Прогресс в виде полукруга
 */

const ProgressArc: React.FC<Props> = ({
  height,
  width,
  progress,
  clipPathID,
  startProgress = 0,
  startAngle = 180,
}) => {
  return (
    <clipPath id={clipPathID}>
      <path
        fill="white"
        d={describeArc(
          width / 2,
          height / 2,
          width * 2,
          startAngle + startProgress * 360,
          startAngle + startProgress * 360 + Math.min(progress, 0.999999) * 360,
        )}
      />
    </clipPath>
  )
}

export default ProgressArc
