import styled from '@emotion/styled'

import hoodTabIcon from 'assets/png/tabbar/hood.png'
import hoodClickTabIcon from 'assets/png/tabbar/hoodClick.png'
import mapTabIcon from 'assets/png/tabbar/map.png'
import mapClickTabIcon from 'assets/png/tabbar/mapClick.png'
import newsTabIcon from 'assets/png/tabbar/news.png'
import newsClickTabIcon from 'assets/png/tabbar/newsClick.png'

const Icon = styled.img`
  width: 52px;
  height: 55px;
`

type IconType = 'hood' | 'map' | 'news'

type BaseTabbarIconProps = {
  /**
   * Иконка таббара
   */
  icon: IconType

  /**
   * Активна ли иконка
   * @default false
   */
  active?: boolean
}

type TabbarIconProps = BaseTabbarIconProps &
  Omit<
    React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >,
    keyof BaseTabbarIconProps
  >

/**
 * Тип иконок таббара
 * @param {string} default - Иконка по умолчанию
 * @param {string} click - Иконка при нажатии
 */
type IconsType = {
  [key in TabbarIconProps['icon']]: {
    default: string
    click: string
  }
}

/**
 * Ассеты иконок таббара
 * @constant
 */
const icons: IconsType = {
  hood: {
    default: hoodTabIcon,
    click: hoodClickTabIcon,
  },
  map: {
    default: mapTabIcon,
    click: mapClickTabIcon,
  },
  news: {
    default: newsTabIcon,
    click: newsClickTabIcon,
  },
}

/**
 * Иконка таббара
 */
function TabbarIcon({
  icon,
  active,
  ...props
}: TabbarIconProps): React.ReactElement {
  const { default: defaultIcon, click: clickIcon } = icons[icon]
  return <Icon src={active ? clickIcon : defaultIcon} {...props} />
}

export default TabbarIcon
export type { IconType }
