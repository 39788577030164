import React from 'react'
import { Application as PixiApplication } from '@pixi/app'
import { PixiComponent, useApp } from '@pixi/react'
import { IViewportOptions, Viewport as BaseViewport } from 'pixi-viewport'

type ViewportComponentProps = {
  /**
   * Приложение
   */
  app: PixiApplication
} & Partial<IViewportOptions>
type ViewportProps = React.PropsWithChildren<
  Omit<ViewportComponentProps, 'app'>
>

/**
 * Компонент для отображения вьюпорта
 * @returns Вьюпорт
 */
const ViewportComponent = PixiComponent('Viewport', {
  create: (props: ViewportComponentProps) => {
    const { app, ...viewportOptions } = props
    const { ticker } = app
    const { events } = app.renderer

    const viewport = new BaseViewport({
      ...viewportOptions,
      ticker: ticker,
      events: events,
    })

    viewport
      .drag()
      .pinch()
      .wheel({ smooth: 10 })
      .clamp({ direction: 'all' })
      .clampZoom({ minScale: 1, maxScale: 3 })
      .decelerate({
        friction: 0.9, // percent to decelerate after movement
      })

    if (props.worldWidth && props.worldHeight) {
      viewport.moveCenter(props.worldWidth / 2, props.worldHeight / 2)
    }

    return viewport
  },
})

export const Viewport = React.forwardRef<BaseViewport, ViewportProps>(
  (props, ref) => <ViewportComponent {...props} ref={ref} app={useApp()} />,
)

Viewport.displayName = 'Viewport'
