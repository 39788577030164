import { FC } from 'react'
import styled from '@emotion/styled'

export const TabbarContainer = styled.div`
  display: flex;
  align-items: start;
  width: 100%;
  text-align: center;
  border: none;
  overflow-x: auto;
  white-space: nowrap;
`

export const TabbarItem = styled.div<{ active: boolean }>`
  width: 100%;
  padding: 6px 20px;
  border: none;
  border-radius: 13px 13px 0 0;
  cursor: pointer;
  backdrop-filter: blur(10px);
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin: 0 -0.01em;
  box-shadow: unset -50px -1px white;

  background-color: ${(props) =>
    props.active ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.60)'};
  ${(props) => (props.active ? 'z-index: 1;' : '')}
`

export interface TabItem {
  name: string
  type: string
}

export type TabsComponentProps = {
  tabItems: TabItem[]
  handleTabClick: (item: TabItem) => void
  activeTab: string | null
}

export const TabsComponent: FC<TabsComponentProps> = ({
  tabItems,
  handleTabClick,
  activeTab,
}) => {
  return (
    <>
      {tabItems && tabItems.length && (
        <TabbarContainer>
          {tabItems.map((item: TabItem) => (
            <TabbarItem
              key={item.type}
              active={activeTab === item.type}
              onClick={() => handleTabClick(item)}
            >
              {item.name}
            </TabbarItem>
          ))}
        </TabbarContainer>
      )}
    </>
  )
}
