import TransformPetToNFTModal from 'modals/TransformPetToNFT/TransfromPetToNFTModal'
import WalletChooseModal from 'modals/WalletChoose/WalletChooseModal'
import WarnTransformModal from 'modals/WarnTransform/WarnTransformModal'
import BiomsPopout from 'popouts/Bioms/BiomsPopout'
import BonusPopout from 'popouts/Bonus/BonusPopout'
import EggsAndPets from 'popouts/EggsAndPets/EggsAndPetsPopout'
import ErrorNetworkPopout from 'popouts/ErrorNetwork/ErrorNetworkPopout'
import LeagueInfoPopout from 'popouts/LeagueInfo/LeagueInfoPopout'
import LoadPopout from 'popouts/Load/LoadPopout'
import LobbyKick from 'popouts/Lobby/LobbyKick'
import LobbyLeave from 'popouts/Lobby/LobbyLeave'
import MintingStarted from 'popouts/Minting/MintingStartedPopout'
import NotificationsAccess from 'popouts/NotificationsAccess/NotificationAccessPopout'
import PetCrossPopout from 'popouts/PetCross/PetCrossPopout'
import PetInfoPopout from 'popouts/PetInfo/PetInfoPopout'
import PetNamePopout from 'popouts/PetName/PetNamePopout'
import PetToStoragePopout from 'popouts/PetToStorage/PetToStoragePopout'
import PromoInputPopout from 'popouts/Promos/PromoInputPopout'
import PromoSuccessCoinsPopout from 'popouts/Promos/PromoSuccessCoinsPopout'
import PromoSuccessPopout from 'popouts/Promos/PromoSuccessPopout'
import PromoWasActivatedPopout from 'popouts/Promos/PromoWasActivatedPopout'
import ReferralSystemPopout from 'popouts/ReferralSystem/ReferralSystemPopout'
import Settings from 'popouts/Settings/SettingsPopout'
import SkinsPopout, { RarityColors } from 'popouts/Skins/SkinsPopout'
import StatusAlert from 'popouts/Status/StatusAlert'
import EggPopout from 'popouts/StoragePopout/EggPopout'
import EssencePopout from 'popouts/StoragePopout/essence/EssencePopout'
import EssenceSuccessPopout from 'popouts/StoragePopout/essence/EssenceSuccessPopout'
import NFTPopout from 'popouts/StoragePopout/NFTPopout'
import SkinPopout from 'popouts/StoragePopout/SkinPopout'
import StoreInfoPopout from 'popouts/StorePopout/StoreInfoPopout'
import TonAcceptPopout from 'popouts/TonExchange/TonAccept'
import TonExchangePopout from 'popouts/TonExchange/TonExchangePopout'
import WalletExist from 'popouts/TransformingNFT/WalletExistPopout'
import WalletNotExist from 'popouts/TransformingNFT/WalletNotExistPopout'
import { OnboardingListNames } from 'src/config/onboardings'

import OnBoardingModal from 'components/OnBoarding/OnBoardingModal'

import {
  BasePet,
  CurrencyType,
  DonateItemType,
  EggItemType,
  EssenceItemType,
  GameLeague,
  ItemType,
  PetChars,
  PetFeelsAliases,
  PetSkins,
  PopulatedEgg,
  PopulatedPet,
  PopulatedPetWithSkins,
  PopulatedUserRegion,
  SkinItemType,
  SkinRarity,
  WaitLobbyUserInfo,
  WarehouseItems,
} from '@gatto/shared'
import WarnEggHatchingPopout from 'popouts/WarnEggHatching/WarnEggHatchingPopout'

export type Params = 'popout' | 'notification-id' | 'fullscreen' | 'modal'

export type Popouts = {
  skinsPopout: SkinsPopout
  eggsAndPets: EggsAndPetsPopout
  promoInput: PromoInputPopout
  mintingStarted: MintingStartedPopout
  walletNotExist: WalletNotExistPopout
  networkError: NetworkErrorPopout
  petInfo: PetInfoPopout
  store: StorePopout
  egg: EggPopout
  essence: EssencePopout
  pet: PetPopout
  nft: NFTPopout
  promoSuccess: PromoSuccessPopout
  promoSuccessValue: PromoSuccessValuePopout
  promoWasActivated: PromoWasActivatedPopout
  settingsModal: SettingsPopout
  petsCross: PetsCrossPopout
  bioms: BiomsPopout
  walletExist: WalletExistPopout
  petName: PetNamePopout
  notificationsAccess: NotificationAccessPopout
  essenceSuccessAlert: EssenceSuccessAlertPopout
  statusAlert: StatusAlertPopout
  petToStorage: PetToStoragePopout
  loading: LoadingPopout
  skin: SkinPopout
  leagueInfo: LeagueInfoPopout
  lobbyLeave: LobbyLeavePopout
  lobbyKick: LobbyKickPopout
  bonus: BonusPopout
  referralSystem: ReferralSystemPopout
  tonExchange: TonExchangePopout
  tonAccept: TonAcceptPopout
  eggHatching: WarnEggHatchPopout
}

type LeagueInfoPopout = {
  league: GameLeague
}

type ReferralSystemPopout = {}
type TonExchangePopout = {}
type TonAcceptPopout = { amount: string }
type BonusPopout = {}
type SkinsPopout = {}
type EggsAndPetsPopout = { region: PopulatedUserRegion }
type PromoInputPopout = {}
type MintingStartedPopout = {}
type WalletNotExistPopout = {}
type NetworkErrorPopout = {}
type PetInfoPopout = {
  basePet?: BasePet
  owner?: string
  avatar?: string
  petId?: string
  localId?: string
  petChars?: PetChars
  petLevel?: number
  petName?: string
  petEvolution?: number
  buttonText?: string
  skins?: PetSkins[]
  petBiome?: string
}
type StorePopout = {
  id: string
  name: string
  price: number
  image: string
  description: string
  currency: CurrencyType
  goodId: string
  itemType:
    | ItemType
    | EssenceItemType
    | DonateItemType
    | SkinItemType
    | EggItemType
  rarity?: SkinRarity
}
type EggPopout = {
  id: string
  localId: string
  type: WarehouseItems | string
  biom: string
  evolution: number
  isInStorage?: boolean
}
type EssencePopout = { id: string; type: WarehouseItems | string }
type PetPopout = {
  basePet?: BasePet
  owner?: string
  avatar?: string
  petId?: string
  localId?: string
  petChars?: PetChars
  petLevel?: number
  petName?: string
  petEvolution?: number
  buttonText?: string
  skins?: PetSkins[]
  petBiome?: string
}
type NFTPopout = { image: string; name: string }
type PromoSuccessPopout = {}
type PromoSuccessValuePopout = { type: string; value: number }
type PromoWasActivatedPopout = { subheader?: string }
type SettingsPopout = {}
type PetsCrossPopout = {
  firstPet?: PopulatedPetWithSkins
  secondPet?: PopulatedPetWithSkins
  firstPetID: string
  secondPetID: string
}
type BiomsPopout = {}
type WalletExistPopout = {}
type PetNamePopout = {}
type NotificationAccessPopout = { feel: PetFeelsAliases | 'prize' }
type EssenceSuccessAlertPopout = {}
export type StatusAlertPopout = {
  type: 'success' | 'error' | 'timeout' | 'info' | 'custom'
  header?: string
  subheader?: string
  icon?: string
  headerFontSize?: number
  navigatePath?: string
  width?: string
}
type LobbyLeavePopout = {
  width?: string
  lobbyId?: string
}
type LobbyKickPopout = {
  width?: string
  user?: WaitLobbyUserInfo
  lobbyId?: string
}

export type WarnEggHatchPopout = {
  egg: PopulatedEgg
  region: PopulatedUserRegion
}

type PetToStoragePopout = { petId: string | undefined }
type LoadingPopout = { assets: string[]; redirect?: string; state?: unknown }
type SkinPopout = {
  name: string
  description: string
  image: string
  rarity: keyof typeof RarityColors
  id: string
}

export const POPOUTS: {
  [key in keyof Popouts]: React.FC<Popouts[key]>
} = {
  statusAlert: StatusAlert,
  egg: EggPopout,
  essence: EssencePopout,
  // Дублирование попаута petInfo из-за особенностей работы storage
  pet: PetInfoPopout,
  nft: NFTPopout,
  petInfo: PetInfoPopout,
  skinsPopout: SkinsPopout,
  store: StoreInfoPopout,
  essenceSuccessAlert: EssenceSuccessPopout,
  promoInput: PromoInputPopout,
  promoSuccess: PromoSuccessPopout,
  promoSuccessValue: PromoSuccessCoinsPopout,
  promoWasActivated: PromoWasActivatedPopout,
  settingsModal: Settings,
  eggsAndPets: EggsAndPets,
  petsCross: PetCrossPopout,
  bioms: BiomsPopout,
  walletExist: WalletExist,
  walletNotExist: WalletNotExist,
  petName: PetNamePopout,
  notificationsAccess: NotificationsAccess,
  networkError: ErrorNetworkPopout,
  petToStorage: PetToStoragePopout,
  mintingStarted: MintingStarted,
  loading: LoadPopout,
  skin: SkinPopout,
  lobbyLeave: LobbyLeave,
  lobbyKick: LobbyKick,
  leagueInfo: LeagueInfoPopout,
  bonus: BonusPopout,
  referralSystem: ReferralSystemPopout,
  tonExchange: TonExchangePopout,
  tonAccept: TonAcceptPopout,
  eggHatching: WarnEggHatchingPopout,
}

export type Modals = {
  transformPetToNFT: TransformPetToNFTPopout
  walletChoose: WalletChoosePopout
  warnTransform: WarnTransformPopout
  onBoarding: OnBoardingPopout
}

type TransformPetToNFTPopout = {
  walletId?: string
  petId?: string
  pet?: PopulatedPet
}
type WalletChoosePopout = {
  petId?: string
  type: 'minting' | 'exchange'
  text: string
}
type WarnTransformPopout = {
  walletId?: string
  petId?: string
  pet?: PopulatedPet
}
export type OnBoardingPopout = { sheetName: OnboardingListNames }

export type ModalsProps = {
  [K in keyof Modals]: Modals[K] & { setBackground: () => void }
}

export const MODALS: {
  [key in keyof Modals]: React.FC<Modals[key] & { setBackground: () => void }>
} = {
  transformPetToNFT: TransformPetToNFTModal,
  walletChoose: WalletChooseModal,
  warnTransform: WarnTransformModal,
  onBoarding: OnBoardingModal,
}
