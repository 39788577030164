import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { PET_LEVEL_HEIGHT, PET_LEVEL_WIDTH } from 'src/config/constants'

import { ProgressArc } from 'components/base'

import { LOCALES } from '@gatto/locales'

import PetLevelRatio from './PetLevelRatio'

const Root = styled.div({
  color: '#FFF500',
  position: 'relative',
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const Container = styled.div({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Nunito',
  fontWeight: 900,
  color: 'white',
})

const Subtitle = styled.span({
  fontSize: 9,
  lineHeight: '9px',
  textTransform: 'uppercase',
})

const Badge = styled.div({
  position: 'absolute',
  borderRadius: 100,
  backgroundColor: '#FCCE3F',
  padding: '5px 7px',
  top: '-17px',
  color: '#000',
  textAlign: 'center',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: 'Nunito',
  fontSize: 10,
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '8px',
})

const Bottom = styled.span({
  fontSize: 8,
})

type PetLevelProps = {
  level: number
  levelRatio?: string | number
  progress: number
  isProgressShown: boolean
} & React.HTMLAttributes<HTMLDivElement>

/**
 * Отображает SVG с заполнением уровня
 * @param props.progress Прогресс заполнения уровня от 0 до 1
 */
const PetLevel: React.FC<PetLevelProps> = ({
  levelRatio,
  isProgressShown,
  level,
  progress,
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <Root {...props}>
      {isProgressShown && (
        <Badge>
          <div>{level}</div>
          <Bottom>{t(LOCALES.lvl)}</Bottom>
        </Badge>
      )}
      <svg
        width={PET_LEVEL_WIDTH}
        height={PET_LEVEL_HEIGHT}
        viewBox={`0 0 ${PET_LEVEL_WIDTH} ${PET_LEVEL_HEIGHT}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.9423 3.29553C23.9902 -1.09851 32.1797 -1.09851 38.2275 3.29554L49.0557 11.1626C55.1035 15.5567 57.6342 23.3453 55.3241 30.455L51.1882 43.1842C48.8781 50.2939 42.2527 55.1076 34.7771 55.1076H21.3928C13.9172 55.1076 7.29178 50.2939 4.9817 43.1842L0.845728 30.455C-1.46436 23.3453 1.06632 15.5567 7.11421 11.1626L17.9423 3.29553Z"
          fill="black"
          fillOpacity="0.16"
        />
        <path
          d="M37.9337 3.70004L48.7618 11.5671C54.6344 15.8339 57.0918 23.3968 54.8486 30.3005L50.7126 43.0297C48.4695 49.9334 42.0361 54.6076 34.7771 54.6076H21.3928C14.1338 54.6076 7.70038 49.9334 5.45723 43.0297L1.32126 30.3005C-0.921893 23.3968 1.53546 15.8339 7.4081 11.5671L18.2362 3.70004C24.1089 -0.566683 32.061 -0.56668 37.9337 3.70004Z"
          stroke="white"
          strokeOpacity="0.1"
        />
        <path
          clipPath="url(#pet-level-mask)"
          d="M37.3459 4.50906L48.174 12.3761C53.6961 16.3882 56.0068 23.4998 53.8975 29.9915L49.7616 42.7207C47.6523 49.2124 41.6028 53.6076 34.7771 53.6076H21.3928C14.567 53.6076 8.51756 49.2124 6.40828 42.7207L2.27231 29.9915C0.163038 23.4998 2.47373 16.3882 7.99589 12.3761L18.824 4.50906C24.3462 0.496979 31.8237 0.496981 37.3459 4.50906Z"
          stroke="currentColor"
          strokeWidth="3"
        />
        <defs>
          <ProgressArc
            size={{ width: PET_LEVEL_WIDTH, height: PET_LEVEL_HEIGHT }}
            clipPathID="pet-level-mask"
            progress={progress}
            startAngle={0}
          />
        </defs>
      </svg>
      <Container>
        <PetLevelRatio level={levelRatio} />
        {!isProgressShown && <Subtitle>{t(LOCALES.lvl)}</Subtitle>}
      </Container>
    </Root>
  )
}

export default PetLevel
