import React from 'react'
import styled from '@emotion/styled'

const ButtonsRoot = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;
  gap: 10px;

  justify-content: center;
  align-items: center;
`

type ModalButtonsProps = {
  /**
   * Кнопки
   */
  children: React.ReactNode
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

/**
 * Компонент-контейнер для кнопок в модальном окне
 *
 * @returns Контейнер для кнопок
 * @example
 * ```tsx
 * <ModalButtons>
 *   <Button>Foobar</Button>
 * </ModalButtons>
 * ```
 */
function ModalButtons({
  children,
  ...props
}: ModalButtonsProps): React.ReactElement {
  return <ButtonsRoot {...props}>{children}</ButtonsRoot>
}

export default ModalButtons
