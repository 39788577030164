import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Spinner } from '@vkontakte/vkui'
import { usePopout } from 'src/hooks'
import { makeRequest } from 'src/utils/api'
import { fixNames } from 'utils/i18/fixItemTypes'

import emptyPlaceholder from 'assets/icons/empty_placeholder.png'
import eggPlaceholder from 'assets/svg/popouts/egg-placeholder.svg'
import petPlaceholder from 'assets/svg/popouts/pet-placeholder.svg'
import {
  BackButton,
  Categories,
  Placeholder,
  Row,
  Wallet,
} from 'components/base'
import { PageContainer, PageHeader, StorageMenu } from 'components/page'
import { HeaderText } from 'components/typography'
import { useAppDispatch, useAppSelector } from 'store'
import { setIsApplied, setIsTabbarHidden } from 'store/storage'

import { LOCALES } from '@gatto/locales'
import {
  BaseEssence,
  CategoriesType,
  Platform,
  WarehouseGoodsGetRes,
} from '@gatto/shared'
import { MINI_APP } from '../../index'

const StoragePage: FC = () => {
  const { t } = useTranslation()
  const openEssence = usePopout('essenceSuccessAlert', true, {})
  const navigate = useNavigate()
  const [sortBy, setSortBy] = useState<CategoriesType | undefined>(
    CategoriesType.All,
  )
  const [warehouseGoods, setWarehouseGoods] = useState<WarehouseGoodsGetRes>([])
  const [firstLoad, setFirstLoad] = useState(false)
  const [offset, setOffset] = useState<number>(0)
  const [load, setLoad] = useState<boolean>(true)
  const [isShouldWork, setIsShouldWork] = useState<boolean>(true)
  const categories = [
    { name: t(LOCALES.itemTypeAll), category: 'all' },
    { name: t(LOCALES.itemTypeEggs), category: 'eggs' },
    { name: t(LOCALES.itemTypeEssences), category: 'essences' },
    { name: t(LOCALES.itemTypePets), category: 'pets' },
    { name: t(LOCALES.itemTypeSkins), category: 'skins' },
  ]

  if (MINI_APP === Platform.TG) {
    categories.push({ name: 'NFT', category: 'nfts' })
  }

  const dispatch = useAppDispatch()

  const { isApplied } = useAppSelector((state) => state.storage)

  useEffect(() => {
    if (isApplied) {
      openEssence()
      dispatch(setIsApplied(false))
    }
  }, [])

  useEffect(() => {
    dispatch(setIsTabbarHidden(false))
  }, [])

  const prevSortBy = useRef<CategoriesType | undefined>()

  const limit = 8

  const getShopGoods = async (): Promise<void> => {
    if (sortBy === CategoriesType.All) {
      const getEssences = makeRequest('warehouseGoods.getByLimit', {
        type: CategoriesType.Essences,
        limit: limit,
        offset: offset,
      })

      const getEggs = makeRequest('warehouseGoods.getByLimit', {
        type: CategoriesType.Egg,
        limit: limit,
        offset: offset,
      })

      const getPets = makeRequest('warehouseGoods.getByLimit', {
        type: CategoriesType.Pet,
        limit: limit,
        offset: offset,
      })

      const getSkins = makeRequest('warehouseGoods.getByLimit', {
        type: CategoriesType.Skins,
        limit: limit,
        offset: offset,
      })

      Promise.all([getEssences, getEggs, getPets, getSkins])
        .then(([essences, eggs, pets, skins]) => {
          const combinedResults = [
            ...essences,
            ...eggs,
            ...pets,
            ...skins,
          ] as WarehouseGoodsGetRes

          if (combinedResults.length < limit) {
            setIsShouldWork(false)
          }
          if (combinedResults.length === 0) {
            setLoad(false)
          } else {
            setLoad(true)
          }

          if (prevSortBy.current === sortBy) {
            setWarehouseGoods(
              (prev) => [...prev, ...combinedResults] as WarehouseGoodsGetRes,
            )
          } else {
            setWarehouseGoods(combinedResults)
          }

          setOffset((prevOffset) => prevOffset + limit)
          prevSortBy.current = sortBy
        })
        .catch(() => {
          setIsShouldWork(false)
          setWarehouseGoods([])
          prevSortBy.current = sortBy
        })
    } else {
      makeRequest('warehouseGoods.getByLimit', {
        type: sortBy,
        limit: limit,
        offset: offset,
      })
        .then((res) => {
          if (res.length < limit) {
            setIsShouldWork(false)
          }
          if (res.length === 0) {
            setLoad(false)
          } else setLoad(true)

          if (prevSortBy.current === sortBy) {
            setWarehouseGoods(
              (prev) => [...prev, ...res] as WarehouseGoodsGetRes,
            )
          } else {
            setWarehouseGoods(res)
          }
          setOffset((prevOffset) => prevOffset + res.length)
          prevSortBy.current = sortBy
        })
        .catch(() => {
          setIsShouldWork(false)
          setWarehouseGoods([])
          prevSortBy.current = sortBy
        })
    }
  }

  useEffect(() => {
    setFirstLoad(false)
    getShopGoods()
    setFirstLoad(true)
  }, [sortBy])

  return (
    <PageContainer>
      <PageHeader over={<Wallet mode="horizontal" />} before={<BackButton />}>
        <HeaderText>{t(LOCALES.warehouse)}</HeaderText>
      </PageHeader>
      <Row>
        <Categories
          categories={categories}
          setSortBy={setSortBy}
          sortBy={sortBy}
          setOffset={setOffset}
          setIsShouldWork={setIsShouldWork}
          setGoods={setWarehouseGoods}
          setFirstLoad={setFirstLoad}
        />
      </Row>
      {!firstLoad && <Spinner size="large" style={{ margin: '20px 0' }} />}
      {warehouseGoods.length === 0 && !load && (
        <Placeholder
          title={t(LOCALES.nothing)}
          subtitle={
            sortBy === CategoriesType.Pet
              ? t(LOCALES.nothingGoBuyEgg)
              : sortBy === CategoriesType.NFT
              ? t(LOCALES.nothingGoConnectNFT)
              : t(LOCALES.nothingGoBuySomething)
          }
          buttonText={
            sortBy === CategoriesType.NFT ? undefined : t(LOCALES.goShop)
          }
          isFullscreen={true}
          img={
            sortBy === CategoriesType.Pet
              ? petPlaceholder
              : sortBy === CategoriesType.Egg
              ? eggPlaceholder
              : emptyPlaceholder
          }
          onClick={() => {
            let storeSortBy = sortBy
            if (
              sortBy === CategoriesType.Pet ||
              sortBy === CategoriesType.All
            ) {
              storeSortBy = CategoriesType.Egg // Из разделов "питомцы" и "все" переходим в раздел "яйца", так как таких разделов нет в магазине
            }
            navigate('/store?sortBy=' + storeSortBy)
          }}
        />
      )}
      {warehouseGoods && sortBy !== undefined && (
        <>
          <StorageMenu
            items={warehouseGoods}
            sortBy={sortBy}
            limit={limit}
            getShopGoods={getShopGoods}
            isShouldWork={isShouldWork}
          />
        </>
      )}
    </PageContainer>
  )
}

export default StoragePage
