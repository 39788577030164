import { useEffect, useRef } from 'react'

export default function useScroll(
  parentRef: React.RefObject<HTMLDivElement> | null,
  childRef: React.RefObject<HTMLDivElement>,
  limit: number,
  callback: () => void,
  dataCount?: number,
  isShouldWork?: boolean,
): void {
  const observer = useRef<IntersectionObserver | null>(null)

  useEffect(() => {
    const options = {
      root: parentRef?.current,
      rootMargin: '0px 0px 200px 0px',
      threshold: 0,
    }

    observer.current = new IntersectionObserver(([target]) => {
      if (target?.isIntersecting) {
        callback()
      }
    }, options)

    if (childRef.current && dataCount && dataCount >= limit && isShouldWork) {
      observer.current.observe(childRef.current)
      const unobserve = childRef.current

      return function () {
        observer.current?.unobserve(unobserve)
      }
    }

    return
  }, [callback, childRef])
}
