import { Dispatch, FC, SetStateAction, useState } from 'react'
import styled from '@emotion/styled'
import { Card, CardScroll } from '@vkontakte/vkui'

import { CategoriesType } from 'pages/StorePage'

import { GoodsGetRes, WarehouseGoodsGetRes } from '@gatto/shared'

import '@vkontakte/vkui/dist/vkui.css'

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 20px 0;
  .list {
    list-style: none;
    display: flex;
    overflow: auto;
    gap: 10px;
    text-align: center;
    min-height: 3%;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .listItem {
    width: auto;
    height: 42px;
    display: flex;
    white-space: nowrap;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    background: transparent;
    border: none;
    cursor: pointer;
    color: white;
    border-radius: 15px;

    background: rgba(255, 255, 255, 0.1);
    //transition: 0.2s ease-in-out;

    &:hover {
      background: white;
      color: black;
    }

    &[data-active='true'] {
      background: white;
      color: black;
    }

    &[data-active='false'] {
      background: transparent;
      background: rgba(255, 255, 255, 0.1);
      color: white;
    }
  }
`

interface CategoryProps {
  categories: Array<{ name: string; category: string }>
  setSortBy: Dispatch<SetStateAction<CategoriesType | undefined>>
  sortBy: CategoriesType | undefined
  setOffset?: Dispatch<SetStateAction<number>>
  setIsShouldWork?: Dispatch<SetStateAction<boolean>>
  setGoods?: Dispatch<SetStateAction<WarehouseGoodsGetRes>>
  setShopGoods?: Dispatch<SetStateAction<GoodsGetRes>>
  setFirstLoad?: Dispatch<SetStateAction<boolean>>
}

const Categories: FC<CategoryProps> = ({
  categories,
  setSortBy,
  sortBy,
  setOffset,
  setIsShouldWork,
  setGoods,
  setFirstLoad,
  setShopGoods,
}) => {
  const [prevCategory, setPrevCategory] = useState<string>()

  const [isClickDisabled, setIsClickDisabled] = useState(false)

  const handleClick = (category: CategoriesType) => {
    if (category !== prevCategory && category !== undefined) {
      setIsClickDisabled(true)

      if (setFirstLoad) {
        setFirstLoad(false)
      }
      if (setOffset) {
        setOffset(0)
      }
      if (setShopGoods) {
        setShopGoods([])
      }
      if (setGoods) {
        setGoods([])
      }
      if (setIsShouldWork) {
        setIsShouldWork(true)
      }
      setSortBy(category)
      setPrevCategory(category)
      setTimeout(() => {
        setIsClickDisabled(false)
      }, 50)
    }
  }

  const categoriesList = categories.map((it) => {
    return (
      <Card
        key={it.name}
        className={`listItem ${sortBy === it.category ? 'disabled' : ''}`}
        onClick={() => {
          if (sortBy !== it.category) {
            handleClick(it.category as CategoriesType)
          }
        }}
        data-active={
          sortBy === it.category && !isClickDisabled ? 'true' : 'false'
        }
      >
        {it.name}
      </Card>
    )
  })

  return (
    <Container>
      <CardScroll size="s" className="list">
        {categoriesList}
      </CardScroll>
    </Container>
  )
}

export default Categories
