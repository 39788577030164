import * as PIXI from 'pixi.js'
/* eslint-disable no-console */
import { GamePet, RunGame, TilingSprite } from 'src/pixi/classes'
import { TRACKS_NAMES } from 'src/pixi/types/rungame'

import { Sprite } from '../../classes'
import {
  BARRIER_HEIGHT,
  CONTAINER_SCALE_COEF,
  START_LINE_X,
  TRACK_HEIGHT,
  TRACK_WIDTH,
  TRACKS_IN_CONTAINER,
} from '../constants'

/**
 * Отрисовка фона
 */
export function setupBeforeEngine(this: RunGame): void {
  console.log('Setuped before engine ', Date.now())

  const grassTexture = this.getTexture('grass')
  const stadiumTexture = this.getTexture('stadium')
  const standsTexture = this.getTexture('stands')
  const stadiumForwardTexture = this.getTexture('stadiumForward')
  const cloudsTexture = this.getTexture('clouds')

  const gameContainerScale =
    this.clientWidth / (TRACK_WIDTH * CONTAINER_SCALE_COEF)
  console.log(gameContainerScale)

  const cloudsSprite = new TilingSprite(
    cloudsTexture,
    this.clientWidth / gameContainerScale,
    cloudsTexture.orig.height,
    { y: 0 },
  )

  const stadiumSprite = new TilingSprite(
    stadiumTexture,
    this.clientWidth / gameContainerScale,
    stadiumTexture.orig.height,
    { y: 190 },
  )

  const standsSprite = new TilingSprite(
    standsTexture,
    this.clientWidth / gameContainerScale,
    standsTexture.orig.height,
    { y: 225 },
  )

  const stadiumForwardSprite = new TilingSprite(
    stadiumForwardTexture,
    this.clientWidth / gameContainerScale,
    stadiumForwardTexture.orig.height,
    { y: 453 },
  )

  const grassSprite = new TilingSprite(
    grassTexture,
    this.clientWidth / gameContainerScale,
    grassTexture.orig.height,
    { y: 514 },
  )

  this.stadiumContainer.addChild(
    stadiumSprite,
    standsSprite,
    stadiumForwardSprite,
  )

  this.gameContainer.addChild(
    this.stadiumContainer,
    cloudsSprite,
    grassSprite,
    this.trackContainer,
    this.interractiveContainer,
  )

  this.gameContainer.pivot.set(0, this.gameContainer.height)
  this.gameContainer.y = this.clientHeight
  this.gameContainer.scale.set(gameContainerScale)

  this.setupCountdown()

  this.interfaceContainer.sortableChildren = true
  this.stage.addChild(this.gameContainer, this.interfaceContainer)

  this.backgroundSprites = {
    clouds: cloudsSprite,
    stadium: stadiumSprite,
    stands: standsSprite,
    stadiumForward: stadiumForwardSprite,
    grass: grassSprite,
  }
  this.backgroundSpritesWidth = {
    clouds: cloudsTexture.width,
    stadium: stadiumTexture.width,
    stands: standsTexture.width,
    stadiumForward: stadiumForwardTexture.width,
    grass: grassTexture.width,
  }
  console.log('Finished setuped before engine ', Date.now())
}

export function setupBackground(this: RunGame): void {
  console.log('Setuped background ', Date.now())
  this.setupTrack()
  this.barriersSetupCoords()
  this.setupBarriers()
  this.setupPets()
  console.log('Finished setuped background ', Date.now())
}

/**
 * Установка координат барьеру
 */
export function barriersSetupCoords(this: RunGame): void {
  this.mapInfo.forEach((bar) => {
    bar.y = this.getObjectSecondCoord({
      x: bar.x % TRACK_WIDTH,
      row: bar.row,
      trackName: this.getTrackNameByX(bar.x),
      objOffset: -BARRIER_HEIGHT + 15,
    })
  })
}

/**
 * Начальная отрисовка барьеров
 */
export function setupBarriers(this: RunGame): void {
  this.interractiveContainer.sortableChildren = true
  this.interractiveContainer.y = 550
  const barrierTexture = this.getTexture('barrier')
  this.mapInfo.forEach((bar) => {
    const barrier = new Sprite(barrierTexture, {
      x: bar.x,
      y: bar.y,
      zIndex: bar.row * 2 - 1,
      anchor: { x: 0.5, y: 0.5 },
    })

    this.interractiveContainer.addChild(barrier)
  })
}

/**
 * Начальная отрисовка питомцев
 */
export function setupPets(this: RunGame): void {
  this.setupPet({
    pet: this.anchorPet,
    x: START_LINE_X,
    trackName: 'trackStart',
  })

  this.pets.forEach((pet) => {
    this.setupPet({ pet, trackName: 'trackStart', x: START_LINE_X })
  })
}

export function setupPet(
  this: RunGame,
  {
    pet,
    trackName,
    x,
  }: {
    pet: GamePet
    trackName: TRACKS_NAMES
    x: number
  },
): void {
  pet.x = x
  const petSpine = pet.petSpine
  const petScale = TRACK_HEIGHT / 2.3 / petSpine.height
  petSpine.pivot = new PIXI.Point(0, petSpine.height * petScale)
  pet.y = this.getObjectSecondCoord({
    row: pet.row,
    trackName: trackName,
    x: x % TRACK_WIDTH,
    objOffset: (-petSpine.height * petScale) / 5,
  })
  petSpine.scale.set(petScale)
  petSpine.verticalReflection()
  pet.setAnimation('idle', true)
  pet.petContainer.zIndex = pet.row * 2

  this.interractiveContainer.addChild(pet.petContainer)
}

/**
 * Начальная отрисока дороги
 */
export function setupTrack(this: RunGame): void {
  this.trackContainer.y = 550
  this.trackContainer.x = -this.currentTrackOffset
  for (let i = 1; i <= 5; i++) {
    const track = this.getTrackNameByNum(i)
    if (track) {
      const trackTexture = this.getTexture(track)
      this.trackSprites[track] = trackTexture
    }
  }

  for (
    let i = this.currentTrack;
    i < this.currentTrack + TRACKS_IN_CONTAINER;
    i++
  ) {
    this.addTrack(i)
  }
}
