import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { PROMOCODE_MAX_LENGTH } from 'src/const'

import { LOCALES } from '@gatto/locales'

const Container = styled.input`
  padding: 10px 20px;

  border: 0;
  outline: none;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  font-family: 'Nunito';
  font-weight: 700;
  font-size: 16px;
  line-height: 11px;
  text-transform: uppercase;

  ::placeholder {
    text-transform: none;
  }

  :focus {
    background: rgba(0, 0, 0, 0.07);
  }
`

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  setText?: (text: string) => void

  children?: ReactNode | ReactNode[]
}

const Input: FC<InputProps> = ({ setText, children, ...props }) => {
  const { t } = useTranslation()
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText && setText(e.target.value)
  }
  return (
    <Container
      type="text"
      placeholder={LOCALES.promocode}
      maxLength={PROMOCODE_MAX_LENGTH}
      onChange={handleChange}
      {...props}
    >
      {children}
    </Container>
  )
}

export default Input
