import * as PIXI from 'pixi.js'
import {
  IRegionAttachment,
  ITrackEntry,
  Spine,
  SpineDebugRenderer,
} from 'pixi-spine'

import { AnimatedSpineSpriteProps, PetClothes } from '../types/spine'

export class AnimatedSpineSprite extends Spine {
  animation: null | ITrackEntry // Текущая анимация
  clothesSpriteSheet?: PIXI.Spritesheet // Spritesheet всей одежды
  clothesBones?: Map<string, string> // Привязка одежды к слотам {"hat": "head", "glasses": "eyes"}
  clothes: string[] // Текущая одежда питомца ["hat", "glasses"]
  skin: string // Текущий скин питомца
  defaultClothes: IRegionAttachment[] = [] // Запоминаем пустые слоты для одежды
  clothesBodyParts: string[] = [] // Части тела питомца для крепления одежды
  bodyParts: string[] // Части тела питомца для привязки одежды

  /**
   * Конструктор
   * @param
   */
  constructor({
    texture,
    clothesSpriteSheet,
    clothesBones,
    currentClothes,
    currentSkin,
    bodyParts,
  }: AnimatedSpineSpriteProps) {
    super(texture)

    this.animation = null
    this.clothesSpriteSheet = clothesSpriteSheet
    this.clothesBones = clothesBones
    this.bodyParts = bodyParts
    this.clothes = []
    this.setClothes(currentClothes || [])

    this.pivot = new PIXI.Point(0, -this.height / 2)
    this.skin = 'normal'
    this.setSkin(currentSkin || 'normal')
  }

  /**
   * Надеть одежду на питомца
   * @param clothes {PetClothes[]} массив с названиями и размерами одежды
   */
  setClothes(clothes: PetClothes[]): void {
    if (!this.clothesSpriteSheet || !this.clothesBones) return
    this.setDefaultClothes()

    this.clothes = clothes
    for (const clth of clothes) {
      this.hackTextureBySlotName(
        this.clothesBones.get(clth) || 'default',
        this.clothesSpriteSheet.textures[clth],
      )
    }
    this.skeleton.setSlotsToSetupPose()
  }

  /**
   * Снять одежду питомца
   */
  setDefaultClothes(): void {
    if (this.defaultClothes.length === 0) {
      for (const item of this.bodyParts) {
        const partTexture = this.skeleton.getAttachmentByName(
          item,
          item,
        ) as IRegionAttachment
        if (partTexture) {
          this.clothesBodyParts.push(item)
          this.defaultClothes.push(partTexture)
        }
      }
    } else {
      for (let i = 0; i < this.clothesBodyParts.length; i++) {
        const sizes = new PIXI.Rectangle(
          0,
          0,
          this.defaultClothes[i]?.width,
          this.defaultClothes[i]?.height,
        )
        this.hackTextureBySlotName(
          this.clothesBodyParts[i] || '',
          this.defaultClothes[i]?.region?.texture,
          sizes,
        )
      }
    }
  }

  /**
   * Установить скин питомцу
   * @param skin {string} название скина
   * @returns существует ли скин
   */
  setSkin(skin: string): boolean {
    try {
      this.skeleton.setSkinByName(skin)
      this.skin = skin
      return true
    } catch {
      return false
    }
  }

  /**
   * Установить анимацию питомцу
   * @param animation {string} название анимации
   * @param loop {boolean} зациклить анимацию
   * @returns {ITrackEntry} состояние анимации
   */
  setAnimation(animation: string, loop: boolean): ITrackEntry | null {
    if (!this.state.hasAnimation(animation)) {
      this.state.setEmptyAnimation(0, 0)
      return null
    }
    this.animation = this.state.setAnimation(0, animation, loop)
    return this.animation
  }

  /**
   * Изменение скорости анимации
   * @param scale {number} -1 - реверс, 0..1 - замедление, 1.. - ускорение
   */
  setTimeScale(scale: number): void {
    this.state.timeScale = scale
  }

  /**
   * Включение режима отладки с показом костей питомца
   */
  enableDebug(): void {
    // Wrong type of debug property in pixi-spine
    this.debug = new SpineDebugRenderer()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.debug.drawBones = true
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.debug.drawMeshTriangles = false
  }

  /**
   * Отразить спрайт по вертикали
   */
  verticalReflection(): void {
    this.width = -this.width
  }
}
