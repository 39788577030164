/* eslint-disable no-console */
import { SwimGame } from 'src/pixi/classes'

/**
 * Очищает память при удалении контейнера
 */
export function destroySwimGameApp(this: SwimGame): void {
  this.view.removeEventListener?.('pointerdown', this.diveAnchorPet)
  this.view.removeEventListener?.('pointerup', this.emergeAnchorPet)
  this.destroy()
}
