import { FC, useState } from 'react'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { Stage } from '@pixi/react'
import { PetClothes } from 'src/pixi/types/spine'

import { Entity, EntityPet } from 'components/pixi'

import { BasePet } from '@gatto/shared'

type PetImageProps = {
  basePet?: Pick<BasePet, 'kind' | '_id' | 'rarity'>
  width?: number
  height?: number
  scale?: number
  skins: PetClothes[]
}

const PetImage: FC<PetImageProps> = ({
  basePet,
  width,
  height,
  scale,
  skins,
}) => {
  const [isPetVisible, setIsPetVisible] = useState(true)

  return (
    <ReactVisibilitySensor
      offset={{ right: -100, left: -100, bottom: -100 }}
      onChange={(isVisible: boolean) => setIsPetVisible(isVisible)}
    >
      <Stage
        width={width ? width : 100}
        height={height ? height : 100}
        options={{
          backgroundAlpha: 0,
          antialias: true,
          resolution: window.devicePixelRatio || 1,
          autoDensity: true,
        }}
      >
        <Entity
          entity={
            basePet && (
              <EntityPet
                basePet={basePet}
                squeeze={false}
                x={width ? width / 2 - width * 0.03 : 50}
                skins={skins}
                y={height ? height : 100}
                scale={scale ? scale : 0.5}
              />
            )
          }
          shadow={false}
          visible={isPetVisible}
        />
      </Stage>
    </ReactVisibilitySensor>
  )
}

export default PetImage
