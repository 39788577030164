import React, { FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import { LOCALES } from '@gatto/locales'
import { PetRarity } from '@gatto/shared'

const Wrapper = styled.div`
  display: inline-flex;
  padding: 10px 20px;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  border-radius: 10px;
  background: rgba(255, 255, 255, 0.32);
`

const Name = styled.p`
  color: #000;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: 'Nunito';
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
`

const Rarity = styled(Name)`
  font-size: 11px;
  text-transform: unset;
  line-height: 8px;
`

interface Props extends HTMLAttributes<HTMLDivElement> {
  name: string
  rarity: PetRarity
}

// TODO: перенести или удалить
const translateRarity = (rarity: PetRarity): LOCALES => {
  switch (rarity) {
    case PetRarity.Normal:
      return LOCALES.normal
    case PetRarity.Rare:
      return LOCALES.rare
    case PetRarity.Mythical:
      return LOCALES.mif
    case PetRarity.Legendary:
      return LOCALES.legendary
    default:
      return LOCALES.Unknown
  }
}

const PetName: FC<Props> = ({ name, rarity, ...props }) => {
  const { t } = useTranslation()
  return (
    <Wrapper {...props}>
      <Name>{name}</Name>
      <Rarity>{t(translateRarity(rarity))}</Rarity>
    </Wrapper>
  )
}

export default PetName
