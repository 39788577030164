import { ScreenDimensions as Dimensions } from 'src/hooks/useScreenDimensions'
import Position from 'src/types/Position'
import Tile from 'src/types/Tile'

import {
  BASE_LARGE_TILE_GAP_X,
  BASE_LARGE_TILE_GAP_Y,
  BASE_LARGE_TILE_HEIGHT,
  BASE_LARGE_TILE_OFFSET_X,
  BASE_LARGE_TILE_OFFSET_Y,
  BASE_LARGE_TILE_WIDTH,
  BASE_TILE_GAP_X,
  BASE_TILE_GAP_Y,
  BASE_TILE_HEIGHT,
  BASE_TILE_WIDTH,
  MIN_STAGE_WIDTH,
  STAGE_WIDTH_MULTIPLIER,
} from './constants'
import { TileDimensions } from './TileSprite'

export const getTilePosition = (
  tile: Tile,
  tileDimensions: TileDimensions,
): Position => {
  const { width, height, gapX, gapY } = tileDimensions
  const x = tile.position.x * (width / 2) - tile.position.y * (width / 2)
  const y = tile.position.x * (height / 2) + tile.position.y * (height / 2)
  const gapXCalculated = tile.position.x * gapX - tile.position.y * gapX
  const gapYCalculated = tile.position.x * gapY + tile.position.y * gapY

  return {
    x: x - gapXCalculated - tileDimensions.offsetX,
    y: y - gapYCalculated - tileDimensions.offsetY,
  }
}

export const getStageDimensions = (
  screenDimensions: Dimensions,
): Dimensions => ({
  width: Math.min(
    Math.max(screenDimensions.width, MIN_STAGE_WIDTH),
    screenDimensions.height * STAGE_WIDTH_MULTIPLIER,
  ),
  height: Math.max(screenDimensions.height, MIN_STAGE_WIDTH),
})

export const getTileDimensions = (
  stageDimensions: Dimensions,
  tilesAmount: number,
): TileDimensions => {
  const width = stageDimensions.width / tilesAmount
  const height = (BASE_TILE_HEIGHT / BASE_TILE_WIDTH) * width
  const gapX = BASE_TILE_GAP_X * (width / BASE_TILE_WIDTH)
  const gapY = BASE_TILE_GAP_Y * (height / BASE_TILE_HEIGHT)

  return {
    width: width,
    height: height,
    gapX,
    gapY,
    offsetX: 0,
    offsetY: 0,
  }
}

export const getLargeTileDimensions = (
  stageDimensions: Dimensions,
  tilesAmount: number,
): TileDimensions => {
  const width = (stageDimensions.width / tilesAmount) * 2
  const height = (BASE_LARGE_TILE_HEIGHT / BASE_LARGE_TILE_WIDTH) * width
  const gapX = BASE_LARGE_TILE_GAP_X * (width / BASE_LARGE_TILE_WIDTH)
  const gapY = BASE_LARGE_TILE_GAP_Y * (height / BASE_LARGE_TILE_HEIGHT)
  const offsetX = BASE_LARGE_TILE_OFFSET_X * (width / BASE_LARGE_TILE_WIDTH)
  const offsetY = BASE_LARGE_TILE_OFFSET_Y * (height / BASE_LARGE_TILE_HEIGHT)

  return {
    width: width,
    height: height,
    gapX,
    gapY,
    offsetX,
    offsetY,
  }
}
