import React, { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { ONE_MINUTE } from 'src/config/constants'
import { useNow } from 'src/hooks/useNow'
import useScreenDimensions from 'src/hooks/useScreenDimensions'
import { makeRequest } from 'utils/api'
import { getEntityDimensions } from 'utils/getEntityDimensions'
import { nowServerTime } from 'utils/nowServerTime'
import { sendPayment } from 'utils/ton/connectWallet'
import { handleGetTonPayments } from 'utils/ton/GetPayments'

import { Wallet } from 'components/base'
import { RootState, useAppDispatch, useAppSelector } from 'store'
import { getPaymentProcess, setPaymentProcess } from 'store/payment'
import { updateRegionEgg } from 'store/region'
import { decrementBalance, updateUserRegion } from 'store/user'

import { LOCALES } from '@gatto/locales'
import {
  Platform,
  PopulatedEgg,
  PopulatedPet,
  PopulatedUserRegion,
} from '@gatto/shared'

import { useModal, usePopout } from '../../hooks'
import { useWalletCheckWithUpdate } from '../../hooks/useWalletCheck'
import { MINI_APP } from '../../index'

import EggButtons from './Egg/EggButtons'
import EggProgressBar from './Egg/EggProgressBar'
import Tabbar from './Tabbar'

type RegionEggProps = {
  egg: PopulatedEgg
  showAds: () => Promise<boolean>
  region: PopulatedUserRegion
  handleOpenEgg: () => Promise<void>
  hatchedPet: PopulatedPet | null
}

const hardMoneySelector = (state: RootState) => state.user.user?.balance.hard

const CustomWallet = styled(Wallet)`
  position: absolute;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.15) 0%,
      rgba(0, 0, 0, 0.15) 100%
    ),
    linear-gradient(
      355deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0.1) 100%
    );
  box-shadow: 0px -4px 0px 0px rgba(38, 43, 11, 0.25) inset;
`

export const HatchedCustomWallet = styled(Wallet)`
  position: absolute;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.15) 0%,
      rgba(0, 0, 0, 0.15) 100%
    ),
    linear-gradient(
      355deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0.1) 100%
    );
  box-shadow: 0px -4px 0px 0px rgba(38, 43, 11, 0.25) inset;
`

const RegionEgg: FC<RegionEggProps> = ({
  egg,
  showAds,
  region,
  handleOpenEgg,
  hatchedPet,
}) => {
  console.info(showAds)
  const dispatch = useAppDispatch()
  const hardMoney = useAppSelector(hardMoneySelector)
  const paymentProcess = useAppSelector(getPaymentProcess)
  const now = useNow(1000)
  const { user } = useAppSelector((state) => state.user)
  const { t } = useTranslation()
  const openModalAreYouSure = usePopout('eggHatching', true, {
    egg,
    region,
  })
  const tonPaymentPopout = usePopout('statusAlert', true, {
    type: 'success',
    header: `${t(LOCALES.completed_payment)}`,
  })
  const successPopout = usePopout('statusAlert', true, {
    type: 'success',
    subheader: `${t(LOCALES.buySuccess)}`,
  })
  const popout = usePopout('statusAlert', true, {
    type: 'error',
    header: `${t(LOCALES.buyError)}`,
  })
  const waitPopout = usePopout('statusAlert', true, {
    type: 'custom',
    subheader: `${t(LOCALES.process_of_purchase)}`,
  })
  const paymentProcessPopout = usePopout('statusAlert', true, {
    type: 'error',
    subheader: `${t(LOCALES.error_paymentProcess)}`,
  })
  const walletExistPopout = usePopout('walletNotExist', false)

  const [data, updateData] = useWalletCheckWithUpdate(false)

  const screenDimensions = useScreenDimensions()
  const entityDimensions = getEntityDimensions(screenDimensions)

  const isPlaced = Boolean(egg?.hatching)
  const isHatched = Boolean(
    egg?.hatching?.isHatched ||
      (egg?.hatching?.hatchingTimeout &&
        nowServerTime() >= egg?.hatching?.hatchingTimeout),
  )

  const isReqNeeded = useRef<boolean>(false)
  const intervalValue: { current: NodeJS.Timeout | null } = useRef(null)

  // вылупление яйца
  if (egg.hatching && !isHatched && isPlaced) {
    const hatchingTimeout = egg.hatching?.hatchingTimeout || 0
    const hatchingTime = egg.hatching?.hatchingTime || 0
    const timeLeft = hatchingTimeout - now

    const price = +(
      MINI_APP === Platform.VK
        ? Math.ceil(timeLeft / 60 / 60 / 1000) * 5
        : Math.ceil(timeLeft / 60 / 60 / 1000) * 0.05
    ).toFixed(3)

    const canPayedSkip = !!(hardMoney && hardMoney >= price)
    const canFreeSkip = egg.hatching && egg.hatching.skipsLeft > 0

    const handleTonPaymentSkip = async () => {
      if (paymentProcess) return paymentProcessPopout()
      if (MINI_APP === Platform.TG) {
        const userWallets = data.length ? data : await updateData()
        if (!userWallets.length) {
          walletExistPopout()
        } else {
          isReqNeeded.current = true
          const uniqueId = await sendPayment(
            price,
            `${'fast_egg_hatch'}:${egg._id}`,
            tonPaymentPopout,
          )
          if (!uniqueId) return
          // dispatch(setPaymentProcess(true))
          intervalValue.current = setInterval(async () => {
            const res = await handleGetTonPayments({
              uniqueId,
              intervalValue,
              isReqNeeded,
              successPopout,
              errorPopout: popout,
              waitPopout,
              stopInterval: () => {
                dispatch(setPaymentProcess(false))
              },
              startInterval: () => {
                dispatch(setPaymentProcess(true))
              },
            })

            if (res?.status === 'got') {
              const { egg } = await makeRequest('region.getById', {
                id: region._id.toString(),
              })
              dispatch(
                updateRegionEgg({
                  egg: egg,
                }),
              )
              dispatch(updateUserRegion({ egg: egg, regionId: region._id }))
            }
          }, 5000)
        }
      }
    }

    const isTelegramPayment =
      MINI_APP === Platform.TG && user && user.balance.hard < price

    return (
      <>
        <EggProgressBar
          entityDimension={entityDimensions}
          hatchingTimeout={egg.hatching.hatchingTimeout}
          hatchingTime={egg.hatching.hatchingTime}
          canSkip={egg.hatching.skipsLeft > 0}
        />
        <EggButtons
          hatchingTime={hatchingTime}
          hatchingTimeout={hatchingTimeout}
          onClick={
            isTelegramPayment
              ? handleTonPaymentSkip
              : (openModalAreYouSure as (
                  payed?: boolean | undefined,
                ) => Promise<void>)
          }
          canPayedSkip={MINI_APP === Platform.VK ? canPayedSkip : true}
          // canFreeSkip={canFreeSkip}
          price={price}
          timeLeft={timeLeft}
          regionId={region._id}
          egg={egg}
          entityDimension={entityDimensions}
          isAdsReady={true}
        />
        {/*<CustomWallet mode={'horizontal'} />*/}
        <Wallet
          mode={'horizontal'}
          customStyle={`
              position: absolute;
              background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), linear-gradient(355deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.10) 100%);
              box-shadow: 0px -4px 0px 0px rgba(38, 43, 11, 0.25) inset;`}
        />

        <Tabbar page="egg" region={region} isHatched={false} isPlaced={true} />
      </>
    )
  }

  // яйцо вылупилось
  if (isHatched) {
    return (
      <>
        <Wallet
          mode={'horizontal'}
          customStyle={`
              position: absolute;
              background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), linear-gradient(355deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.10) 100%);
              box-shadow: 0px -4px 0px 0px rgba(38, 43, 11, 0.25) inset;`}
        />

        {!hatchedPet && (
          <Tabbar
            page="egg"
            isHatched={true}
            isPlaced={true}
            region={region}
            onEggPlace={handleOpenEgg}
          />
        )}
      </>
    )
  }

  return null
}

export default RegionEgg
