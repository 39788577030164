/* eslint-disable no-console */
import * as PIXI from 'pixi.js'
import { SwimGame } from 'src/pixi/classes'
import { SpineTexture } from 'src/pixi/types/spine'
import { TEXTURE_NAMES } from 'src/pixi/types/swimgame'

import { SWIMGAME_TEXTURES } from '../constants'

export function getObjectSecondCoord(
  this: SwimGame,
  {
    row,
    objOffset,
  }: {
    row: number
    objOffset?: number
  },
): number {
  return 20 + 90 * row + (objOffset ?? 0)
}

/**
 * Получение текстуры по имени
 */
export function getTexture(this: SwimGame, name: TEXTURE_NAMES): PIXI.Texture {
  return (
    this.textures.textures[SWIMGAME_TEXTURES[name]] || PIXI.Texture.from('')
  )
}

export function getSpineTexture(this: SwimGame, name: string): SpineTexture {
  return this.spineTextures[name] as SpineTexture
}
