export default function copyTextToClipboard(text: string): boolean {
  if (typeof text !== 'string') {
    throw new TypeError(
      `Expected parameter \`text\` to be a \`string\`, got \`${typeof text}\`.`,
    )
  }

  const element = document.createElement('textarea')

  element.value = text

  // Prevent keyboard from showing on mobile
  element.setAttribute('readonly', '')

  element.style.contain = 'strict'
  element.style.position = 'absolute'
  element.style.left = '-9999px'
  element.style.fontSize = '12pt' // Prevent zooming on iOS

  const selection = document.getSelection()
  if (!selection) return false
  const originalRange = selection.rangeCount > 0 && selection.getRangeAt(0)

  document.body.append(element)
  element.select()

  // Explicit selection workaround for iOS
  element.selectionStart = 0
  element.selectionEnd = text.length

  let isSuccess = false
  try {
    isSuccess = document.execCommand('copy')
  } catch (error) {
    console.info(`[Clipboard] Copy error`, error)
  }

  element.remove()

  if (originalRange) {
    selection.removeAllRanges()
    selection.addRange(originalRange)
  }

  return isSuccess
}
