export function fixEndingByNumber(word: string, num: number): string {
  const lastDigit = Number(`${num}`.at(-1))

  const end =
    num >= 11 && num <= 14
      ? 'ов'
      : lastDigit === 1
      ? ''
      : lastDigit >= 2 && lastDigit <= 4
      ? 'а'
      : ''

  return word + end
}

export const fixEndingByGender = (
  word: string,
  ending: 'male' | 'female' | 'neuter',
): string => {
  if (ending === 'male' && word.at(-1) === 'к') return word + 'ий'
  if (ending === 'male') return word + 'ый'
  if (ending === 'female') return word + 'ая'
  return word + 'ое'
}
