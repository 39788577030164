import { MAP_SIZE } from 'src/config/constants'
import Tile from 'src/types/Tile'

import { PopulatedUserRegion } from '@gatto/shared'

function addLargeTile(
  field: Tile[][],
  type: Tile['type'],
  position: Tile['position'],
) {
  const { x, y } = position

  const currentTileRow = field[y] as Tile[]
  const currentTile = currentTileRow[x] as Tile
  currentTile.type = type
  currentTile.isLarge = true

  // Тайлы под большим тайлом
  const nearTiles = [
    { x: x + 1, y },
    { x, y: y + 1 },
    { x: x + 1, y: y + 1 },
  ]

  for (const { x: nearX, y: nearY } of nearTiles) {
    const nearTileRow = field[nearY] as Tile[]
    const nearTile = nearTileRow[nearX] as Tile
    nearTile.tileFor = currentTile
  }
}

export function generateTiles(regions: PopulatedUserRegion[] = []): Tile[][] {
  // Генерируем пустую карту
  const field: Tile[][] = Array.from({ length: MAP_SIZE }, (_, y) =>
    Array.from({ length: MAP_SIZE }, (_, x) => ({
      type: 'empty',
      position: { x, y },
      skin: 0,
    })),
  )

  // Регионы пользователя
  for (const userRegion of regions) {
    const { position } = userRegion
    const fieldRow = field[position.y] as Tile[]
    fieldRow[position.x] = {
      position,
      id: userRegion._id.toString(),
      type: userRegion.baseRegion.biome,
      skin: userRegion.skin,
      pet: userRegion.pet,
    }
  }

  // Стадион
  const stadiumPosition = { x: 0, y: 0 }
  addLargeTile(field, 'stadium', stadiumPosition)

  // Магазин
  const shopPosition = { x: MAP_SIZE - 2, y: 0 }
  addLargeTile(field, 'store', shopPosition)

  // Склад
  const stockPosition = { x: 0, y: MAP_SIZE - 2 }
  addLargeTile(field, 'storage', stockPosition)

  // Парк
  const parkPosition = { x: MAP_SIZE - 2, y: MAP_SIZE - 2 }
  addLargeTile(field, 'park', parkPosition)

  return field
}
