import React, { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import designTokens from 'src/config/designTokens'

const Root = styled.span`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;

  font-family: Nunito;
  font-size: 14px;
`

const CounterButton = styled.button<{ theme?: typeof designTokens }>`
  width: 30px;
  height: 30px;
  padding: 10px 6px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  font-family: Nunito;
  font-size: 15px;
  font-weight: 800;
  text-transform: uppercase;

  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: 60ms;

  &:hover {
    background: ${({ theme }) => theme.color['black-24']};
  }

  &:active {
    background: ${({ theme }) => theme.color['black-32']};
    transform: translateY(2px);
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.1);
  }
`

type ModalCounterProps = {
  /**
   * Начальное значение
   * @default 1
   */
  defaultValue?: number

  /**
   * Максимальное значение
   */
  maxValue?: number

  /**
   * Единица измерения
   * @default 'шт.'
   */
  valueType?: string

  /**
   * Обработчик изменения значения
   * @event ModalCounter#onChange
   */
  onChange: (value: number) => void
}

/**
 * Компонент-счетчик для модального окна
 * @returns Счётчик
 */
function ModalCounter({
  defaultValue = 1,
  maxValue,
  valueType = 'шт.',
  onChange,
}: ModalCounterProps): React.ReactElement {
  const [value, setValue] = useState<number>(defaultValue)

  const handleDecrement = useCallback(() => {
    setValue((lastValue) => Math.max(lastValue - 1, defaultValue))
  }, [defaultValue])

  const handleIncrement = useCallback(() => {
    setValue((lastValue) =>
      maxValue ? Math.min(lastValue + 1, maxValue) : lastValue + 1,
    )
  }, [maxValue])

  useEffect(() => {
    onChange(value)
  }, [onChange, value])

  return (
    <Root>
      <CounterButton onClick={handleDecrement}>-</CounterButton>
      {value} {valueType}
      <CounterButton onClick={handleIncrement}>+</CounterButton>
    </Root>
  )
}

export default ModalCounter
