import React from 'react'
import styled from '@emotion/styled'
import { API_URL } from 'src/config/constants'

export const BannerImage = styled.img`
  height: 180px;
  flex-shrink: 0;
  background: black;
  border-radius: 26px;
`

const BannerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

/**
 * Баннер магазина
 * @component
 */
function Banner(): React.ReactElement {
  return (
    <BannerContainer>
      <BannerImage src={`https://frontend.gattogame.site/uploads/store/banner2.png`} />
    </BannerContainer>
  )
}

export default Banner
