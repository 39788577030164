import styled from '@emotion/styled'

import { Button } from 'components/base'
import { Container } from 'components/page'

export const biomeColors = {
  /** Степной биом */
  plain: '#C0CA1F',
  /** Лесной биом */
  forest: '#0C6352',
  /** Ледяной биом */
  ice: '#63D6CF',
  /** Горный биом */
  mountain: '#574B56',
  /** Песчаный биом */
  desert: '#CF872A',
  /** Морской биом */
  sea: '#2473BD',
  /** Морской биом */
  random: '#000000',
}

export const PageItems = styled(Container)`
  overflow: auto;
  overflow-x: hidden;
`

export const ShareButton = styled(Button)`
  :disabled {
    scale: 0.95;
    opacity: 0.5;
  }
  :active {
    scale: 0.95;
    opacity: 0.5;
  }
`

export const StartButton = styled(Button)`
  :disabled {
    scale: 0.95;
    opacity: 0.5;
  }
  :active {
    scale: 0.95;
    opacity: 0.5;
  }
`

export const LobbyUsersIcon = styled.img`
  width: 32px;
  height: 32px;
`

export const CrownIcon = styled.img`
  width: 24px;
  height: 24px;
`

export const ChangePetButton = styled(Button)`
  margin-left: auto;
  :disabled {
    scale: 0.95;
    opacity: 0.5;
  }
  :active {
    scale: 0.95;
    opacity: 0.5;
  }
`

export const UserPhotoStyle = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 18px;
`

export const FriendsList = styled.div`
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1);
  flex-direction: column;
  align-content: center;
`

export const FriendsListEmpty = styled.div`
  max-width: 312px;
  align-self: stretch;
  color: rgba(255, 255, 255, 0.6);

  text-align: center;
  leading-trim: both;

  text-edge: cap;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Nunito;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`

export const KickButton = styled.div<{
  src: string
  color: string
  hidden: boolean
}>`
  fill: #ffffff;
  width: 24px;
  min-height: 24px;
  background: ${({ color }) => color};
  mask: url(${({ src }) => src}) no-repeat center;
  border: none;
  cursor: pointer;
  margin-left: auto;
  ${({ hidden }) => (hidden ? 'display: none;' : '')}
`

export const GamesList = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;
`

export const GameItem = styled.div<{
  pos: string
  selected: boolean
  cursor: boolean
}>`
  flex: 1 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  img {
    cursor: ${({ cursor }) => (cursor ? 'pointer' : 'default')};
    max-height: 250px;
    max-width: 250px;
    aspect-ratio: 1 / 1;
    display: block;
    width: 100%;
    object-fit: cover;
    object-position: ${({ pos }) => pos};
    border-radius: 10px;
    box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.15) inset;

    ${({ selected }) =>
      selected
        ? `border: 4px solid #fff; box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.15) inset, 0px 0px 4px 0px #fff;`
        : ''}
  }
`

// Wrapper, SelectGameTitle (SearchTitle) взяты из pages/SelectPetPage/PrizeInfoPage.tsx
// потому в идеале скинуть их в отдельный компонент
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin: 0 20px;
`

export const Title = styled.div<{
  weight: number
}>`
  color: #fff;
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: ${({ weight }) => weight};
  line-height: 12px;
`

// А все ниже вообще из components/Rating/RatingCard.tsx. Но они не экспортированы :(
export const InfoBlock = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  gap: 7px;
`

// Avatar
export const AvatarStyle = styled.div<{ size: number; background: string }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  flex-shrink: 0;
  border-radius: 18px;
  background: ${({ background }) => background};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;

  * {
    cursor: default !important;
  }
`

// PlayerInfo
export const PlayerInfoStyle = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  padding-right: 5px;
`

export const NicknameLevel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
`

export const NickName = styled.div`
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;
  font-weight: 700;
  line-height: normal;
  leading-trim: both;
  text-edge: cap;
  font-style: normal;
`

export const Level = styled.div`
  display: flex;
  padding: 5px;
  align-content: center;
  border-radius: 5px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(255, 255, 255, 0.15) 100%
  );
  leading-trim: both;
  text-edge: cap;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 9px;
`

export const Name = styled.div`
  color: rgba(255, 255, 255, 0.7);
  width: 100%;
  word-wrap: break-word;
  leading-trim: both;
  text-edge: cap;
  font-family: Nunito;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const ControlButtons = styled(Wrapper)`
  margin: 0;
  padding: 10px 20px;
  padding-bottom: 24px;
  flex-direction: row;
  gap: 10px;
  background: rgba(62, 112, 242, 0.99);
  box-shadow: 0em -1em 1em rgba(62, 112, 242, 0.99);
  position: fixed;
  bottom: 0;
  width: 100%;
`
