import { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Container, Stage } from '@pixi/react'
import { Icon24InfoCircleOutline as InfoIcon } from '@vkontakte/icons'
import { CardScroll } from '@vkontakte/vkui'
import * as PIXI from 'pixi.js'
import { API_URL } from 'src/config/constants'
import { usePopout, useRating } from 'src/hooks'

import placehoder from 'assets/svg/selectPet/placeholder.svg'
import {
  BackButton,
  Button,
  PetRatingCard,
  RatingCard,
  Row,
  Wallet,
} from 'components/base'
import { Page, PageContainer, PageHeader } from 'components/page'
import { Entity, EntityPet, EntityRays } from 'components/pixi'
import SelectCard from 'components/SelectCard'
import Toggle from 'components/Toggle'
import { HeaderText } from 'components/typography'
import { useAppSelector } from 'store/index'

import { LOCALES } from '@gatto/locales'
import { GameLeague, GameLeagues, PopulatedPetWithSkins } from '@gatto/shared'

export const HorizontalScroll = styled(CardScroll)`
  width: 353px;
  align-self: center;
`

const LeagueImg = styled.img`
  width: 125px;
  height: 125px;
`

const PageRow = styled(Row)`
  width: 100%;
  max-width: 353px;
  align-self: center;
`

const Subtitle = styled.div`
  color: var(--white-white-100, #fff);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  flex-shrink: 1;
`

const UserPets = styled.div`
  display: flex;
  width: 100%;
  max-width: 353px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 18px;
  background: rgba(255, 255, 255, 0.05);
  align-self: center;
`

const DescriptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 15px 15px 15px;
`

const Description = styled.div`
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Nunito;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
`

const PlayersTopWrapper = styled(Row)`
  display: flex;
  max-width: 353px;
  height: 100%;
  width: 100%;
  align-self: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 15px 15px 15px;
  // box-shadow: 0px -6px 15px 0px rgba(255, 255, 255, 0.045);
`

const ToggleContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 353px;
  z-index: 10;
`

export const Scroller = styled.div<{ height: number }>`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 100%;
  height: ${(props) => props.height}px;
  max-width: 353px;
  border-radius: 0 0 18px 18px;
  align-self: center;
  &::-webkit-scrollbar {
    display: none;
  }
  mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(62, 112, 242, 0.99) 2%
  );
  gap: 20px;
  padding-bottom: 90px;
`

const HeaderMain = styled.div`
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Nunito;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
`

const Img = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 10px;
`

const PlaceholderWrapper = styled.div`
  display: flex;
  padding: 15px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--white-white-15, rgba(255, 255, 255, 0.15));
  margin: 0 15px 15px;
`

export const leagues = [
  {
    name: GameLeague.Bronze,
    src: `https://frontend.gattogame.site/uploads/leagues/league_bronze.png`,
    title: LOCALES.league_Bronze,
    subtitle: LOCALES.league_Bronze_desc,
  },
  {
    name: GameLeague.Silver,
    src: `https://frontend.gattogame.site/uploads/leagues/league_silver.png`,
    title: LOCALES.league_Silver,
    subtitle: LOCALES.league_Silver_desc,
  },
  {
    name: GameLeague.Golden,
    src: `https://frontend.gattogame.site/uploads/leagues/league_gold.png`,
    title: LOCALES.league_Golden,
    subtitle: LOCALES.league_Golden_desc,
  },
  {
    name: GameLeague.Platinum,
    src: `https://frontend.gattogame.site/uploads/leagues/league_platinum.png`,
    title: LOCALES.league_Platinum,
    subtitle: LOCALES.league_Platinum_desc,
  },
  {
    name: GameLeague.Diamond,
    src: `https://frontend.gattogame.site/uploads/leagues/league_diamond.png`,
    title: LOCALES.league_Diamond,
    subtitle: LOCALES.league_Diamond_desc,
  },
]

export const LeaguesPage: FC = () => {
  const { t } = useTranslation()
  const [currentLeague, setCurrentLeague] = useState(GameLeague.Bronze)
  const [title, setTitle] = useState<LOCALES | string>(LOCALES.league_Bronze)
  const scroller = useRef<HTMLDivElement>(null)
  const stageRef = useRef<PIXI.Container>(null)
  const navigate = useNavigate()
  const openPopout = usePopout('leagueInfo', false, { league: currentLeague })

  const { user } = useAppSelector((state) => state.user)

  const { ratingList } = useRating(currentLeague)

  const tabbarItems = [
    {
      title: LOCALES.games,
      path: '/stadium',
    },
    {
      title: LOCALES.leagues,
      path: '/leagues',
    },
  ]

  const myRegionPets = user?.regions.map((r, i) => {
    let rating = 0

    if (r.pet) {
      rating = Object.values(r.pet?.chars).reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        rating,
      )
      const rCard: PetRatingCard = {
        position: i + 1,
        name: String(user.about.firstName + ' ' + user.about.lastName),
        nickname: r.pet.name,
        avatar: user.about.photoUrl,
        petId: r._id.toString(),
        owner: user._id,
        rating,
        level: r.pet.level,
        chars: r.pet.chars,
        evolution: r.pet.evolution,
        basePet: r.pet.basePet,
        skins: (r.pet as PopulatedPetWithSkins).skins,
      }

      return rCard
    } else {
      return
    }
  })

  const mySortedRegionPets = myRegionPets?.sort((a, b) => {
    if (a && b) {
      return b?.rating - a.rating
    }

    return 1
  })

  const myPets = mySortedRegionPets
    ?.map((petCard, i) => {
      if (petCard) {
        let isCurrentLeague = false

        GameLeagues[currentLeague][0]?.forEach((evolution) => {
          if (petCard?.evolution === evolution) {
            isCurrentLeague = true
          }
        })

        if (isCurrentLeague) {
          return {
            ...petCard,
            position: i + 1,
          }
        }
      }

      return
    })
    .filter((item) => item)
    .slice(0, 3)

  const myPetsLength = myPets?.length

  const handleClick = (name: GameLeague, title: string) => {
    setCurrentLeague(name)
    setTitle(title)
  }

  return (
    <PageContainer>
      <PageHeader over={<Wallet mode="horizontal" />} before={<BackButton />}>
        <HeaderText>{t(LOCALES.leagues)}</HeaderText>
      </PageHeader>
      <HorizontalScroll>
        {leagues.map(({ name, src, title }, index) => (
          <SelectCard
            isSelected={name === currentLeague}
            width="125px"
            height="125px"
            key={index}
            onClick={() => handleClick(name, title)}
          >
            <LeagueImg src={src} key={index} />
          </SelectCard>
        ))}
      </HorizontalScroll>
      <PageRow>
        <Subtitle>{t(title)}</Subtitle>
        <Button
          width={'50px'}
          size={'small'}
          style={{ padding: '9px 9px 11px 9px' }}
          variant="primary"
          onClick={() => {
            openPopout()
          }}
        >
          <InfoIcon />
        </Button>
      </PageRow>
      <Scroller ref={scroller} id={'petContainer'} height={window.innerHeight}>
        <Stage
          id="stage1"
          width={window.innerWidth}
          height={window.innerHeight}
          options={{
            backgroundAlpha: 0,
            antialias: true,
            resolution: window.devicePixelRatio || 1,
            autoDensity: true,
          }}
          style={{
            position: 'absolute',
            left: `calc(50% - 120px)`,
            pointerEvents: 'none',
          }}
        >
          <Container ref={stageRef}>
            {ratingList &&
              myPets &&
              [...myPets, ...ratingList].map(
                (pet, i) =>
                  pet && (
                    <Entity
                      key={i}
                      reactTracking={{
                        elementId: 'petBox' + i,
                        scrollableElementId: 'petContainer',
                        stageId: 'stage1',
                      }}
                      entity={
                        pet.basePet && (
                          <EntityPet
                            basePet={pet.basePet}
                            squeeze={false}
                            y={50}
                            x={28}
                            scale={0.3}
                            skins={pet.skins}
                          />
                        )
                      }
                      shadow={false}
                      rays={<EntityRays x={30} y={30} width={70} height={70} />}
                    />
                  ),
              )}
          </Container>
        </Stage>
        <UserPets>
          <DescriptionWrapper>
            <Description>{t(LOCALES.yourPets)}</Description>
            <Description>{t(LOCALES.characteristics)}</Description>
          </DescriptionWrapper>

          <PlayersTopWrapper>
            {myPets?.map((pet, i) => {
              if (!pet) return

              return (
                <RatingCard
                  key={i}
                  pet={pet}
                  avatarBoxId={'petBox' + i}
                  biome={pet.basePet?.allowedRegion}
                />
              )
            })}
          </PlayersTopWrapper>

          {((myPets && !myPets.length) || !myPets) && (
            <PlaceholderWrapper>
              <Img src={placehoder} />
              <HeaderMain>{t(LOCALES.youDontHavePetInLeague)}</HeaderMain>

              <Button variant="success" onClick={() => navigate('/store')}>
                {t(LOCALES.goShop)}
              </Button>
            </PlaceholderWrapper>
          )}
        </UserPets>
        <UserPets>
          <DescriptionWrapper>
            <Description>{t(LOCALES.overall)}</Description>
            <Description>{t(LOCALES.characteristics)}</Description>
          </DescriptionWrapper>

          <PlayersTopWrapper>
            {ratingList?.map((pet, i) => {
              if (!pet) return

              return (
                <RatingCard
                  key={i}
                  pet={pet}
                  avatarBoxId={'petBox' + (i + (myPetsLength || 0))}
                  biome={pet.basePet?.allowedRegion}
                />
              )
            })}
          </PlayersTopWrapper>
        </UserPets>
      </Scroller>

      <ToggleContainer>
        <Toggle items={tabbarItems} />
      </ToggleContainer>
    </PageContainer>
  )
}
