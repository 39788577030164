import React from 'react'
import styled from '@emotion/styled'
import { PET_CHARS } from 'src/config/petChars'

import { PetChars as IPetChars } from '@gatto/shared'

const Root = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
  alignItems: 'center',
  padding: 4,
  borderRadius: 14,
  zIndex: 10,
  width: '100%',
  justifyContent: 'center',
  flexWrap: 'wrap',
})

const Container = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
  alignItems: 'center',
  padding: '4px 8px',
  paddingRight: 12,
  borderRadius: 10,
  background: 'rgba(0, 0, 0, 0.2)',
  color: 'white',
  fontFamily: 'Nunito',
  fontSize: 15,
  fontWeight: 900,
  textTransform: 'uppercase',
  maxWidth: 80,
  height: 40,
})

const PetChars: React.FC<{ chars: IPetChars }> = ({ chars, ...props }) => {
  return (
    <Root {...props}>
      {PET_CHARS.map(({ icon, type }, i) => (
        <Container key={i}>
          <img width={27} height={29} src={icon} />
          {chars[type]}
        </Container>
      ))}
    </Root>
  )
}

export default PetChars
