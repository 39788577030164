import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '.'

export interface WaitLobbyState {
  lobbyId?: string
}

export const initialState: WaitLobbyState = {
  lobbyId: undefined,
}

export const waitLobbySlice = createSlice({
  name: 'waitLobby',
  initialState,
  reducers: {
    setAlreadyConnectedOnce: (state, action: PayloadAction<string>) => {
      state.lobbyId = action.payload
    },
  },
})

export const { setAlreadyConnectedOnce } = waitLobbySlice.actions

export default waitLobbySlice.reducer

export const getWaitLobby = (state: RootState): WaitLobbyState['lobbyId'] =>
  state.waitLobby.lobbyId
