import { FC } from 'react'

import EggPlaceholder from './Eggs/EggPlaceholder'
import PetPlaceholder from './Pets/PetPlaceholder'

type EggAndPetPlaceholderProps = {
  activeTab: string
}

const EggAndPetPlaceholder: FC<EggAndPetPlaceholderProps> = ({ activeTab }) => {
  if (activeTab === 'pets') {
    return <PetPlaceholder />
  }

  return <EggPlaceholder />
}

export default EggAndPetPlaceholder
