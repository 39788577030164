import styled from '@emotion/styled'

type Props = {
  children: string
}

const HeaderText = styled.p`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`

const HeaderTextPage = ({ children }: Props): JSX.Element => {
  return <HeaderText>{children}</HeaderText>
}

export default HeaderTextPage
