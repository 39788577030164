import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import crownIcon from 'assets/svg/lobby/crown.svg'

import { LOCALES } from '@gatto/locales'
import { WaitLobbyUserInfo } from '@gatto/shared'

import {
  CrownIcon,
  Level,
  Name,
  NickName,
  NicknameLevel,
  PlayerInfoStyle,
} from './styles'

type PlayerInfoProps = {
  user: WaitLobbyUserInfo
  ownerId: number
  width?: string
}

export const PlayerInfo: FC<PlayerInfoProps> = ({ user, ownerId, width }) => {
  const { t } = useTranslation()
  const PetInfo = user.pet ? (
    <>
      <NickName>{user.pet.name}</NickName>
      <Level>{user.pet.evolution}</Level>
    </>
  ) : (
    <NickName>{t(LOCALES.petNotFound)}</NickName>
  )

  const LobbyOwnerIcon =
    user.id === ownerId ? <CrownIcon src={crownIcon} /> : ''

  return (
    <PlayerInfoStyle width={width ?? 'inherit'}>
      <NicknameLevel>
        {PetInfo}
        {LobbyOwnerIcon}
      </NicknameLevel>
      <Name>
        {user.about.firstName} {user.about.lastName}
      </Name>
    </PlayerInfoStyle>
  )
}
