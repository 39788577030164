import { BaseOlympicGame } from 'src/pixi/classes'

import { Pet as PetEngine } from '@gatto/engine'

export function getPetByUserId(
  this: BaseOlympicGame,
  userId: number,
): PetEngine | undefined {
  const user = this.engine?.userById(userId)
  if (!user) return
  return user.pet
}

export function getPetById(
  this: BaseOlympicGame,
  petId: string,
): PetEngine | undefined {
  const pet = this.engine?.petById(petId)
  if (!pet) return
  return pet
}

export function getTimerString(this: BaseOlympicGame): string {
  if (this.timeoutIn === 0) return ''
  const timeDiff = this.timeoutIn - this.engine.localTime()
  const time = timeDiff >= 0 ? timeDiff / 1000 : 0
  return `${String(Math.floor(time / 60)).padStart(2, '0')}:${String(
    Math.floor(time % 60),
  ).padStart(2, '0')}`
}
