import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import bridge, {
  BannerAdLayoutType,
  BannerAdLocation,
} from '@vkontakte/vk-bridge'
import { io, Socket } from 'socket.io-client'
import { MINI_APP } from 'src'
import { WAITROOM_URL } from 'src/config/constants'
import { getLocationSearch } from 'utils/getLocationSearch'

import { GreyPentagonSVG } from 'assets/svg/grey_pentagon'
import { Button, OnlineStack } from 'components/base'
import { PageContainer as Container } from 'components/page'
import { RootState, useAppSelector } from 'store/index'

import { LOCALES } from '@gatto/locales'
import {
  GameType,
  Platform,
  WaitroomNewGameRes,
  WaitroomSocketEvents,
  WaitroomUpdateList,
  WaitroomUpdateListRes,
} from '@gatto/shared'

import Corgy from '../../assets/loading/corgy.png'

const SVGdiv = styled.div``

const SVG = () => {
  return (
    <SVGdiv>
      <GreyPentagonSVG />
    </SVGdiv>
  )
}

const MainContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Bottom = styled.div`
  display: flex;
  width: 250px;
  margin-top: 40px;
`

const PageContainer = styled(Container)`
  align-items: center;

  padding: 70px 20px 60px;
`
const StyledButton = styled(Button)`
  width: 100%;
  font-size: 18px;
  height: 57px;
`

const CorgyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 271.633px;
  height: 261.932px;
  flex-shrink: 0;
  position: relative;
  align-items: center;
  justify-content: center;
`

const SearchText = styled.div<{ mt: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  > span {
    margin-top: ${(props) => props.mt && `${props.mt}px`};
    color: #fff;
    text-align: center;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  }
  > div {
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 11px;
  }
`

const Corgyinfo = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  position: absolute;
  height: 120px;
`

const Image = styled.img`
  margin-top: -24px;
  margin-bottom: -6px;
  width: 67px;
  height: 75px;
  flex-shrink: 0;
  object-fit: fill;
`

const Time = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);

  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 11px;
`

interface SearchPageLocationState {
  gameType: GameType
  petId: string
}

const getUser = (state: RootState) => state.user.user

const SearchPage = (): JSX.Element => {
  const { t } = useTranslation()
  const user = useAppSelector(getUser)
  const [waitingTime, setWaitingTime] = useState<number>(0)
  const location = useLocation()
  const navigate = useNavigate()
  const [socketClient, setSocketClient] = useState<Socket | null>(null)
  const [locationState, setLocationState] =
    useState<SearchPageLocationState | null>(null)
  const [users, setUsers] = useState<WaitroomUpdateList>([])

  useEffect(() => {
    let token = getLocationSearch()
    if (MINI_APP === Platform.TG) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      token = window.Telegram.WebApp.initData
    }
    const client = io(WAITROOM_URL, {
      transports: ['websocket'],
      auth: {
        token: `Bearer ${
          MINI_APP === Platform.TG ? token : getLocationSearch()
        }`,
      },
    })

    setSocketClient(client)
    setLocationState(location.state)
  }, [])

  useEffect(() => {
    setUsersArray([])
  }, [user])

  const setUsersArray = useCallback(
    (newUsers: WaitroomUpdateList) => {
      if (!user) {
        setUsers(newUsers)
        return
      }
      const avatar = newUsers.find((item) => item.userId === user._id)
      if (avatar) {
        setUsers(newUsers)
        return
      }
      setUsers([{ userId: user._id, avatar: user.about.photoUrl }, ...newUsers])
    },
    [user],
  )

  useEffect(() => {
    if (!socketClient || !locationState) return
    setTimeout(() => {
      socketClient.emit(
        WaitroomSocketEvents.Connect,
        {
          petId: locationState.petId,
          gameType: locationState.gameType,
        },
        (response: WaitroomNewGameRes) => {
          console.info('response from waitroom connect', response)
        },
      )
    }, 1000)

    socketClient.on(
      WaitroomSocketEvents.WaitroomNewGame,
      async (data: WaitroomNewGameRes) => {
        navigate('/olympicGame', {
          state: { anchorPetId: locationState.petId, data: data },
          replace: true,
        })
      },
    )

    socketClient.on(
      WaitroomSocketEvents.UpdateList,
      async (data: WaitroomUpdateListRes) => {
        setUsersArray(data.avatars)
      },
    )

    return () => {
      if (socketClient) {
        socketClient.disconnect()
      }
    }
  }, [socketClient, locationState])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setWaitingTime((prev) => prev + 1)
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    let adsTryInterval: NodeJS.Timer | undefined

    if (MINI_APP === Platform.VK) {
      adsTryInterval = setInterval(async () => {
        const response = await bridge.send('VKWebAppShowBannerAd', {
          banner_location: BannerAdLocation.BOTTOM,
          layout_type: BannerAdLayoutType.OVERLAY,
        })
        if (response.result === true) {
          clearInterval(adsTryInterval)
        }
      }, 3000)
    }
    return () => {
      clearInterval(adsTryInterval)
    }
  }, [])

  const onCancel = () => {
    navigate(-1)
  }

  return (
    <PageContainer>
      <SearchText mt={50}>
        <span>{locationState && t(locationState.gameType)}</span>
      </SearchText>
      <MainContainer>
        <CorgyContainer>
          <Corgyinfo>
            <Image src={Corgy} />
            <SearchText mt={0}>
              <span>{t(LOCALES.searchPlayers)}</span>
              <div>{t(LOCALES.waitingTime)}</div>
            </SearchText>
            <Time>
              {Math.floor(waitingTime / 60)}:
              {String(waitingTime % 60).padStart(2, '0')}
            </Time>
          </Corgyinfo>
          <SVG />
        </CorgyContainer>
        <Bottom>
          <StyledButton
            style={{ fontSize: '18px' }}
            // fontSize={18}
            // height={57}
            variant="dangerous"
            onClick={onCancel}
          >
            {t(LOCALES.cancel)}
          </StyledButton>
          <OnlineStack users={users} text={t(LOCALES.inSearch)} />
        </Bottom>
      </MainContainer>
    </PageContainer>
  )
}

export default SearchPage
