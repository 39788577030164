import { ReactNode, useEffect, useRef, useState } from 'react'
import { _ReactPixi, Container, useTick } from '@pixi/react'
import { useSpringValue } from '@react-spring/web'
import * as PIXI from 'pixi.js'

import { EntityShadow } from './EntityShadow'

type EntityProps = _ReactPixi.IContainer & {
  shadow?: boolean
  appear?: boolean
  onClick?: () => void
  rays?: ReactNode
  entity: ReactNode
  shadowProps?: _ReactPixi.IGraphics
  isEssenceMode?: boolean | null
  reactTracking?: {
    elementId: string // Элемент в который помещается питомец
    scrollableElementClass?: string
    scrollableElementId?: string // Элемент в котором происходит скролл, чтобы обновлять позицию
    stageId: string
  }
}

const useEntityAppearAnimation = () => {
  const animationOpacity = useSpringValue(0, {
    config: { mass: 10, tension: 1000, friction: 100 },
  })
  const animationOffsetY = useSpringValue(-16, {
    config: { mass: 10, tension: 1000, friction: 100 },
  })
  const [opacity, setOpacity] = useState(animationOpacity.get())
  const [offsetY, setOffsetY] = useState(animationOffsetY.get())

  // Анимация появления
  useEffect(() => {
    animationOpacity.start(1)
    animationOffsetY.start(0)
  }, [animationOffsetY, animationOpacity])

  useTick(() => {
    setOpacity(animationOpacity.get())
    setOffsetY(animationOffsetY.get())
  })

  return { opacity, offsetY }
}

/**
 * Базовый компонент сущности
 * @component
 */
export function Entity({
  entity,
  rays,
  shadow = true,
  onClick = () => void 0,
  appear,
  shadowProps,
  reactTracking,
  isEssenceMode,
  ...rest
}: EntityProps): React.ReactElement {
  const containerRef = useRef<PIXI.Container>(null)
  const { opacity } = useEntityAppearAnimation()

  useEffect(() => {
    // Код для трекинга pixi элементом положения реакт элемента
    if (reactTracking?.elementId && containerRef) {
      const object = document.getElementById(reactTracking.elementId)
      const stage = document.getElementById(reactTracking.stageId)
      if (!object || !stage)
        return console.error('Undefined reactTracking object or stage')

      const scrollableElement = reactTracking.scrollableElementId
        ? document.getElementById(reactTracking.scrollableElementId)
        : reactTracking.scrollableElementClass
        ? document.getElementsByClassName(
            reactTracking.scrollableElementClass,
          )[0]
        : undefined

      const resize = () => {
        const objectReact = object.getBoundingClientRect()
        const stageReact = stage.getBoundingClientRect()

        if (!containerRef.current) return
        containerRef.current.x = objectReact.x - stageReact.x
        containerRef.current.y = objectReact.y - stageReact.y
      }
      resize()

      if (!scrollableElement || !containerRef.current) return

      scrollableElement.addEventListener('scroll', resize)

      return () => {
        if (!scrollableElement || !containerRef.current) return
        scrollableElement.removeEventListener('scroll', resize)
      }
    }
  }, [reactTracking, containerRef])

  return (
    <Container
      ref={containerRef}
      cursor={
        location.search.indexOf('mode=cross') !== -1 || isEssenceMode
          ? 'default'
          : 'pointer'
      }
      eventMode="static"
      onpointerdown={onClick}
      sortableChildren
      alpha={appear ? opacity : 1}
      {...rest}
    >
      {shadow && <EntityShadow alpha={opacity} {...shadowProps} />}
      {rays}
      {entity}
    </Container>
  )
}
