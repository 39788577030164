import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Stage } from '@pixi/react'
import bridge from '@vkontakte/vk-bridge'
import { Spinner } from '@vkontakte/vkui'
import { useCloudStorage } from '@vkruglikov/react-telegram-web-app'
import { MINI_APP } from 'src'
import useOnBoarding from 'src/hooks/router/useOnBoarding'
import { makeRequest } from 'utils/api'
import { getVKStorage, setVKStorage } from 'utils/vkStorage'

import buttonIcon from 'assets/svg/selectPet/buttonIcon.svg'
import placehoder from 'assets/svg/selectPet/placeholder.svg'
import {
  BackButton,
  Button,
  Menu,
  Placeholder,
  Row,
  Wallet,
} from 'components/base'
import { Container } from 'components/page'
import { Entity, EntityPet, StageWithMaskWrapper } from 'components/pixi'
import { HeaderText } from 'components/typography'
import { useAppDispatch, useAppSelector } from 'store/index'
import { setOnboarding } from 'store/onboarding'
import {
  getSelectedPet,
  getTempSelectedPet,
  selectPet,
  setSelectedPet,
} from 'store/selectPet'

import { LOCALES } from '@gatto/locales'
import { GameType, PetSearchRes, Platform } from '@gatto/shared'

import CardList from './CardList'
import Search from './Search'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`

const SearchTitle = styled.div`
  color: #fff;
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  margin-left: 20px;
`

const ButtonIcon = styled.img``

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: calc(100% - 40px);
`

const PAGE_HEADER_OFFSET = 233

interface SelectPetLocation {
  headerText: string
  gameType: GameType
  redirectTo?: string
  redirectReplace?: boolean
}

export const SelectPetPage: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const onBoarding = useOnBoarding()
  const selectedPet = useAppSelector(getSelectedPet)
  const menu = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const { setItem, getItems, getItem } = useCloudStorage()

  const [locationState, setLocationState] = useState<SelectPetLocation | null>(
    null,
  )

  const [pets, setPets] = useState<PetSearchRes[]>([])
  const [filteredPets, setFilteredPets] = useState<PetSearchRes[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isFirstLoading, setIsFirstLoading] = useState(true)

  useEffect(() => {
    setLocationState(location.state)
    makeRequest('pet.search', { name: '' })
      .then((pets) => {
        setPets(pets)
        setFilteredPets(pets)
        setIsLoading(false)
      })
      .catch(() => {
        setPets([])
        setFilteredPets([])
        setIsLoading(false)
      })

    if (MINI_APP === Platform.VK) {
      bridge
        .send('VKWebAppStorageGet', { keys: ['selectedPet'] })
        .then((data) => dispatch(setSelectedPet(data.keys[0]?.value)))
    }

    if (MINI_APP === Platform.TG) {
      getItem('selectedPet').then((data) => dispatch(setSelectedPet(data)))
    }
  }, [])

  // Онбординг
  useEffect(() => {
    if (!isLoading && locationState) {
      const onboarding = async () => {
        let cross, stadium, pool
        if (MINI_APP === Platform.VK) {
          const { onboarding_stadium2, onboarding_pool, onboarding_cross } =
            await getVKStorage(
              'onboarding_stadium2',
              'onboarding_pool',
              'onboarding_cross',
            )

          stadium = onboarding_stadium2
          pool = onboarding_pool
          cross = onboarding_cross
        }

        if (MINI_APP === Platform.TG) {
          cross = await getItems(['onboarding_cross'])
          stadium = await getItems(['onboarding_stadium2'])
          pool = await getItems(['onboarding_pool'])
          cross = Object.values(cross)[0]
          stadium = Object.values(stadium)[0]
          pool = Object.values(pool)[0]
        }
        dispatch(setOnboarding(['cross', cross === 'true']))
        switch (locationState.gameType) {
          case GameType.Race:
            if (stadium === 'true') return
            if (MINI_APP === Platform.VK) {
              await setVKStorage('onboarding_stadium2', 'true')
            }
            if (MINI_APP === Platform.TG) {
              await setItem('onboarding_stadium2', 'true')
            }
            await onBoarding('stadium')
            break
          case GameType.Swim:
            if (pool === 'true') return
            if (MINI_APP === Platform.VK) {
              await setVKStorage('onboarding_pool', 'true')
            }
            if (MINI_APP === Platform.TG) {
              await setItem('onboarding_pool', 'true')
            }
            await onBoarding('pool')
            break
          default:
            return
        }
        dispatch(setOnboarding(['welcome', true]))
      }
      onboarding()
    }
  }, [isLoading, locationState])

  const onStart = useCallback(() => {
    dispatch(selectPet())
    if (MINI_APP === Platform.VK) {
      bridge.send('VKWebAppStorageSet', {
        key: 'selectedPet',
        value: selectedPet || '',
      })
    }

    if (MINI_APP === Platform.TG) {
      setItem('selectedPet', selectedPet || '')
    }

    if (!locationState) return
    const url = locationState.redirectTo ?? '/gameSearch'
    navigate(url, {
      state: { gameType: locationState.gameType, petId: selectedPet },
      replace: locationState.redirectReplace ?? true,
    })
  }, [locationState, selectedPet])

  const buttonText = !locationState?.redirectTo
    ? LOCALES.choosePet_startRoom
    : LOCALES.choosePet_startLobby

  return (
    <Container>
      <Wallet mode={'horizontal'} />
      <Row
        style={{
          boxSizing: 'border-box',
          minHeight: '40px',
          marginBottom: '20px',
          marginTop: '8px',
          marginRight: 'calc(20px + 30px + 5px)',
          color: '#fff',
          fontFamily: 'Nunito',
        }}
      >
        <BackButton
          onClick={() =>
            locationState?.redirectTo
              ? navigate(locationState?.redirectTo)
              : navigate(-1)
          }
        />
        <HeaderText>{locationState && locationState.headerText}</HeaderText>
      </Row>
      {isLoading ? (
        <Spinner size="large" style={{ margin: '20px 0' }} />
      ) : filteredPets.length === 0 && isFirstLoading ? (
        <Row
          style={{
            flexDirection: 'column',
            justifyContent: 'flex-start',
            gap: '15px',
          }}
        >
          <Placeholder
            title={t(LOCALES.choosePet_dontHavePets)}
            subtitle={t(LOCALES.choosePet_dontHavePetsSubtitle)}
            buttonText={t(LOCALES.choosePet_dontHavePetsBtn)}
            isFullscreen={true}
            img={placehoder}
            buttonIcon={<ButtonIcon src={buttonIcon} />}
            onClick={() => {
              navigate('/')
            }}
          />
        </Row>
      ) : (
        <>
          <Wrapper>
            <SearchTitle>{t(LOCALES.choosePet)}:</SearchTitle>
            <Search
              pets={pets}
              setFilteredPets={setFilteredPets}
              setIsFirstLoading={setIsFirstLoading}
            />
          </Wrapper>

          {filteredPets.length === 0 ? (
            <Row
              style={{
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <Placeholder
                img={placehoder}
                title={t(LOCALES.choosePet_notFound)}
                isFullscreen={true}
              />
            </Row>
          ) : (
            <>
              <Menu
                id="menu"
                ref={menu}
                ltRow={
                  filteredPets.length < Math.floor(window.innerWidth / 180)
                }
                style={{ paddingBottom: '110px' }}
              >
                <CardList pets={filteredPets} />
              </Menu>

              <StageWithMaskWrapper offsetY={PAGE_HEADER_OFFSET}>
                <Stage
                  id="selectPetStage"
                  width={window.innerWidth}
                  height={window.innerHeight - PAGE_HEADER_OFFSET}
                  options={{
                    backgroundAlpha: 0,
                    antialias: true,
                    resolution: window.devicePixelRatio || 1,
                    autoDensity: true,
                  }}
                >
                  {filteredPets.map((item, key) => (
                    <Entity
                      key={key}
                      reactTracking={{
                        elementId: `pet_` + item._id,
                        stageId: 'selectPetStage',
                        scrollableElementId: 'menu',
                      }}
                      shadow={false}
                      entity={
                        item.basePet && (
                          <EntityPet
                            basePet={item.basePet}
                            squeeze={false}
                            x={50}
                            y={100}
                            scale={0.55}
                            skins={item.userSkinPets[0]?.skinData}
                          />
                        )
                      }
                    />
                  ))}
                </Stage>
              </StageWithMaskWrapper>
            </>
          )}
          <ButtonWrapper>
            <Button
              variant="success"
              width="100%"
              disabled={!selectedPet}
              onClick={onStart}
            >
              {t(selectedPet ? buttonText : LOCALES.choosePet)}
            </Button>
          </ButtonWrapper>
        </>
      )}
    </Container>
  )
}
