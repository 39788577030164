import React, { FC } from 'react'
import bridge, { EAdsFormats } from '@vkontakte/vk-bridge'
import { MINI_APP } from 'src'
import { YM_COUNTER_ID } from 'src/config/constants'
import useScreenDimensions from 'src/hooks/useScreenDimensions'
import { Entity, EntityPet, EntityRays } from 'components/pixi'
import { useAppSelector } from 'store'
import { getPetSkins } from 'store/region'

import { BasePet, Platform, PrizeResult } from '@gatto/shared'

import { GiftAnimationContainer } from './GiftAnimationContainer'

type RegionPetEntityProps = {
  handleEntityClick: () => void
  basePet: BasePet
  prize: PrizeResult | null
  setPrize: React.Dispatch<React.SetStateAction<PrizeResult | null>>
  isPrizeReady: boolean
}

const RegionPetEntity: FC<RegionPetEntityProps> = ({
  basePet,
  handleEntityClick,
  prize,
  setPrize,
  isPrizeReady,
}) => {
  const skins = useAppSelector(getPetSkins)

  const screenDimensions = useScreenDimensions()

  return (
    <>
      <Entity
        onClick={handleEntityClick}
        x={screenDimensions.width / 2}
        y={screenDimensions.height / 2}
        visible={!location.search.startsWith('?popout=petName')}
        entity={
          <EntityPet basePet={basePet} scale={1.2} y={100} skins={skins} />
        }
        shadow={true}
        shadowProps={{
          y: 90,
        }}
        rays={
          isPrizeReady && (
            <EntityRays
              isInvert={true}
              x={135}
              y={25}
              width={220}
              height={220}
            />
          )
        }
      />
      {prize && (
        <GiftAnimationContainer
          prizes={prize}
          onEnd={async () => {
            setPrize(null)
            if (MINI_APP === Platform.TG) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              ym(YM_COUNTER_ID, 'reachGoal', 'gift_open')
            }

            if (MINI_APP === Platform.VK) {
              const data = await bridge.send('VKWebAppCheckBannerAd')

              if (data.result) {
                bridge.send('VKWebAppHideBannerAd')
              }

              bridge.send('VKWebAppShowNativeAds', {
                ad_format: EAdsFormats.INTERSTITIAL,
              })
            }
          }}
        />
      )}
    </>
  )
}

export default RegionPetEntity
