import * as PIXI from 'pixi.js'

import { SpriteOptions } from '../types/sprite'

import { optionInstaller } from './../utils/optionInstaller'

export class Sprite extends PIXI.Sprite {
  optionInstaller = optionInstaller

  constructor(texture: PIXI.Texture, options: SpriteOptions = {}) {
    super(texture)
    this.optionInstaller(options)
  }
}
