import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Types as MongooseTypes } from 'mongoose'
import { makeRequest } from 'src/utils/api'

import { useAppDispatch } from 'store'
import { updateRegionEgg } from 'store/region'
import { updateUserRegion } from 'store/user'

import { PetRes, PopulatedUserRegion } from '@gatto/shared'

type AdditionalInfo = {
  egg_id: MongooseTypes.ObjectId | string
  regionToPlace?: MongooseTypes.ObjectId
  isPlacedFromMap: boolean
}

const usePlaceEgg = (): ((params: AdditionalInfo) => Promise<void>) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const region: PopulatedUserRegion | undefined =
    state && state.region ? state.region : undefined
  const regionIdFromURL = region && region._id

  const placeEgg = useCallback(
    async (params: AdditionalInfo) => {
      const regionId = params.regionToPlace
        ? params.regionToPlace
        : regionIdFromURL
      if (!regionId || !params.egg_id) {
        return
      }

      const nextEgg = await makeRequest('egg.place', {
        eggId: params.egg_id.toString(),
        regionId,
      })

      dispatch(
        updateRegionEgg({
          egg: nextEgg,
        }),
      )
      dispatch(updateUserRegion({ egg: nextEgg, regionId }))
      if (!params.isPlacedFromMap) {
        navigate(-1)
      }
    },
    [regionIdFromURL, dispatch, navigate],
  )
  return placeEgg
}

export default usePlaceEgg
