import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { useModalRootContext } from '@vkontakte/vkui'
import { PriceInfo } from 'popouts/StorePopout/StoreInfoPopout'
import { ModalsProps } from 'src/const/router'
import { useModal } from 'src/hooks'

import Ton from 'assets/png/ton/Ton.png'
import { Button } from 'components/base'
import Modal from 'components/modals/Modal'

import { LOCALES } from '@gatto/locales'
import { mintPrice } from '@gatto/shared'

const StyledButton = styled(Button)`
  width: 100%;
  //padding: 14px 14px 17px 14px;
  height: 50px;
`

const StyledPurpleButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  width: 100%;
  padding: 10px;
  padding-bottom: 14px;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(153, 105, 255, 0.1) 0%,
    rgba(116, 50, 255, 0.1) 100%
  );
  box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.05) inset;
  border: none;
  height: 50px;

  cursor: pointer;

  transition: all 120ms ease;

  &:not([disabled]):active {
    scale: 0.95;
    opacity: 0.9;
  }
`

const StyledPurpleSpan = styled.span`
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  background-image: linear-gradient(90deg, #9666ff, rgba(116, 50, 255, 1));
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > span {
    max-width: 269.488px;
    color: rgba(136, 136, 136, 1);
    text-align: center;
    font-family: Nunito;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px; /* 115.385% */
  }
`

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  align-self: stretch;
`

const TransformWithClothesMain = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`

const TransformButton = styled(Button)`
  width: 35px;
  height: 35px;
`

const TransformText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > span {
    color: #000;
    text-align: center;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px; /* 107.143% */
  }
`

const TransformWithClothes = () => {
  const [clicked, setClicked] = useState(false)
  return (
    <></>
    // <TransformWithClothesMain>
    //   <TransformButton
    //     onClick={() => setClicked(!clicked)}
    //     // boxShadow={!clicked ? 0.07 : 0.15}
    //     iconComponent={
    //       clicked && (
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           width="14"
    //           height="11"
    //           viewBox="0 0 14 9"
    //           fill="none"
    //         >
    //           <path
    //             d="M1.3457 4.57999L5.26571 8.5L12.2657 1.5"
    //             stroke="white"
    //             strokeWidth="2.1401"
    //             strokeLinecap="round"
    //           />
    //         </svg>
    //       )
    //     }
    //     variant={clicked ? 'green' : 'translucent'}
    //   ></TransformButton>
    //   <TransformText>
    //     <span>Превратить с одеждой</span>
    //   </TransformText>
    // </TransformWithClothesMain>
  )
}

const Currency = styled.img`
  width: 13.501px;
  height: 15px;
`

type TransformPetToNFTModalProps = ModalsProps['transformPetToNFT']

const TransformPetToNFTModal: FC<TransformPetToNFTModalProps> = ({
  setBackground,
  petId,
  walletId,
  pet,
}) => {
  const { t } = useTranslation()
  const { updateModalHeight } = useModalRootContext()

  useEffect(() => {
    updateModalHeight()
  }, [updateModalHeight])

  const openWalletChoose = useModal('walletChoose', true, {
    petId,
    type: 'minting',
    text: '',
  })

  const openModalAreYouSure = useModal('warnTransform', true, {
    walletId,
    petId,
    pet,
  })

  if (!pet) return

  const price = mintPrice[pet.evolution] as number

  return (
    <Modal
      type="minting"
      header={t(LOCALES.convertPetToNTFConfirm)}
      headerPosition="center"
      setBackground={setBackground}
      background={false}
    >
      <Main>
        <Text>
          <span>{t(LOCALES.convertPetToNTFInfo)}</span>
        </Text>
        <TransformWithClothes></TransformWithClothes>
        {/*<StyledPurpleButton onClick={() => openWalletChoose()}>*/}
        {/*  <StyledPurpleSpan>{t(LOCALES.changeWallet)}</StyledPurpleSpan>*/}
        {/*</StyledPurpleButton>*/}
        <StyledButton onClick={() => openModalAreYouSure()} variant={'viol'}>
          {t(LOCALES.convertIntoNFT)}
          <PriceInfo>
            {price} <Currency src={Ton} />
          </PriceInfo>
        </StyledButton>
      </Main>
    </Modal>
  )
}

export default TransformPetToNFTModal
