import React, { FC, useState } from 'react'
import { Sprite, useTick } from '@pixi/react'
import * as PIXI from 'pixi.js'
import useScreenDimensions from 'src/hooks/useScreenDimensions'

interface CloudProps {
  cloudObject: PIXI.Texture | undefined
  x: number
  y: number
  speedX: number
  speedY: number
  sizeScale: number
  zIndex: number
}

const Cloud: FC<CloudProps> = React.memo(
  ({ cloudObject, x, y, speedX, speedY, sizeScale, zIndex }) => {
    const [posX, setPosX] = useState(x)
    const [posY, setPosY] = useState(y)

    const screenDimensions = useScreenDimensions()

    const width = screenDimensions.width
    const height = screenDimensions.height

    useTick((delta) => {
      if (cloudObject) {
        setPosX((prev) => prev + speedX * delta)
        setPosY((prev) => prev - speedY * delta)

        // if (posX === x && posY === y) {
        //   if (xi >= posX + width / 2 || yi >= posY + height / 2) {
        //     setXI(0)
        //     setYI(0)
        //     setPosX(Math.random() * -width)
        //     setPosY(height + Math.random() * (height * 4))
        //   }
        // }

        if (
          posX > width + cloudObject.width / 2 ||
          posY < -cloudObject.height / 2
        ) {
          setPosX(-width / 4 - cloudObject.width + Math.random())
          setPosY(height / 4 + Math.random() * height)
        }
      }
    })

    if (!cloudObject) return

    return (
      <Sprite
        texture={cloudObject}
        x={posX}
        y={posY}
        scale={sizeScale}
        zIndex={zIndex}
      />
    )
  },
)

Cloud.displayName = 'Cloud'

export default Cloud
