import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import { Button } from 'components/base'
import { Popout, StatusType, Subheader } from 'components/base/Popout'

import { LOCALES } from '@gatto/locales'

import PromoSuccessImage from '/src/assets/svg/popouts/promo-success-big.svg'

const Image = styled.img`
  width: 126.7px;
  height: 160.4px;
  margin: 0 auto;
  border-radius: 20px;
`

const PromoSuccessPopout: FC = () => {
  const { t } = useTranslation()
  const [status, setStatus] = useState<StatusType>()

  return (
    <Popout
      type="default"
      background={true}
      width="284px"
      isFullScreen={false}
      status={status}
      setStatus={setStatus}
    >
      <Image src={PromoSuccessImage} />
      <Subheader>{t(LOCALES.promoActivated)}</Subheader>
      <Button variant="success">{t(LOCALES.look)}</Button>
    </Popout>
  )
}

export default PromoSuccessPopout
