import styled from '@emotion/styled'
import Corgy from 'src/assets/loading/corgy.png'
import { ProgressBar } from 'src/components/base'

import { useAppSelector } from 'store/index'

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 99;
`

const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 15px;
  > span {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 11.103px;
  }
`
const Image = styled.img`
  width: 67px;
  height: 75px;
  flex-shrink: 0;
`

function StartupLoadPage(): React.ReactElement {
  const percent = useAppSelector((state) => state.server.pixiLoadingProgress)

  return (
    <Main>
      <Head>
        <Image src={Corgy}></Image>
        <span>Загрузка...</span>
        <ProgressBar progressWidth={150} progressPercentage={percent} />
      </Head>
    </Main>
  )
}

export default StartupLoadPage
