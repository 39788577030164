import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import StatusAlert from 'src/popouts/Status/StatusAlertContent'

import { Popout, StatusType } from 'components/base/Popout'

import { LOCALES } from '@gatto/locales'

const EssenceSuccessPopout: FC = () => {
  const { t } = useTranslation()
  const [status, setStatus] = useState<StatusType>()

  const navigate = useNavigate()

  useEffect(() => {
    const handlePopstate = () => {
      navigate('storage', { replace: false })
    }

    window.addEventListener('popstate', handlePopstate)

    return () => {
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [])

  return (
    <Popout
      type="default"
      background={true}
      width="330px"
      isFullScreen={false}
      status={status}
      setStatus={setStatus}
      navigatePath={'storage'}
    >
      <StatusAlert subheader={t(LOCALES.essenceUseSuccess)} type="success" />
    </Popout>
  )
}

export default EssenceSuccessPopout
