import React, { HTMLProps } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { ClothingSVG, HouseSVG, NFTStarSVG } from 'src/config/assets'
import { useModal, usePopout } from 'src/hooks'

import eggButtonIcon from 'assets/svg/egg/eggIcon.svg'
import { Button } from 'components/base'

import { LOCALES } from '@gatto/locales'
import { Platform, PopulatedPet, PopulatedUserRegion } from '@gatto/shared'

import { useWalletCheckWithUpdate } from '../../hooks/useWalletCheck'
import { MINI_APP } from '../../index'
const Root = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
  alignItems: 'center',
  padding: 4,
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: 14,
  zIndex: 10,
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  bottom: 16,
  pointerEvents: 'all',
})

const CustomButton = styled.button({
  width: 48,
  height: 48,
  borderRadius: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: 4,
  cursor: 'pointer',
  border: 'none',
  whiteSpace: 'nowrap',
  flex: '1 0',
  background: 'var(--petTabbarButtonBackground)',
  boxShadow: '0 -4px 0 0 inset rgba(0, 0, 0, 0.12)',
  transition: 'all 120ms ease',
  '&:active': {
    scale: '0.95',
    opacity: 0.9,
  },
})

const HomeButton = styled(CustomButton)({
  '--petTabbarButtonBackground': '#F0FFDB',
})

const ClothingButton = styled(CustomButton)({
  '--petTabbarButtonBackground':
    'linear-gradient(180deg, #EEF5FF 0%, #E7EFFF 100%), #FFF1F0',
  paddingLeft: '1px',
})

const NFTButton = styled(CustomButton)({
  width: 'fit-content',
  padding: 10,
  paddingBottom: 13,
  fontFamily: 'Nunito',
  color: 'white',
  fontSize: 15,
  gap: 6,
  fontWeight: 800,
  textTransform: 'uppercase',
  textShadow: '0 1.5px rgba(39, 34, 64, 0.35)',
  '--petTabbarButtonBackground':
    'linear-gradient(180deg, #9969FF 0%, #7432FF 100%)',
})

const Img = styled.img`
  width: 27px;
  height: 26px;
`

const ButtonWrapper = styled.div`
  width: 227px;
  display: flex;
  flex-direction: column;
`

type TabbarProps = {
  page: 'egg' | 'pet'
  props?: HTMLProps<HTMLDivElement>
  isPlaced?: boolean
  isHatched?: boolean
  region: PopulatedUserRegion
  pet?: PopulatedPet
  onEggPlace?: () => void
}

const Tabbar: React.FC<TabbarProps> = ({
  page,
  isPlaced,
  isHatched,
  region,
  pet,
  onEggPlace,
  ...props
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [data, updateData] = useWalletCheckWithUpdate(false)

  const openModaltransformPetToNFT = useModal('transformPetToNFT', false, {
    walletId: data[0]?._id,
    petId: region.pet?._id.toString(),
    pet: pet,
  })

  const eggsAndPetsPopout = usePopout('eggsAndPets', false, {
    region,
  })
  const skinsPopout = usePopout('skinsPopout', false, {})
  const openModalNFT = useModal('walletChoose', false, {
    petId: region.pet?._id.toString(),
    type: 'minting',
    text: `${t(LOCALES.chooseWalletToMint)}`,
  })
  const walletExistPopout = usePopout('walletNotExist', false)

  const mintingHook = () => {
    openModaltransformPetToNFT()
  }

  const handleClick = () => {
    if (!isPlaced) {
      eggsAndPetsPopout()
    }

    if (isHatched && onEggPlace) {
      onEggPlace()
    }
  }

  return (
    <Root {...props}>
      <HomeButton
        onClick={() => {
          navigate(-1)
        }}
      >
        <HouseSVG />
      </HomeButton>
      {page === 'pet' && (
        <ClothingButton onClick={() => skinsPopout()}>
          <ClothingSVG />
        </ClothingButton>
      )}
      {page === 'pet'
        ? MINI_APP === Platform.TG && (
            <>
              <NFTButton
                onClick={async () => {
                  const userWallets = data.length ? data : await updateData()
                  if (userWallets.length) {
                    mintingHook()
                  } else {
                    walletExistPopout()
                  }
                }}
              >
                <NFTStarSVG />
                {t(LOCALES.convertIntoNFT)}
              </NFTButton>
            </>
          )
        : (!isPlaced || (isPlaced && isHatched)) && (
            <ButtonWrapper>
              <Button variant="success" onClick={handleClick}>
                <Img src={eggButtonIcon} />
                {!isPlaced && t(LOCALES.settle)}
                {isHatched && t(LOCALES.open)}
              </Button>
            </ButtonWrapper>
          )}
    </Root>
  )
}

export default Tabbar
