import { useEffect, useState } from 'react'
import { makeRequest } from 'utils/api'

import { PetRatingCard } from 'components/Rating/RatingCard'

import { GameLeague } from '@gatto/shared'

const useRating = (league?: GameLeague): { ratingList: PetRatingCard[] } => {
  const [ratingList, setRatingList] = useState<PetRatingCard[]>([])

  const getTopRating = async (): Promise<void> => {
    try {
      const response = await makeRequest('rating.getTopRating', { league })
      const res: PetRatingCard[] = []
      response.rating.forEach((r, i) => {
        const playerInfo = response.players.filter((p) => p._id === r.owner)[0]
        const rCard: PetRatingCard = {
          position: i + 1,
          name: playerInfo
            ? String(
                playerInfo.about.firstName + ' ' + playerInfo.about.lastName,
              )
            : 'undefined',
          nickname: r.name,
          avatar: playerInfo ? playerInfo.about.photoUrl : 'undefined',
          petId: r._id,
          owner: r.owner,
          rating: r.rating,
          level: r.level,
          chars: r.chars,
          evolution: r.evolution,
          basePet: r.basePet[0],
          skins: r.userSkinPets[0]?.skinData || [],
        }
        res.push(rCard)
      })
      setRatingList((prev) => [...res])
    } catch (e) {
      throw new Error()
    }
  }

  useEffect(() => {
    getTopRating()
  }, [league])

  return { ratingList }
}

export default useRating
