import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { makeRequest } from 'utils/api'

import { PopulatedUserEssence, WarehouseGoodsGetRes } from '@gatto/shared'

export interface storageSlice {
  goods: WarehouseGoodsGetRes[]
  essence: PopulatedUserEssence | null
  isEssenceApplying: boolean | null
  isApplied: boolean
  isTabbarHidden: boolean
}

const initialState: storageSlice = {
  goods: [],
  essence: null,
  isEssenceApplying: null,
  isApplied: false,
  isTabbarHidden: false,
}

export const getGoods = createAsyncThunk(
  'storage/wareGoods',
  async (sortBy: string | undefined) => {
    return await makeRequest('warehouseGoods.get', {
      type: sortBy,
    })
  },
)

const storageSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    //TODO ОПТИМИЗИРОВАТЬ В ОДИН
    setEssence(state, action) {
      state.essence = action.payload
    },
    setIsTabbarHidden(state, action) {
      state.isTabbarHidden = action.payload
    },
    setIsEssence(state, action) {
      state.isEssenceApplying = action.payload
    },
    setIsApplied(state, action) {
      state.isApplied = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGoods.pending, (state) => {
        state.goods = []
      })
      .addCase(getGoods.fulfilled, (state, action) => {
        state.goods = action.payload as WarehouseGoodsGetRes[]
      })
  },
})

export const { setIsEssence, setEssence, setIsApplied, setIsTabbarHidden } =
  storageSlice.actions

export default storageSlice.reducer
