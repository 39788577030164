import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { usePopout } from 'src/hooks'
import { getBiomeColor } from 'utils/getBiomColor'

import FirstPlacePNG from 'assets/png/rating/first_place.png'
import SecondPlacePNG from 'assets/png/rating/second_place.png'
import StarPNG from 'assets/png/rating/star.png'
import ThirdPlacePNG from 'assets/png/rating/third_place.png'

import { LOCALES } from '@gatto/locales'
import { BasePet, Biome, PetChars, PetSkins } from '@gatto/shared'

const Container = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
  align-content: center;
  font-family: Nunito;
  color: white;
  cursor: pointer;
  width: 100%;
`
const Position = styled.div`
  display: flex;
  padding: 4.5px 8.5px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.1);
  leading-trim: both;
  text-edge: cap;
  font-style: normal;
  font-size: 12px;
  font-wight: 600;
  margin-right: 10px;
`

const PlayerCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 283px;
  width: 100%;
`

const InfoBlock = styled.div`
  display: inline-flex;
  align-items: center;
  max-width: 100px;
`

const Avatar = styled.div<{ background?: string }>`
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 18px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: transparent;
  margin-right: 5px;
  ${(props) => (props.background ? 'background:' + props.background + ';' : '')}
  ${(props) =>
    props.background
      ? 'box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1) inset;'
      : ''}
`

const PlayerInfo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 122px;
  padding-right: 5px;
`

const NicknameLevel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
`

const NickName = styled.div`
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;
  font-weight: 700;
  line-height: normal;
  leading-trim: both;
  text-edge: cap;
  font-style: normal;
`

const Level = styled.div`
  display: flex;
  padding: 5px;
  align-content: center;
  border-radius: 5px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(255, 255, 255, 0.15) 100%
  );
  leading-trim: both;
  text-edge: cap;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 9px;
`

const Name = styled.div`
  color: rgba(255, 255, 255, 0.7);
  width: 100%;
  word-wrap: break-word;
  leading-trim: both;
  text-edge: cap;
  font-family: Nunito;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

const Abilities = styled.div`
  display: inline-flex;
  padding: 8px;
  align-items: center;
  align-self: right;
  border-radius: 15px;
  max-width: 83px;
  width: 100%;
  gap: 5px;
  max-height: 40px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0.15) 100%
    );
  align-self: center;
`

const Star = styled.div`
  display: flex;
  padding: 5px;
  max-width: 25px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.1);
`

const StarImg = styled.img`
  align-self: center;
`

const AbilitiesInfo = styled.div`
  display: inline-flex;
  flex-direction: column;
`

const AbilityNumber = styled.div`
  font-size: 12px;
  font-weight: 600;
  leading-trim: both;
  text-edge: cap;
  font-style: normal;
`

const AbilityText = styled.div`
  font-size: 8px;
  font-weight: 400;
  leading-trim: both;
  text-edge: cap;
  font-style: normal;
`

const TopThree = styled(Position)`
  max-width: 25px;
  background: none;
  position: relative;
`

export type PetRatingCard = {
  position: number
  name: string
  nickname: string
  avatar: string
  petId: string
  owner: number
  rating: number
  level: number
  chars: PetChars
  evolution: number
  basePet?: BasePet
  skins: PetSkins[]
}

interface RatingCardProps {
  pet: PetRatingCard
  avatarBoxId?: string
  biome?: Biome
}

export const RatingCard: FC<RatingCardProps> = ({
  pet,
  avatarBoxId,
  biome,
}) => {
  const { t } = useTranslation()
  const props = {
    basePet: pet.basePet,
    owner: pet.name,
    avatar: pet.avatar,
    petChars: pet.chars,
    petLevel: pet.level,
    petName: pet.nickname,
    petEvolution: pet.evolution,
    skins: pet.skins,
  }
  const openPopout = usePopout('petInfo', false, props)
  pet.basePet?.allowedRegion

  return (
    <Container onClick={() => openPopout()}>
      {[1, 2, 3].includes(pet.position) ? (
        <TopThree>
          <img
            width={52}
            height={52}
            src={
              pet.position === 1
                ? FirstPlacePNG
                : pet.position === 2
                ? SecondPlacePNG
                : ThirdPlacePNG
            }
          />
        </TopThree>
      ) : (
        <Position>{pet.position}</Position>
      )}
      <PlayerCard>
        <InfoBlock>
          <Avatar id={avatarBoxId} background={biome && getBiomeColor(biome)}>
            {/* <PetImage basePet={pet.basePet} width={60} height={60} /> */}
          </Avatar>
          <PlayerInfo>
            <NicknameLevel>
              <NickName>{pet.nickname}</NickName>
              <Level>{pet.evolution}</Level>
            </NicknameLevel>
            <Name>{pet.name}</Name>
          </PlayerInfo>
        </InfoBlock>
        <Abilities>
          <Star>
            <StarImg src={StarPNG} />
          </Star>
          <AbilitiesInfo>
            <AbilityNumber>{pet.rating !== -1 ? pet.rating : 0}</AbilityNumber>
            <AbilityText>{t(LOCALES.skills)}</AbilityText>
          </AbilitiesInfo>
        </Abilities>
      </PlayerCard>
    </Container>
  )
}
