import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import bridge from '@vkontakte/vk-bridge'
import { Popouts } from 'const/router'
import { SkinBackground, SkinImg, SkinRarity } from 'popouts/Skins/SkinsPopout'
import { APP_LANG } from 'src/config/constants'
import { EVENT_ICONS } from 'src/config/eventSettings'
import { usePopout } from 'src/hooks'
import { makeRequest } from 'utils/api'
import { fixEndingByNumber } from 'utils/i18/fixRussianWords'
import { sendPayment } from 'utils/ton/connectWallet'
import { handleGetTonPayments } from 'utils/ton/GetPayments'

import Ton from 'assets/png/ton/Ton.png'
import CurrencyIcon from 'assets/svg/coin.svg'
import DiamondPaw from 'assets/svg/diamond_paw.svg'
import { Button, Header, Subheader } from 'components/base'
import { Popout, StatusType } from 'components/base/Popout'
import { useAppDispatch, useAppSelector } from 'store'
import { getPaymentProcess, setPaymentProcess } from 'store/payment'
import { decrementBalance, incrementBalance } from 'store/user'

import { LOCALES } from '@gatto/locales'
import {
  AwardCurrencyType,
  CurrencyType,
  DonateGetRes,
  PetRarity,
  Platform,
} from '@gatto/shared'

import { useWalletCheckWithUpdate } from '../../hooks/useWalletCheck'
import { MINI_APP } from '../../index'
import { connector } from 'utils/ton/connector'

export interface StorePopoutProps {
  name: string
  price: number
  image: string
  description: string
  currency: CurrencyType
  goodId: string
  itemType: ItemType
  setStatus?: Dispatch<SetStateAction<StatusType>>
  rarity?: PetRarity
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 20px;
  align-self: stretch;
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Nunito;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 11.103px;
`

const InfoField = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  white-space: nowrap;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const HeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  align-self: stretch;
`

const BigImage = styled.img`
  width: 99px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  align-self: center;
  border-radius: 10px;
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;
`

const DecButton = styled.button<{ amount: number }>`
  display: flex;
  width: 30px;
  height: 30px;
  padding: 10px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.1);
  border: none;
  cursor: ${(props) => (props.amount === 0 ? 'default' : 'pointer')};
`

const IncButton = styled.button`
  display: flex;
  width: 30px;
  height: 30px;
  padding: 10px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.1);
  border: none;
  cursor: pointer;
`

const InfoText = styled.p`
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  flex-grow: 2;
`

const Currency = styled.img`
  width: 13.501px;
  height: 15px;
`

export const PriceInfo = styled.div`
  display: flex;
  padding: 3px 5px;
  gap: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
`

const NotEnoughMoney = styled.div`
  display: flex;
  justify-content: center;
  padding: 7px 10px;
  align-items: center;
  gap: 10px;
  border-radius: 7px;
  background: rgba(255, 62, 50, 0.05);
  height: 25px;
  color: #ff3e32;
  text-align: center;
  font-family: Nunito;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 11px;
`

enum ItemType {
  Egg = 'egg',
  Coin = 'coin', //donate
  Brilliant = 'brilliant', //donate
  Essence = 'essence',
  Skin = 'skin',
}

type StoreInfoPopoutProps = Popouts['store']

const StoreInfoPopout: FC<StoreInfoPopoutProps> = ({
  id,
  currency,
  description,
  goodId,
  image,
  itemType,
  name,
  price,
  rarity,
}) => {
  const { t } = useTranslation()
  const [status, setStatus] = useState<StatusType>()
  const paymentProcess = useAppSelector(getPaymentProcess)

  const [count, setCount] = useState(1)
  const [buyLoad, setBuyLoad] = useState(false)
  const [good, setGood] = useState<DonateGetRes>()
  const [closePopout, setClosePopout] = useState(false)
  const popout = usePopout('statusAlert', true, {
    type: 'error',
    header: t(LOCALES.buyError),
  })
  const tonPaymentPopout = usePopout('statusAlert', true, {
    type: 'success',
    header: t(LOCALES.completed_payment),
  })
  const successPopout = usePopout('statusAlert', true, {
    type: 'success',
    subheader: t(LOCALES.buySuccess),
  })
  const waitPopout = usePopout('statusAlert', true, {
    type: 'custom',
    subheader: t(LOCALES.process_of_purchase),
  })
  const paymentProcessPopout = usePopout('statusAlert', true, {
    type: 'error',
    subheader: `${t(LOCALES.error_paymentProcess)}`,
  })
  const walletExistPopout = usePopout('walletNotExist', false)
  const [data, updateData] = useWalletCheckWithUpdate(false)
  const navigate = useNavigate()

  useEffect(() => {
    const handlePopstate = () => {
      navigate('/store', {
        replace: true,
        state: {
          currency,
          description,
          goodId,
          image,
          itemType,
          name,
          price,
          rarity,
        },
      })
    }

    window.addEventListener('popstate', handlePopstate)

    return () => {
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [])

  const { user } = useAppSelector((state) => state.user)

  const dispatch = useAppDispatch()

  const getGoodById = async () => {
    const res = await makeRequest('donate.getById', {
      donateId: goodId,
    })
    setGood(res)
  }

  useEffect(() => {
    getGoodById()
  }, [])

  const increment = () => {
    setCount((count) => count + 1)
  }

  const decrement = () => {
    setCount((prev) => Math.max(prev - 1, 0))
  }

  const buyGood = async () => {
    if (!user) {
      return
    }

    try {
      setBuyLoad(true)

      await makeRequest('goods.buy', {
        goodId: goodId,
        amount: count,
        itemType: itemType as ItemType,
      })

      if (good && good.item.award.currency === AwardCurrencyType.Hard) {
        dispatch(incrementBalance({ hard: good.item.award.amount * count }))
      }
      if (good && good.item.award.currency === AwardCurrencyType.Soft) {
        dispatch(incrementBalance({ soft: good.item.award.amount * count }))
      }

      if (currency === CurrencyType.Soft) {
        dispatch(decrementBalance({ soft: count * price }))
      }
      if (currency === CurrencyType.Hard) {
        dispatch(decrementBalance({ hard: count * price }))
      }

      if (currency === CurrencyType.Event) {
        dispatch(decrementBalance({ event: count * price }))
      }
      successPopout()
      setBuyLoad(false)
    } catch (e) {
      popout()
      setBuyLoad(false)
    }
  }

  const isReqNeeded = useRef<boolean>(false)
  const intervalValue: { current: NodeJS.Timeout | null } = useRef(null)
  const totalPrice = price * count

  const handlePaymentButtonClick = async () => {
    if (paymentProcess) return paymentProcessPopout()
    try {
      setBuyLoad(true)
      if (MINI_APP === Platform.VK) {
        bridge
          .send('VKWebAppShowOrderBox', {
            type: 'item',
            item: `goodId=${goodId}&type=${itemType}&c=${count}`,
          })
          .then(() => {
            if (itemType === ItemType.Brilliant || itemType === ItemType.Coin) {
              if (good && good.item.award.currency === AwardCurrencyType.Hard) {
                dispatch(
                  incrementBalance({ hard: good.item.award.amount * count }),
                )
              } else if (
                good &&
                good.item.award.currency === AwardCurrencyType.Soft
              ) {
                dispatch(
                  incrementBalance({ soft: good.item.award.amount * count }),
                )
              } else if (
                good &&
                good.item.award.currency === AwardCurrencyType.Event
              ) {
                dispatch(
                  incrementBalance({ event: good.item.award.amount * count }),
                )
              }
            }
            successPopout()
            setBuyLoad(false)
          })
          .catch(() => {
            popout()
            setBuyLoad(false)
          })
      }
      if (MINI_APP === Platform.TG) {
        const userWallets = data.length ? data : await updateData()
        if (!userWallets.length) {
          walletExistPopout()
        } else {
          isReqNeeded.current = true
          setClosePopout(true)
          const uniqueId = await sendPayment(
            totalPrice,
            `${itemType}:${id}:${count}`,
            tonPaymentPopout,
          )
          if (!uniqueId) return
          intervalValue.current = setInterval(async () => {
            const res = await handleGetTonPayments({
              uniqueId,
              intervalValue,
              isReqNeeded,
              successPopout,
              errorPopout: popout,
              waitPopout,
              stopInterval: () => {
                dispatch(setPaymentProcess(false))
              },
              startInterval: () => {
                dispatch(setPaymentProcess(true))
              },
            })

            if (res?.status === 'got' && itemType === 'coin') {
              if (good && good.item.award.currency === AwardCurrencyType.Soft) {
                dispatch(
                  incrementBalance({ soft: good.item.award.amount * count }),
                )
              }
            }
          }, 5000)
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const isEnoughVK =
    currency === CurrencyType.Soft
      ? user && user.balance.soft < totalPrice
      : currency === CurrencyType.Hard
      ? user && user.balance.hard < totalPrice
      : currency === CurrencyType.Event
      ? user && user.balance.event < totalPrice
      : currency !== CurrencyType.Votes

  const isEnoughTG =
    currency === CurrencyType.Soft
      ? user && user.balance.soft < price * count
      : currency === CurrencyType.Event
      ? user && user.balance.event < price * count
      : currency !== CurrencyType.Hard

  const isTonCheck =
    MINI_APP === Platform.TG &&
    currency === 'hard' &&
    user &&
    user.balance.hard < price * count

  // console.log(user && user.balance.hard < price)
  const isVotesCheck = MINI_APP === Platform.VK && currency === 'votes'

  const lastDigit = (count * price) % 10
  const lastTwoDigits = (count * price) % 100

  return (
    <Popout
      type="default"
      background={true}
      width="284px"
      isFullScreen={false}
      status={status}
      setStatus={setStatus}
      close={closePopout}
    >
      <Container>
        <ImageContainer>
          {itemType === 'skin' ? (
            <SkinBackground rarity={rarity}>
              <SkinImg src={`${image}`} />
              <SkinRarity rarity={rarity} />
            </SkinBackground>
          ) : (
            <BigImage src={image} />
          )}
        </ImageContainer>

        <HeaderWrap>
          <Header lineHeight={20}>{name}</Header>
          <Subheader>{description}</Subheader>
        </HeaderWrap>
        <InfoField>
          <Row>
            <DecButton amount={count} onClick={decrement}>
              -
            </DecButton>
            <InfoText>
              {count} {t(LOCALES.pcs)}.
            </InfoText>
            <IncButton onClick={increment}>+</IncButton>
          </Row>
          {isEnoughVK && MINI_APP === Platform.VK && (
            <NotEnoughMoney>{t(LOCALES.insufficientFunds)}</NotEnoughMoney>
          )}
          {isEnoughTG && MINI_APP === Platform.TG && (
            <NotEnoughMoney>{t(LOCALES.insufficientFunds)}</NotEnoughMoney>
          )}
          {user &&
          user.balance.hard < totalPrice &&
          MINI_APP === Platform.TG &&
          count !== 0 &&
          currency !== CurrencyType.Soft &&
          currency !== CurrencyType.Event ? (
            <NotEnoughMoney>{t(LOCALES.real_ton)}</NotEnoughMoney>
          ) : (
            currency !== CurrencyType.Soft &&
            currency !== CurrencyType.Event &&
            count !== 0 && (
              <NotEnoughMoney>{t(LOCALES.game_ton)}</NotEnoughMoney>
            )
          )}
          <Button
            onClick={
              isVotesCheck || isTonCheck ? handlePaymentButtonClick : buyGood
            }
            variant={isEnoughTG || count === 0 || buyLoad ? 'gray' : 'success'}
            disabled={!!isEnoughTG || count === 0 || buyLoad}
            width="238px"
          >
            {`${t(LOCALES.buy)} `}
            {count === 0 ? (
              ''
            ) : (
              <PriceInfo>
                {MINI_APP === Platform.TG && currency === CurrencyType.Hard
                  ? totalPrice.toFixed(1)
                  : totalPrice}{' '}
                {currency === CurrencyType.Soft && (
                  <Currency src={CurrencyIcon} />
                )}
                {currency === CurrencyType.Hard && (
                  <Currency src={MINI_APP === Platform.VK ? DiamondPaw : Ton} />
                )}
                {currency === CurrencyType.Event && (
                  <Currency src={EVENT_ICONS.small} />
                )}
                {currency === CurrencyType.Votes &&
                  (APP_LANG === 'ru'
                    ? fixEndingByNumber(t(LOCALES.votes), count * price)
                    : t(LOCALES.votes))}
              </PriceInfo>
            )}
          </Button>
        </InfoField>
      </Container>
    </Popout>
  )
}

export default StoreInfoPopout
