/* eslint-disable no-console */
import * as PIXI from 'pixi.js'
import { Spine } from 'pixi-spine'

import { SplashAnimation, SURF_RANGE, SwimStatus } from '../swimgame/constants'
import { MyITrackEntry, SwimGamePetProps } from '../types/swimgame'

import { GamePet } from '.'

export class SwimGamePet extends GamePet {
  private _swimStatus!: SwimStatus
  splashesSpine: Spine
  isPlayingAnimation: boolean

  constructor({
    id,
    petSpine,
    petEngine,
    x,
    y,
    gameType,
    splashes,
  }: SwimGamePetProps) {
    super({ id, petSpine, petEngine, x, y, gameType })
    this.splashesSpine = new Spine(splashes)
    this.splashesSpine.zIndex = 1

    this.splashesSpine.state.addListener({
      start: (e) => {
        if (
          (e as unknown as MyITrackEntry).animation.name !==
          SplashAnimation.Surf
        ) {
          this.isPlayingAnimation = true
          this.splashesSpine.state.timeScale = 1.7
          this.splashesSpine.zIndex = 1
        } else {
          this.splashesSpine.state.timeScale = 1.2
          this.splashesSpine.zIndex = 3
        }
        this.splashesSpine.alpha = 1
      },
      complete: () => {
        this.isPlayingAnimation = false
      },
    })

    this.petContainer.addChild(this.splashesSpine)
    this.swimStatus = SwimStatus.Run
    this.petContainer
    this.isPlayingAnimation = false

    console.log('Pet Container ', this.petContainer)
  }

  setY(y: number, addedY: number): void {
    this._y = y + addedY
    this.petSpine.y = y
    this.petContainer.y = addedY
    this.splashesSpine.y = -this.splashesSpine.height / 2

    // console.log('Y, addedY, status ', y, addedY, SwimStatus[this.swimStatus])
    this.setSwimStatus(y)
  }

  setSwimStatus(y: number): void {
    if (this.swimStatus === SwimStatus.Run || this.isPlayingAnimation) return

    if (
      y <= SURF_RANGE &&
      (this.swimStatus === SwimStatus.Emerge ||
        this.swimStatus === SwimStatus.Surf)
    ) {
      this.swimStatus = SwimStatus.Surf
    } else if (
      y > SURF_RANGE &&
      (this.swimStatus === SwimStatus.Surf ||
        this.swimStatus === SwimStatus.Emerge)
    ) {
      this.swimStatus = SwimStatus.Dive
    } else if (y <= SURF_RANGE && this.swimStatus === SwimStatus.UnderWater) {
      this.swimStatus = SwimStatus.Emerge
    } else {
      this.swimStatus = SwimStatus.UnderWater
    }
  }

  get swimStatus(): SwimStatus {
    return this._swimStatus
  }

  set swimStatus(value: SwimStatus) {
    if (this._swimStatus === value) return
    this._swimStatus = value

    switch (this._swimStatus) {
      case SwimStatus.Run:
        this.splashesSpine.alpha = 0
        break
      case SwimStatus.Dive:
        this.setSplashesAnimation(SplashAnimation.Dive, false)
        break
      case SwimStatus.Emerge:
        this.setSplashesAnimation(SplashAnimation.Emerge, false)
        break
      case SwimStatus.Surf:
        this.setSplashesAnimation(SplashAnimation.Surf, true)
        break
      case SwimStatus.UnderWater:
        this.splashesSpine.alpha = 0
        break
      default:
        break
    }
  }

  setSplashesAnimation(name: SplashAnimation, loop: boolean): void {
    if (this._swimStatus === SwimStatus.Run) return
    this.splashesSpine.state.setAnimation(0, name, loop)
  }
}
