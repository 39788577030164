import { FC, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { WalletInfo } from '@tonconnect/ui'
import { connector } from 'utils/ton/connector'

import DeleteIcon from 'assets/svg/modal/delete.svg'
const CryptoWalletContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  align-items: center;
  gap: 15px;
  align-self: stretch;
`

const CryptoWalletItem = styled.div`
  display: flex;
  width: 143px;
  align-items: center;
  gap: 10px;
`

const CryptoIcon = styled.div`
  display: flex;
  width: 30px;
  height: 30px;

  > img {
    flex-shrink: 0;
    border-radius: 10px;
  }
`

const CryptoWalletName = styled.div`
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Nunito;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 11.103px; /* 85.405% */
`

const DeleteWallet = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
`

const DeleteWalletIcon = styled.div`
  display: flex;
  padding: 5px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 6px;
  background: linear-gradient(
    180deg,
    rgba(255, 62, 50, 0.05) 0%,
    rgba(255, 50, 99, 0.05) 100%
  );
`

const CryptoItem: FC<{
  name: string
  onDelete: () => void
}> = ({ name, onDelete }) => {
  const [wallet, setWallet] = useState<WalletInfo | undefined>()

  useEffect(() => {
    const findWallet = async () => {
      const wallets = await connector.getWallets()
      console.info('wallets', wallets)
      const findedWallet = wallets.find((w) => w.appName === name)
      console.info('Find', name, findedWallet)
      setWallet(findedWallet)
    }
    findWallet()
  }, [])
  const wallets = connector.getWallets()

  wallets

  return (
    <CryptoWalletContainer>
      {wallet ? (
        <>
          <CryptoWalletItem>
            <CryptoIcon>
              <img src={wallet.imageUrl}></img>
            </CryptoIcon>
            <CryptoWalletName>{wallet.name}</CryptoWalletName>
          </CryptoWalletItem>
          <DeleteWallet onClick={onDelete}>
            <DeleteWalletIcon>
              <img src={DeleteIcon}></img>
            </DeleteWalletIcon>
          </DeleteWallet>
        </>
      ) : (
        ''
      )}
    </CryptoWalletContainer>
  )
}

export default CryptoItem
