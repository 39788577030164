import { FC, useState } from 'react'
import styled from '@emotion/styled'
import { Popouts } from 'const/router'

import { Popout, StatusType, Subheader } from 'components/base/Popout'

import PromoWarningImage from '/src/assets/svg/popouts/promo-warning.svg'

const Image = styled.img`
  width: 47.823px;
  height: 47.823px;
  align-self: center;
  flex-shrink: 0;
`

type PromoWasActivatedProps = Popouts['promoWasActivated']

const PromoNotFoundPopout: FC<PromoWasActivatedProps> = ({ subheader }) => {
  const [status, setStatus] = useState<StatusType>()

  return (
    <Popout
      background={true}
      isFullScreen={false}
      width="284px"
      type="default"
      status={status}
      setStatus={setStatus}
    >
      <Image src={PromoWarningImage} />
      <Subheader>{subheader}</Subheader>
    </Popout>
  )
}

export default PromoNotFoundPopout
