import { SHOULD_BE_ANCHOR_PET_ROW } from 'src/pixi/baseOlympicGame/constants'
import { SwimGame } from 'src/pixi/classes'
import { SwimGamePet } from 'src/pixi/classes/SwimGamePet'

import { StateInfoEvent } from '@gatto/engine'

/* eslint-disable no-console */
export function setup(this: SwimGame, data: StateInfoEvent): void {
  if (this.isSetuped) return

  console.log('setupEvent ', data, Date.now())
  console.log('pets ', this.anchorPet, this.pets, Date.now())

  this.isSetuped = true

  this.engine.refreshInfo(data)

  this.timeoutIn = data.timeoutIn
  this.startGameAt = data.gameStartedAt
  this.setupTimer()

  this.mapInfo = JSON.parse(JSON.stringify(data.mapInfo.barriers))
  this.fullTrack = JSON.parse(JSON.stringify(data.mapInfo.tracks))
  this.timeoutIn = data.timeoutIn

  // Меняем дорожки местами
  for (const id in data.users) {
    if (id === this.currentUserId) {
      this.anchorPetRow = data.users[id]?.row || this.anchorPetRow
    }
  }

  this.anchorPet.mapAnchorRowTo = this.anchorPetRow
  this.pets.forEach((pet) => {
    pet.mapAnchorRowTo = this.anchorPetRow
  })

  this.mapInfo.forEach((bar) => {
    if (bar.row === SHOULD_BE_ANCHOR_PET_ROW) {
      bar.row = this.anchorPetRow
    } else if (bar.row === this.anchorPetRow) {
      bar.row = SHOULD_BE_ANCHOR_PET_ROW
    }
  })

  this.sync(data)
  this.setupBackground()

  this.startRun()
}

export function sync(this: SwimGame, data: StateInfoEvent): void {
  // Привязка дорожки к питомцам
  console.log('sync event ', data, Date.now())
  console.log('sync pets ', this.anchorPet, this.pets, Date.now())

  for (const id in data.users) {
    const user = data.users[id]
    if (!user) continue
    const petId = user.petInfo.id
    const findPet = (
      this.anchorPet.id === petId ? this.anchorPet : this.pets.get(petId)
    ) as SwimGamePet

    if (!findPet) continue
    if (!findPet.petEngine) {
      findPet.petEngine = this.getPetById(user.petInfo.id)
      this.movePetY(findPet, 0)
    }
    if (findPet.originalRow !== user.row) {
      findPet.row = user.row
      this.movePetY(findPet, 0)
    }
  }
}
