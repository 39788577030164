import React from 'react'
import styled from '@emotion/styled'

const Root = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;
  gap: 10px;

  justify-content: center;
  align-items: center;
`

type BaseButtonGroupProps = {
  /**
   * Кнопки
   * @see {@link Button}
   */
  children: React.ReactNode
}

type ButtonGroupProps = BaseButtonGroupProps &
  Omit<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    keyof BaseButtonGroupProps
  >

/**
 * Группа кнопок
 */
function ButtonGroup({
  children,
  ...props
}: ButtonGroupProps): React.ReactElement {
  return <Root {...props}>{children}</Root>
}

export default ButtonGroup
