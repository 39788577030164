import { FC } from 'react'
import styled from '@emotion/styled'
import { useNow } from 'src/hooks/useNow'

import { ProgressLevel } from 'components/base'
import { MINI_APP } from '../../../index'
import { Platform } from '@gatto/shared'

const ProgressWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`

type ProgressProsp = {
  color: string
}

const Progress = styled(ProgressLevel)<ProgressProsp>`
  color: ${(props) => props.color};
  position: absolute;
  left: 0;
  z-index: -2;
`

type EggProgressBarProps = {
  entityDimension: {
    width: number
    height: number
  }
  hatchingTimeout: number
  hatchingTime: number
  canSkip: boolean
}

const EggProgressBar: FC<EggProgressBarProps> = ({
  hatchingTime,
  hatchingTimeout,
  canSkip,
}) => {
  const now = useNow(1000)
  const totalProgress = 1 - Math.max(0, hatchingTimeout - now) / hatchingTime
  const timeAfterSkip = Math.min(1, totalProgress + (canSkip ? 1 / 6 : 0))

  const width = 240
  const height = 240

  return (
    <ProgressWrapper>
      <ProgressLevel
        progress={totalProgress}
        startAngle={0}
        width={width}
        height={height}
        strokeWidth={0.5}
        linearGradientId="gradient1"
      />
      <Progress
        withBackground
        color={'rgba(0, 0, 0, 0)'}
        progress={1}
        startAngle={0}
        width={width}
        height={height}
        strokeWidth={0.5}
      />
      {MINI_APP === Platform.VK && (
        <Progress
          color={'#43ff56'}
          progress={timeAfterSkip}
          startAngle={0}
          width={width}
          height={height}
          strokeWidth={0.5}
        />
      )}
    </ProgressWrapper>
  )
}

export default EggProgressBar
