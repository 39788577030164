import styled from '@emotion/styled'

const Page = styled.div`
  height: 100vh;
  background: var(--background-gradient);
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default Page
