import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Types as MongooseTypes } from 'mongoose'
import { makeRequest } from 'src/utils/api'

import { useAppDispatch } from 'store'
import { updateRegionPet } from 'store/region'
import { updateUserRegion } from 'store/user'

import { PetRes, PopulatedUserRegion } from '@gatto/shared'

type AdditionalInfo = {
  pet_id: MongooseTypes.ObjectId | string
  regionToPlace?: MongooseTypes.ObjectId
  isPlacedFromMap: boolean
}

const usePlacePet = (): ((params: AdditionalInfo) => Promise<void>) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const region: PopulatedUserRegion | undefined =
    state && state.region ? state.region : undefined
  const regionIdFromURL = region && region._id

  const placePet = useCallback(
    async (params: AdditionalInfo) => {
      const regionId = params.regionToPlace
        ? params.regionToPlace
        : regionIdFromURL
      if (!regionId || !params.pet_id) {
        return
      }

      const nextPet = await makeRequest('pet.place', {
        petId: params.pet_id.toString(),
        regionId,
      })

      dispatch(updateRegionPet({ pet: nextPet }))
      dispatch(updateUserRegion({ pet: nextPet, regionId }))

      if (!params.isPlacedFromMap) {
        navigate(-1)
      }
    },
    [regionIdFromURL, dispatch, navigate],
  )
  return placePet
}

export default usePlacePet
