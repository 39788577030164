import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { API_URL, APP_LANG } from 'src/config/constants'

console.info('USE LANG', APP_LANG)

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: true,

    defaultNS: 'translation',
    fallbackLng: 'en',
    lng: APP_LANG,
    backend: {
      loadPath: `https://frontend.gattogame.site/uploads/locales/{{lng}}.json`,
    },
  })
