/* eslint-disable no-unsafe-finally */
import {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { IPointData } from '@pixi/math/lib/IPointData'
import { Container as PixiContainer, Sprite } from '@pixi/react'
import bridge, { EAdsFormats } from '@vkontakte/vk-bridge'
import { TFunction } from 'i18next'
import * as PIXI from 'pixi.js'
import { MINI_APP } from 'src'
import { REWARDS_SPRITESHEET_PATH } from 'src/config/assets'
import { API_URL } from 'src/config/constants'
import { EVENT_ICONS } from 'src/config/eventSettings'
import { usePopout } from 'src/hooks'
import useLoadAsset from 'src/hooks/useLoadAsset'
import useScreenDimensions from 'src/hooks/useScreenDimensions'
import { useSystemBackListener } from 'src/hooks/useSystemBackListener'
import { PetClothes } from 'src/pixi/types/spine'
import { makeRequest } from 'utils/api'
import { getVkUserId } from 'utils/getLocationSearch'

import IconExp from 'assets/icons/exp.png'
import IconCoin from 'assets/svg/coin.svg'
import Diamond from 'assets/svg/diamond.png'
import IconAD from 'assets/svg/other/ad_x3.svg'
import IconHouse from 'assets/svg/other/house.svg'
import { DotsBackground } from 'components/Background'
import { Button, Wallet } from 'components/base'
import { Page, PageTitle } from 'components/page'
import { Entity, EntityPet, ResizableStage } from 'components/pixi'
import { RootDispatch, RootState, useAppSelector } from 'store/index'
import { incrementBalance, updateCanWatchAds } from 'store/user'

import { LOCALES } from '@gatto/locales'
import {
  BaseEssence,
  ExtraGameAwardPrize,
  ExtraPrize,
  GameType,
  Platform,
  PopulatedEgg,
  PopulatedPet,
  PopulatedPetWithSkins,
  UserPrize,
} from '@gatto/shared'

const Root = styled(Page)({
  width: '100%',
  background:
    'linear-gradient(360deg, rgba(40,169,164,1) 20%, rgba(84,210,224,1) 82%, rgba(99,238,245,1) 100%)',
})

const RewardPageTitle = styled(PageTitle)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  left: 0;
  right: 0;
  color: #fff;
  z-index: 2;
`

const CustomWallet = styled(Wallet)`
  z-index: 5;
`

const Container = styled(DotsBackground)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;

  max-width: 450px;
  margin: 0 auto;

  padding: 1.5rem;

  display: flex;
  flex-direction: column;
  justify-content: end;

  gap: 1rem;
  z-index: 2;
`

const RewardsContainer = styled.div<{ hidden?: boolean }>`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 0.9375rem;
  height: 12rem;
  border-radius: 0.8125rem;
  padding: 1.25rem;
  background: #fff;
  box-shadow:
    0px -4px 0px 0px rgba(0, 0, 0, 0.15) inset,
    0px 0px 14px 0px #fff;
  ${({ hidden }) => (hidden ? 'display: none;' : '')}
`

const RewardsTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  color: #000;
  text-align: center;
  font-size: 1rem;
`

const Rewards = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  height: 100%;
  width: 100%;
`

const Buttons = styled.div`
  display: flex;
  padding: 0.3125rem;
  border-radius: 0.6875rem;
  align-items: center;
  gap: 0.4375rem;
  background: rgba(0, 0, 0, 0.05);
`

const Ground = styled.div`
  position: absolute;
  aspect-ratio: 2 / 1.75;
  width: 750px;
  bottom: -30%;

  border-radius: 100%;
  background: radial-gradient(
    circle,
    rgba(237, 234, 137, 1) 20%,
    rgba(246, 244, 172, 1) 82%,
    rgba(249, 248, 185, 1) 100%
  );
`

const AwardsStandContainer = styled.div<{ bottomPosition?: boolean }>`
  z-index: 1;
  background-image: url('/sprites/objects/venok_final_results.png');
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: 50% 10%;
  width: 350px;
  height: 350px;
  position: absolute;
  bottom: 360px;

  &:before {
    content: ' ';

    position: absolute;
    top: -25%;
    left: -25%;
    width: 150%;
    z-index: -1;
    aspect-ratio: 1;

    background-image: url('/sprites/objects/shine_final_results.png');
    background-repeat: no-repeat;
    background-position: center;

    animation: rotation infinite 10s linear;
  }

  @keyframes rotation {
    to {
      transform: rotateZ(1turn);
    }
  }

  canvas {
    position: fixed;
    left: 0;
    top: 0;
  }
`

const NameBadge = styled.div`
  position: absolute;

  height: 1.8125rem;
  padding: 0.3125rem 0.4375rem 0.4375rem 0.4375rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  border-radius: 0.4375rem;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px -2px 0px 0px rgba(33, 62, 64, 0.15) inset;
  backdrop-filter: blur(9.5px);

  &[data-place='1'] {
    top: 33%;
    left: 40%;
  }

  &[data-place='2'] {
    top: 58%;
    right: 16%;
  }

  &[data-place='3'] {
    top: 58%;
    left: 5%;
  }
`

type StandObject = {
  texture: string
  place: number
  stand_offset: IPointData
  pet_offset: IPointData
}

const stands: StandObject[] = [
  {
    place: 3,
    texture: '3_final_results.png',
    stand_offset: { x: -120, y: 100 },
    pet_offset: { x: -40, y: 40 },
  },
  {
    place: 1,
    texture: '1_final_results.png',
    stand_offset: { x: 0, y: 0 },
    pet_offset: { x: -5, y: 45 },
  },
  {
    place: 2,
    texture: '2_final_results.png',
    stand_offset: { x: 120, y: 95 },
    pet_offset: { x: -20, y: 45 },
  },
]

const AwardsStand: FC<{
  pets: PopulatedPetWithSkins[]
  onPetClick: (pet: PopulatedPetWithSkins) => void
  bottomPosition?: boolean
}> = ({ pets, onPetClick, bottomPosition }) => {
  const spritesheet = useLoadAsset<PIXI.Spritesheet>(REWARDS_SPRITESHEET_PATH)
  const screenDimensions = useScreenDimensions()

  const stand = useMemo(() => {
    if (!spritesheet) return
    const textures = spritesheet.textures

    const centerX = screenDimensions.width / 2
    const centerY = screenDimensions.height - (bottomPosition ? 370 : 480)

    const scale = 0.75

    return (
      <PixiContainer
        x={centerX + 10}
        y={centerY}
        anchor={{ x: 0.5, y: 1 }}
        scale={{ x: scale, y: scale }}
      >
        {stands.map((stand) => {
          const pet = pets[stand.place - 1]
          if (!pet) return null

          return (
            <PixiContainer
              key={'stand-' + stand.place}
              position={stand.stand_offset}
            >
              <Sprite
                texture={textures[stand.texture]}
                anchor={{ x: 0.5, y: 0 }}
              />
              {stand.place === 1 && (
                <Sprite
                  texture={textures['cup_final_results.png']}
                  anchor={{ x: 0.5, y: 1 }}
                  x={-65}
                  y={25}
                />
              )}
              <Entity
                key={pet.basePet.kind}
                entity={<EntityPet basePet={pet.basePet} skins={pet.skins} />}
                shadow={false}
                position={stand.pet_offset}
                onClick={() => onPetClick(pet)}
              />
            </PixiContainer>
          )
        })}
      </PixiContainer>
    )
  }, [
    spritesheet,
    screenDimensions.width,
    screenDimensions.height,
    pets,
    onPetClick,
  ])

  return <PixiContainer>{stand}</PixiContainer>
}

type Award = {
  type: 'exp' | 'coin' | 'item' | 'ton' | 'event'
  value: number | string
  name?: string
}

const Card = styled.div`
  height: 100%;
  flex: 1;
  border-radius: 0.625rem;
  box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.15) inset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0.44rem;
  font-weight: bold;

  z-index: 1;

  > button {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
  }

  &:before {
    content: ' ';

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    width: 100%;
    height: 100%;
    border-radius: inherit;

    background-image: var(--background);
    background-repeat: no-repeat;
    background-size: 200%;
    background-position: center;

    filter: opacity(0.25);
  }
`
const AwardIconWrapper = styled.div`
  position: relative;
  width: 60px;
  height: 60px;

  svg,
  img {
    position: absolute;
  }

  img {
    top: 8%;
    left: 8%;
  }
`

const ReturnToMapButton = styled(Button)<{ hidden: boolean }>`
  ${({ hidden }) => (hidden ? 'display: none;' : '')}
`

const AwardIcon: FC<{ src: string; scale?: number }> = ({ src, scale = 1 }) => {
  return (
    <AwardIconWrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="60"
        viewBox="0 0 61 61"
        fill="none"
      >
        <path
          d="M3.14086 35.6752C-1.78094 27.1504 1.13988 16.2498 9.66468 11.328L25.1002 2.41625C33.625 -2.50555 44.5256 0.415267 49.4474 8.94007L58.3591 24.3756C63.2809 32.9004 60.3601 43.801 51.8353 48.7228L36.3998 57.6345C27.875 62.5563 16.9744 59.6355 12.0526 51.1107L3.14086 35.6752Z"
          fill="black"
          fillOpacity="0.03"
        />
      </svg>
      <img
        src={src}
        alt={''}
        width={50}
        style={{ zIndex: 1, transform: `scale(${scale})` }}
      />
    </AwardIconWrapper>
  )
}

const AwardCard: FC<{ award: Award; dispatch: RootDispatch }> = ({
  award,
  dispatch,
}) => {
  const [isAdsShowen, setIsAdsShowen] = useState<boolean>(false)
  const canWatchAds = useAppSelector(getCanWatchAds)

  useEffect(() => {
    setIsAdsShowen(!canWatchAds)
  }, [canWatchAds])

  const onVideoClick = async () => {
    try {
      if (isAdsShowen) return
      const response = await bridge.send('VKWebAppShowNativeAds', {
        ad_format: EAdsFormats.REWARD,
      })
      if (!response.result) return
    } finally {
      setIsAdsShowen(true)

      const gameBonusResponse = await makeRequest('game.bonus', {})
      if (!gameBonusResponse?.success) return

      if (award.type !== 'coin' || typeof award.value !== 'number') return
      dispatch(
        incrementBalance({
          soft: award.value * 2,
        }),
      )
      dispatch(updateCanWatchAds(false))
    }
  }

  let scale = 1

  let imgSrc: string
  if (award.type === 'item') {
    imgSrc = API_URL + award.value.toString()
    scale = 1.5
  } else if (award.type === 'ton') {
    imgSrc = Diamond
  } else if (award.type === 'event') {
    imgSrc = EVENT_ICONS.small
  } else {
    imgSrc = award.type === 'coin' ? IconCoin : IconExp
  }
  const style = { '--background': `url(${imgSrc})` } as CSSProperties

  return (
    <Card style={style}>
      <AwardIcon src={imgSrc} scale={scale} />
      {award.type === 'coin' && (
        <>
          <span>
            +
            {isAdsShowen && typeof award.value === 'number'
              ? award.value * 3
              : award.value}
          </span>
          {!isAdsShowen && MINI_APP === Platform.VK && (
            <Button variant={'success'} onClick={onVideoClick}>
              <img src={IconAD} alt={'adv'} height={33} />
            </Button>
          )}
        </>
      )}
      {award.type === 'exp' && <span>+{award.value} EXP</span>}
      {(award.type === 'ton' || award.type === 'event') && (
        <span>+{award.value}</span>
      )}
      {award.type === 'item' && <span>{award?.name}</span>}
    </Card>
  )
}

const getExtraAward = (t: TFunction, extraAward: ExtraPrize): Award => {
  if (extraAward.type === ExtraGameAwardPrize.Ton) {
    return {
      type: 'ton',
      value: `${extraAward.item} TON`,
    }
  }

  if (extraAward.type === ExtraGameAwardPrize.Event) {
    return {
      type: 'event',
      value: `${extraAward.item} ${t(LOCALES.ticket)}`,
    }
  }

  if (extraAward.type === ExtraGameAwardPrize.Egg) {
    const egg = extraAward.item as unknown as PopulatedEgg
    return {
      type: 'item',
      value: `${egg.baseEgg.photoUrl}`,
      name: t(LOCALES.egg),
    }
  }

  const essences = extraAward.item as BaseEssence
  return {
    type: 'item',
    value: `${essences.photoUrl}`,
    name: t(LOCALES.essence),
  }
}

type RewardingPageLocationState = {
  usersPrizes: UserPrize[]
  gameType: GameType
  lobbyId?: string
}

const getCanWatchAds = (state: RootState) => state.user.canWatchAds

export const RewardingPage: FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch: RootDispatch = useDispatch()
  const [isButtonClick, setIsButtonClick] = useState(false)

  useSystemBackListener(({ location }) => {
    if (isButtonClick) {
      setIsButtonClick(false)
      navigate(`${location.pathname}${location.search}`, {
        state: location.state,
        replace: true,
      })
    } else {
      return true
    }
  })

  const [locationGameState, setLocationGameState] =
    useState<RewardingPageLocationState | null>(null)
  const [pets, setPets] = useState<
    Array<PopulatedPet & { skins: PetClothes[] }>
  >([])
  const [awards, setAwards] = useState<Award[]>([])

  useEffect(() => {
    if (!location?.state?.usersPrizes) return
    setLocationGameState(location.state as RewardingPageLocationState)
  }, [location.state])

  useEffect(() => {
    const processPrizes = async () => {
      if (!locationGameState) return
      const { usersPrizes } = locationGameState
      const petsArray: PopulatedPetWithSkins[] = []

      if (usersPrizes) {
        usersPrizes.sort(
          (item1, item2) => item1.winningPlace - item2.winningPlace,
        )

        for await (const item of usersPrizes) {
          const pet = item.user.pet as PopulatedPet

          if (pet) {
            const skins = await makeRequest('skin.getPetSkins', {
              petId: pet._id.toString(),
            })

            petsArray.push({ ...pet, skins })
          }
        }

        usersPrizes.forEach((prize) => {
          if (prize.userId === Number(getVkUserId())) {
            const awards: Award[] = [
              {
                type: 'exp',
                value: prize.prize.experience,
              },
              {
                type: 'coin',
                value: prize.prize.moneyAmount,
              },
            ]
            if ('extraAwards' in prize) {
              const extraAwards = prize.extraAwards
              extraAwards.forEach((item) => {
                awards.push(getExtraAward(t, item))
              })
            }
            setAwards(awards)
          }
        })
        console.info(usersPrizes, pets)
      }
      setPets(petsArray)
    }

    processPrizes()
  }, [locationGameState])

  const openPopout = usePopout('petInfo', false)

  const onPlayClick = () => {
    if (!locationGameState?.gameType) return
    setIsButtonClick(true)
    if (locationGameState.lobbyId) {
      const userId = +getVkUserId()
      navigate(`/lobby/${locationGameState.lobbyId}`, {
        state: {
          pet: pets.find((pet) => pet.owner === userId),
        },
        replace: true,
      })
    } else {
      navigate('/selectPet', {
        state: {
          headerText: t(locationGameState.gameType),
          gameType: locationGameState.gameType,
        },
        replace: true,
      })
    }
  }
  const onHomeClick = () => {
    setIsButtonClick(true)
    navigate('/', { replace: true })
  }

  const onPetClick = useCallback(
    (pet: PopulatedPetWithSkins) => {
      if (window.location.search === '?popout=petInfo') return
      const user = locationGameState?.usersPrizes.find(
        (item) => (item.user.pet as PopulatedPet)?._id === pet._id,
      )?.user

      if (!user) return
      const props = {
        basePet: pet.basePet,
        owner: `${user?.about.firstName} ${user?.about.lastName}`,
        avatar: user?.about.photoUrl,
        petId: pet._id.toString(),
        petChars: pet.chars,
        petLevel: pet.level,
        petName: pet.name,
        petEvolution: pet.evolution,
        skins: pet.skins,
      }

      openPopout(props)
    },
    [locationGameState],
  )

  const isLobby = !!locationGameState?.lobbyId

  return (
    <Root>
      <Container>
        <CustomWallet mode={'horizontal'} />
        <RewardPageTitle>
          {t(LOCALES.gameResults).replace(
            '{{GAME}}',
            locationGameState?.gameType === GameType.Race
              ? t(LOCALES.race)
              : t(LOCALES.swim),
          )}
        </RewardPageTitle>
        <Ground />
        <AwardsStandContainer bottomPosition={isLobby}>
          <ResizableStage>
            <AwardsStand
              bottomPosition={isLobby}
              pets={pets}
              onPetClick={onPetClick}
            />
          </ResizableStage>
          {pets.map((pet, index) => (
            <NameBadge key={pet.name} data-place={index + 1}>
              {pet.name}
            </NameBadge>
          ))}
        </AwardsStandContainer>
        <Overlay>
          <RewardsContainer hidden={isLobby}>
            <RewardsTitle>{t(LOCALES.rewards)}</RewardsTitle>
            <Rewards>
              {awards.map((award) => (
                <AwardCard key={award.type} award={award} dispatch={dispatch} />
              ))}
            </Rewards>
          </RewardsContainer>
          <Buttons>
            <Button
              width={'100%'}
              style={{ height: '48px' }}
              variant={'success'}
              onClick={onPlayClick}
            >
              {t(isLobby ? LOCALES.returnLobby : LOCALES.play)}
            </Button>
            <Button width={'49px'} variant={'white'} onClick={onHomeClick}>
              <img src={IconHouse} alt={''} />
            </Button>
            {/*<ReturnToMapButton*/}
            {/*    hidden={isLobby}*/}
            {/*    onClick={onHomeClick}*/}
            {/*/>*/}
          </Buttons>
        </Overlay>
      </Container>
    </Root>
  )
}
