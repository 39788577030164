import React, { FC, useEffect, useRef } from 'react'
import { _ReactPixi, Container } from '@pixi/react'
import * as PIXI from 'pixi.js'
import { Spritesheet } from 'pixi.js'
import { PETS_SPRITESHEET_PATH } from 'src/config/assets'
import useLoadAsset from 'src/hooks/useLoadAsset'
import useScreenDimensions from 'src/hooks/useScreenDimensions'
import { Pet } from 'src/pixi/classes'
import { PetClothes } from 'src/pixi/types/spine'
import { getCurrentSkin } from 'utils/getCurrentSkin'
import { getEntityDimensions } from 'utils/getEntityDimensions'
import { petSpriteMapping } from 'utils/petSpriteMapping'

import { useSqueezeAnimation } from 'components/pixi/Entity'

import { BasePet, PopulatedPet } from '@gatto/shared'

const TextureSprites = '/sprites/clothes/spritesheet.json'

type EntityPetProps = Omit<_ReactPixi.IContainer, 'scale'> & {
  basePet: Pick<BasePet, 'kind' | '_id' | 'rarity'>
  pet?: PopulatedPet
  scale?: number
  squeeze?: boolean
  getRef?: React.MutableRefObject<PIXI.Container | null>
  getPetRef?: React.MutableRefObject<Pet | null>
  skins?: PetClothes[]
  isEssenceMode?: boolean | null
  isPetAlone?: boolean
}

/**
 * Компонент сущности питомца
 * @component
 */
export const EntityPet: FC<EntityPetProps> = (props) => {
  const {
    basePet,
    pet,
    scale = 1,
    getRef,
    getPetRef,
    squeeze = true,
    skins,
    isEssenceMode,
    isPetAlone,
    ...rest
  } = props

  const screenDimensions = useScreenDimensions()
  const entityDimensions = getEntityDimensions(screenDimensions)

  const containerRef = useRef<PIXI.Container | null>(null)
  const textSp = useLoadAsset<Spritesheet>(TextureSprites)
  const { squeezeAnimation, squeezeAnimationController } = useSqueezeAnimation()

  useEffect(() => {
    if (!textSp) return
    if (!containerRef.current) return
    const container = containerRef.current

    const addUnit = async (container: PIXI.Container<PIXI.DisplayObject>) => {
      const texture = await PIXI.Assets.load(
        PETS_SPRITESHEET_PATH + petSpriteMapping[basePet?.kind],
      )
      const currentSkin = getCurrentSkin(basePet.rarity)
      const sprite = new Pet({
        texture: texture.spineData,
        currentSkin: currentSkin,
        clothesSpriteSheet: textSp,
        currentClothes: skins,
      })

      sprite.x = 0
      sprite.y = 0

      sprite.pivot.set(0.5, 1)
      sprite.scale.set(0.275)

      sprite.setAnimation('idle', true)

      container.removeChildren()
      container.addChild(sprite)

      if (getPetRef) {
        getPetRef.current = sprite
      }
    }

    addUnit(container).catch(console.error)
  }, [
    basePet,
    entityDimensions.height,
    entityDimensions.width,
    getPetRef,
    textSp,
    skins,
  ])

  useEffect(() => {
    containerRef.current?.on('pointerdown', (e) => {
      containerRef.current?.parent.onpointerdown?.(e)
    })
  }, [])

  return (
    <Container
      interactive
      cursor={
        pet?.level !== 10 && isEssenceMode
          ? 'pointer'
          : ((isPetAlone || pet?.level !== 10) &&
              location.search.indexOf('mode=cross') !== -1) ||
            isEssenceMode
          ? 'default'
          : 'pointer'
      }
      ref={(ref) => {
        containerRef.current = ref
        if (getRef) getRef.current = ref
      }}
      scale={
        squeeze ? [scale / squeezeAnimation, scale * squeezeAnimation] : scale
      }
      onpointerup={() => squeezeAnimationController.start(1)}
      onpointerdown={() => squeezeAnimationController.start(0.95)}
      onpointerleave={() => squeezeAnimationController.start(1)}
      {...rest}
    />
  )
}
