import { Sprite, TilingSprite } from '../classes'
import { SpriteOptions } from '../types/sprite'

export function optionInstaller(
  this: TilingSprite | Sprite,
  {
    x = 0,
    y = 0,
    scale = 1,
    width,
    height,
    anchor,
    rotation = 0,
    alpha,
    zIndex,
  }: SpriteOptions = {},
): void {
  this.x = x
  this.y = y

  if (rotation !== undefined) {
    this.rotation = rotation
  }

  if (zIndex !== undefined) {
    this.zIndex = zIndex
  }

  if (scale && this.scale?.set) {
    this.scale.set(scale, scale)
  }

  if (width !== undefined) {
    this.width = width
  }

  if (height !== undefined) {
    this.height = height
  }

  if (anchor?.x && anchor?.y) {
    this.anchor.set(anchor.x, anchor.y)
  } else if (anchor?.x) {
    this.anchor.set(anchor.x)
  }

  if (alpha !== undefined) {
    this.alpha = alpha
  }
}
