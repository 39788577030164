import React from 'react'
import styled from '@emotion/styled'
import {
  EmotionIcon,
  EmotionIconBg,
  Evolution,
  FishIcon,
  FishIconBg,
  NoticeSVG,
} from 'src/config/assets'

import PetIcon from 'pages/RegionPage/Pet/PetIcon'
import { StatsObject } from 'pages/RegionPage/RegionPet'

import { PetFeelsAliases } from '@gatto/shared'

import PetLevel from './PetLevel'

const Root = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  gap: 5,
})

const IconContainer = styled.div({
  width: 56,
  height: 56,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
})

const Warning = styled(NoticeSVG)({
  position: 'absolute',
  right: -2,
  top: -2,
})

const FishIconStyled = styled(FishIcon)({
  position: 'absolute',
  zIndex: 1,
  left: 0,
  top: -10,
})

const EmotionIconStyled = styled(EmotionIcon)({
  position: 'absolute',
  zIndex: 1,
  left: 10,
  top: 5,
})

const PetEvolution = styled.div({
  borderRadius: 6,
  background: 'rgba(0, 0, 0, 0.25)',
  padding: 3,
  display: 'flex',
  height: 'max-content',
  alignItems: 'center',
  gap: 1,
})

const PetEvolutionStyled = styled(PetEvolution)({
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  top: '90%',
})

const PetEvolutionValue = styled.div({
  color: '#FFF',
  lineHeight: '1.2',
  textTransform: 'capitalize',
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: 'Nunito',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: '700',
})

type Props = {
  level: number
  levelRatio?: string | number
  /** Прогресс заполнения уровня от 0 до 1 */
  levelProgress: number
  stats: StatsObject[]
  evolution: number
  showProgress: boolean
}

const PetStatsIconsOnly: React.FC<
  Props & React.HTMLAttributes<HTMLDivElement>
> = ({
  levelRatio,
  showProgress = false,
  level,
  levelProgress,
  evolution,
  stats,
  ...props
}) => {
  const hasAliasWarning = (alias: PetFeelsAliases) => {
    return (stats.find((e) => e.alias === alias)?.value ?? 0) <= 2
  }

  const playValue = stats.find((e) => e.alias === PetFeelsAliases.Play)?.value
  const feedValue = stats.find((e) => e.alias === PetFeelsAliases.Feed)?.value
  const beforePlayHeight = Math.min(Number(playValue) * 10, 100)
  const beforeFeedHeight = Math.min(Number(feedValue) * 10, 100)
  return (
    <Root {...props}>
      <IconContainer>
        <PetIcon
          color={'rgba(252, 206, 63, 1)'}
          beforeHeight={beforePlayHeight}
          beforeText={beforePlayHeight}
          showProgress={showProgress}
          IconComponent={<EmotionIconStyled />}
        />
        <img src={EmotionIconBg} alt="" />
        {hasAliasWarning(PetFeelsAliases.Play) && <Warning />}
      </IconContainer>
      <PetLevel
        isProgressShown={showProgress}
        style={{
          transform: 'translateY(-24px)',
          WebkitTransform: 'translateY(-24px)',
        }}
        progress={levelProgress}
        level={level}
        levelRatio={levelRatio}
      />

      <PetEvolutionStyled>
        <Evolution />
        <PetEvolutionValue>{evolution}</PetEvolutionValue>
      </PetEvolutionStyled>
      <IconContainer>
        <PetIcon
          color={'rgba(20, 255, 72, 1)'}
          beforeHeight={beforeFeedHeight}
          beforeText={beforeFeedHeight}
          showProgress={showProgress}
          IconComponent={<FishIconStyled />}
        />
        <img src={FishIconBg} alt="" />
        {hasAliasWarning(PetFeelsAliases.Feed) && <Warning />}
      </IconContainer>
    </Root>
  )
}

export default PetStatsIconsOnly
