import hideBannerAd from 'utils/hideBannerAd'

import useRoute from './useRoute'

const useBannerAdHiding = (): void => {
  const route = useRoute()
  if (!route?.bannerAd) {
    hideBannerAd()
  }
}

export default useBannerAdHiding
