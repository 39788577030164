/* eslint-disable no-console */
import { BaseOlympicGame } from 'src/pixi/classes'

import { BaseEngineEvents, GameEndedEvent, StateInfoEvent } from '@gatto/engine'
import { GameConnectReq, GameSocketEvents } from '@gatto/shared'

export function ready(this: BaseOlympicGame): void {
  console.info('ready: gameId ', this.gameId, Date.now())
  console.info('ready engine build 2: ', this.engine)
  this.engine?.sendWebsocketEvent<GameConnectReq, StateInfoEvent>(
    GameSocketEvents.Connect,
    { gameId: this.gameId },
    async (res) => {
      if (res.success === false || !res?.data) {
        return
      }
      console.log('GameReqConnected response ', Date.now())
      this.setup(res.data)
    },
  )
}

export function makeSubscriptions(this: BaseOlympicGame): void {
  console.log('Made subscriptions ', Date.now())
  this.engine.handleWebsocketUpdates()
  this.engine.onWebsocketEvent<StateInfoEvent>(
    BaseEngineEvents.StateInfo,
    async (data) => {
      console.log('sync event ', Date.now())
      if (!this.isSetuped) {
        this.setup(data)
        return
      }
      this.sync(data)
    },
  )

  this.engine.onWebsocketEvent(
    BaseEngineEvents.GameEnded,
    async (data: GameEndedEvent) => this.finishRun(data),
  )
}
