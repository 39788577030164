import { useEffect, useRef } from 'react'
import { Container, Sprite, useTick } from '@pixi/react'
import * as PIXI from 'pixi.js'
import { OPEN_EGG_SPRITESHEET_PATH } from 'src/config/assets'
import useLoadAsset from 'src/hooks/useLoadAsset'
import useScreenDimensions from 'src/hooks/useScreenDimensions'
import { OpenEggAnimation } from 'src/pixi/egg-animation'
import { SpineTexture } from 'src/pixi/types/spine'
import { getEntityDimensions } from 'utils/getEntityDimensions'
import lerp from 'utils/lerp'

import rays from 'assets/svg/egg/rays.svg'
import { Entity, EntityPet } from 'components/pixi'

import { BasePet } from '@gatto/shared'

export const EggAnimationContainer: React.FC<{
  eggSrc: string
  hatchedPet: BasePet
  onEnd: () => void
}> = ({ eggSrc, hatchedPet, onEnd }) => {
  const spineEggAnimation = useLoadAsset<{ spineData: SpineTexture }>(
    OPEN_EGG_SPRITESHEET_PATH,
  )
  const eggContainerRef = useRef<PIXI.Container>(null)
  const rayRef = useRef<PIXI.Sprite>(null)
  const pet = useRef<PIXI.Container>(null)

  const screenDimensions = useScreenDimensions()
  const entityDimensions = getEntityDimensions(screenDimensions)

  const containerX = screenDimensions.width / 2
  const containerY = screenDimensions.height / 2 + 100

  const setPetVisible = (visible: boolean) => {
    if (pet.current) {
      pet.current.visible = visible
    }
  }

  useEffect(() => {
    setPetVisible(false)
    if (eggContainerRef.current && spineEggAnimation) {
      const texture = spineEggAnimation.spineData

      eggContainerRef.current.y += eggContainerRef.current.height / 2

      const addUnit = async (container: PIXI.Container) => {
        const eggTexture = await PIXI.Assets.load(eggSrc)
        const animation = new OpenEggAnimation({
          texture,
          eggTexture,
        })

        animation.onEnd = onEnd
        animation.onCrack = () => {
          setPetVisible(true)
          const ray = rayRef.current
          if (ray) {
            ray.visible = true
            ray.scale.set(1)
          }
        }

        animation.run()

        container.removeChildren()
        container.addChild(animation)
      }

      addUnit(eggContainerRef.current).catch(console.error)
    }
  }, [
    eggSrc,
    entityDimensions.height,
    entityDimensions.width,
    onEnd,
    spineEggAnimation,
  ])

  useTick((delta) => {
    const ray = rayRef.current
    if (ray && ray.visible) {
      const targetValue = 0.5

      ray.rotation += (delta / 100) * ((ray.scale.x / targetValue) * 2)
      ray.scale.set(lerp(ray.scale.x, targetValue, delta / 50))
    }
  })

  return (
    <Container x={containerX} y={containerY}>
      <Sprite
        ref={rayRef}
        image={rays}
        visible={false}
        anchor={[0.5, 0.5]}
        y={-entityDimensions.height / 3}
        scale={1.25}
      />
      <Entity
        shadow={false}
        appear={false}
        scale={1.2}
        entity={<EntityPet basePet={hatchedPet} getRef={pet} />}
      />
      <Container ref={eggContainerRef} scale={0.5} />
    </Container>
  )
}
