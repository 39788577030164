/* eslint-disable no-console */
import * as PIXI from 'pixi.js'
import { BaseOlympicGame, Sprite } from 'src/pixi/classes'

import { CountdownStatus } from '../constants'

export function setupTimer(this: BaseOlympicGame): void {
  const texture = this.getTexture('timerBg')
  this.timerSprite = new Sprite(texture, { anchor: { x: 0.5 } })

  this.timerTextSprite = new PIXI.Text('До окончания:', {
    fontFamily: 'Nunito',
    fontSize: 26,
  })
  this.timerTextSprite.scale.set(0.5)
  this.timerTextSprite.anchor.set(0.5)
  this.timerTextSprite.y -= 16

  this.timerCountSprite = new PIXI.Text(this.getTimerString(), {
    fontSize: 96,
    fontFamily: 'Cartonsix_NC',
  })
  this.timerCountSprite.scale.set(0.5)
  this.timerCountSprite.anchor.set(0.5)
  this.timerCountSprite.y += 8

  this.timerContainer.pivot.set(0.5, 0)
  this.timerContainer.addChild(
    this.timerSprite,
    this.timerTextSprite,
    this.timerCountSprite,
  )

  this.timerContainer.x = this.clientWidth / 2
  this.timerContainer.y = 118
  this.timerContainer.alpha = 0
  this.interfaceContainer.addChild(this.timerContainer)
}

export function setupCountdown(
  this: BaseOlympicGame,
  isHidden?: boolean,
): void {
  if (isHidden) {
    this.countdownStatus = CountdownStatus.Hidden
    this.timerContainer.alpha = 1
    return
  }

  let timeLeft: number | null
  if (!this.startGameAt) {
    timeLeft = null
  } else {
    timeLeft = Math.ceil((this.startGameAt - Date.now()) / 1000)
  }

  if (timeLeft === null) {
    this.countdownStatus = CountdownStatus.Wait
  } else if (timeLeft <= 0) {
    this.countdownStatus = CountdownStatus.Hidden
    this.timerContainer.alpha = 1
  }

  if (this.countdownStatus === CountdownStatus.Hidden) return

  const countdownWaitTexture = this.getTexture('countdownWait')
  const countdownGlowTexture = this.getTexture('countdownGlow')
  this.countdownBg = new PIXI.Graphics()

  this.countdownBg.beginFill(0x000000, 0.3)
  this.countdownBg.drawRect(0, 0, this.clientWidth, this.clientHeight)
  this.countdownBg.endFill()

  this.countdownCountGlow = new Sprite(countdownGlowTexture, {
    x: this.clientWidth / 2,
    y: this.clientHeight / 2,
    alpha: 0.4,
    anchor: { x: 0.5 },
  })
  this.countdownWait = new Sprite(countdownWaitTexture, {
    x: this.clientWidth / 2,
    y: this.clientHeight / 2,
    anchor: { x: 0.5 },
    scale: 0.5,
  })

  this.countdownText = new PIXI.Text('', {
    fontSize: 222,
    fontWeight: '500',
    fontFamily: 'Cartonsix_NC',
    fill: '0xFFFFFF',
  })
  this.countdownText.anchor.set(0.5)
  this.countdownText.x = this.clientWidth / 2
  this.countdownText.y = this.clientHeight / 2

  this.countdownContainer.pivot.set(0.5)
  this.countdownContainer.addChild(this.countdownBg)

  if (!timeLeft) {
    this.countdownContainer.addChild(this.countdownWait)
  } else {
    this.countdownText.text = timeLeft
    this.countdownContainer.addChild(
      this.countdownCountGlow,
      this.countdownText,
    )
  }
  this.countdownContainer.zIndex = 10
  this.interfaceContainer.addChild(this.countdownContainer)
}
