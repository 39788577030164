import { FC, useState } from 'react'
import { Popouts } from 'src/const/router'

import { Popout, StatusType } from 'components/base/Popout'
import { LoadPage } from 'pages/LoadPage'

export type LoadProps = Popouts['loading']

const LoadPopout: FC<LoadProps> = ({ assets, redirect, state }) => {
  const [status, setStatus] = useState<StatusType>()

  return (
    <Popout
      isFullScreen={true}
      background={true}
      type="default"
      status={status}
      setStatus={setStatus}
    >
      <LoadPage
        setStatus={setStatus}
        assets={assets}
        redirect={redirect}
        state={state}
      />
    </Popout>
  )
}

export default LoadPopout
