import React, { useCallback, useRef } from 'react'
import styled from '@emotion/styled'
import closeIcon from 'src/assets/icons/cancelIcon.svg'
import corgy from 'src/assets/loading/corgy.png'
import designTokens from 'src/config/tokens'
import useSquircle from 'src/hooks/useSquircle'

type BaseModalProps = {
  /**
   * Картинка заголовка
   */
  iconSrc: string

  /**
   * Заголовок
   */
  title: string

  /**
   * Подзаголовок
   */
  subtitle: string

  /**
   * Выравнивание заголовка
   * @default 'center'
   */
  headerAlign?: 'left' | 'center' | 'right'

  /**
   * Контент
   */
  children: React.ReactNode

  /**
   * Событие на закрытие модалки
   * @event onClose
   */
  onClose?: () => void
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

const ModalContainer = styled.div<{
  theme?: typeof designTokens
  svgPath: string
}>`
  width: 384px;
  height: auto;
  padding: 8px 16px 16px 16px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  font-family: Nunito;
  font-style: normal;
  line-height: normal;

  background: ${({ theme }) => theme.color['white-100']};
  color: ${({ theme }) => theme.color['black-100']};
  clip-path: ${({ svgPath }) => `path('${svgPath}')`};

  @media (max-width: 416px) {
    width: 100%;
    margin: 0 16px;
  }
`

const CloseButton = styled.img`
  aspect-ratio: 1 / 1;
  width: 20px;
  height: auto;
  margin: 20px 28px 0 0;

  position: absolute;
  right: 0;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  cursor: pointer;
`

const HeaderBlock = styled.div<{ align: BaseModalProps['headerAlign'] }>`
  display: flex;
  flex-direction: column;
  gap: 3px;

  /* Выравнивание слева */
  ${({ align }) => align === 'left' && 'align-items: flex-start'}

  /* Выравнивание по-центру */
  ${({ align }) => align === 'center' && 'align-items: center'}

  /* Выравнивание справа */
  ${({ align }) => align === 'right' && 'align-items: flex-end'}
`

const Icon = styled.img<{ svgPath: string }>`
  aspect-ratio: 1 / 1;
  width: 60px;
  height: 60px;

  /* border-radius: 10px; */
  clip-path: ${({ svgPath }) => `path('${svgPath}')`};
`

const Title = styled.div<{ theme?: typeof designTokens }>`
  width: fit-content;
  color: ${({ theme }) => theme.color['black-100']};

  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
`

const Subtitle = styled.div<{ theme?: typeof designTokens }>`
  width: fit-content;
  color: ${({ theme }) => theme.color['grey-secondary']};

  font-size: 14px;
  font-weight: 500;
`

/**
 * Базовый компонент модального окна
 * @returns Базовое модальное окно
 */
function BaseModal({
  iconSrc = corgy,
  title,
  subtitle,
  headerAlign = 'center',
  onClose,
  children,
}: BaseModalProps): React.ReactElement {
  const modalRef = useRef<HTMLDivElement>(null)
  const modalSvgPath = useSquircle({
    ref: modalRef,
    cornerRadius: 26,
  })

  const iconRef = useRef<HTMLImageElement>(null)
  const iconSvgPath = useSquircle({
    ref: iconRef,
    cornerRadius: 10,
  })

  const handleClose = useCallback(() => {
    onClose?.()
  }, [onClose])

  return (
    <ModalContainer ref={modalRef} svgPath={modalSvgPath}>
      <CloseButton src={closeIcon} onClick={handleClose} />
      <Icon src={iconSrc} ref={iconRef} svgPath={iconSvgPath} />
      <HeaderBlock align={headerAlign}>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </HeaderBlock>
      {children}
    </ModalContainer>
  )
}

export default BaseModal
