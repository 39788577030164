import React from 'react'
import styled from '@emotion/styled'

const TopText = styled.span({
  display: 'block',
  fontSize: 16,
  fontWeight: 800,
  textTransform: 'uppercase',
  position: 'relative',
  zIndex: 2,
})

const TextWrapper = styled.div({
  color: 'white',
  position: 'absolute',
  top: 7,
  textAlign: 'center',
  textIndent: '0',
  textEdge: 'cap',
  fontFeatureSettings: "'clig' off, 'liga' off",
  textShadow: '0 1px 0 rgba(0, 0, 0, 0.25)',
  fontFamily: 'Nunito',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  zIndex: 2,
})

const BottomText = styled(TopText)({
  fontSize: 10,
  fontWeight: 700,
  textTransform: 'unset',
})

const Wrapper = styled.div<{ color: string; beforeHeight: number }>(
  ({ color, beforeHeight }) => ({
    '&:before': {
      content: '""',
      zIndex: 1,
      width: '44.4px',
      backgroundColor: `${color}`,
      height: '43px',
      left: '6px',
      top: '-3px',
      borderRadius: '100%',
      position: 'absolute',
      clipPath: `inset(calc(100% - ${beforeHeight}%) 0% 0%)`,
    },
    position: 'relative',
    width: '56px',
    height: '61px',
  }),
)

type Props = {
  color: string
  beforeHeight: number
  beforeText: string | number
  showProgress: boolean
  IconComponent: React.ReactNode
}

const PetIcon: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  color,
  beforeHeight,
  beforeText,
  showProgress,
  IconComponent,
}) => {
  return (
    <TextWrapper>
      <Wrapper color={color} beforeHeight={beforeHeight}>
        {showProgress ? (
          <>
            <TopText>{beforeText}</TopText>
            <BottomText>/100</BottomText>
          </>
        ) : (
          IconComponent
        )}
      </Wrapper>
    </TextWrapper>
  )
}

export default PetIcon
