import bridge from '@vkontakte/vk-bridge'

export const getVKStorage = async <T extends string>(
  ...keys: T[]
): Promise<Record<T, string>> => {
  const values = {} as Record<T, string>

  const responses = await bridge.send('VKWebAppStorageGet', { keys })

  for (const response of responses.keys) {
    values[response.key as T] = response.value
  }

  return values
}

export const setVKStorage = (key: string, value: string): Promise<unknown> => {
  return bridge.send('VKWebAppStorageSet', { key, value }).catch(console.error)
}
