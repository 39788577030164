import React, { FC, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { Types as MongooseTypes } from 'mongoose'
import * as PIXI from 'pixi.js'
import { MINI_APP } from 'src'
import { StockSVG } from 'src/config/assets'
import {
  BACKGROUND_IMAGE_HEIGHT,
  BACKGROUND_IMAGE_WIDTH,
  EXPERIENCE_TABLE,
  ONE_MINUTE,
  PET_LEVEL_WIDTH,
  PET_LEVELS,
} from 'src/config/constants'
import { usePopout } from 'src/hooks'
import useScreenDimensions from 'src/hooks/useScreenDimensions'
import { makeRequest } from 'utils/api'
import { getEntityDimensions } from 'utils/getEntityDimensions'

import { Wallet } from 'components/base'
import Button from 'components/base/Button/Button'
import PetName from 'pages/RegionPage/Pet/PetName'
import PetPrize from 'pages/RegionPage/Pet/PetPrize'
import { useAppDispatch } from 'store'
import { fetchRegion } from 'store/region'
import { incrementBalance, updatePet } from 'store/user'

import {
  GetPrizeValueResult,
  PetFeelsAliases,
  Platform,
  PopulatedPet,
  PopulatedUserRegion,
  PRIZE_SCALE_MAX,
  PrizeResult,
} from '@gatto/shared'

import PetButtons from './Pet/PetButtons'
import PetChars from './Pet/PetChars'
import PetStatsIconsOnly from './Pet/PetStatsIconsOnly'
import Tabbar from './Tabbar'

const Overlay = styled.div({
  position: 'absolute',
  zIndex: 10,
  top: 0,
  height: '100%',
  width: '100%',
  left: '50%',
  transform: 'translateX(-50%)',
  pointerEvents: 'none',
})

const StockButton = styled(Button)({
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  top: 'calc(100% + 4px)',
  left: '4px',
  zIndex: 20,
  pointerEvents: 'all',
})

const StyledPetChars = styled(PetChars)({
  top: 8,
  pointerEvents: 'all',
})

const StyledPetButtons = styled(PetButtons)({
  position: 'absolute',
  bottom: 74,
  pointerEvents: 'all',
})

const StyledPetStatsIconsOnlyContainer = styled.div({
  position: 'absolute',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
})

const StyledPetName = styled(PetName)({
  position: 'absolute',
  bottom: 180,
  transform: 'translateX(-50%)',
  left: '50%',
  zIndex: 20,
})

const StyledPetStatsIconsOnly = styled(PetStatsIconsOnly)({
  userSelect: 'none',
  pointerEvents: 'all',
  transition: 'opacity 90ms ease, transform 90ms ease',
  WebkitTransition: 'opacity 90ms ease, -webkit-transform 90ms ease',
})

const StyledPetPrize = styled(PetPrize)<{ top: number; left: number }>`
  position: absolute;
  right: calc(50% - 133px);
  bottom: calc(50% - 44px);
  cursor: pointer;
  z-index: 10;
  pointer-events: auto;
  transform: translate(50%, 50%);
`

const CustomWallet = styled(Wallet)`
  position: absolute;
  margin: 0;
  bottom: calc(100% + 4px);
  left: 4px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.15) 0%,
      rgba(0, 0, 0, 0.15) 100%
    ),
    linear-gradient(
      355deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0.1) 100%
    );
  box-shadow: 0px -4px 0px 0px rgba(38, 43, 11, 0.25) inset;
`

export type StatsObject = {
  /** Значение от 0 до 10 */
  value: number
  icon?: React.ReactElement
  label: string
  alias: PetFeelsAliases
}

type RegionPetProps = {
  bgTexture?: PIXI.Texture<PIXI.Resource>
  showAds: () => Promise<boolean>
  pet: PopulatedPet
  region: PopulatedUserRegion
  regionId: MongooseTypes.ObjectId
  isPetLevelShown: boolean
  prize: PrizeResult | null
  setPrize: React.Dispatch<React.SetStateAction<PrizeResult | null>>
  setIsPrizeReady: React.Dispatch<React.SetStateAction<boolean>>
}

export const updateValue = async ({
  pet,
}: {
  pet: PopulatedPet
}): Promise<GetPrizeValueResult | undefined> => {
  if (pet.prizeScale.currentPrizeValue >= PRIZE_SCALE_MAX) {
    return { value: PRIZE_SCALE_MAX }
  }
  const updatedPet = await makeRequest('pet.getById', {
    id: String(pet._id),
  })

  const fullness = updatedPet.fullness.value
  const happiness = updatedPet.happiness.value
  const now = Date.now()
  const shouldUpdateValue =
    fullness >= 20 ||
    happiness >= 50 ||
    pet.prizeScale.currentPrizeValue === PRIZE_SCALE_MAX ||
    pet.prizeScale.end <= now
  if (!shouldUpdateValue) return

  return await makeRequest('pet.getPrizeValue', {
    id: String(pet._id),
  })
}

const RegionPet: FC<RegionPetProps> = ({
  region,
  pet,
  showAds,
  regionId,
  isPetLevelShown,
  setPrize,
  prize,
  setIsPrizeReady,
}) => {
  const dispatch = useAppDispatch()
  const now = Date.now()

  const openFeedNotificationsPopup = usePopout('notificationsAccess', false, {
    feel: PetFeelsAliases.Feed,
  })

  const openPlayNotificationsPopup = usePopout('notificationsAccess', false, {
    feel: PetFeelsAliases.Play,
  })

  const openPrizeNotificationsPopup = usePopout('notificationsAccess', false, {
    feel: 'prize',
  })

  const updateRegion = () => {
    if (!regionId) return
    dispatch(fetchRegion(regionId))
  }

  const handleActionClick = async (buttonAlias: PetFeelsAliases) => {
    if (!pet) return

    switch (buttonAlias) {
      case 'feed': {
        if (pet.fullness.end <= now) {
          const { showNotificationsPopup } = await makeRequest('pet.feed', {
            id: `${pet._id}`,
          })

          if (showNotificationsPopup && MINI_APP === Platform.VK)
            openFeedNotificationsPopup()
          updateRegion()
          return
        }
        // FIX вечно крутящегося спинера в конпках
        updateRegion()

        if (pet.fullness.adSkippedTimes > 3) return

        await showAds()
        await makeRequest('pet.skipTime', {
          id: `${pet._id}`,
          alias: buttonAlias,
        }).catch(() => null)
        updateRegion()

        break
      }

      case 'play': {
        if (pet.happiness.end <= now) {
          const { showNotificationsPopup } = await makeRequest('pet.play', {
            id: `${pet._id}`,
          })
          if (showNotificationsPopup && MINI_APP === Platform.VK)
            openPlayNotificationsPopup()
          updateRegion()
          return
        }

        // FIX вечно крутящегося спинера в конпках
        updateRegion()

        if (pet.happiness.adSkippedTimes > 3) return

        await showAds()
        await makeRequest('pet.skipTime', {
          id: `${pet._id}`,
          alias: buttonAlias,
        }).catch(() => null)
        updateRegion()
        break
      }

      default:
        return
    }
  }

  const petToStoragePopout = usePopout('petToStorage', false, {
    petId: region?.pet?._id.toString(),
  })

  const screenDimensions = useScreenDimensions()
  const entityDimensions = getEntityDimensions(screenDimensions)

  const containerX = screenDimensions.width / 4
  const containerY = screenDimensions.height / 4

  const petLevelContainerY =
    screenDimensions.height / 2.1 - entityDimensions.width / 2 - PET_LEVEL_WIDTH

  const overlayWidth = Math.min(
    BACKGROUND_IMAGE_WIDTH *
      (screenDimensions.height / BACKGROUND_IMAGE_HEIGHT),
  )

  const petStats: StatsObject[] = useMemo(() => {
    return [
      {
        label: 'Счастье',
        value: Math.round(pet.happiness.value / 10),
        alias: PetFeelsAliases.Play,
      },
      {
        label: 'Сытость',
        alias: PetFeelsAliases.Feed,
        value: Math.round(pet.fullness.value / 10),
      },
    ]
  }, [pet])

  const returnPetExp = () => {
    const level = pet.level
    const experience = pet.experience

    if (level === PET_LEVELS.TEN) {
      const currentLevel = isPetLevelShown ? level : `MAX`
      return (
        <StyledPetStatsIconsOnly
          showProgress={!isPetLevelShown}
          level={level}
          levelRatio={currentLevel}
          levelProgress={0}
          stats={petStats}
          evolution={pet.evolution}
        />
      )
    }

    const maxExperience = EXPERIENCE_TABLE[level + 1]
    if (maxExperience !== undefined) {
      const levelProgress = experience / maxExperience
      const currentLevel = isPetLevelShown
        ? level
        : `${experience}/${maxExperience}`
      return (
        <StyledPetStatsIconsOnly
          showProgress={!isPetLevelShown}
          level={level}
          levelRatio={currentLevel}
          levelProgress={levelProgress}
          stats={petStats}
          evolution={pet.evolution}
        />
      )
    }

    return
  }

  const [currentValue, setCurrentValue] = useState<number>(
    pet.prizeScale.currentPrizeValue,
  )

  const updatePrizeValue = async ({ pet }: { pet: PopulatedPet }) => {
    const newCurrentValue = await updateValue({ pet })
    if (!newCurrentValue) {
      return
    }

    setIsPrizeReady(newCurrentValue.value === PRIZE_SCALE_MAX)

    if (newCurrentValue.value === PRIZE_SCALE_MAX) {
      setCurrentValue(() => PRIZE_SCALE_MAX)
    }

    setCurrentValue(() => newCurrentValue?.value)
  }

  useEffect(() => {
    updatePrizeValue({ pet })
    const intervalId = setInterval(() => updatePrizeValue({ pet }), ONE_MINUTE)
    dispatch(updatePet(pet))

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const handlePrizeClick = async () => {
    try {
      const petRes = await makeRequest('pet.getPrize', {
        id: pet._id.toString(),
      })

      // FIXME: временный фикс бага. Модалку в тг не показываем (бот не готов)
      if (MINI_APP !== Platform.TG) {
        if (
          'showNotificationsPopup' in petRes &&
          petRes.showNotificationsPopup
        ) {
          openPrizeNotificationsPopup()
        }

        updateRegion()
      }

      if ('soft' in petRes && currentValue === PRIZE_SCALE_MAX) {
        dispatch(
          incrementBalance({
            hard: petRes.hard ?? undefined,
            soft: petRes.soft,
          }),
        )
        setPrize(petRes)
        setIsPrizeReady(false)
        setCurrentValue(0)
      }

      updateRegion()
      return
    } catch (e) {
      console.info(e)
      setIsPrizeReady(false)
      setCurrentValue(0)
      setPrize(null)
    }
  }

  return (
    <>
      <Overlay style={{ width: overlayWidth }}>
        <div
          style={{
            position: 'relative',
            maxWidth: 'fit-content',
            margin: 'auto',
            pointerEvents: 'all',
            top: 'calc(45px + 12px + 4px)',
          }}
        >
          <Wallet
            mode={'horizontal'}
            /*FIX: удалил margin: 0; в рамках фикса кошелька */
            customStyle={`
                        position: absolute; ${
                          MINI_APP === Platform.VK && 'margin: 0'
                        }; bottom: calc(100% + 4px);
                        position: absolute; bottom: calc(100% + 4px);
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), linear-gradient(355deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.10) 100%);
                        box-shadow: 0px -4px 0px 0px rgba(38, 43, 11, 0.25) inset;`}
          />
          <StyledPetChars chars={pet.chars} />
          <StockButton
            variant="skyBlue"
            onClick={() => petToStoragePopout()}
            width="square"
          >
            <StockSVG />
          </StockButton>
        </div>
        {isPetLevelShown ? (
          <StyledPetButtons pet={pet} onClick={handleActionClick} />
        ) : (
          <StyledPetName name={pet.name} rarity={pet.basePet.rarity} />
        )}
        {!prize && (
          <StyledPetPrize
            left={containerX}
            top={containerY}
            isProgressShown={isPetLevelShown}
            value={currentValue}
            onClick={handlePrizeClick}
          />
        )}
      </Overlay>
      <StyledPetStatsIconsOnlyContainer
        style={{
          top: petLevelContainerY,
        }}
      >
        {returnPetExp()}
      </StyledPetStatsIconsOnlyContainer>
      <Tabbar page="pet" pet={pet} region={region} />
    </>
  )
}

export default RegionPet
