import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { AppRoot } from '@vkontakte/vkui'
import { useInitData, WebAppProvider } from '@vkruglikov/react-telegram-web-app'
import { routes } from 'src/config/routes'
import { usePopout } from 'src/hooks'
import useNetworkErrorHandling from 'src/hooks/useNetworkErrorHandling'
import useRoute from 'src/hooks/useRoute'
import { PetClothes } from 'src/pixi/types/spine'
import { ModalContext } from 'src/utils/ModalContext'
import { makeRequest } from 'utils/api'

import { GetParameterPopouts, Tabbar, OnlineProvider } from 'components/base'
import GetParameterModals from 'components/modals/GetParameterModals'
import { useAppDispatch } from 'store'
import { fetchServerInfo } from 'store/server'
import { setUser, updateUserRegionPetSkins } from 'store/user'

import { Platform } from '@gatto/shared'

import { LOCATION_HASH, YM_COUNTER_ID } from '../../config/constants'
import useBannerAdHiding from '../../hooks/useBannerAdHiding'
import { MINI_APP } from '../../index'

import '@vkontakte/vkui/dist/vkui.css'
import 'src/style/fonts.css'
import 'src/style/normalize.css'
import 'src/style/vars.css'

type Props = {
  activeModal?: string | null
  setActiveModal: React.Dispatch<React.SetStateAction<string | null>>
}

function AppWithProviders({
  children,
  activeModal,
  setActiveModal,
}: PropsWithChildren & Props) {
  return (
    <OnlineProvider>
      <ModalContext.Provider
        value={{ activeModal, updateActiveModal: setActiveModal }}
      >
        {children}
      </ModalContext.Provider>
    </OnlineProvider>
  )
}
const App: FC = () => {
  const [activeModal, setActiveModal] = useState<string | null>('root')
  const isOnline = useNetworkErrorHandling()
  const route = useRoute()
  useBannerAdHiding()

  const openNetworkErrorPopout = usePopout('networkError', true, {})
  useEffect(() => {
    if (!isOnline) {
      openNetworkErrorPopout()
    }
  }, [isOnline, openNetworkErrorPopout])

  const [initDataUnsafe] = useInitData()

  useEffect(() => {
    const tgUserId =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (window.Telegram?.WebApp?.initDataUnsafe?.user?.id as number) || undefined
    if (!tgUserId) {
      return
    }

    const initDataParams = new URLSearchParams(
      (initDataUnsafe?.start_param || '').split('_').join('&'),
    )
    const entrypoint = initDataParams.get('entrypoint') || 'unknown'

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    ym(YM_COUNTER_ID, 'init', {
      clickmap: true,
      webvisor: true,
      userParams: {
        UserID: tgUserId,
        entrypoint,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dispatch = useAppDispatch()
  useEffect(() => {
    const func = async () => {
      // await tonConnector.restoreConnection()
      let invitedBy
      if (MINI_APP === Platform.VK) {
        invitedBy = LOCATION_HASH
      }
      if (MINI_APP === Platform.TG) {
        const params = new URLSearchParams(LOCATION_HASH)
        const initData = new URLSearchParams(params.get('tgWebAppData') || '')
        invitedBy = initData.get('start_param') || undefined
      }
      const response = await makeRequest('user.getSelf', {
        invitedBy: invitedBy,
      })
      console.info('response from app', response)
      if (!response) return
      dispatch(setUser(response.user))
      dispatch(fetchServerInfo())

      const res = await makeRequest('skin.getAllPetsSkins', {})

      for (let i = 0; i < res.length; i++) {
        dispatch(
          updateUserRegionPetSkins({
            petId: res[i]?.pet || '',
            skins: res[i]?.skin as PetClothes[],
          }),
        )
      }
    }
    func()
  }, [dispatch])

  return (
    <AppWithProviders activeModal={activeModal} setActiveModal={setActiveModal}>
      <WebAppProvider>
        <AppRoot>
          <Routes>
            {routes.map(({ path, component }, i) => (
              <Route element={component} key={i} path={path} />
            ))}
            <Route path="/*" />
          </Routes>
          <GetParameterPopouts />
          <GetParameterModals />
          <Tabbar
            isVisible={!route?.shouldHideTabbar}
            isOneButtonMode={false}
          />
        </AppRoot>
      </WebAppProvider>
    </AppWithProviders>
  )
}

App.displayName = 'App'
export default App
