import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Spinner } from '@vkontakte/vkui'
import { usePopout } from 'src/hooks'
import { makeRequest } from 'utils/api'

import { Button, Input } from 'components/base'
import { Header, Popout, StatusType, Subheader } from 'components/base/Popout'
import { useAppDispatch } from 'store/index'
import { incrementBalance } from 'store/user'

import { LOCALES } from '@gatto/locales'

import promoGiftVoucherImage from '/src/assets/svg/popouts/promo-gift-voucher.svg'

const Image = styled.img`
  width: 64.01px;
  height: 38px;
  margin: 0 auto;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  align-self: stretch;
`

const PromoInputPopout: FC = () => {
  const { t } = useTranslation()
  const [status, setStatus] = useState<StatusType>()

  const openFailedPopout = usePopout('statusAlert', false, {
    type: 'error',
    width: '284px',
    headerFontSize: 18,
    subheader: t(LOCALES.useAnotherPromo),
    header: t(LOCALES.promoNotFound),
  })
  const openActivatedPopout = usePopout('promoWasActivated', false, {
    subheader: t(LOCALES.promoAlreadyActivated),
  })
  const openPopout = usePopout('promoSuccessValue', false)
  const dispatch = useAppDispatch()
  const [text, setText] = useState('')
  const [request, setRequest] = useState(true)

  const fetchActivatePromocode = async (code: string) => {
    try {
      setRequest(false)
      const result = await makeRequest('user.activatePromocode', {
        code: code.toUpperCase(),
      })
      if (result) {
        openPopout({ type: result.type, value: result.value })
      } else {
        setRequest(true)
        openFailedPopout()
      }
      if (result.type === 'soft') {
        dispatch(incrementBalance({ soft: result.value }))
      }
      if (result.type === 'hard') {
        dispatch(incrementBalance({ hard: result.value }))
      }
    } catch (error: unknown) {
      const responseMessage = (
        error as { response: { data: { message: string } } }
      ).response?.data?.message
      if (responseMessage === 'Промокод уже был активирован') {
        setRequest(true)
        openActivatedPopout()
      } else {
        setRequest(true)
        openFailedPopout()
      }
    }
  }

  return (
    <Popout
      type="default"
      background={true}
      width="284px"
      isFullScreen={false}
      status={status}
      setStatus={setStatus}
    >
      <Image src={promoGiftVoucherImage} />
      <InfoContainer>
        <Header>{t(LOCALES.promoActivation)}</Header>
        <Subheader>{t(LOCALES.enterPromo)}</Subheader>
      </InfoContainer>
      {request && (
        <Input value={text} onChange={(e) => setText(e.currentTarget.value)} />
      )}
      {!request && <Spinner size="large" style={{ margin: '20px 0' }} />}
      <Button
        onClick={() => {
          fetchActivatePromocode(text)
        }}
        variant="success"
      >
        {t(LOCALES.activate)}
      </Button>
    </Popout>
  )
}

export default PromoInputPopout
