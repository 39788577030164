import { TRACKS_NAMES } from '../types/rungame'

export const RUNGAME_TEXTURES = {
  clouds: 'Back_2_clouds.png',
  stadium: 'Back_3_stadium.png',
  stands: 'Back_4_stands.png',
  stadiumForward: 'Back_5_stadium_forward.png',
  grass: 'Back_6_grass.png',

  trackStart: 'track_7_start.png',
  trackFinish: 'track_7_finish.png',

  trackMiddle1: 'track_7_middle_1.png',
  trackMiddle2: 'track_7_middle_2.png',
  trackMiddle3: 'track_7_middle_3.png',
  barrier: 'barrier.png',
  timerBg: 'timerBg.png',
  countdownGlow: 'countdownGlow.png',
  countdownWait: 'countdownWait.png',
}

export const TRACKS_COORDS = {
  trackStart: [
    102, 102, 102, 102, 102, 100, 98, 96, 94, 92, 90, 88, 86, 85, 84, 84, 85,
    86, 86, 87, 88, 89, 90, 91, 92, 92,
  ],

  trackMiddle1: [
    91, 92, 95, 101, 110, 121, 131, 137, 141, 141, 140, 138, 134, 127, 119, 112,
    107, 102, 99, 96, 94, 93, 92, 92, 91, 91,
  ],

  trackMiddle2: [
    92, 92, 92, 92, 92, 92, 92, 92, 92, 92, 92, 92, 92, 92, 92, 92, 92, 92, 92,
    92, 92, 92, 92, 92, 92, 92,
  ],

  trackMiddle3: [
    88, 88, 89, 89, 90, 92, 94, 97, 101, 106, 113, 121, 129, 135, 139, 141, 141,
    140, 137, 131, 121, 110, 100, 93, 89, 88,
  ],

  trackFinish: [
    93, 94, 96, 99, 101, 102, 102, 100, 97, 95, 92, 89, 87, 85, 84, 84, 85, 86,
    86, 87, 88, 89, 90, 91, 92, 93,
  ],
}

export const TRACK_BY_NUM: { [key: number]: TRACKS_NAMES } = {
  1: 'trackStart',
  2: 'trackMiddle1',
  3: 'trackMiddle2',
  4: 'trackMiddle3',
  5: 'trackFinish',
}

export const TRACK_WIDTH = 800
export const TRACK_HEIGHT = 350
export const TRACKS_IN_CONTAINER = 5
export const CONTAINER_SCALE_COEF = window.innerWidth / window.innerHeight + 0.2

export const START_LINE_X = 118
export const FINISH_LINE_X = 590

export const BARRIER_Y_OFFSET = 50
export const BARRIER_WIDTH = 50
export const BARRIER_HEIGHT = 114
