import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '.'

export interface PaymentState {
  in_process: boolean
}

export const initialState: PaymentState = {
  in_process: false,
}

export const paymentSlice = createSlice({
  name: 'selectPet',
  initialState,
  reducers: {
    setPaymentProcess: (state, action: PayloadAction<boolean>) => {
      state.in_process = action.payload
    },
  },
})

export const { setPaymentProcess } = paymentSlice.actions

export default paymentSlice.reducer

export const getPaymentProcess = (
  state: RootState,
): PaymentState['in_process'] => state.payment.in_process
