import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import useNetworkStatus from './useNetworkStatus'

const useNetworkErrorHandling = (): boolean => {
  const location = useLocation()
  const navigate = useNavigate()
  const history = createBrowserHistory()
  const isOnline = useNetworkStatus()
  useEffect(() => {
    const unblock = history.block(() => {
      if (!isOnline) {
        // A transition was blocked!
        return false // Returning false will block the transition
      }
      return true // Returning true will allow the transition
    })

    if (isOnline) {
      unblock()
      if (location.search.includes('?popout=networkError')) {
        navigate(location.pathname, { replace: true })
      }
    }

    // Clean up the effect
    return () => {
      unblock() // Unblock on component unmount
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline])

  return isOnline
}

export default useNetworkErrorHandling
