import { ColorOverlayFilter } from '@pixi/filter-color-overlay'
import { MASK_CHANNEL, MaskConfig, MaskFilter } from '@pixi/picture'
import * as PIXI from 'pixi.js'
import { Spine } from 'pixi-spine'
import { SwimGame, TilingSprite } from 'src/pixi/classes'
import { SwimGamePet } from 'src/pixi/classes/SwimGamePet'

import { Sprite } from '../../classes'
import {
  BARRIER_HEIGHT,
  CONTAINER_SCALE_COEF,
  POOL_START_Y,
  POOL_WIDTH,
  START_LINE_X,
  TRACK_HEIGHT,
  TRACK_WIDTH,
} from '../constants'

export function setupBeforeEngine(this: SwimGame): void {
  console.info('Setuped before engine ', Date.now())

  const windowsTexture = this.getTexture('windows')
  const standsTexture = this.getTexture('stands')
  const floorTexture = this.getTexture('floor')
  const floorDownTexture = this.getTexture('floorDown')
  const trackStartTexture = this.getTexture('trackStart')
  const trackEndTexture = this.getTexture('trackEnd')
  const backEndPoolDownTexture = this.getTexture('backEndPoolDown')
  const backEndPoolUpTexture = this.getTexture('backEndPoolUp')
  const lineDownTexture = this.getTexture('lineDown')
  const lineUpTexture = this.getTexture('lineUp')
  const poolBackTexture = this.getTexture('poolBack')

  this.gameContainerScale =
    this.clientWidth / (TRACK_WIDTH * CONTAINER_SCALE_COEF)
  console.info(this.gameContainerScale)

  const windowsSprite = new TilingSprite(
    windowsTexture,
    this.clientWidth / this.gameContainerScale,
    windowsTexture.orig.height,
    { y: 0 },
  )

  const standsSprite = new TilingSprite(
    standsTexture,
    this.clientWidth / this.gameContainerScale,
    standsTexture.orig.height,
    { y: 91 },
  )

  const poolBackSprite = new TilingSprite(
    poolBackTexture,
    this.clientWidth / this.gameContainerScale,
    poolBackTexture.orig.height,
    { x: 200, y: 2, scale: 1.1, zIndex: 1 },
  )

  const trackStartSprite = new Sprite(trackStartTexture, { y: 0 })
  const trackEndSprite = new Sprite(trackEndTexture, { x: 2000, y: 0 })

  const backEndPoolDownSprite = new TilingSprite(
    backEndPoolDownTexture,
    this.clientWidth / this.gameContainerScale,
    backEndPoolDownTexture.orig.height,
    { x: 300, y: 270, zIndex: 50 },
  )

  const backEndPoolUpSprite = new TilingSprite(
    backEndPoolUpTexture,
    this.clientWidth / this.gameContainerScale,
    backEndPoolUpTexture.orig.height,
    { x: 222, y: 0, scale: 1.1, zIndex: 3 },
  )

  const floorSprite = new TilingSprite(
    floorTexture,
    (2 * this.clientWidth) / this.gameContainerScale,
    floorTexture.orig.height,
    { x: -200, y: 306 },
  )

  const floorDownSprite = new TilingSprite(
    floorDownTexture,
    (2 * this.clientWidth) / this.gameContainerScale,
    floorDownTexture.orig.height,
    { x: -200, y: 680 },
  )
  floorSprite.skew.set(0.165, 0.165)
  floorSprite.rotation = -0.165

  floorDownSprite.skew.set(0.165, 0.165)
  floorDownSprite.rotation = -0.165
  floorDownSprite.scale.set(1.1)

  const lineDownSprite = new TilingSprite(
    lineDownTexture,
    this.clientWidth / this.gameContainerScale,
    lineDownTexture.orig.height,
    { x: 270, y: 160, zIndex: 9 },
  )

  const lineUpSprite = new TilingSprite(
    lineUpTexture,
    this.clientWidth / this.gameContainerScale,
    lineUpTexture.orig.height,
    { x: 250, y: 80, zIndex: 6 },
  )

  const waveTexture = this.getSpineTexture('wave')
  console.info('waveTexture ', waveTexture)

  for (let i = 0; i < 3; i++) {
    const waveSprite = new Spine(waveTexture)
    waveSprite.state.setAnimation(0, 'wave', true)

    waveSprite.x = 600 + i * POOL_WIDTH
    waveSprite.y = 160
    this.waveContainer.addChild(waveSprite, trackStartSprite, trackEndSprite)
    this.waveArray.push(waveSprite)
  }

  this.waveContainer.addChild(trackStartSprite)
  this.waveContainer.zIndex = 2

  this.interractiveContainer.addChild(
    poolBackSprite,
    this.waveContainer,
    backEndPoolDownSprite,
    lineDownSprite,
    lineUpSprite,
    backEndPoolUpSprite,
  )

  this.gameContainer.addChild(
    windowsSprite,
    standsSprite,
    floorSprite,
    this.interractiveContainer,
    floorDownSprite,
  )

  this.gameContainer.pivot.set(0, this.gameContainer.height)
  this.gameContainer.y = this.clientHeight
  this.gameContainer.scale.set(this.gameContainerScale)

  this.setupCountdown()

  this.interfaceContainer.sortableChildren = true
  this.interractiveContainer.sortableChildren = true
  this.interractiveContainer.y = POOL_START_Y
  this.stage.addChild(this.gameContainer, this.interfaceContainer)

  this.backgroundSprites = {
    windows: windowsSprite,
    stands: standsSprite,
    floor: floorSprite,
    floorDown: floorDownSprite,
    trackStart: trackStartSprite,
    trackEnd: trackEndSprite,
    backEndPoolDown: backEndPoolDownSprite,
    backEndPoolUp: backEndPoolUpSprite,
    lineDown: lineDownSprite,
    lineUp: lineUpSprite,
    poolBack: poolBackSprite,
  }
  this.backgroundSpritesWidth = {
    windows: windowsTexture.width,
    stands: standsTexture.width,
    floor: floorTexture.width,
    floorDown: floorDownTexture.width,
    trackStart: trackStartTexture.width,
    trackEnd: trackEndTexture.width,
    backEndPoolDown: backEndPoolDownTexture.width,
    backEndPoolUp: backEndPoolUpTexture.width,
    lineDown: lineDownTexture.width,
    lineUp: lineUpTexture.width,
    poolBack: poolBackTexture.width,
  }
  console.info('Finished setuped before engine ', Date.now())
}

export function createFilter(
  this: SwimGame,
  { x, y, zIndex }: { x: number; y: number; zIndex: number },
): PIXI.Graphics {
  const colorFilter = new ColorOverlayFilter(0x33d2cd, 0.4)
  const filterGraphics = this.createRect()

  const filter = new MaskFilter(
    colorFilter,
    new MaskConfig(false, MASK_CHANNEL.ALPHA),
  )
  filterGraphics.x = x
  filterGraphics.y = y
  filterGraphics.zIndex = zIndex
  filterGraphics.skew.set(0.28, 0)

  filterGraphics.filters = [filter]
  return filterGraphics
}

export function toggleFilter(
  this: SwimGame,
  filter: PIXI.Graphics,
  isEnable: boolean,
): void {
  filter.alpha = isEnable ? 1 : 0
}

export function createRect(this: SwimGame): PIXI.Graphics {
  const maskGraphics = new PIXI.Graphics()
  maskGraphics.beginFill(0x2fe3ea)
  maskGraphics.drawRect(
    0,
    -60,
    this.clientWidth / this.gameContainerScale + 50,
    94,
  )
  maskGraphics.endFill()
  return maskGraphics
}

export function setupFilters(this: SwimGame): void {
  for (let i = 1; i <= 3; i++) {
    const fY = this.getObjectSecondCoord({ row: i })
    const filter = this.createFilter({
      x: 305 - (3 - i) * 26,
      y: fY,
      zIndex: i * 3 + 2,
    })
    this.filtersArray.push(filter)
    this.interractiveContainer.addChild(filter)
  }
}

/**
 * Отрисовка фона
 */
export function setupBackground(this: SwimGame): void {
  console.info('Setuped background ', Date.now())
  this.setupTrack()
  this.barriersSetupCoords()
  this.setupBarriers()
  this.setupPets()
  this.setupFilters()
  console.info('Finished setuped background ', Date.now())
}

/**
 * Установка координат барьеру
 */
export function barriersSetupCoords(this: SwimGame): void {
  this.mapInfo.forEach((bar) => {
    bar.y = this.getObjectSecondCoord({
      row: bar.row,
      objOffset: -BARRIER_HEIGHT,
    })
  })
}

/**
 * Начальная отрисовка барьеров
 */
export function setupBarriers(this: SwimGame): void {
  const barrierTexture = this.getSpineTexture('barrier')
  this.mapInfo.forEach((bar) => {
    const barrier = new Spine(barrierTexture)

    barrier.x = bar.x
    barrier.y = bar.y
    barrier.zIndex = bar.row * 3 + 1
    barrier.pivot.set(0.5)
    barrier.scale.set(0.8)
    barrier.state.setAnimation(0, 'idle', true)

    this.interractiveContainer.addChild(barrier)
  })
}

/**
 * Начальная отрисовка питомцев
 */
export function setupPets(this: SwimGame): void {
  this.setupPet({
    pet: this.anchorPet as SwimGamePet,
    x: START_LINE_X,
  })

  this.pets.forEach((pet) => {
    this.setupPet({ pet: pet as SwimGamePet, x: START_LINE_X })
  })
}

export function setupPet(
  this: SwimGame,
  {
    pet,
    x,
  }: {
    pet: SwimGamePet
    x: number
  },
): void {
  pet.x = x
  const petSpine = pet.petSpine
  const petScale = TRACK_HEIGHT / 2.3 / petSpine.height
  const petY = this.getObjectSecondCoord({
    row: pet.row,
    objOffset: (-petSpine.height * petScale) / 5,
  })
  petSpine.pivot = new PIXI.Point(0, petSpine.height * petScale)

  pet.setY(0, petY)
  petSpine.scale.set(petScale)
  petSpine.verticalReflection()
  pet.setAnimation('idle', true)
  pet.petContainer.zIndex = pet.row * 3 + 2

  this.interractiveContainer.addChild(pet.petContainer)
}

/**
 * Начальная отрисока дороги
 */
export function setupTrack(this: SwimGame): void {
  this.backgroundSprites.trackEnd.x = 800 * this.fullTrack.length
}
