/* eslint-disable no-console */
import * as PIXI from 'pixi.js'
import { getLocationSearch, getVkUserId } from 'utils/getLocationSearch'

import { BarrierModel, GameEndedEvent, StateInfoEvent } from '@gatto/engine'
import { GameType } from '@gatto/shared'

import { EngineClient } from '../../engine/client'
import { MINI_APP } from '../../index'
import { CountdownStatus, PET_ANIMATION } from '../baseOlympicGame/constants'
import {
  cancelRenderLoop,
  finishRun,
  getPetById,
  getPetByUserId,
  getTimerString,
  makeSubscriptions,
  ready,
  renderFunction,
  setupCountdown,
  setupTimer,
  startRun,
  update,
  updateCountdown,
  updateTimer,
} from '../baseOlympicGame/methods'
import {
  OlympicGameOption,
  OlympicGameTextures,
} from '../types/baseOlympicGame'

import { GamePet } from '.'

export abstract class BaseOlympicGame extends PIXI.Application {
  // Глобальные поля
  readonly textures: PIXI.Spritesheet
  readonly spineTextures: OlympicGameTextures
  readonly gameId: string
  readonly gameType: GameType
  readonly mainPetAnimation: PET_ANIMATION
  readonly lobbyUrl: string
  readonly finishGame: (data: GameEndedEvent) => void
  readonly clientWidth: number
  readonly clientHeight: number
  readonly currentUserAuth = getLocationSearch()
  readonly currentUserId = getVkUserId()
  private _engine: EngineClient | null = null
  isSetuped = false

  // Контейнеры и спрайты
  gameContainer = new PIXI.Container()
  stadiumContainer = new PIXI.Container()
  interractiveContainer = new PIXI.Container()
  interfaceContainer = new PIXI.Container()

  // Таймер
  timerSprite: PIXI.Sprite | null = null
  timerTextSprite: PIXI.Text | null = null
  timerCountSprite: PIXI.Text | null = null
  timerContainer = new PIXI.Container()
  timeoutIn = 0

  // Обратный отсчет
  countdownContainer = new PIXI.Container()
  countdownBg: PIXI.Graphics | null = null
  countdownWait: PIXI.Sprite | null = null
  countdownCountGlow: PIXI.Sprite | null = null
  countdownText: PIXI.Text | null = null
  countdownStatus: CountdownStatus = CountdownStatus.Wait
  startGameAt: number | null = null

  // Питомцы
  anchorPet: GamePet // Питомец за которым следит камера
  pets: Map<string, GamePet> // Другие питомцы
  anchorPetRow = 3 // Дорожка anchor пета

  mapInfo: BarrierModel[] = []

  loopRequestId?: number

  constructor(runGameOptions: OlympicGameOption) {
    const {
      lobbyUrl,
      gameId,
      finishGame,
      textures,
      anchorPet,
      pets,
      gameType,
      ...options
    } = runGameOptions
    super(options)

    this.textures = textures['main']
    this.spineTextures = textures
    this.anchorPet = anchorPet
    this.pets = pets

    this.gameId = gameId
    this.lobbyUrl = lobbyUrl
    this.gameType = gameType
    this.mainPetAnimation = gameType === GameType.Race ? 'run' : 'swim'
    this.finishGame = finishGame
    this.clientWidth = this.screen.width
    this.clientHeight = this.screen.height
  }

  get engine(): EngineClient {
    return this._engine as EngineClient
  }

  set engine(engine: EngineClient) {
    this._engine = engine
  }

  // Socket
  ready = ready
  makeSubscriptions = makeSubscriptions

  // Get Methods
  getPetById = getPetById
  getPetByUserId = getPetByUserId
  getTimerString = getTimerString
  abstract getTexture(name: string): PIXI.Texture
  abstract getObjectSecondCoord(obj: unknown): number

  // Interface
  startRun = startRun
  finishRun = finishRun

  // Setup
  abstract setup(data: StateInfoEvent): void
  abstract sync(data: StateInfoEvent): void
  abstract setupBackground(): void
  abstract setupBeforeEngine(): void
  abstract setupTrack(): void
  abstract setupPet(obj: unknown): void
  abstract setupPets(): void
  abstract barriersSetupCoords(): void
  abstract setupBarriers(): void
  setupTimer = setupTimer
  setupCountdown = setupCountdown

  // Move
  abstract moveAnchorPet(): void
  abstract movePet(obj: unknown): void
  abstract moveBackground(obj: unknown): void
  abstract moveTrack(obj: unknown): void

  // Render
  renderFunction = renderFunction
  update = update
  updateTimer = updateTimer
  updateCountdown = updateCountdown
  cancelRenderLoop = cancelRenderLoop
  abstract destroyApp(): void
}
