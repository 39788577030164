import { useEffect } from 'react'
import {
  Location,
  NavigationType,
  useLocation,
  useNavigate,
  useNavigationType,
} from 'react-router-dom'

type CallbackProps = {
  location: Location
}

export function useSystemBackListener(
  callback: (data: CallbackProps) => boolean | void,
): void {
  const navigate = useNavigate()
  const location = useLocation()
  const navigationType = useNavigationType()

  useEffect(() => {
    navigate(location.pathname)

    if (navigationType === NavigationType.Pop) {
      if (callback({ location })) return
    } else {
      navigate(`${location.pathname}${location.search}`, {
        state: location.state,
        replace: navigationType === NavigationType.Replace,
      })
    }
  }, [navigationType])
}
