import React, { ReactNode, useEffect } from 'react'
import bridge from '@vkontakte/vk-bridge'
import { makeRequest } from 'utils/api'

import { useAppSelector } from 'store'

const handleBeforeUnload = (shouldSendAd?: boolean) => {
  makeRequest('user.updateOnline', { shouldSendAd })
}
// TODO: костыль с timeDifferenceInSeconds возможно больше не нужен
export const OnlineProvider = ({
  children,
}: {
  children: ReactNode
}): ReactNode => {
  const { user } = useAppSelector((state) => state.user)
  bridge.subscribe((e) => {
    if (e.detail.type === 'VKWebAppViewHide') {
      handleBeforeUnload()
    }
  })

  useEffect(() => {
    const updateOnline = async () => {
      const isOnline = localStorage.getItem('1')
      const userLastTimeInGame = Number(
        user?.inactiveNotifications?.lastTimeInGame,
      )

      const timeDifferenceInSeconds = (Date.now() - userLastTimeInGame) / 1000
      if (timeDifferenceInSeconds > 5 && !isOnline) {
        handleBeforeUnload(true)
      }

      localStorage.setItem('1', '2')
    }

    updateOnline()
  }, [])

  const handleVisibilityChange = () => {
    handleBeforeUnload(true)
    localStorage.removeItem('1')
  }

  useEffect(() => {
    window.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return <>{children}</>
}
