import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import bridge, {
  BannerAdLayoutType,
  BannerAdLocation,
} from '@vkontakte/vk-bridge'
import { MINI_APP } from 'src'
import { LOCATION_HASH } from 'src/config/constants'
import { POPOUT_ANIMATION_MS } from 'src/const'
import useLoadAllAssets from 'src/hooks/useLoadAllAssets'
import useRoute from 'src/hooks/useRoute'
import { PetClothes } from 'src/pixi/types/spine'
import { makeRequest } from 'utils/api'

import Corgy from 'assets/loading/corgy.png'
import { ProgressBar } from 'components/base'
import { type StatusType } from 'components/base/Popout'
import { useAppDispatch } from 'store/index'
import { setUser, updateUserRegionPetSkins } from 'store/user'

import { Platform } from '@gatto/shared'

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 99;
`

const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 15px;
  > span {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 11.103px;
  }
`
const Image = styled.img`
  width: 67px;
  height: 75px;
  flex-shrink: 0;
`

type LoadPageProps = {
  setStatus?: (status: StatusType) => void
  assets: string[]
  redirect?: string
  state?: unknown
  mode?: 'default' | 'suspense' // suspense для первоначальной загрузки приложения, default для догрузки изображений
  onLoad?: () => void
}
const fetchInitialData = async (
  dispatch: ReturnType<typeof useAppDispatch>,
) => {
  let invitedBy
  if (MINI_APP === Platform.VK) {
    invitedBy = LOCATION_HASH
  }
  if (MINI_APP === Platform.TG) {
    const params = new URLSearchParams(LOCATION_HASH)
    const initData = new URLSearchParams(params.get('tgWebAppData') || '')
    const startParam = initData.get('start_param') || undefined
    invitedBy = startParam
  }
  const response = await makeRequest('user.getSelf', {
    invitedBy: invitedBy,
  })
  if (!response) return

  dispatch(setUser(response.user))

  const skins = await makeRequest('skin.getAllPetsSkins', {})

  skins.forEach((item) => {
    dispatch(
      updateUserRegionPetSkins({
        petId: item.pet,
        skins: item.skin as PetClothes[],
      }),
    )
  })
}

// Фейковая анимация для Suspense
let fakePercentPage = 0
setInterval(() => {
  fakePercentPage += 5
}, 100)

const LoadPage: FC<LoadPageProps> = ({
  setStatus,
  assets,
  redirect,
  state,
  mode = 'default',
  onLoad,
}) => {
  const [assetsState, setAssetsState] = useState<string[]>([])
  const [fakePercent, setFakePercent] = useState<number>(0)
  const { percent, isLoaded } = useLoadAllAssets(assetsState)

  const { t } = useTranslation()

  const navigate = useNavigate()
  const route = useRoute()

  // Фейковая анимация для Suspense
  useEffect(() => {
    const reqId = setInterval(() => {
      setFakePercent(fakePercentPage + percent)
    }, 100)

    return () => {
      clearInterval(reqId)
    }
  }, [percent])

  useEffect(() => {
    if (assets) {
      setAssetsState(assets)
    }
  }, [assets])

  useEffect(() => {
    if (!isLoaded) return
    if (onLoad) onLoad()
    if (!route || mode === 'suspense') return
    setTimeout(() => {
      if (setStatus) setStatus('disappear')
      if (redirect) {
        navigate(redirect, {
          state,
          replace: true,
        })
      } else {
        navigate(route.path)
      }
    }, POPOUT_ANIMATION_MS)
  }, [isLoaded, onLoad])

  // Получение всех исходных данных для игрока
  // Баг: https://trello.com/c/UvLbtLJf
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (mode === 'suspense') fetchInitialData(dispatch)
  }, [dispatch, mode])

  useEffect(() => {
    if (MINI_APP === Platform.VK) {
      bridge
        .send('VKWebAppShowBannerAd', {
          banner_location: BannerAdLocation.BOTTOM,
          layout_type: BannerAdLayoutType.OVERLAY,
        })
        .then((data) => {
          if (data.result) {
            setTimeout(() => {
              bridge.send('VKWebAppHideBannerAd')
            }, 3100)
          }
        })
    }
  }, [])

  return (
    <Main>
      <Head>
        <Image src={Corgy}></Image>
        <span>{t('loading')}</span>
        <ProgressBar
          progressWidth={150}
          progressPercentage={mode === 'suspense' ? fakePercent : percent}
        />
      </Head>
    </Main>
  )
}

export default LoadPage
