import React, { useEffect, useState } from 'react'
import { GlowFilter } from '@pixi/filter-glow'
import { OutlineFilter } from '@pixi/filter-outline'
import { Container, Graphics } from '@pixi/react'
import { Viewport } from 'pixi-viewport'
import { PLATFORM_OUTLINE_POLYGON } from 'src/config/platforms'
import Position from 'src/types/Position'
import Tile from 'src/types/Tile'
import { positionToStringifiedPosition } from 'src/utils/position'

import { BASE_TILE_WIDTH, TILE_UP_HEIGTH } from './constants'
import { SelectedTiles } from './MapPage'
import { TileDimensions } from './TileSprite'
import { getTilePosition } from './utils'

type SelectedTilesOutlineProps = {
  tiles: Tile[][]
  selectedTiles?: SelectedTiles
  viewport: Viewport | null
  tileDimensions: TileDimensions
  deleteBiomRef?: React.MutableRefObject<string | undefined>
}
const SelectedTilesOutline: React.FC<SelectedTilesOutlineProps> = ({
  tiles,
  tileDimensions,
  viewport,
  selectedTiles,
  deleteBiomRef,
}) => {
  const [viewportZoom, setViewportZoom] = useState(1)

  const drawSelectedTilesRects = () => {
    const graphics: JSX.Element[] = []

    const addRectAtPosition = (position: Position) => {
      const tile = tiles[position.y]?.[position.x]

      if (!tile) return

      const { x, y } = getTilePosition(tile, tileDimensions)

      graphics.push(
        <Graphics
          key={positionToStringifiedPosition(position)}
          x={x}
          y={
            deleteBiomRef &&
            tile.id === deleteBiomRef.current &&
            deleteBiomRef.current
              ? y - TILE_UP_HEIGTH
              : y
          }
          scale={tileDimensions.width / BASE_TILE_WIDTH}
          draw={(g) => {
            g.beginFill(0xffffff)
            g.drawPolygon(PLATFORM_OUTLINE_POLYGON)
            g.endFill()
          }}
        />,
      )
    }

    selectedTiles?.forEach((selectedTile) => {
      if (!selectedTile.selectionTypes.has('outline')) return
      addRectAtPosition(selectedTile.position)
    })

    return graphics
  }

  useEffect(() => {
    const onZoom = () => {
      setViewportZoom(viewport?.scale.x || 1)
    }

    viewport?.on('zoomed', onZoom)

    return () => {
      viewport?.off('zoomed', onZoom)
    }
  }, [viewport])

  return (
    <Container
      zIndex={tiles.length ** 2 + 100}
      filters={[
        new OutlineFilter(1.5 * viewportZoom, 0xffffff, 0.1, 1, true),
        new GlowFilter({
          color: 0xffffff,
          distance: 10,
          quality: 0.1,
          alpha: 0.5,
        }),
      ]}
    >
      {drawSelectedTilesRects()}
    </Container>
  )
}

export default SelectedTilesOutline
