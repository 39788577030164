import React from 'react'
import { ReactReduxContext } from 'react-redux'
import { Stage } from '@pixi/react'
import useScreenDimensions from 'src/hooks/useScreenDimensions'

const ReduxContextBridge: React.FC<
  React.PropsWithChildren<{
    render: (children: React.ReactNode) => React.ReactNode
  }>
> = ({ children, render }) => {
  return (
    <ReactReduxContext.Consumer>
      {(value) =>
        render(
          <ReactReduxContext.Provider value={value}>
            {children}
          </ReactReduxContext.Provider>,
        )
      }
    </ReactReduxContext.Consumer>
  )
}

const ResizableStage: React.FC<React.PropsWithChildren> = ({ children }) => {
  const screenDimensions = useScreenDimensions()

  return (
    <ReduxContextBridge
      render={(children) => (
        <Stage
          width={screenDimensions.width}
          height={screenDimensions.height}
          options={{
            backgroundAlpha: 0,
            antialias: true,
            resolution: window.devicePixelRatio || 1,
            autoDensity: true,
          }}
        >
          {children}
        </Stage>
      )}
    >
      {children}
    </ReduxContextBridge>
  )
}

export default ResizableStage
