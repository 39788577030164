import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Placeholder } from 'src/components/base'

import { LOCALES } from '@gatto/locales'

import petPlaceholder from '../../../assets/svg/popouts/pet-placeholder.svg'

const PetPlaceholder: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <Placeholder
      title={t(LOCALES.youDontHavePets)}
      subtitle={t(LOCALES.buyPetInShop)}
      img={petPlaceholder}
      buttonText={t(LOCALES.goShop)}
      onClick={() => {
        navigate('/store')
      }}
      isGrayBorder={true}
    />
  )
}

export default PetPlaceholder
