import React, { ReactNode } from 'react'
import styled from '@emotion/styled'

const Container = styled.h2<{ fontSize?: number; lineHeight?: number }>`
  margin: 0;
  padding: 0;

  font-family: 'Nunito';
  font-weight: 500;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '13px')};
  line-height: ${(props) =>
    props.lineHeight ? `${props.lineHeight}px` : '13px'};

  text-align: center;

  color: rgba(136, 136, 136, 1);
`
type SubheaderProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
> & {
  children: ReactNode
  fontSize?: number
  lineHeight?: number
}

export function Subheader({
  children,
  ...props
}: SubheaderProps): React.ReactElement {
  return <Container {...props}>{children}</Container>
}
