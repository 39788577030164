import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { GenIcon, TileIcon, TimeIcon } from 'src/config/assets'
import { Popouts } from 'src/const/router'
import { getTimeFormatted } from 'utils/getTimeFormatted'
import { fixNames } from 'utils/i18/fixItemTypes'

import { Popout, StatusType } from 'components/base/Popout'
import { setIsTabbarHidden } from 'store/storage'

import { LOCALES } from '@gatto/locales'
import { BaseEgg } from '@gatto/shared'

import { makeRequest } from '../../utils/api'

import StorageContent from './StorageContent'

export type EggPopoutProps = Popouts['egg']

const EggPopout: FC<EggPopoutProps> = ({
  id,
  localId,
  type,
  evolution,
  isInStorage,
}) => {
  const { t } = useTranslation()
  const [status, setStatus] = useState<StatusType>()
  const [item, setItem] = useState<BaseEgg>()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [isBack, setIsBack] = useState(false)

  const items = [
    {
      icon: TileIcon,
      title: t(LOCALES.biome),
      value: t(`biomeType_${item?.allowedRegion}`),
    },
    { icon: GenIcon, title: t(LOCALES.generation), value: evolution || '1' },
    {
      icon: TimeIcon,
      title: t(LOCALES.hatchingTime),
      value: item ? getTimeFormatted(t, item.hatchingTime) : '0',
    },
  ]

  const getWareItem = async (): Promise<void> => {
    try {
      const response = await makeRequest('warehouseGoods.getGoodById', {
        type: type,
        goodId: id,
      })

      const egg = fixNames(t, response) as BaseEgg

      setItem(egg)
    } catch (error) {
      throw new Error()
    }
  }

  const placeOnMap = () => {
    const egg_id = item?._id
    if (!egg_id) return

    dispatch(setIsTabbarHidden(true))
    navigate(
      `/?${createSearchParams({
        placing: 'egg',
        egg_id: localId,
        allowed_region: item?.allowedRegion,
      })}`,
      { replace: true },
    )
  }

  useEffect(() => {
    getWareItem()
  }, [])

  useEffect(() => {
    const handlePopstate = () => {
      navigate('/storage', { replace: true })
      setIsBack(true)
    }

    window.addEventListener('popstate', handlePopstate)

    return () => {
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [])

  return (
    <Popout
      type="default"
      background={true}
      width="315px"
      isFullScreen={false}
      status={status}
      setStatus={setStatus}
      navigatePath={isBack ? '/storage' : undefined}
    >
      {item && (
        <StorageContent
          header={item?.name}
          subheader={item.description}
          photoUrl={item.photoUrl}
          items={items}
          buttonText={isInStorage ? t(LOCALES.settle) : ''}
          handleClick={placeOnMap}
        />
      )}
    </Popout>
  )
}

export default EggPopout
