import { ReactNode } from 'react'
import styled from '@emotion/styled'

import { CustomCard } from 'components/base'

import EggAndPetPlaceholder from './EggAndPetPlaceholder'

export type CardItem = {
  buttonText?: string
  img?: string
  isGrayBorder?: boolean
  subtitle?: React.ReactNode
  title?: string
  buttonIcon?: React.ReactNode
  onClick?: () => void
  id?: string
}

export type PetCardItem = {
  buttonText?: string
  img?: ReactNode
  isGrayBorder?: boolean
  subtitle?: React.ReactNode
  title?: string
  onClick?: () => void
  id?: string
}

type EggCardListProps = {
  cards: CardItem[] | PetCardItem[]
  activeTab: string
}

export const Scroller = styled.div`
  display: flex;
  overflow-x: scroll;
`

const EggCardList: React.FC<EggCardListProps> = ({ cards, activeTab }) => {
  return (
    <>
      {cards.length === 0 ? (
        <EggAndPetPlaceholder activeTab={activeTab} />
      ) : (
        <Scroller id="cardScroll">
          {cards.map(
            (
              {
                id,
                buttonText,
                img,
                isGrayBorder,
                subtitle,
                title,
                onClick,
                ...props
              },
              index,
            ) => {
              return (
                <CustomCard
                  id={id}
                  key={index}
                  onClick={onClick}
                  buttonText={buttonText}
                  img={img}
                  isGrayBorder={isGrayBorder}
                  subtitle={subtitle}
                  title={title}
                  {...props}
                />
              )
            },
          )}
        </Scroller>
      )}
    </>
  )
}

export default EggCardList
