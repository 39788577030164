import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Popouts } from 'const/router'
import StatusAlert from 'src/popouts/Status/StatusAlertContent'
import { makeRequest } from 'utils/api'

import { Button, Popout, StatusType } from 'components/base'
import { useAppDispatch } from 'store'
import { decrementBalance, setWallet } from 'store/user'

import { LOCALES } from '@gatto/locales'

import { usePopout } from '../../hooks'
import { useWalletCheckWithUpdate } from '../../hooks/useWalletCheck'

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`

type TonAcceptPopoutProps = Popouts['tonAccept']

const TonAcceptPopout: FC<TonAcceptPopoutProps> = ({ amount }) => {
  const { t } = useTranslation()
  const [status, setStatus] = useState<StatusType>()
  const [data, updateData] = useWalletCheckWithUpdate(false)

  const successPopout = usePopout('statusAlert', true, {
    type: 'success',
    subheader: t(LOCALES.exchange_success),
  })
  const errorPopout = usePopout('statusAlert', true, {
    type: 'error',
    header: t(LOCALES.exchange_error),
  })

  const dispatch = useAppDispatch()

  // useEffect(() => {
  //   const handlePopstate = () => {
  //     navigate('storage', { replace: false })
  //   }
  //
  //   window.addEventListener('popstate', handlePopstate)
  //
  //   return () => {
  //     window.removeEventListener('popstate', handlePopstate)
  //   }
  // }, [])

  const handleExchangeTon = async () => {
    await updateData()
    if (!data[0]) {
      errorPopout()
      return
    }
    makeRequest('ton.exchange', {
      amount,
      walletId: data[0]._id,
    })
      .then(() => {
        dispatch(decrementBalance({ hard: +amount }))
        successPopout()
      })
      .catch(() => {
        errorPopout()
      })
      .finally(() => {
        dispatch(setWallet(''))
      })
  }

  const navigate = useNavigate()

  return (
    <Popout
      type="default"
      background={true}
      width="330px"
      isFullScreen={false}
      status={status}
      setStatus={setStatus}
    >
      <StatusAlert
        header={`${t(LOCALES.withdraw_ton)} ${amount} TON`}
        subheader={t(LOCALES.lobbyKickUserSubheader)}
        type="error"
      >
        <ButtonContainer>
          <Button
            variant={'dangerous'}
            width="155px"
            style={{ margin: '5px' }}
            onClick={() => {
              navigate(-1)
            }}
          >
            {t(LOCALES.no)}
          </Button>
          <Button
            variant={'success'}
            style={{ margin: '5px' }}
            width="155px"
            onClick={handleExchangeTon}
          >
            {t(LOCALES.yes)}
          </Button>
        </ButtonContainer>
      </StatusAlert>
    </Popout>
  )
}

export default TonAcceptPopout
