import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { useModalRootContext } from '@vkontakte/vkui'
import { Text } from 'modals/TransformPetToNFT/TransfromPetToNFTModal'
import { allowedCryptoWallets } from 'src/config/constants'
import { ModalsProps } from 'src/const/router'
import { useModal } from 'src/hooks'
import { makeRequest } from 'utils/api'

import Modal from 'components/modals/Modal'
import { useAppDispatch, useAppSelector } from 'store'
import { setWallet } from 'store/user'

import { LOCALES } from '@gatto/locales'
import { PopulatedPet } from '@gatto/shared'

import { useWalletCheck } from '../../hooks/useWalletCheck'

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
`

const WalletItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  padding: 5px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.02);
  cursor: pointer;
`
const WalletItemText = styled.div`
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 11.103px; /* 79.305% */
  text-transform: capitalize;
`
const WalletItemImage = styled.div<{ url: string }>`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`

const WalletMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
`

const WalletItem = ({
  petId,
  name,
  icon,
  wallets,
  type,
}: {
  petId?: string
  name: string
  icon: string
  wallets: Wallet[]
  type: string
}) => {
  const wallet = wallets.find((wallet) => wallet.walletType === name)
  const [pet, setPet] = useState<PopulatedPet>()

  const { updateModalHeight } = useModalRootContext()

  useEffect(() => {
    updateModalHeight()
  }, [updateModalHeight])

  useEffect(() => {
    const getPet = async () => {
      const res = await makeRequest('pet.getById', { id: petId })
      setPet(res)
    }

    if (type !== 'exchange') {
      getPet()
    }
  }, [petId])

  const openModaltransformPetToNFT = useModal('transformPetToNFT', true, {
    walletId: wallet?._id,
    petId: petId,
    pet: pet,
  })
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  if (type === 'exchange') {
    dispatch(setWallet(wallet?._id))
    return (
      <WalletItemContainer onClick={() => navigate(-1)}>
        <WalletItemImage url={icon}></WalletItemImage>
        <WalletItemText>{name}</WalletItemText>
      </WalletItemContainer>
    )
  } else {
    dispatch(setWallet(''))
    return (
      <WalletItemContainer onClick={openModaltransformPetToNFT}>
        <WalletItemImage url={icon}></WalletItemImage>
        <WalletItemText>{name}</WalletItemText>
      </WalletItemContainer>
    )
  }
}
export interface Wallet {
  _id: string
  userId: number
  walletType: string
  walletAddress: string
}
const Wallets = ({
  allowedCryptoWallets,
  wallets,
  petId,
  type,
}: {
  petId?: string
  allowedCryptoWallets: Array<{ name: string; icon: string }>
  wallets: Wallet[]
  type: string
}) => {
  return (
    <WalletMain>
      {allowedCryptoWallets.map((item, index) => (
        <WalletItem
          key={index}
          petId={petId}
          name={item.name}
          icon={item.icon}
          wallets={wallets}
          type={type}
        />
      ))}
    </WalletMain>
  )
}
// ОБЯЗАТЕЛЬНО! Пропс setBackground передается для всех модалок. Далее ее нужно передавать компоненту Modal
// Также при вы можете получить те пропсы которые передаются при вызове useModal(props) и ее функции openModal(props)
// Т.е работает как в попаутах

type WalletChooseModalProps = ModalsProps['walletChoose']

const WalletChooseModal: FC<WalletChooseModalProps> = ({
  setBackground,
  type,
  text,
  petId,
}) => {
  const { t } = useTranslation()
  const { updateModalHeight } = useModalRootContext()

  useEffect(() => {
    updateModalHeight()
  }, [updateModalHeight])

  console.info('WALLET_CHOOSE')
  const data = useWalletCheck()
  return (
    <Modal
      type="minting"
      header={t(LOCALES.chooseWallet)}
      headerPosition="center"
      setBackground={setBackground}
      background={true}
    >
      <Main>
        <Text>
          <span>{text}</span>
        </Text>
        {type === 'minting' && petId && (
          <Wallets
            petId={petId}
            allowedCryptoWallets={allowedCryptoWallets}
            wallets={data}
            type={type}
          />
        )}
        {type === 'exchange' && !petId && (
          <Wallets
            allowedCryptoWallets={allowedCryptoWallets}
            wallets={data}
            type={type}
          />
        )}
      </Main>
    </Modal>
  )
}
export default WalletChooseModal
