import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { usePopout } from 'src/hooks'
import { makeRequest } from 'utils/api'
import {
  disconnectTonWallet,
  openTonConnectModal,
} from 'utils/ton/connectWallet'

import { Button } from 'components/base'

import { LOCALES } from '@gatto/locales'
import { UserWallet } from '@gatto/shared'

import CryptoItem from './CryptoItem'

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const WalletHeadContainer = styled.div`
  display: flex;

  align-items: center;
  gap: 7px;
  align-self: stretch;

  > span {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 11.103px;
    flex: 1 0 0;
  }
`

const NoWalletMain = styled.div`
  display: flex;
  height: 40px;
  padding: 0px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 13px;
  background: rgba(0, 0, 0, 0.02);

  > span {
    color: rgba(136, 136, 136, 1);
    leading-trim: both;
    text-edge: cap;
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 11.103px; /* 92.523% */
    white-space: nowrap;
  }
`

type WalletRootProps = {
  closeModal: () => void
}

const WalletRoot: FC<WalletRootProps> = ({ closeModal }) => {
  const { t } = useTranslation()
  const [status, setStatus] = useState<boolean>(true)
  const [data, setData] = useState<UserWallet[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const openPopoutWalletSuccess = usePopout('walletExist', true)

  const findWallets = async () => {
    const response = await makeRequest('nft.getUserWallets', {})
    if (response.wallets.length !== 0) {
      setData(response.wallets)
    } else {
      setStatus(false)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    findWallets()
  }, [status])

  const handleDeleteCrypto = async () => {
    setStatus(false)
    if (data[0]?._id) {
      await makeRequest('nft.deleteWallet', {
        walletId: data[0]?._id,
      })
      disconnectTonWallet()
    }
  }

  return (
    <Main>
      <WalletHeadContainer>
        <span>{t(LOCALES.wallets)}</span>
        {isLoading ? (
          <div>{t(LOCALES.loading)}</div>
        ) : (
          !status && (
            <Button
              variant="success"
              width={'200px'}
              style={{ height: 15, fontSize: '12px' }}
              onClick={async () => {
                await openTonConnectModal(openPopoutWalletSuccess)
                // closeModal()
              }}
            >
              {t(LOCALES.addWallet)}
            </Button>
          )
        )}
      </WalletHeadContainer>
      {!status && (
        <NoWalletMain>
          <span>{t(LOCALES.dontHaveAnyLinkedWallets)}</span>
        </NoWalletMain>
      )}
      {!isLoading && status && (
        <CryptoItem
          name={data[0]?.walletType || 'telegram-wallet'}
          onDelete={handleDeleteCrypto}
        />
      )}
    </Main>
  )
}

export default WalletRoot
