import { TFunction } from 'i18next'
import { MINI_APP } from 'src'
import { APP_LANG } from 'src/config/constants'

import { LOCALES } from '@gatto/locales'
import {
  BaseDonate,
  BaseEgg,
  BaseEssence,
  BaseSkin,
  DonateItemType,
  EggItemType,
  EssenceItemType,
  ItemProps,
  Platform,
  SkinItemType,
} from '@gatto/shared'

import { fixEndingByNumber } from './fixRussianWords'

type PopulatedEssence = {
  _id: string
  owner: number
  essence: {
    _id: string
    name: string
    photoUrl: string
    description: string
    itemType: string
  }
}

type AllTypes = BaseEgg | BaseEssence | BaseDonate | ItemProps | BaseSkin

export const fixNames = (t: TFunction, good: AllTypes): AllTypes => {
  if (good.itemType === EggItemType.Egg) {
    good.name = t(`egg_${good.allowedRegion}_${good.rarity}`)
    good.description = t(`egg_${good.allowedRegion}_desc`)
  }
  if (good.itemType === DonateItemType.Coin) {
    const price = good.name?.split(' ').slice(0, -1)
    good.name =
      price?.join(' ') +
      ' ' +
      (APP_LANG === 'ru'
        ? fixEndingByNumber(t(LOCALES.coinsOnce), Number(price?.join('')))
        : t(LOCALES.coins))
    good.description = t(`${good.description}_desc`)
  }
  if (good.itemType === DonateItemType.Brilliant) {
    const price = good.name?.split(' ').slice(0, -1)
    const vkORtg = MINI_APP === Platform.VK ? LOCALES.votes : LOCALES.ton
    good.name =
      price?.join(' ') +
      ' ' +
      (APP_LANG === 'ru'
        ? fixEndingByNumber(t(vkORtg), Number(price?.join('')))
        : t(vkORtg))
    good.description = t(`${good.description}_desc`)
  }
  if (good.itemType === EssenceItemType.Essence) {
    good.name = t(`essence_${good.type}`)
    good.description = t(`essence_${good.type}_desc`)
  }
  if (good.itemType === SkinItemType.Skin) {
    good.name = t(`skin_${good.itemName}`)
    good.description = t(`skin_${good.itemName}_desc`)
  }
  return good as AllTypes
}

/*
if (good.skin?.itemType === SkinItemType.Skin) {
      good.skin.name = t(`egg_${good.allowedRegion}_${good.rarity}`)
      good.skin.description = t(`egg_${good.allowedRegion}_desc`)
    }
*/
