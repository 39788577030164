import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '.'

export interface SelectPetState {
  selectedPet: string | undefined
  tempSelectedPet: string | undefined
}

export const initialState: SelectPetState = {
  selectedPet: undefined,
  tempSelectedPet: undefined,
}

export const selectPetSlice = createSlice({
  name: 'selectPet',
  initialState,
  reducers: {
    selectPet: (state) => {
      state.selectedPet = state.tempSelectedPet
    },
    setSelectedPet: (state, action: PayloadAction<string | undefined>) => {
      state.selectedPet = action.payload
      state.tempSelectedPet = action.payload
    },
    setTempSelectedPet: (state, action: PayloadAction<string | undefined>) => {
      state.tempSelectedPet = action.payload
    },
  },
})

export const { selectPet, setSelectedPet, setTempSelectedPet } =
  selectPetSlice.actions

export default selectPetSlice.reducer

export const getSelectedPet = (
  state: RootState,
): SelectPetState['selectedPet'] => state.selectPet.selectedPet

export const getTempSelectedPet = (
  state: RootState,
): SelectPetState['tempSelectedPet'] => state.selectPet.tempSelectedPet
