export const GreyPentagonSVG = (): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="273"
      height="263"
      viewBox="0 0 273 263"
      fill="none"
    >
      <path
        d="M87.4512 16.1982C116.698 -4.68716 156.302 -4.68714 185.549 16.1982L237.913 53.5912C267.16 74.4765 279.398 111.497 268.227 145.29L248.225 205.793C237.054 239.586 205.014 262.466 168.863 262.466H104.137C67.986 262.466 35.9461 239.586 24.7747 205.793L4.77346 145.29C-6.39792 111.497 5.84025 74.4765 35.0873 53.5912L87.4512 16.1982Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M89.7757 19.4534C117.632 -0.438898 155.368 -0.438885 183.224 19.4534L235.588 56.8464C263.428 76.7271 275.043 111.928 264.429 144.034L244.427 204.537C233.809 236.657 203.326 258.466 168.863 258.466H104.137C69.6741 258.466 39.1908 236.657 28.5725 204.537L8.57132 144.034C-2.04258 111.928 9.57172 76.7271 37.4119 56.8464L89.7757 19.4534Z"
        stroke="url(#paint0_linear_3956_9262)"
        strokeOpacity="0.2"
        strokeWidth="8"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3956_9262"
          x1="136.5"
          y1="-73.8359"
          x2="136.5"
          y2="333.157"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  )
}
