import { TFunction } from 'i18next'
import { ONE_HOUR, ONE_MINUTE } from 'src/config/constants'

import { LOCALES } from '@gatto/locales'

export const getTimeFormatted = (t: TFunction, time: number): string => {
  const hours = Math.floor(time / ONE_HOUR)
  time -= hours * ONE_HOUR
  const minutes = Math.floor(time / ONE_MINUTE)
  time -= minutes * ONE_MINUTE
  const seconds = Math.floor(time / 1000)

  const digitsToSkip = hours > 100 ? 3 : 2

  const timeUnits = [
    ...(hours
      ? [`${('0' + hours).slice(-digitsToSkip)}${t(LOCALES.timerHours)}.`]
      : []),
    ...(minutes || !hours
      ? [`${('0' + minutes).slice(-2)}${t(LOCALES.timerMinutes)}.`]
      : []),
    `${('0' + seconds).slice(-2)}${t(LOCALES.timerSeconds)}.`,
  ]

  return timeUnits.slice(0, 2).join(' ')
}
