import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Popouts } from 'src/const/router'

import { CoinsButton } from 'components/base'
import { Header, Popout, StatusType, Subheader } from 'components/base/Popout'

import { LOCALES } from '@gatto/locales'
import { PromocodePrizeType } from '@gatto/shared'

import PromoGiftIcon from '/src/assets/svg/popouts/promo-gift.svg'

const Image = styled.img`
  width: 47.823px;
  height: 47.823px;
  align-self: center;
  flex-shrink: 0;
`

export type PromoSuccessCoinsProps = Popouts['promoSuccessValue']

const PromoSuccessCoinsPopout: FC<PromoSuccessCoinsProps> = ({
  type,
  value,
}) => {
  const { t } = useTranslation()
  const [status, setStatus] = useState<StatusType>()

  let subheaderText = ''

  if (type === 'essence' || type === 'egg') {
    subheaderText = LOCALES.addedToWarehouse
  } else if (type === 'coin' || type === 'paw') {
    subheaderText = LOCALES.receivedBalance
  }

  return (
    <Popout
      type="default"
      width="284px"
      background={true}
      isFullScreen={false}
      setStatus={setStatus}
      status={status}
    >
      <Image src={PromoGiftIcon} />
      <Header>{t(LOCALES.promoActivated)}</Header>
      <Subheader>{t(subheaderText)}</Subheader>
      <CoinsButton type={type as PromocodePrizeType}>{value}</CoinsButton>
    </Popout>
  )
}

export default PromoSuccessCoinsPopout
