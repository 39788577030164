import { ISpritesheetFrameData } from 'pixi.js'

export default function texturePackerToCanvas(
  canvas: HTMLCanvasElement,
  image: HTMLImageElement,
  frame: ISpritesheetFrameData['frame'],
  rotated?: boolean,
): void {
  const context = canvas.getContext('2d') as CanvasRenderingContext2D
  context.clearRect(0, 0, canvas.width, canvas.height)

  const ratio = canvas.height / image.width
  const centerX = (canvas.width - image.width * ratio) / 2
  const centerY = (canvas.height - image.height * ratio) / 2

  if (rotated) {
    context.save()
    context.translate(
      centerX + (image.width * ratio) / 2,
      centerY + (image.height * ratio) / 2,
    )
    context.rotate(-Math.PI / 2)
    context.drawImage(
      image,
      frame.x,
      frame.y,
      frame.h,
      frame.w,
      (-frame.h * ratio) / 2,
      (-frame.w * ratio) / 2,
      frame.h * ratio,
      frame.w * ratio,
    )
    context.restore()
  } else {
    context?.drawImage(
      image,
      frame.x,
      frame.y,
      frame.w,
      frame.h,
      centerX,
      centerY,
      frame.w * ratio,
      frame.h * ratio,
    )
  }
}
