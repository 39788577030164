import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import bridge from '@vkontakte/vk-bridge'
import { useCloudStorage } from '@vkruglikov/react-telegram-web-app'
import { MINI_APP } from 'src'
import { OnboardingStore } from 'src/config/onboardings'
import { makeRequest } from 'utils/api'
import { fixNames } from 'utils/i18/fixItemTypes'
import { getVKStorage, setVKStorage } from 'utils/vkStorage'

import { BackButton, Banner, Categories, Row, Wallet } from 'components/base'
import { PageContainer, PageHeader, StoreMenu } from 'components/page'
import Scrollable from 'components/Scrollable'
import { HeaderText } from 'components/typography'
import { useAppDispatch } from 'store'
import { setOnboarding } from 'store/onboarding'

import { LOCALES } from '@gatto/locales'
import { GoodsGetRes, Platform } from '@gatto/shared'

import useOnBoarding from '../../hooks/router/useOnBoarding'

import '../../style/fonts.css'

interface Category {
  category: string
  products: number
}

export enum CategoriesType {
  Egg = 'eggs',
  Donates = 'donates',
  Brilliants = 'brilliants',
  Essences = 'essences',
  All = 'all',
  Pet = 'pets',
  Skins = 'skins',
}

export const StorePage: FC = () => {
  const { t } = useTranslation()
  const [sortBy, setSortBy] = useState<CategoriesType | undefined>(
    CategoriesType.Egg,
  )
  const [goods, setGoods] = useState<GoodsGetRes>([])
  const [category, setCategory] = useState<Category[]>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const sortByQuery = searchParams.get('sortBy')
  const { setItem, getItems } = useCloudStorage()

  useMemo(() => {
    if (sortByQuery) {
      setSortBy(sortByQuery as CategoriesType)
    }
  }, [])

  const categories = [
    // { name: 'Все', category: 'all' },
    { name: t(LOCALES.itemTypeEggs), category: 'eggs' },
    { name: t(LOCALES.currency), category: 'donates' },
    { name: t(LOCALES.itemTypeEssences), category: 'essences' },
    { name: t(LOCALES.itemTypeSkins), category: 'skins' },
  ]

  const dispatch = useAppDispatch()

  const onBoarding = useOnBoarding()

  useEffect(() => {
    const onboarding = async () => {
      let cross, store
      if (MINI_APP === Platform.VK) {
        const { onboarding_store26, onboarding_cross } = await getVKStorage(
          'onboarding_store26',
          'onboarding_cross',
        )

        store = onboarding_store26
        cross = onboarding_cross
      }

      if (MINI_APP === Platform.TG) {
        cross = await getItems(['onboarding_cross'])
        store = await getItems(['onboarding_store26'])
        cross = Object.values(cross)[0]
        store = Object.values(store)[0]
      }
      dispatch(setOnboarding(['cross', cross === 'true']))
      if (store === 'true') return
      await onBoarding('store')

      if (MINI_APP === Platform.VK) {
        await setVKStorage('onboarding_store26', 'true')
      }
      if (MINI_APP === Platform.TG) {
        await setItem('onboarding_store26', 'true')
      }
      dispatch(setOnboarding(['welcome', true]))
    }
    onboarding()
  }, [dispatch])

  const limit = 7

  const [offset, setOffset] = useState<number>(0)

  const getCategories = async (): Promise<void> => {
    try {
      const response = await makeRequest('goods.getCategories', {
        type: '',
      })
      setCategory(response)
    } catch (e) {
      throw new Error()
    }
  }

  const [isShouldWork, setIsShouldWork] = useState<boolean>(true)

  const prevSortBy = useRef<CategoriesType | undefined>()

  const getGoods = async (): Promise<void> => {
    try {
      makeRequest('goods.getByType', {
        type: [sortBy || CategoriesType.Skins],
        limit: limit,
        offset: offset,
      }).then((response) => {
        if (response.length < limit) {
          setIsShouldWork(false)
        }
        response = response.map((item) => fixNames(t, item)) as GoodsGetRes
        if (prevSortBy.current === sortBy) {
          setGoods((prev) => [...prev, ...response] as GoodsGetRes)
        } else {
          setGoods(response)
        }
        setOffset((prevOffset) => prevOffset + response.length)
        prevSortBy.current = sortBy
      })
    } catch (e) {
      setIsShouldWork(false)
      prevSortBy.current = sortBy
      throw new Error()
    }
  }

  useEffect(() => {
    getCategories()
  }, [])
  useEffect(() => {
    getGoods()
  }, [sortBy])

  const filteredCategories = categories.filter((item) => {
    const categoryData = category.find(
      (data) => data.category === item.category,
    )

    return (
      (categoryData && categoryData.products > 0) || item.category === 'all'
    )
  })

  //     position: fixed;
  //     bottom: 77px;

  return (
    <PageContainer>
      <PageHeader over={<Wallet mode="horizontal" />} before={<BackButton />}>
        <HeaderText>{t(LOCALES.shop)}</HeaderText>
      </PageHeader>
      <Scrollable>
        <Row>
          <Banner />
        </Row>
        <Row>
          <Categories
            categories={filteredCategories}
            setSortBy={setSortBy}
            sortBy={sortBy}
            setOffset={setOffset}
            setIsShouldWork={setIsShouldWork}
            setShopGoods={setGoods}
          />
        </Row>
        {sortBy !== undefined && (
          <StoreMenu
            items={goods}
            sortBy={sortBy}
            getShopGoods={getGoods}
            isShouldWork={isShouldWork}
            limit={limit}
          />
        )}
      </Scrollable>
    </PageContainer>
  )
}
