import React, { useState } from 'react'
import styled from '@emotion/styled'

interface CheckBoxProps {
  children?: React.ReactNode
  onClick?: () => void
  disabled?: boolean
  className?: string
  label: string
  value?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

const CheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;

  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;

  cursor: pointer;
`

const CheckBoxInput = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const CheckBoxBlock = styled.div<{ isSelected: boolean }>`
  border-radius: 10px;
  background: rgba(234, 234, 234, 0.69);
  box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.07) inset;

  display: flex;
  width: 35px;
  height: 35px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7px;

  ${({ isSelected }) => {
    return (
      isSelected &&
      `background: linear-gradient(0deg, rgba(36, 156, 77, 0.69) 0%, rgba(36, 156, 77, 0.69) 100%), linear-gradient(180deg, #32ff6b 0%, #32ff5f 100%), linear-gradient(180deg, rgba(68, 104, 255, 0.2) 0%, rgba(59, 91, 224, 0.2) 100%), linear-gradient(180deg, #448fff 0%, #3b73e0 100%);`
    )
  }}
`

const CheckBoxSVG = styled.svg`
  width: 100%;
`

/**
 * Компонент чекбокса
 * @component
 */
function CheckBox({
  disabled = false,
  className,
  value = false,
  onChange,
  label,
}: CheckBoxProps): React.ReactElement {
  const [isSelected, setIsSelected] = useState(value)

  return (
    <CheckBoxLabel>
      <CheckBoxInput
        type="checkbox"
        disabled={disabled}
        onChange={(e) => {
          onChange?.(e)
          setIsSelected((prev) => !prev)
        }}
      />
      <CheckBoxBlock isSelected={isSelected}>
        <CheckBoxSVG
          className={className}
          width="14"
          height="11"
          viewBox="0 0 14 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.3457 4.57999L5.26571 8.5L12.2657 1.5"
            stroke={isSelected ? '#fff' : 'none'}
            strokeWidth="2.1401"
            strokeLinecap="round"
          />
        </CheckBoxSVG>
      </CheckBoxBlock>
      {label}
    </CheckBoxLabel>
  )
}

export default CheckBox
