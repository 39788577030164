import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Popouts } from 'const/router'
import StatusAlertContent from 'popouts/Status/StatusAlertContent'

import { Button, Popout, StatusType } from 'components/base'

import { LOCALES } from '@gatto/locales'

const ControlButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
`

type LeaveProps = Popouts['lobbyLeave']

const LobbyLeave: FC<LeaveProps> = ({ width = '330px', lobbyId }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [status, setStatus] = useState<StatusType>()

  const leave = () => {
    if (lobbyId)
      navigate(`/lobby/${lobbyId}`, {
        state: { leave: true },
        replace: true,
      })
  }

  return (
    <Popout
      type="default"
      background={true}
      width={width}
      isFullScreen={false}
      status={status}
      setStatus={setStatus}
      isClosable={false}
    >
      <StatusAlertContent type="info" header={t(LOCALES.lobbyLeaveConfirm)}>
        <ControlButtons>
          <Button
            width="100%"
            variant="dangerous"
            onClick={() => {
              navigate(`/lobby/${lobbyId}`, {
                state: { leave: false },
                replace: true,
              })
            }}
          >
            {t(LOCALES.no)}
          </Button>
          <Button width="100%" variant="success" onClick={() => leave()}>
            {t(LOCALES.yes)}
          </Button>
        </ControlButtons>
      </StatusAlertContent>
    </Popout>
  )
}

export default LobbyLeave
