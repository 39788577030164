import React, { FC, useCallback } from 'react'
import { _ReactPixi, Graphics } from '@pixi/react'
import * as PIXI from 'pixi.js'
import { Graphics as PixiGraphics } from 'pixi.js'
import useScreenDimensions from 'src/hooks/useScreenDimensions'
import { getEntityDimensions } from 'utils/getEntityDimensions'

type EntityShadowProps = _ReactPixi.IGraphics

/**
 * Компонент тени для сущности
 * @component
 */
export const EntityShadow: FC<EntityShadowProps> = (props) => {
  const screenDimensions = useScreenDimensions()
  const entityDimensions = getEntityDimensions(screenDimensions)

  const {
    width = entityDimensions.width,
    height = entityDimensions.height,
    ...rest
  } = props

  const drawEntityShadow = useCallback(
    (g: PixiGraphics) => {
      g.clear()
      g.beginFill(0x000000, 0.5)
      g.drawEllipse(0, -10, width / 2, height / 5)
      g.endFill()
    },
    [width, height],
  )

  return (
    <Graphics
      draw={drawEntityShadow}
      anchor={[0.5, 1]}
      filters={[new PIXI.BlurFilter(64, 20, window.devicePixelRatio)]}
      {...rest}
    />
  )
}
