import { CLOTHES_BONES, PET_BODY_PARTS } from '../constants/spine'
import { AnimatedPetProps } from '../types/spine'

import { AnimatedSpineSprite } from './AnimatedSpineSprite'

export class Pet extends AnimatedSpineSprite {
  constructor({
    texture,
    clothesSpriteSheet,
    currentClothes,
    currentSkin,
  }: AnimatedPetProps) {
    super({
      texture,
      clothesSpriteSheet,
      currentClothes,
      currentSkin,
      clothesBones: CLOTHES_BONES,
      bodyParts: PET_BODY_PARTS,
    })
  }
}
