import styled from '@emotion/styled'

export const Item = styled.div`
  width: 169px;
  display: inline-flex;
  padding: 10px 10px 15px 10px;
  justify-content: center;
  flex-shrink: 0;
  flex-wrap: wrap;
  gap: 11px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px -4px 0px 0px rgba(255, 255, 255, 0.15) inset;
  transition: 0.2s ease-in-out;
  cursor: pointer;

  @media screen and (max-width: 375px) {
    width: 160px;
    height: 220px;
  }
`
