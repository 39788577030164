import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Popouts } from 'const/router'
import StatusAlertContent from 'popouts/Status/StatusAlertContent'

import { Button, Popout, StatusType } from 'components/base'
import { Avatar } from 'pages/LobbyPage/Avatar'

import { LOCALES } from '@gatto/locales'

const ControlButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
`

type LobbyKickProps = Popouts['lobbyKick']

const LobbyKick: FC<LobbyKickProps> = ({ width = '353px', user, lobbyId }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [status, setStatus] = useState<StatusType>()

  const kickUser = () => {
    if (lobbyId && user)
      navigate(`/lobby/${lobbyId}`, {
        state: { kickUser: user.id },
        replace: true,
      })
  }

  return (
    <Popout
      type="default"
      background={true}
      width={width}
      isFullScreen={false}
      status={status}
      setStatus={setStatus}
      isClosable={false}
    >
      {user ? (
        <StatusAlertContent
          type="custom"
          header={`${t(LOCALES.lobbyKickUserConfirm)} ${user.about.firstName} ${
            user.about.lastName
          }?`}
          subheader={t(LOCALES.lobbyKickUserSubheader)}
          headerFontSize={18}
          headerLineHeight={20}
          subHeaderFontSize={14}
          subHeaderLineHeight={14}
          icon={
            <Avatar
              pet={user.pet}
              photoUrl={user.about.photoUrl}
              size={50}
              scale={0.18}
            />
          }
        >
          <ControlButtons>
            <Button
              width="100%"
              variant="dangerous"
              onClick={() => {
                navigate(`/lobby/${lobbyId}`, {
                  state: { leave: false },
                  replace: true,
                })
              }}
            >
              {t(LOCALES.no)}
            </Button>
            <Button width="100%" variant="success" onClick={() => kickUser()}>
              {t(LOCALES.yes)}
            </Button>
          </ControlButtons>
        </StatusAlertContent>
      ) : (
        ''
      )}
    </Popout>
  )
}

export default LobbyKick
