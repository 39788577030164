import React from 'react'
import { getSvgPath } from 'figma-squircle'

interface SquircleOptions {
  /**
   * Ссылка на элемент
   */
  ref: React.RefObject<HTMLElement>

  /**
   * Радиус скругления
   */
  cornerRadius: number

  /**
   * Сглаживание скругления
   * @default 1
   */
  cornerSmoothing?: number
}

/**
 * Хук для отрисовки скругленных углов (как в Figma)
 *
 * @hook
 * @see {@link https://ru.wikipedia.org/wiki/Сквиркл}
 *
 * @example
 * ```tsx
 * const buttonRef = React.useRef<HTMLButtonElement>(null)
 * const svgPath = useSquircle({
 *  ref: buttonRef,
 *  cornerRadius: 10,
 * })
 * return <button ref={buttonRef} style={{ clipPath: `path('${svgPath}')` }}>
 * ```
 */
const useSquircle = ({
  ref,
  cornerRadius,
  cornerSmoothing = 1,
}: SquircleOptions): string => {
  const [path, setPath] = React.useState('')

  // Отслеживаем изменения размеров элемента
  React.useEffect(() => {
    if (!ref.current) return
    const currentRef = ref.current

    const observer = new ResizeObserver(() => {
      const { width, height } = currentRef.getBoundingClientRect()
      const svgPath = getSvgPath({
        width,
        height,
        cornerRadius,
        cornerSmoothing,
      })

      setPath(svgPath)
    })

    observer.observe(currentRef)
    return () => {
      observer.disconnect()
    }
  }, [ref, cornerRadius, cornerSmoothing])

  return path
}

export default useSquircle
