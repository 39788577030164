export const SWIMGAME_TEXTURES = {
  backEndPoolDown: 'swim_back_end_pool_down_7.png',
  backEndPoolUp: 'swim_back_end_pool_up_6.png',
  floor: 'swim_back_floor_2.png',
  floorDown: 'swim_back_floor_down_2.png',
  lineDown: 'swim_back_line_down.png',
  lineUp: 'swim_back_line_up.png',
  poolBack: 'swim_back_pool_5.png',
  trackEnd: 'swim_back_track_end.png',
  trackStart: 'swim_back_track_start.png',
  stands: 'swim_back_tribuns_4.png',
  windows: 'swim_back_windows_3.png',

  timerBg: 'timerBg.png',
  countdownGlow: 'countdownGlow.png',
  countdownWait: 'countdownWait.png',
}

export enum SplashAnimation {
  Surf = 'swim_splash',
  Dive = 'swim_dive',
  Emerge = 'swim_up',
}

export enum SwimStatus {
  Run,
  Dive,
  Emerge,
  Surf,
  UnderWater,
}

export const TRACK_WIDTH = 650
export const TRACK_HEIGHT = 350
export const POOL_WIDTH = 800
export const TRACKS_IN_CONTAINER = 5
export const CONTAINER_SCALE_COEF = window.innerWidth / window.innerHeight + 0.2

export const START_LINE_X = 118
export const FINISH_LINE_X = 118
export const POOL_START_Y = 398

export const BARRIER_Y_OFFSET = 50
export const BARRIER_WIDTH = 50
export const BARRIER_HEIGHT = 30

export const SWIM_DEPTH = 50
export const SURF_RANGE = 25
