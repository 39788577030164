import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { openTonConnectModal } from 'utils/ton/connectWallet'

import { Button, Subheader } from 'components/base'
import { Popout, StatusType } from 'components/base/Popout'

import { LOCALES } from '@gatto/locales'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ethereum?: any
  }
}

const SvgContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 47.823px;
  height: 47.823px;
  align-self: center;
  flex-shrink: 0;
  border-radius: 7px;
  background: rgba(174, 179, 177, 0.1);
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.103px; /* 89.46% */
`
const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`
const MainButton = styled(Button)`
  width: 100%;
  height: 50px;
`
const NotSuccessSVG = () => {
  return (
    <SvgContainer>
      <svg
        width="33"
        height="33"
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.6943 9.5H5.69434C4.58977 9.5 3.69434 10.3954 3.69434 11.5V27.5C3.69434 28.6046 4.58977 29.5 5.69434 29.5H27.6943C28.7989 29.5 29.6943 28.6046 29.6943 27.5V11.5C29.6943 10.3954 28.7989 9.5 27.6943 9.5Z"
          fill="#4C5960"
          fillOpacity="0.24"
        />
        <path d="M3.69434 14.5H29.6943V16.5H3.69434V14.5Z" fill="#4C5960" />
        <path
          d="M16.6943 15.5C20.008 15.5 22.6943 12.8137 22.6943 9.5C22.6943 6.18629 20.008 3.5 16.6943 3.5C13.3806 3.5 10.6943 6.18629 10.6943 9.5C10.6943 12.8137 13.3806 15.5 16.6943 15.5Z"
          fill="#D33333"
        />
        <rect
          x="19.5225"
          y="10.9141"
          width="2"
          height="6"
          rx="1"
          transform="rotate(135 19.5225 10.9141)"
          fill="#FFF3F3"
        />
        <rect
          x="15.2803"
          y="12.3286"
          width="2"
          height="6"
          rx="1"
          transform="rotate(-135 15.2803 12.3286)"
          fill="#FFF3F3"
        />
        <path
          d="M13.6943 24.5H7.69434C7.42912 24.5 7.17477 24.3946 6.98723 24.2071C6.79969 24.0196 6.69434 23.7652 6.69434 23.5C6.69434 23.2348 6.79969 22.9804 6.98723 22.7929C7.17477 22.6054 7.42912 22.5 7.69434 22.5H13.6943C13.9596 22.5 14.2139 22.6054 14.4014 22.7929C14.589 22.9804 14.6943 23.2348 14.6943 23.5C14.6943 23.7652 14.589 24.0196 14.4014 24.2071C14.2139 24.3946 13.9596 24.5 13.6943 24.5Z"
          fill="#4C5960"
        />
        <path
          d="M25.6943 24.5H24.6943C24.4291 24.5 24.1748 24.3946 23.9872 24.2071C23.7997 24.0196 23.6943 23.7652 23.6943 23.5C23.6943 23.2348 23.7997 22.9804 23.9872 22.7929C24.1748 22.6054 24.4291 22.5 24.6943 22.5H25.6943C25.9596 22.5 26.2139 22.6054 26.4014 22.7929C26.589 22.9804 26.6943 23.2348 26.6943 23.5C26.6943 23.7652 26.589 24.0196 26.4014 24.2071C26.2139 24.3946 25.9596 24.5 25.6943 24.5ZM21.6943 24.5H20.6943C20.4291 24.5 20.1748 24.3946 19.9872 24.2071C19.7997 24.0196 19.6943 23.7652 19.6943 23.5C19.6943 23.2348 19.7997 22.9804 19.9872 22.7929C20.1748 22.6054 20.4291 22.5 20.6943 22.5H21.6943C21.9596 22.5 22.2139 22.6054 22.4014 22.7929C22.589 22.9804 22.6943 23.2348 22.6943 23.5C22.6943 23.7652 22.589 24.0196 22.4014 24.2071C22.2139 24.3946 21.9596 24.5 21.6943 24.5Z"
          fill="#F14E41"
        />
      </svg>
    </SvgContainer>
  )
}

const WalletNotExist: FC = () => {
  const { t } = useTranslation()
  const [status, setStatus] = useState<StatusType>()
  const [closePopout, setClosePopout] = useState(false)

  return (
    <Popout
      background={true}
      isFullScreen={false}
      type="default"
      width="310px"
      status={status}
      setStatus={setStatus}
      close={closePopout}
    >
      <Main>
        <NotSuccessSVG />
        <Header>{t(LOCALES.not_connected_wallet)}</Header>
        <Subheader>{t(LOCALES.connect_wallet_to)}</Subheader>
        <MainButton
          onClick={() => {
            setClosePopout(true)
            openTonConnectModal()
          }}
          variant="success"
        >
          {t(LOCALES.connect_wallet)}
        </MainButton>
      </Main>
    </Popout>
  )
}

export default WalletNotExist
