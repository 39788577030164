import Position, { StringifiedPosition } from 'src/types/Position'

export const STRINGIFIED_POSITION_SEPARATOR = '_'

export const stringifiedPositionToPosition = (
  s: StringifiedPosition,
): Position => {
  const [x, y] = s.split(STRINGIFIED_POSITION_SEPARATOR)

  if (!x || !y) {
    throw new Error(`Unknown type of stringified position, got ${s}`)
  }

  return {
    x: Number(x),
    y: Number(y),
  }
}

export const positionToStringifiedPosition = (
  position: Position,
): StringifiedPosition => {
  return `${position.x}${STRINGIFIED_POSITION_SEPARATOR}${position.y}`
}
