import React, { FC, useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { MODAL_ANIMATION_MS } from 'src/const'
import { ModalContext } from 'utils/ModalContext'

import { TypeTabItem } from 'components/base/Popout/Popout'
import { TabsComponent } from 'components/modals/TabComponent'

import cancelIcon from './../../assets/icons/cancelIcon.svg'

interface IModal {
  type: 'tabs' | 'default' | 'minting'
  header: string
  headerPosition: 'left' | 'center' | 'right'
  setBackground: React.Dispatch<React.SetStateAction<boolean>>
  background: boolean
  children?: React.ReactNode
  activeTab?: string
  setActiveTab?: React.Dispatch<React.SetStateAction<string>>
  tabItems?: TypeTabItem[]
}

const ModalHeader = styled.div<{
  headerPosition: IModal['headerPosition']
  modalType: IModal['type']
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: ${(props) =>
    props.modalType === 'default'
      ? '30px 20px 20px 20px'
      : props.modalType === 'minting'
      ? '30px 20px 10px 20px'
      : '15px 15px 0 15px'};
  border: 2px solid white;
  color: #000;
  font-family: Nunito;
  font-size: ${(props) => {
    return props.modalType === 'default' || props.modalType === 'minting'
      ? '18px'
      : '14px'
  }};
  font-style: normal;
  font-weight: 600;
  line-height: 22px;

  border-radius: ${(props) =>
    props.modalType === ('default' || 'minting') ? '13px 13px 0 0' : '0'};

  justify-content: ${(props) => {
    if (props.headerPosition === 'left') return 'flex-start'
    if (props.headerPosition === 'center') return 'center'
    if (props.headerPosition === 'right') return 'flex-end'
    return 'flex-start'
  }};
  background: white;
`

const Cancel = styled.img`
  position: absolute;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  right: 23px;
  transition: opacity 0.1s;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  :active {
    opacity: 0.8;
  }
`

const ModalBlockStyle = styled.div<{
  modalType: IModal['type']
}>`
  padding: ${(props) =>
    // eslint-disable-next-line no-constant-condition
    props.modalType === ('default' || 'minting')
      ? '0 20px 30px 20px'
      : '5px 15px 15px 15px'};
`

const ModalHeaderStyle = styled.div``

const ModalTab = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  position: absolute;
  height: 30px;
  width: 100%;
  top: -31px;
  padding: 0;
  z-index: 10;
  font-size: 12px;
`

const Modal: FC<IModal> = ({
  type,
  header,
  tabItems,
  activeTab,
  setActiveTab,
  headerPosition,
  setBackground,
  background,
  children,
}) => {
  const navigate = useNavigate()
  const [headerContainer, setHeaderContainer] = useState<HTMLDivElement>()
  const { updateActiveModal } = useContext(ModalContext)

  const handleTabClick = ({ type }: TypeTabItem) => {
    if (setActiveTab) {
      setActiveTab(type)
    }
  }

  const onClose = () => {
    setTimeout(() => navigate(-1), MODAL_ANIMATION_MS)
    updateActiveModal(null)
  }

  useEffect(() => {
    setHeaderContainer(
      document.querySelector('.vkuiModalPage__header') as HTMLDivElement,
    )
    setBackground(background)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerContainer])

  return (
    <>
      {headerContainer &&
        tabItems?.length &&
        activeTab &&
        createPortal(
          <ModalTab>
            <TabsComponent
              handleTabClick={handleTabClick}
              tabItems={tabItems}
              activeTab={activeTab}
            />
          </ModalTab>,
          headerContainer,
        )}
      <ModalHeader headerPosition={headerPosition} modalType={type}>
        <Cancel src={cancelIcon} onClick={onClose} />
        <ModalHeaderStyle>{header}</ModalHeaderStyle>
      </ModalHeader>
      <ModalBlockStyle modalType={type}>{children}</ModalBlockStyle>
    </>
  )
}

export default Modal
