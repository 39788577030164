import styled from '@emotion/styled'

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  line-height: var(min-height);
  align-items: center;
`
