import { FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import { LOCALES } from '@gatto/locales'

import { Prize, PrizeOpacity } from '../../../config/assets'

const Root = styled.div`
  position: relative;
  width: 102px;
  height: 110px;
`

const PrizeName = styled.p`
  width: max-content;
  position: relative;
  top: 115px;
  left: 21px;
  padding: 3px 5px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.25);
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Nunito';
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  line-height: normal;
`

const PrizeValueWrapper = styled.div`
  top: 38%;
  left: 30%;
  position: absolute;
  padding: 3px 5px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.25);

  backdrop-filter: blur(9px);
`

const PrizeTopValue = styled.p`
  color: #fff;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Nunito';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

const PrizeBottomValue = styled(PrizeTopValue)`
  font-size: 10px;
  font-weight: 700;
`

const PrizeImageBg = styled.div<{ value: number }>`
  position: absolute;
  top: 0;
  background-image: url(${PrizeOpacity});
  width: 100%;
  height: 100%;
  background-size: cover;
  transition: clip-path 0.3s linear;
`

const PrizeImageTop = styled(PrizeImageBg)<{ value: number }>`
  clip-path: inset(calc(100% - ${({ value }) => value}%) 0 0 0);
  background-image: url(${Prize});
`

interface Props extends HTMLAttributes<HTMLDivElement> {
  value: number
  isProgressShown: boolean
}

const PetPrize: FC<Props> = ({ isProgressShown, value, ...props }) => {
  const { t } = useTranslation()
  return (
    <Root {...props}>
      <PrizeImageBg value={value} />
      <PrizeImageTop value={value} />
      {!isProgressShown && (
        <>
          <PrizeValueWrapper>
            <PrizeTopValue>{value}</PrizeTopValue>
            <PrizeBottomValue>/100</PrizeBottomValue>
          </PrizeValueWrapper>
          <PrizeName>{t(LOCALES.gift)}</PrizeName>
        </>
      )}
    </Root>
  )
}

export default PetPrize
