import styled from '@emotion/styled'

const StageWithMaskWrapper = styled.div<{ offsetY?: number }>`
  position: absolute;
  pointer-events: none;
  margin-top: ${(props) => props.offsetY || 0}px;

  mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(62, 112, 242, 0.99) 3%
  );
`

export default StageWithMaskWrapper
