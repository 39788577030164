import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'

import { LinkType } from './Tabbar'

type HighlightPositionType = {
  /**
   * Позиция подсветки по оси X
   */
  left: number

  /**
   * Ширина подсветки
   */
  width: number
}

function highlightBackground(mode: 'light' | 'dark') {
  if (mode === 'light') {
    return `linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))`
  }
  return `linear-gradient(180deg, rgba(39, 184, 180, 0.15), rgba(28, 180, 175, 0.00))`
}

const Highlight = styled.div<{
  mode: 'light' | 'dark'
  position?: HighlightPositionType
}>`
  width: 100%;
  height: 150%;
  position: absolute;

  border-radius: 15px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );

  z-index: -1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    mask: linear-gradient(#fff 0 0) padding-box linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;

    border: 1px solid transparent;
    border-radius: 15px;

    background: ${(props) => highlightBackground(props.mode)};
  }

  ${(props) => props.position && `left: ${props.position.left}px;`}
  ${(props) => props.position && `width: ${props.position.width * 1.5}px;`}
  ${(props) =>
    props.position &&
    `transform: translateX(calc(-50% + ${props.position.width / 2}px));`}
  transition: all 0.3s ease-out;
`

type BaseTabbarHighlightProps = {
  /**
   * Активная вкладка
   */
  activeTab: LinkType

  /**
   * Тема подсветки
   * @default 'light'
   */
  mode?: 'light' | 'dark'
}

type TabbarHighlightProps = BaseTabbarHighlightProps &
  Omit<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    keyof BaseTabbarHighlightProps
  >

/**
 * Подсветка вкладки таббара
 */
function TabbarHighlight({
  activeTab,
  mode = 'light',
  ...props
}: TabbarHighlightProps): React.ReactElement {
  const [currentPosition, setCurrentPosition] = useState<HighlightPositionType>(
    { left: 0, width: 0 },
  )

  useEffect(() => {
    // Получаем элемент вкладки
    const element = document.querySelector(
      `[data-link="${activeTab}"]`,
    ) as HTMLElement | null
    if (!element) return

    // Находим позицию вкладки и перемещаем подсветку
    setCurrentPosition({ left: element.offsetLeft, width: element.offsetWidth })
  }, [activeTab])

  return (
    <Highlight
      id="highlight"
      mode={mode}
      position={currentPosition}
      {...props}
    />
  )
}

export default TabbarHighlight
