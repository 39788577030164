import styled from '@emotion/styled'

const Menu = styled.div<{
  ltRow: boolean
}>`
  width: 100%;
  padding: 20px;
  padding-top: 10px;

  ${({ ltRow }) =>
    !ltRow &&
    `display: grid; grid-template-columns: repeat(auto-fit, 169px); grid-gap: 15px; justify-content: space-between;`}
  ${({ ltRow }) => ltRow && `display: flex; gap: 15px;`}
    overflow: auto;
  overflow-x: hidden;

  @media screen and (max-width: 400px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }
  mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(62, 112, 242, 0.99) 3%
  );
  padding-top: 3px;
  padding-bottom: 3px;

  &::-webkit-scrollbar {
    display: none;
  }

  position: relative;
`

export default Menu
