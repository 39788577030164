import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import bridge from '@vkontakte/vk-bridge'
import { useCloudStorage } from '@vkruglikov/react-telegram-web-app'
import { MINI_APP } from 'src'
import { POPOUT_ANIMATION_MS } from 'src/const'
import { Popouts } from 'src/const/router'
import { makeRequest } from 'src/utils/api'

import { Button } from 'components/base'
import { Popout, StatusType } from 'components/base/Popout'
import { RootState, useAppDispatch, useAppSelector } from 'store/index'
import { clearPetState } from 'store/region'
import { getSelectedPet } from 'store/selectPet'
import { updateUserRegion } from 'store/user'

import { LOCALES } from '@gatto/locales'
import { Platform } from '@gatto/shared'

const ButtonsWrapper = styled.div`
  display: grid;
  column-gap: 10px;
  grid-template-columns: 1fr 1fr;
`

const Title = styled.div`
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 5px;
`

const Subtitle = styled.div`
  color: #888888;
  text-align: center;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  margin-bottom: 10px;
`

type PetToStorageProps = Popouts['petToStorage']

const PetToStoragePopout: FC<PetToStorageProps> = ({ petId }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const region = useAppSelector((state: RootState) => state.region).data
  const selectedPet = useAppSelector(getSelectedPet)
  const { setItem } = useCloudStorage()

  const [status, setStatus] = useState<StatusType>()

  const onClickYes = async () => {
    if (!petId) return
    await makeRequest('pet.moveToStorage', { id: petId })
    if (selectedPet === petId) {
      if (MINI_APP === Platform.VK) {
        bridge.send('VKWebAppStorageSet', {
          key: 'selectedPet',
          value: '',
        })
      }

      if (MINI_APP === Platform.TG) {
        setItem('selectedPet', '')
      }
    }
    dispatch(updateUserRegion({ regionId: region?._id, egg: null, pet: null }))
    dispatch(clearPetState())
    setStatus('disappear')
    setTimeout(() => navigate(-1), POPOUT_ANIMATION_MS)
  }

  const onClickNo = () => {
    setStatus('disappear')
    setTimeout(() => navigate(-1), POPOUT_ANIMATION_MS)
  }

  return (
    <Popout
      type="default"
      width={'330px'}
      background={true}
      isFullScreen={false}
      status={status}
      setStatus={setStatus}
    >
      <div>
        <Title>{t(LOCALES.petPitConfirm)}</Title>
        <Subtitle>{t(LOCALES.happinessAndFullnessReset)}</Subtitle>
        <ButtonsWrapper>
          <Button variant="dangerous" onClick={onClickNo}>
            {t(LOCALES.no)}
          </Button>
          <Button variant="success" onClick={onClickYes}>
            {t(LOCALES.yes)}
          </Button>
        </ButtonsWrapper>
      </div>
    </Popout>
  )
}

export default PetToStoragePopout
