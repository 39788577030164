import React, { FC, useMemo } from 'react'
import { Container, ParticleContainer } from '@pixi/react'
import * as PIXI from 'pixi.js'
import {
  CLOUD_SPRITESHEET_PATH,
  HALLOWEEN_CLOUD_SPRITESHEET_PATH,
} from 'src/config/assets'
import useLoadAsset from 'src/hooks/useLoadAsset'
import exhaustivenessCheck from 'utils/exhaustivenessCheck'

import Cloud from './Cloud'

const CLOUDS_SPRITE_NAMES = [
  'cloud_1.png',
  'cloud_2.png',
  'cloud_3.png',
  'cloud_4.png',
  'cloud_5.png',
  'cloud_6.png',
  'cloud_7.png',
  'cloud_8.png',
  'cloud_9.png',
]

const HALLOWEEN_SPRITE_NAMES = [
  'Group 65.png',
  'Group 64.png',
  'Group 63.png',
  'Group 50.png',
  'Group 51.png',
  'Group 52.png',
  'Group 53.png',
  'Group 54.png',
  'Group 55.png',
]

type CloudsBackgroundProps = {
  type: 'default' | 'halloween'
}

const getCloudSizeScale = (type: CloudsBackgroundProps['type']) => {
  switch (type) {
    case 'default':
      return 0
    case 'halloween':
      return 0.3
    default:
      exhaustivenessCheck(type)
      return 0
  }
}

const getCloudSprite = (type: CloudsBackgroundProps['type']) => {
  switch (type) {
    case 'default':
      return { sprite: CLOUD_SPRITESHEET_PATH, names: CLOUDS_SPRITE_NAMES }
    case 'halloween':
      return {
        sprite: HALLOWEEN_CLOUD_SPRITESHEET_PATH,
        names: HALLOWEEN_SPRITE_NAMES,
      }
    default:
      exhaustivenessCheck(type)
      return { sprite: '', names: [] }
  }
}

const CloudsBackground: FC<CloudsBackgroundProps> = React.memo(({ type }) => {
  type CloudObject = {
    sprite: string
    x: number
    y: number
    speedX: number
    speedY: number
    sizeScale: number
    zIndex: number
  }

  const width = window.innerWidth
  const height = window.innerHeight

  const { sprite, names } = getCloudSprite(type)

  const cloud_spritesheet = useLoadAsset<PIXI.Spritesheet>(sprite)

  const CLOUDS_AMOUNT = 50

  const clouds: CloudObject[] = useMemo(
    () =>
      new Array(CLOUDS_AMOUNT).fill(null).map(() => {
        const speedRandom = Math.random() + 0.1
        return {
          sprite: names[Math.floor(Math.random() * names.length)] as string,
          x: -width / 2 + Math.random() * width * 2,
          y: Math.random() * height,
          speedX: speedRandom * 0.25,
          speedY: speedRandom * 0.075,
          sizeScale: 0.3 + Math.random() * 0.2 + getCloudSizeScale(type),
          zIndex: Math.floor(speedRandom) * 10,
        }
      }, []),
    [height, width],
  )

  return (
    <ParticleContainer sortableChildren={true} zIndex={-1}>
      {clouds.map((cloudObj, i) => {
        return (
          <Cloud
            key={i}
            cloudObject={cloud_spritesheet?.textures[cloudObj.sprite]}
            x={cloudObj.x}
            y={cloudObj.y}
            speedX={cloudObj.speedX}
            speedY={cloudObj.speedY}
            sizeScale={cloudObj.sizeScale}
            zIndex={cloudObj.zIndex}
          />
        )
      })}
    </ParticleContainer>
  )
})

CloudsBackground.displayName = 'CloudsBackground'

export default CloudsBackground
