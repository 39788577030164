import React from 'react'
import hash from '@emotion/hash'
import { Size } from 'src/types'
import { nowServerTime } from 'utils/nowServerTime'

import ProgressArc from './ProgressArc'

/**
 * Размер компонента уровня
 * @constant
 */
const COMPONENT_SIZE: Size = {
  width: 32,
  height: 31,
}

type Props = {
  /**
   * Прогресс заполнения от 0 до 1
   */
  progress: number

  /**
   * Начальный прогресс заполнения от 0 до 1
   * @default 0
   */
  startProgress?: number

  /**
   * Начальный угол в градусах
   * @default 180
   */
  startAngle?: number

  /**
   * Ширина линии
   * @default 3
   */
  strokeWidth?: number

  /**
   * ID для linearGradient
   */
  linearGradientId?: string

  /**
   * С фоном или без
   * @default false
   */
  withBackground?: boolean
} & React.DetailedHTMLProps<React.HTMLProps<SVGSVGElement>, SVGSVGElement>

/**
 * Компонент для отображения уровня в виде прогресса
 *
 * @
 */
const ProgressLevel: React.FC<Props> = ({
  progress,
  startProgress = 0,
  startAngle = 180,
  strokeWidth = 3,
  linearGradientId,
  withBackground = false,
  ...props
}) => {
  const hashName = hash(
    progress.toString() +
      startProgress.toString() +
      startAngle.toString() +
      nowServerTime().toString() +
      Math.random().toString(),
  )

  const backgroundProps = withBackground
    ? { fill: 'white', fillOpacity: '0.15' }
    : {}

  return (
    <svg
      width={COMPONENT_SIZE.width}
      height={COMPONENT_SIZE.height}
      viewBox={`0 0 ${COMPONENT_SIZE.width} ${COMPONENT_SIZE.height}`}
      fill="none"
      {...props}
    >
      <linearGradient id="gradient1">
        <stop offset="0%" stopColor="#9969FF" />
        <stop offset="100%" stopColor="#5683eb" />
      </linearGradient>

      <path
        d="M10.944 3.61465C13.9588 1.46178 18.0412 1.46178 21.056 3.61465L26.4537 7.46913C29.4685 9.622 30.73 13.438 29.5784 16.9215L27.5167 23.1581C26.3651 26.6416 23.0624 29 19.336 29H12.664C8.93755 29 5.63487 26.6416 4.48332 23.1581L2.42159 16.9215C1.27003 13.438 2.53155 9.622 5.54635 7.46913L10.944 3.61465Z"
        stroke={linearGradientId ? `url(#${linearGradientId})` : 'currentColor'}
        strokeWidth={strokeWidth}
        clipPath={`url(#pentagon-overlay-stroke-${hashName})`}
        {...backgroundProps}
      />
      <defs>
        <ProgressArc
          width={COMPONENT_SIZE.width}
          height={COMPONENT_SIZE.height}
          clipPathID={`pentagon-overlay-stroke-${hashName}`}
          progress={progress}
          startProgress={startProgress}
          startAngle={startAngle}
        />
      </defs>
    </svg>
  )
}

export default ProgressLevel
