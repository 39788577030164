import {
  LeaguesPage,
  LobbyPage,
  MapPage,
  NewsPage,
  OlympicGamePage,
  PrizeInfoPage,
  RatingPage,
  RegionPage,
  RewardingPage,
  SearchPage,
  SelectPetPage,
  StadiumPage,
  StoragePage,
  StorePage,
  WorldMapPage,
} from 'src/pages'

export type RouteAlias =
  | 'worldMap'
  | ''
  | 'news'
  | 'region'
  | 'store'
  | 'storage'
  | 'rating'
  | 'gameSearch'
  | 'stadium'
  | 'olympicGame'
  | 'selectPet'
  | 'rewarding'
  | 'lobby'
  | 'leagues'
  | 'prizeInfo'

export interface Route {
  path: string
  component: React.ReactElement
  shouldHideTabbar?: boolean
  alias: RouteAlias
  bannerAd?: boolean // Скрывает рекламный баннер в значении false если баннер ранее отображался на экране до перехода в этот роут. Работает через хук useBannedAdHiding.
}

export const routes: Route[] = [
  {
    path: '/news',
    component: <NewsPage />,
    shouldHideTabbar: false,
    alias: 'news',
  },
  {
    path: '/',
    component: <MapPage />,
    shouldHideTabbar: false,
    alias: '',
    bannerAd: true, // Разрешаем допоказать баннер после загрузочного экрана
  },
  {
    path: '/worldMap',
    component: <WorldMapPage />,
    shouldHideTabbar: false,
    alias: 'worldMap',
  },
  {
    path: '/store',
    component: <StorePage />,
    shouldHideTabbar: true,
    alias: 'store',
  },
  {
    path: '/lobby/:lobbyId',
    component: <LobbyPage />,
    shouldHideTabbar: true,
    alias: 'lobby',
  },
  {
    path: '/lobby',
    component: <LobbyPage />,
    shouldHideTabbar: true,
    alias: 'lobby',
  },
  {
    path: '/storage',
    component: <StoragePage />,
    shouldHideTabbar: true,
    alias: 'storage',
  },
  {
    path: '/region/:regionId',
    component: <RegionPage />,
    shouldHideTabbar: true,
    alias: 'region',
  },
  {
    path: '/rating',
    component: <RatingPage />,
    shouldHideTabbar: true,
    alias: 'rating',
  },
  {
    path: '/gameSearch',
    component: <SearchPage />,
    shouldHideTabbar: true,
    alias: 'gameSearch',
    bannerAd: true,
  },
  {
    path: '/stadium',
    component: <StadiumPage />,
    shouldHideTabbar: true,
    alias: 'stadium',
    bannerAd: true,
  },
  {
    path: '/olympicGame',
    component: <OlympicGamePage />,
    shouldHideTabbar: true,
    alias: 'olympicGame',
  },
  {
    path: '/rewarding',
    component: <RewardingPage />,
    shouldHideTabbar: true,
    alias: 'rewarding',
  },
  {
    path: '/selectPet',
    component: <SelectPetPage />,
    shouldHideTabbar: true,
    alias: 'selectPet',
  },
  {
    path: '/leagues',
    component: <LeaguesPage />,
    shouldHideTabbar: true,
    alias: 'leagues',
  },
  {
    path: '/prizeInfo',
    component: <PrizeInfoPage />,
    shouldHideTabbar: true,
    alias: 'prizeInfo',
  },
]
