import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ExpIcon } from 'src/config/assets'
import { Popouts } from 'src/const/router'
import { makeRequest } from 'utils/api'
import { fixNames } from 'utils/i18/fixItemTypes'

import { Popout, StatusType } from 'components/base/Popout'
import { useAppDispatch, useAppSelector } from 'store'
import { setEssence, setIsEssence, setIsTabbarHidden } from 'store/storage'

import { LOCALES } from '@gatto/locales'
import { BaseEssence, PopulatedUserEssence } from '@gatto/shared'

import StorageContent from '../StorageContent'

export type EssencePopoutProps = Popouts['essence']

const EggPopout: FC<EssencePopoutProps> = ({ id, type }) => {
  const { t } = useTranslation()
  const { isEssenceApplying } = useAppSelector((state) => state.storage)
  const [status, setStatus] = useState<StatusType>()
  const [item, setItem] = useState<PopulatedUserEssence>()
  const [isBack, setIsBack] = useState(false)
  const navigate = useNavigate()

  const items = [
    {
      icon: ExpIcon,
      title: t(LOCALES.experience),
      value: item && item?.essence.experience,
    },
  ]

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(setIsEssence(false))
  }, [isEssenceApplying])

  const handleEssenceUse = () => {
    setIsBack(true)
    dispatch(setEssence(item))
    dispatch(setIsEssence(true))
    dispatch(setIsTabbarHidden(true))
    navigate('/', { replace: true })
  }
  const getWareEssences = async (): Promise<void> => {
    try {
      const response = await makeRequest('warehouseGoods.getGoodById', {
        type: type,
        goodId: id,
      })

      setItem(response)
    } catch (e) {
      throw new Error()
    }
  }

  useEffect(() => {
    getWareEssences()
  }, [])

  useEffect(() => {
    const handlePopstate = () => {
      dispatch(setIsTabbarHidden(false))
      navigate('/storage', { replace: true })
      setIsBack(true)
    }

    window.addEventListener('popstate', handlePopstate)

    return () => {
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [])

  const essence = item
    ? (fixNames(t, { ...item.essence }) as BaseEssence)
    : undefined

  return (
    <Popout
      type="default"
      background={true}
      width="315px"
      isFullScreen={false}
      status={status}
      setStatus={setStatus}
      navigatePath={isBack ? '/storage' : undefined}
    >
      {item && essence && (
        <StorageContent
          header={essence.name}
          subheader={essence.description}
          photoUrl={item.essence.photoUrl}
          items={items}
          handleClick={handleEssenceUse}
          buttonText={t(LOCALES.use)}
        />
      )}
    </Popout>
  )
}

export default EggPopout
