import { FC } from 'react'

import { PetImage } from 'components/base'

import { PopulatedPetWithSkins } from '@gatto/shared'

import { AvatarStyle, biomeColors, UserPhotoStyle } from './styles'

type AvatarProps = {
  pet?: PopulatedPetWithSkins
  photoUrl: string
  size?: number
  scale?: number
}

export const Avatar: FC<AvatarProps> = ({ pet, photoUrl, scale, size }) => {
  if (!scale) scale = 0.22
  if (!size) size = 60
  if (pet) {
    return (
      <AvatarStyle
        background={biomeColors[pet.basePet.allowedRegion]}
        size={size}
      >
        <PetImage
          basePet={pet.basePet}
          width={size}
          height={size - scale * 100}
          scale={scale}
          skins={pet.skins}
        />
      </AvatarStyle>
    )
  } else {
    return (
      <AvatarStyle background={'rgba(0,0,0,0.1)'} size={size}>
        <UserPhotoStyle src={photoUrl} />
      </AvatarStyle>
    )
  }
}
