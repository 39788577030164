import { FC } from 'react'
import styled from '@emotion/styled'
import { Popouts } from 'const/router'
import { API_URL } from 'src/config/constants'
import { usePopout } from 'src/hooks'
import { PetClothes } from 'src/pixi/types/spine'

import { ItemDesc } from 'components/base'

import { BasePet, PetChars, SkinRarity, WarehouseItems } from '@gatto/shared'

import { NFTItem } from './StorageMenu'

const Item = styled.div`
  width: 169px;
  min-height: 220px;
  display: inline-flex;
  padding: 10px 10px 13px 10px;
  justify-content: center;
  flex-shrink: 0;
  flex-wrap: wrap;
  border-radius: 26px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px -4px 0px 0px rgba(255, 255, 255, 0.15) inset;
  transition: 0.2s ease-in-out;
  cursor: pointer;

  @media screen and (max-width: 375px) {
    width: 160px;
  }
`

const StorageItemPet = styled.div`
  width: 149.114px;
  min-height: 155.949px;
  flex-shrink: 0;
  border-radius: 16px;
  background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(255, 255, 255, 0.11) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(0deg, #233d75 0%, #233d75 100%),
    lightgray -35.397px -26.732px / 146.54% 131.553% no-repeat;
  @media screen and (max-width: 375px) {
    width: 140px;
  }
`

const StorageItemImage = styled.img`
  width: auto;
  max-width: 150.61px;
  height: 169.44299px;
  flex-shrink: 0;
  object-fit: contain;
  border-radius: 16px;

  background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(255, 255, 255, 0.11) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(0deg, #233d75 0%, #233d75 100%),
    lightgray -35.397px -26.732px / 146.54% 131.553% no-repeat;
  @media screen and (max-width: 375px) {
    width: 140px;
    height: 155.949px;
  }
`

const StorageItemDesc = styled.div`
  width: 149.114px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  min-height: 34.5px;

  @media screen and (max-width: 375px) {
    width: 140px;
  }
`
const CardText = styled.p`
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  width: 100%;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const PetContainer = styled.div<{ isVisible: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.isVisible && 'display: none;'}
  width: 149.114px;
  height: 169.44299px;
  flex-shrink: 0;
  border-radius: 16px;
  background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(255, 255, 255, 0.11) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(0deg, #233d75 0%, #233d75 100%),
    lightgray -35.397px -26.732px / 146.54% 131.553% no-repeat;

  @media screen and (max-width: 375px) {
    width: 140px;
    height: 155.949px;
  }
`

type propsItems = {
  id?: string
  localId?: string
  type?: WarehouseItems | string
  generation?: number
  evolution?: number
  biom?: string
  isHatched?: boolean
  isInStorage?: boolean
  name?: string
  level?: number
  description?: string
  petName?: string
  pet?: NFTItem
  image?: string
  rarity?: SkinRarity
  petLevel?: number
  petBiome?: string
  petEvolution?: number
  basePet?: Pick<BasePet, '_id' | 'kind' | 'rarity' | 'allowedRegion'>
  petChars?: PetChars
  skins?: PetClothes[]
}

interface StorageItemProps {
  name: string
  image?: string
  props?: propsItems
  category: keyof Popouts
  id?: string
}

const StorageItem: FC<StorageItemProps> = ({
  name,
  image,
  category,
  props,
  id,
}) => {
  const openPopout = usePopout(category, false, props)

  return (
    <Item onClick={() => openPopout()} key={name} id={id}>
      {category !== 'pet' ? (
        <StorageItemImage src={`${API_URL}${image}`} />
      ) : (
        <StorageItemPet />
      )}
      <ItemDesc>
        <CardText>{name}</CardText>
      </ItemDesc>
    </Item>
  )
}

export default StorageItem
