import { FC, ReactNode } from 'react'

import { Row } from 'components/base'

interface PageHeaderProps {
  before?: JSX.Element
  over: JSX.Element
  children: ReactNode
}

const PageHeader: FC<PageHeaderProps> = ({ before, over, children }) => {
  return (
    <>
      {over}
      <Row style={{ marginRight: before ? '50px' : '20px' }}>
        {before}
        {children}
      </Row>
    </>
  )
}

export default PageHeader
