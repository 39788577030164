import React, {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useState,
} from 'react'
import styled from '@emotion/styled'
import {
  ConfigProvider,
  ModalPage,
  ModalRoot,
  Platform,
  usePlatform,
} from '@vkontakte/vkui'

type ModalControllerProps = {
  activeModal: string | null | undefined
  onClose: (goBack: boolean) => void
  children: ReactElement<{ setBackground: Dispatch<SetStateAction<boolean>> }>
}

const ModalRootZIndexStyle = styled.div`
  .vkuiModalRoot__mask {
    z-index: 100;
  }
`

const ModalRootStyle = styled(ModalRoot)`
  height: 100%;
`

const ModalPageStyle = styled(ModalPage)`
  color: #fff;
  position: relative;
  z-index: 500;
  --webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  scroll-snap-type: y mandatory;

  .vkuiModalPage__in {
    background-color: white;
    z-index: 100;
  }

  .vkuiModalPage__content-in {
    z-index: 1000;
  }

  .vkuiModalPage__content {
    text-align: center;
  }
`

const OnBoardingStyle = styled(ModalPage)`
  position: relative;
  z-index: 500;
  --webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  scroll-snap-type: y mandatory;

  .vkuiModalPage__in-wrap {
    max-width: 430px;
    height: auto;
  }

  .vkuiModalPage__in {
    z-index: 100;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .vkuiModalPage__content-in {
    z-index: 1000;
  }

  .vkuiModalPage__content {
    text-align: center;
    overflow: hidden !important;
  }
`

export const ModalController: FC<ModalControllerProps> = ({
  activeModal,
  onClose,
  children,
}) => {
  let platform = usePlatform()
  if (platform === Platform.VKCOM && activeModal !== 'onboarding') {
    platform = Platform.ANDROID
  }
  const [background, setBackground] = useState<boolean>(true)

  return (
    <ConfigProvider platform={platform}>
      <ModalRootZIndexStyle>
        <div className={background ? 'vk-background' : ''}>
          <ModalRootStyle activeModal={activeModal}>
            <ModalPageStyle
              id="root"
              onClose={() => onClose(true)}
              hideCloseButton={true}
            >
              {children && React.cloneElement(children, { setBackground })}
            </ModalPageStyle>
            <OnBoardingStyle
              settlingHeight={100}
              dynamicContentHeight={true}
              id="onboarding"
              onClose={() => onClose(false)}
            >
              {children && React.cloneElement(children, { setBackground })}
            </OnBoardingStyle>
          </ModalRootStyle>
        </div>
      </ModalRootZIndexStyle>
    </ConfigProvider>
  )
}
