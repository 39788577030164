import { useEffect, useState } from 'react'
import { makeRequest } from 'utils/api'

import { UserWallet } from '@gatto/shared'

import { usePopout } from './index'

export const useWalletCheck = (isPopoutNeeded?: boolean): UserWallet[] => {
  const openPopoutWalletNotExist = usePopout('walletNotExist', true, {})
  const [data, setData] = useState<UserWallet[]>([])
  const findWallets = async () => {
    try {
      const response = await makeRequest('nft.getUserWallets', {})
      if (response.wallets.length !== 0) {
        setData(response.wallets)
      } else {
        if (isPopoutNeeded) {
          openPopoutWalletNotExist()
        }
      }
    } catch (e) {
      if (isPopoutNeeded) {
        openPopoutWalletNotExist()
      }
    }
  }

  useEffect(() => {
    findWallets()
  }, [])

  return data
}

export const useWalletCheckWithUpdate = (
  isPopoutNeeded?: boolean,
): [UserWallet[], () => Promise<UserWallet[]>] => {
  const openPopoutWalletNotExist = usePopout('walletNotExist', true, {})
  const [data, setData] = useState<UserWallet[]>([])
  const findWallets = async () => {
    try {
      const response = await makeRequest('nft.getUserWallets', {})
      if (response.wallets.length !== 0) {
        setData(response.wallets)
        return response.wallets
      } else {
        if (isPopoutNeeded) {
          openPopoutWalletNotExist()
        }
        return []
      }
    } catch (e) {
      if (isPopoutNeeded) {
        openPopoutWalletNotExist()
      }
      return []
    }
  }

  useEffect(() => {
    findWallets()
  }, [])

  return [data, findWallets]
}
