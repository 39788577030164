import React, { FC } from 'react'

import { Wallet } from 'components/base'
import { HatchedCustomWallet } from 'pages/RegionPage/RegionEgg'

import { PopulatedUserRegion } from '@gatto/shared'

import Tabbar from './Tabbar'

type EmptyProps = {
  region: PopulatedUserRegion
}

const RegionEmpty: FC<EmptyProps> = ({ region }) => {
  return (
    <>
      <Wallet
        mode={'horizontal'}
        customStyle={`
              position: absolute;
              background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), linear-gradient(355deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.10) 100%);
              box-shadow: 0px -4px 0px 0px rgba(38, 43, 11, 0.25) inset;`}
      />

      <Tabbar page="egg" region={region} isHatched={false} isPlaced={false} />
    </>
  )
}

export default RegionEmpty
