import { Biome } from '@gatto/shared'

import exhaustivenessCheck from './exhaustivenessCheck'

export const getBiomeColor = (biome: Biome): string => {
  switch (biome) {
    case Biome.Plain:
      return '#c0ca1f'
    case Biome.Sea:
      return '#2473bd'
    case Biome.Mountain:
      return '#574b56'
    case Biome.Forest:
      return '#0c6352'
    case Biome.Desert:
      return '#cf872a'
    case Biome.Ice:
      return '#63d6cf'
    case Biome.Random:
      return ''
    default:
      exhaustivenessCheck(biome)
      return ''
  }
}
