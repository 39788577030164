import { useEffect, useState } from 'react'
import { nowServerTime } from 'utils/nowServerTime'

export const useNow = (interval: number = 1000 * 60): number => {
  const [now, setNow] = useState(nowServerTime)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setNow(nowServerTime())
    }, interval)

    return () => clearInterval(intervalId)
  }, [interval])

  return now
}
