import { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { getSvgPath } from 'figma-squircle'
import { POPOUT_ANIMATION_MS } from 'src/const'

import cancelIcon from 'assets/icons/cancelIcon.svg'
import {
  TabbarContainer,
  TabbarItem,
  type TabItem,
} from 'components/modals/TabComponent'

type BackgroundProps = {
  background: boolean
  status: StatusType
  isFullScreen: boolean
  position: TypePosition
  type: TypePopout
}

const Background = styled.div<BackgroundProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  ${(props) => (props.position === 'bottom' ? 'align-items: flex-end;' : '')}
  ${(props) => (props.position === 'top' ? 'align-items: flex-start;' : '')}
  ${(props) => !props.isFullScreen && 'padding: 0 10px;'}

  background: rgba(0, 0, 0, 0);
  transition: background ${POPOUT_ANIMATION_MS}ms;
  ${(props) => props.status === 'appear' && 'background: rgba(0, 0, 0, 0.3)'}
  ${(props) => !props.background && 'background: none'}
`

const Main = styled.div<{
  status: StatusType
  path: string
  isFullScreen: boolean
  position: TypePosition
  type: TypePopout
  width?: string
  minWidth: string | null | undefined
  maxWidth: string | null | undefined
}>`
  margin: auto;
  position: absolute;
  ${(props) => (props.type === 'tabs' ? 'position: static; ' : '')}
  ${(props) => {
    switch (props.position) {
      case 'top':
        return 'margin: 70px 0 0 0;'
      case 'bottom':
        return 'margin: 0 0 15px 0;'
      default:
        return ''
    }
  }};
  height: max-content;
  ${(props) => (props.width ? 'width: ' + props.width + ';' : '')}
  ${(props) => (props.maxWidth ? 'max-width: ' + props.maxWidth + ';' : '')}
  ${(props) => (props.minWidth ? 'min-width: ' + props.minWidth + ';' : '')}
  ${(props) => (props.isFullScreen ? 'width: 100vw;' : '')}
  ${(props) => (props.isFullScreen ? 'height: 100vh;' : '')}
  ${(props) => (props.isFullScreen ? '' : 'padding: 20px;')}
  z-index: 5;
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.type === 'default' ? '15px' : '5px')};
  ${(props) => props.position === 'bottom' && 'gap: 5px;'}
  border-radius: ${(props) =>
    props.type === 'default' ? '26px;' : '0 0 18px 18px;'};
  background-color: rgba(255, 255, 255, 1);
  transition: opacity ${POPOUT_ANIMATION_MS}ms,
    transform ${POPOUT_ANIMATION_MS}ms;

  opacity: 0;
  transform: translateY(40px);

  ${(props) => {
    if (props.isFullScreen) {
      return ''
    } else {
      // При использовании clip-path контейнер не может полноценно растянуться. Временно отключено
      // return css`
      //   clip-path: path('${props.path}');
      // `
      return ''
    }
  }}

  ${(props) =>
    props.status === 'appear' && 'transform: translateY(0); opacity: 1'}
  ${(props) =>
    props.status === 'disappear' && 'transform: translateY(40px); opacity: 0'}
`

const ClickDeny = styled.div`
  top: 0;
  right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
`

const Header = styled.div<{
  headerPosition: TypeHeaderPosition | null
  type: TypePopout
}>`
  display: flex;
  padding-right: 40px;
  width: 100%;
  justify-content: ${(props) =>
    props.headerPosition === 'left'
      ? 'flex-start'
      : props.headerPosition === 'center'
      ? 'center'
      : 'flex-end'};
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`

const Cancel = styled.img`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 13px;

  transition: opacity 0.1s;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  :active {
    opacity: 0.8;
  }
`

const TabbarContainerPopout = styled(TabbarContainer)`
  position: absolute;
  top: -33px;
  left: 0px;
  width: 100%;
  overflow-x: hidden;
`

type TypePosition = 'top' | 'bottom' | 'center'
type TypePopout = 'tabs' | 'default'
export type TypeTabItem = { name: string; type: string }
type TypeHeaderPosition = 'left' | 'center' | 'right'
export type StatusType = 'appear' | 'disappear' | undefined

type PopoutProps = {
  children: ReactNode | ReactNode[]
  isFullScreen: boolean
  position?: TypePosition
  type: TypePopout
  background: boolean
  tabItems?: TypeTabItem[]
  header?: TypeTabItem[] | string
  headerPosition?: TypeHeaderPosition | null
  width?: string | undefined
  minWidth?: string | null
  maxWidth?: string | null
  activeTab?: string
  setActiveTab?: React.Dispatch<React.SetStateAction<string>>
  status: StatusType
  setStatus: (status: StatusType) => void
  navigatePath?: string
  navigateReplace?: boolean
  isClosable?: boolean
  popoutRef?: React.MutableRefObject<HTMLDivElement | null>
  close?: boolean
}

const Popout: FC<PopoutProps> = ({
  popoutRef,
  children,
  status,
  setStatus,
  isFullScreen,
  position = 'center',
  type = 'default',
  background,
  tabItems,
  header,
  headerPosition,
  width,
  maxWidth,
  minWidth,
  activeTab,
  setActiveTab,
  navigatePath,
  navigateReplace,
  isClosable = true,
  close,
}) => {
  const navigate = useNavigate()
  const [height, setHeight] = useState<number>(0)
  const mainRef = useRef<HTMLDivElement | null>(null)
  const isClosing = useRef<boolean>(false)

  let currentHeader

  if (header instanceof Array) {
    currentHeader = header?.find((item) => {
      return item.type === activeTab
    })
  } else {
    currentHeader = header
  }

  const path = getSvgPath({
    width: 284,
    height: height,
    cornerRadius: 30,
    cornerSmoothing: 1,
  })

  const onClose = () => {
    if (!isClosable) return
    if (isClosing.current) return
    if (navigatePath) {
      setStatus('disappear')
      isClosing.current = true
      setTimeout(
        () =>
          navigate(navigatePath, {
            replace: navigateReplace ? navigateReplace : true,
          }),
        POPOUT_ANIMATION_MS,
      )

      if (navigateReplace === true) navigate(-1)
    } else {
      setStatus('disappear')
      isClosing.current = true
      setTimeout(() => {
        navigate(-1)
      }, POPOUT_ANIMATION_MS)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setStatus('appear'), [])

  useEffect(() => setHeight(mainRef.current?.offsetHeight || 0), [])
  // Для правильного закрытия попаута из вне
  useEffect(() => {
    if (close) {
      onClose()
    }
  }, [close])

  return (
    <Background
      status={status}
      background={background}
      type={type}
      position={position}
      isFullScreen={isFullScreen}
      onClick={onClose}
    >
      <Main
        position={position}
        path={path}
        ref={(refer) => {
          mainRef.current = refer
          if (popoutRef) popoutRef.current = refer
        }}
        status={status}
        onClick={(e) => e.stopPropagation()}
        isFullScreen={isFullScreen}
        type={type}
        width={width}
        maxWidth={maxWidth}
        minWidth={minWidth}
      >
        {/* загородим модалку пока она исчезает чтобы избежать лишних взаимодействий */}
        {status === 'disappear' && <ClickDeny />}
        {type === 'tabs' && tabItems && tabItems.length && setActiveTab && (
          <TabbarContainerPopout>
            {tabItems.map((item: TabItem) => (
              <TabbarItem
                key={item.type}
                active={activeTab === item.type}
                onClick={() => setActiveTab(item.type)}
              >
                {item.name}
              </TabbarItem>
            ))}
          </TabbarContainerPopout>
        )}
        {currentHeader && headerPosition && (
          <Header headerPosition={headerPosition} type={type}>
            {typeof currentHeader === 'string'
              ? currentHeader
              : currentHeader?.name}
          </Header>
        )}
        {!isFullScreen && isClosable && (
          <Cancel src={cancelIcon} onClick={onClose} />
        )}
        {children}
      </Main>
    </Background>
  )
}

export default Popout
