import React from 'react'
import styled from '@emotion/styled'
import designTokens, { type ButtonSize, type Gradient } from 'src/config/tokens'
import useSquircle from 'src/hooks/useSquircle'

type ButtonColor = Exclude<Gradient, 'clothes'>
type ButtonWidth = 'auto' | 'square' | 'stretch' | `${number}%` | `${number}px`

type BaseButtonProps = {
  /**
   * Ширина кнопки
   * @default 'auto'
   */
  width?: ButtonWidth

  /**
   * Размер кнопки
   * @default 'medium'
   */
  size?: ButtonSize

  /**
   * Цвет кнопки
   * @default 'primary'
   */
  variant?: ButtonColor

  /**
   * Отключена ли кнопка
   */
  disabled?: boolean

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  iconPosition?: 'left' | 'right'

  /**
   * Иконки и текст
   */
  children: React.ReactNode
}

type ButtonProps = BaseButtonProps &
  Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    keyof BaseButtonProps
  >

type StyleButtonProps = {
  svgPath: string
} & Required<BaseButtonProps>

function shouldForwardProp(prop: string): boolean {
  return !['width', 'size', 'variant', 'svgPath'].includes(prop)
}

const StyledButton = styled('button', { shouldForwardProp })<StyleButtonProps>`
  height: ${({ size }) => designTokens.button[size].height};
  max-height: ${({ size }) => designTokens.button[size].height};
  padding: ${({ width, size }) =>
    designTokens.button[width === 'square' ? 'mini' : size].padding};
  position: relative;
  width: fit-content;

  /* Делаем кнопку квадратной */
  ${({ size, width }) =>
    width === 'square' &&
    `aspect-ratio: 1; width: ${designTokens.button[size].height}`}

  /* Растягиваем кнопку на всю ширину */
  ${({ width }) => width === 'stretch' && 'width: 100%;'}

  /* Ширина кнопки указана в процентах или пикселях */
  ${({ width }) =>
    (width.endsWith('px') || width.endsWith('%')) && `width: ${width};`}

  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: ${({ size }) => designTokens.button[size].gap};

  color: ${designTokens.color.white};
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  text-shadow: ${designTokens.shadow.text};

  /* Украшения кнопки */
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  background: transparent;
  z-index: 0;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: -1;

    // Тень кнопки
    border-radius: 10px;
    box-shadow: 0px -4px 0px 0px ${designTokens.color.black}26 inset;

    // Фон кнопки
    overflow: hidden;
    clip-path: ${({ svgPath }) => `path('${svgPath}')`};
    background: ${({ variant, disabled }) =>
      disabled
        ? designTokens.color.grey.secondary
        : designTokens.gradient[variant]};
  }

  // Цвет кнопки при наведении
  &:hover:not([disabled]) {
    &::before {
      background: ${({ variant, disabled }) =>
        disabled
          ? designTokens.color.grey.secondary
          : [designTokens.gradient.hover, designTokens.gradient[variant]].join(
              ', ',
            )};
    }
  }

  /* Анимации кнопки */
  transition: all 120ms ease;
  &:not([disabled]):active {
    scale: 0.95;
    opacity: 0.9;
  }
`

/**
 * Компонент кнопки
 * @component
 */
function Button({
  size = 'medium',
  variant = 'primary',
  width = '100%',
  disabled = false,
  iconPosition = 'left',
  children,
  ...props
}: ButtonProps): React.ReactElement {
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const svgPath = useSquircle({
    ref: buttonRef,
    cornerRadius: size === 'mini' ? 6 : 10,
  })

  return (
    <StyledButton
      width={width}
      svgPath={svgPath}
      size={size}
      ref={buttonRef}
      disabled={disabled}
      iconPosition={iconPosition}
      variant={variant}
      {...props}
    >
      {children}
    </StyledButton>
  )
}

export default Button
export type { ButtonColor, ButtonWidth }
