import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { CardScroll } from '@vkontakte/vkui'
import { makeRequest } from 'src/utils/api'

import { CustomCard } from 'components/base'
import { Popout, StatusType } from 'components/base/Popout'
import { useAppSelector } from 'store/index'
import { setBalance, setRegion } from 'store/user'

import { LOCALES } from '@gatto/locales'
import { BaseRegion } from '@gatto/shared'

import coin from '../../assets/svg/coin.svg'

const ButtonIcon = styled.div`
  display: flex;
  padding: 2px;
  column-gap: 3px;
  align-items: center;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
`

const Img = styled.img`
  width: 11px;
  height: 12px;
`

const BiomsPopout: FC = () => {
  const { t } = useTranslation()
  const [status, setStatus] = useState<StatusType>()
  const [cards, setCards] = useState<BaseRegion[]>([])
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userBalance = useAppSelector((root) => root.user.user?.balance.soft)

  useEffect(() => {
    makeRequest('regions.get', {}).then(({ regions }) => {
      setCards(regions)
    })
  }, [])

  const handleClick = async (item: BaseRegion) => {
    await makeRequest('region.purchase', {
      regionId: item._id,
      position: location.state.selectedTile,
    })
      .then((result) => {
        dispatch(setRegion(result.region))
        dispatch(setBalance(result.balance))
        navigate(-1)
      })
      .catch((e) => console.error(e))
  }

  return (
    <Popout
      header={t(LOCALES.biome)}
      headerPosition="left"
      type="default"
      background={false}
      isFullScreen={false}
      width="373px"
      position="bottom"
      status={status}
      setStatus={setStatus}
    >
      <CardScroll size={false}>
        {cards.map((item, index) => {
          return (
            <CustomCard
              id={index.toString()}
              key={index}
              title={t(`biome_${item.biome}`)}
              buttonText={t(LOCALES.buy)}
              biomeName={item.biome}
              isGrayBorder={false}
              disabled={item.price > (userBalance ?? 0)}
              buttonIcon={
                <ButtonIcon>
                  {item.price}
                  <Img src={coin}></Img>
                </ButtonIcon>
              }
              onClick={() => handleClick(item)}
            />
          )
        })}
      </CardScroll>
    </Popout>
  )
}

export default BiomsPopout
