/* eslint-disable no-console */
import * as PIXI from 'pixi.js'
import { Spine } from 'pixi-spine'
import { EngineClient } from 'src/engine/client'

import { BaseUnitEvents } from '@gatto/engine'

import {
  barriersSetupCoords,
  createFilter,
  createRect,
  destroySwimGameApp,
  getObjectSecondCoord,
  getSpineTexture,
  getTexture,
  moveAnchorPet,
  moveBackground,
  moveFilters,
  movePet,
  movePetY,
  moveTilingSprite,
  moveTrack,
  setup,
  setupBackground,
  setupBarriers,
  setupBeforeEngine,
  setupFilters,
  setupPet,
  setupPets,
  setupTrack,
  sync,
  toggleFilter,
} from '../swimgame/methods'
import { OlympicGameOption } from '../types/baseOlympicGame'
import {
  SwimGameBackgroundSprites,
  SwimGameBackgroundSpritesWidth,
} from '../types/swimgame'

import { BaseOlympicGame } from '.'

export class SwimGame extends BaseOlympicGame {
  gameContainerScale = 1
  backgroundSprites!: SwimGameBackgroundSprites
  backgroundSpritesWidth!: SwimGameBackgroundSpritesWidth

  // Трек
  waveContainer = new PIXI.Container()
  waveArray: Spine[] = []
  fullTrack: number[] = []
  currentTrack = 0
  currentTrackOffset = 0
  canDive = false

  filtersArray: PIXI.Graphics[] = []

  constructor(swimGameOptions: OlympicGameOption) {
    super(swimGameOptions)

    this.setupBeforeEngine()

    this.engine = new EngineClient(this.lobbyUrl, this.currentUserAuth)

    this.makeSubscriptions()

    this.ready()

    this.view.addEventListener?.('pointerdown', this.diveAnchorPet)
    this.view.addEventListener?.('pointerup', this.emergeAnchorPet)
  }

  // Setup
  setupBeforeEngine = setupBeforeEngine
  setupBackground = setupBackground
  setupTrack = setupTrack
  setupPet = setupPet
  setupPets = setupPets
  barriersSetupCoords = barriersSetupCoords
  setupBarriers = setupBarriers
  createRect = createRect
  createFilter = createFilter
  toggleFilter = toggleFilter
  setupFilters = setupFilters

  // Socket
  sync = sync
  setup = setup

  // Move
  moveAnchorPet = moveAnchorPet
  movePet = movePet
  moveBackground = moveBackground
  moveTrack = moveTrack
  movePetY = movePetY
  moveTilingSprite = moveTilingSprite
  moveFilters = moveFilters

  // Get Methods
  getTexture = getTexture
  getObjectSecondCoord = getObjectSecondCoord
  getSpineTexture = getSpineTexture

  //Render
  destroyApp = destroySwimGameApp

  /**
   * Погружение и всплытие
   */
  diveAnchorPet = (): void => {
    if (!this.canDive) return
    console.log('pet dive ', this.engine?.localTime())
    this.engine?.sendWebsocketEvent(BaseUnitEvents.PetDive)
  }

  emergeAnchorPet = (): void => {
    if (!this.canDive) return
    console.log('pet emerge ', this.engine?.localTime())
    this.engine?.sendWebsocketEvent(BaseUnitEvents.PetEmerge)
  }
}
