import styled from '@emotion/styled'

export const ItemDesc = styled.div`
  width: 149.114px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 375px) {
    width: 140px;
  }
`
