import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MODAL_ANIMATION_MS } from 'const/index'
import { OnboardingListNames } from 'src/config/onboardings'
import { ModalContext } from 'src/utils/ModalContext'

// Хук для открытия OnBoarding'ов
// принимает название онбординга из config/onboardings.ts
// возвращает функцию открытия OnBoarding'а

const useOnBoarding = (): ((
  sheetName: OnboardingListNames,
  redirect?: string,
  additionalParams?: string,
) => Promise<void>) => {
  const navigate = useNavigate()
  const [resolve, setResolve] = useState<() => () => void>()
  const { activeModal, updateActiveModal } = useContext(ModalContext)

  useEffect(() => {
    if (activeModal === null && resolve) {
      resolve()
      setResolve(undefined)
    }
  }, [activeModal, resolve])

  return (
    sheetName: OnboardingListNames,
    redirect?: string,
    additionalParams?: string,
  ) => {
    return new Promise((res) => {
      const queryParams = `?modal=onBoarding&sheetName=${sheetName}${
        additionalParams ?? ''
      }`

      setTimeout(() => {
        navigate(queryParams, { state: { sheetName }, replace: false })
        updateActiveModal('onboarding')
        setResolve(() => () => {
          res()
          if (redirect) {
            setTimeout(() => navigate(redirect), MODAL_ANIMATION_MS)
          } else {
            if (window.location.search !== '')
              setTimeout(() => navigate(-1), MODAL_ANIMATION_MS)
          }
        })
      }, 100)
    })
  }
}

export default useOnBoarding
