import { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import {
  Button,
  ButtonGroup,
  Footnote,
  Gallery,
  Spacing,
  Title,
  useModalRootContext,
} from '@vkontakte/vkui'
import { ModalsProps } from 'const/router'
import { OnboardingList } from 'src/config/onboardings'
import { ModalContext } from 'utils/ModalContext'

import { LOCALES } from '@gatto/locales'

import { ActionSheetSlideMedia } from './types'

const GalleryStyle = styled(Gallery)`
  .vkuiBaseGallery__bullets {
    padding-bottom: 20px;
    .vkuiBaseGallery__bullet {
      background-color: var(--vkui--color_icon_secondary);
    }
  }
`

const Main = styled.div`
  min-height: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const TextBlock = styled.div`
  padding: 17px var(--onboarding-step-horizontal-padding, 24px) 16px;
  width: 100%;
  box-sizing: border-box;
`

const ButtonsBlock = styled(ButtonGroup)`
  padding: 0 20px 45px;
  margin-top: auto;
`

const SubTitle = styled(Footnote)`
  color: var(--vkui--color_text_secondary);
`

const MediaVideoStyle = styled.video``
const MediaImageStyle = styled.img`
  width: 100%;
  min-height: 48px;
`

type OnBoardingModalProps = ModalsProps['onBoarding']

const OnBoardingModal: FC<OnBoardingModalProps> = ({
  setBackground,
  sheetName,
}) => {
  const { t } = useTranslation()
  const [page, setPage] = useState(0)
  const { updateModalHeight } = useModalRootContext()
  const { updateActiveModal } = useContext(ModalContext)

  useEffect(setBackground, [setBackground])

  const onClose = () => updateActiveModal(null)

  const onNext = () => {
    const next = page + 1
    if (next >= OnboardingList[sheetName].slides.length) return onClose()
    setPage(next)
  }

  const getMediaBlock = (media: ActionSheetSlideMedia) =>
    media.type === 'video' ? (
      <MediaVideoStyle src={media.url} onLoad={updateModalHeight} />
    ) : (
      <MediaImageStyle src={media.url} onLoad={updateModalHeight} />
    )

  return (
    <GalleryStyle bullets="dark" slideIndex={page}>
      {OnboardingList[sheetName].slides.map((slide, index) => (
        <Main key={index}>
          {getMediaBlock(slide.media)}
          <TextBlock>
            <Title level="2">{t(slide.title)}</Title>
            <Spacing size={8} />
            <SubTitle>{t(slide.subtitle)}</SubTitle>
          </TextBlock>
          <Spacing size={4} />
          <ButtonsBlock mode="vertical" gap="s" stretched align="center">
            <Button
              size="l"
              mode="primary"
              appearance="accent"
              align="center"
              stretched
              hasHover
              onClick={onNext}
            >
              {t(slide.button ?? LOCALES.default_NextButton)}
            </Button>
            <Button
              size="l"
              mode="tertiary"
              appearance="accent"
              align="center"
              stretched
              hasHover
              onClick={onClose}
            >
              {t(slide.buttonSkip ?? LOCALES.default_SkipButton)}
            </Button>
          </ButtonsBlock>
        </Main>
      ))}
    </GalleryStyle>
  )
}

export default OnBoardingModal
