import React, { FC, useState } from 'react'
import styled from '@emotion/styled'
import { Spinner } from '@vkontakte/vkui'
import errorIcon from 'src/assets/svg/popouts/promo-warning.svg'
import successIcon from 'src/assets/svg/success.svg'

import infoIcon from 'assets/svg/popouts/info-circle.svg'
import waitIcon from 'assets/svg/popouts/wait-circle.svg'
import { Header, Subheader } from 'components/base'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 47.823px;
  height: 47.823px;
  border-radius: 7px;
  background: rgba(174, 179, 177, 0.1);
`

const Image = styled.img<{
  imgLoad: boolean
}>`
  display: ${(props) => (props.imgLoad ? 'none' : 'block')};
  width: 32px;
  height: 32px;
  margin: 0 auto;
`

type StatusPopoutProps = {
  type: 'success' | 'error' | 'timeout' | 'info' | 'custom'
  header?: string
  subheader?: string
  icon?: string | React.ReactNode
  children?: React.ReactNode
  headerFontSize?: number
  headerLineHeight?: number
  subHeaderFontSize?: number
  subHeaderLineHeight?: number
}

// компонент для создания попаутов статуса с различным содержимым
// используется, когда требуется создать попаут состояния с уникальной логикой, иначе можно обойтись попаутом StatusAlert

const StatusAlertContent: FC<StatusPopoutProps> = ({
  type,
  header,
  subheader,
  icon,
  children,
  headerFontSize,
  headerLineHeight,
  subHeaderFontSize,
  subHeaderLineHeight,
}) => {
  const [loading, setLoading] = useState<boolean>(type !== 'custom')

  const getIcon = () => {
    switch (type) {
      case 'success':
        return successIcon
      case 'error':
        return errorIcon
      case 'timeout':
        return waitIcon
      case 'info':
        return infoIcon
      default:
        return icon
    }
  }

  const onLoad = () => {
    setLoading(false)
  }

  const currentIcon = getIcon()

  return (
    <Container>
      <ImageContainer>
        {typeof currentIcon === 'string' ? (
          <Image imgLoad={loading} src={currentIcon} onLoad={onLoad} />
        ) : (
          icon
        )}

        {loading && <Spinner size="medium" style={{ margin: '20px 0' }} />}
      </ImageContainer>
      {header && (
        <Header fontSize={headerFontSize} lineHeight={headerLineHeight}>
          {header}
        </Header>
      )}
      {subheader && (
        <Subheader
          fontSize={subHeaderFontSize}
          lineHeight={subHeaderLineHeight}
        >
          {subheader}
        </Subheader>
      )}
      {children}
    </Container>
  )
}

export default StatusAlertContent
