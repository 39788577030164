function getGradient(from: string, to: string) {
  return `linear-gradient(180deg, ${from} 0%, ${to} 100%)`
}

/** Дизайн-токены для
 * - Типографии (размеры и цвета)
 * - Градиентов (фоны)
 * - Кнопок (размеры)
 * - Цветов (черный, серый и белый)
 */
const designTokens = {
  typography: {
    size: {
      header: { large: '20px', medium: '20px', small: '18px' },
      body: { large: '16px', medium: '16px', small: '13px' },
      caption: '10px',
    },
    color: {
      primary: '#040921',
      secondary: '#888',
    },
  },
  gradient: {
    white: getGradient('#ffffff', '#ffffff'),
    gray: getGradient('#888', '#888'),
    translucent: getGradient('#00000033', '#00000033'),
    hover: getGradient('#0000000D', '#0000000D'),
    primary: getGradient('#4386FE', '#3A6EDF'),
    dangerous: getGradient('#FF3E32', '#FF3263'),
    warning: getGradient('#FFAA45', '#FF7A33'),
    success: [
      getGradient('#249C4DB0', '#249C4DB0'),
      getGradient('#32FF6B', '#32FF5F'),
    ].join(', '),
    legendary: getGradient('#ffEA2F', '#00F0FF'),
    viol: getGradient('#9969FF', '#7432FF'),
    skyBlue: getGradient('#0DB2FF', '#0B98F9'),
    clothes: {
      without: getGradient('#F7F7F7', '#E6E6E6'),
      common: getGradient('#ECF0FF', '#C4CEF6'),
      rare: getGradient('#FFF9EE', '#FFEECE'),
      mythical: getGradient('#F1EBFF', '#D7C3FF'),
      legendary: getGradient('#FFEA2F', '#00F0FF'),
    },
  },
  button: {
    huge: {
      gap: '4px',
      height: '57px',
      padding: '14px 14px 17px 14px',
    },
    large: {
      gap: '4px',
      height: '50px',
      padding: '14px 14px 17px 14px',
    },
    medium: {
      gap: '4px',
      height: '42px',
      padding: '14px 14px 17px 14px',
    },
    small: {
      gap: '5px',
      height: '37px',
      padding: '10px 14px 13px 14px',
    },
    mini: {
      gap: '7px',
      height: '28px',
      padding: '14px 8px 17px 8px',
    },
  },
  color: {
    black: '#040921',
    white: '#FFF',
    grey: {
      primary: '#888',
      secondary: '#CCC',
    },
  },
  shadow: {
    text: '0px 1.33px 0px #27224059',
    block: '0px -4px 0px 0px #00000026 inset',
  },
} as const

type ButtonSize = keyof typeof designTokens.button
type Gradient = keyof typeof designTokens.gradient
type TypographySize = keyof typeof designTokens.typography.size.header

export default designTokens
export type { ButtonSize, Gradient, TypographySize }
