import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { API_URL } from 'src/config/constants'
import { Popouts } from 'src/const/router'

import { Popout, StatusType } from 'components/base/Popout'

import { LOCALES } from '@gatto/locales'

import { SkinBackground, SkinImg, SkinRarity } from '../Skins/SkinsPopout'

import StorageContent from './StorageContent'

type SkinPopoutProps = Popouts['skin']

const SkinPopout: FC<SkinPopoutProps> = ({
  name,
  rarity,
  description,
  id,
  image,
}) => {
  const { t } = useTranslation()
  const [status, setStatus] = useState<StatusType>()
  const [isBack, setIsBack] = useState(false)

  const items = [{ title: t(LOCALES.type), value: t(LOCALES[rarity]) }]

  const navigate = useNavigate()

  useEffect(() => {
    const handlePopstate = () => {
      navigate('/storage', { replace: true })
      setIsBack(true)
    }

    window.addEventListener('popstate', handlePopstate)

    return () => {
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [])

  const imageComponent = () => (
    <SkinBackground rarity={rarity}>
      <SkinImg src={`${API_URL}${image}`} />
      <SkinRarity rarity={rarity} />
    </SkinBackground>
  )

  return (
    <Popout
      type="default"
      background={true}
      width="315px"
      isFullScreen={false}
      status={status}
      setStatus={setStatus}
      navigatePath={isBack ? '/storage' : undefined}
    >
      <StorageContent
        image={imageComponent}
        header={name}
        subheader={description}
        items={items}
      />
    </Popout>
  )
}

export default SkinPopout
