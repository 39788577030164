export const TRACK_WIDTH = 800
export const TRACK_HEIGHT = 350
export const TRACKS_IN_CONTAINER = 5
export const CONTAINER_SCALE_COEF = 0.6

export const START_LINE_X = 118
export const FINISH_LINE_X = 590

export const MAX_TIMEOUT = 30000

export const SHOULD_BE_ANCHOR_PET_ROW = 3

export const COUNTDOWN_TIME = 3_000

export type PET_ANIMATION = 'idle' | 'run' | 'swim' | ''

export enum CountdownStatus {
  Hidden = 'hidden',
  Wait = 'wait',
  Count = 'count',
}
