export const GAP = 22
export const MIN_STAGE_WIDTH = 600
export const BASE_TILE_WIDTH = 800
export const BASE_TILE_HEIGHT = 700
export const BASE_LARGE_TILE_WIDTH = 1500
export const BASE_LARGE_TILE_HEIGHT = 1800
export const BASE_TILE_GAP_X = 18 - GAP
export const BASE_TILE_GAP_Y = 84 - GAP
export const BASE_LARGE_TILE_GAP_X = 391 - GAP
export const BASE_LARGE_TILE_GAP_Y = 651 - GAP
export const BASE_LARGE_TILE_OFFSET_X = 376
export const BASE_LARGE_TILE_OFFSET_Y = 512
export const STAGE_WIDTH_MULTIPLIER = 0.75
export const TILE_UP_HEIGTH = 10
