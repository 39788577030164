import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import bridge from '@vkontakte/vk-bridge'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localeData from 'dayjs/plugin/localeData'
import updateLocale from 'dayjs/plugin/updateLocale'
import weekday from 'dayjs/plugin/weekday'

import { AppSuspense } from 'components/App'

import { Platform } from '@gatto/shared'

import 'src/components/i18n'

import store from './store'

import '@vkontakte/vkui/dist/vkui.css'
import './style/fonts.css'
import './style/normalize.css'
import './style/vars.css'
import React from 'react'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(weekday)
dayjs.extend(updateLocale)
dayjs.extend(localeData)
dayjs.updateLocale('ru', {
  weekStart: 1,
})
dayjs.locale('ru')

const App = React.lazy(() => import('components/App/App'))
export let MINI_APP: Platform = Platform.TG

bridge.send('VKWebAppInit').then((data) => {
  if (data.result) {
    MINI_APP = Platform.VK
  }
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <Router>
      <AppSuspense>
        <App />
      </AppSuspense>
    </Router>
  </Provider>,
)
