import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import bridge, {
  BannerAdLayoutType,
  BannerAdLocation,
} from '@vkontakte/vk-bridge'
import { Spacing } from '@vkontakte/vkui'
import { useCloudStorage } from '@vkruglikov/react-telegram-web-app'

import { BackButton, Wallet } from 'components/base'
import {
  ImageContainer,
  Page,
  PageContainer,
  PageHeader,
  TileCard,
} from 'components/page'
import { HeaderText } from 'components/typography'
import { useAppDispatch } from 'store'

import { LOCALES } from '@gatto/locales'
import { GameType, Platform } from '@gatto/shared'

import Toggle from '../../components/Toggle'
import {
  REWARDS_SPRITESHEET_PATH,
  RUNGAME_SPRITESHEET_PATH,
  SWIGAME_ASSETS,
} from '../../config/assets'
import { API_URL, APP_LANG } from '../../config/constants'
import { usePopout } from '../../hooks'
import { MINI_APP } from '../../index'

const ToggleContainer = styled.div`
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  width: 342px;
  z-index: 10;
`

const StadiumPage: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const openLoadingPopout = usePopout('loading', false)
  const { getItems, setItem } = useCloudStorage()

  const tabbarItems = [
    {
      title: t(LOCALES.games),
      path: '/stadium',
    },
    {
      title: t(LOCALES.leagues),
      path: '/leagues',
    },
  ]

  const news = [
    {
      id: 0,
      title: t(LOCALES.playWithFriends),
      imageUrl:
        APP_LANG === 'ru'
          ? `https://frontend.gattogame.site/uploads/stadium/lobby-new.png`
          : `https://frontend.gattogame.site/uploads/stadium/lobby_eng.png`,
      callback: () => {
        openLoadingPopout({
          assets: [RUNGAME_SPRITESHEET_PATH, REWARDS_SPRITESHEET_PATH],
          redirect: '/lobby',
        })
      },
      grid: {
        row: 1,
        column: 1,
        width: 2,
        height: 1,
      },
    },
    {
      id: 1,
      title: t(LOCALES.swim),
      imageUrl:
        APP_LANG === 'ru'
          ? `https://frontend.gattogame.site/uploads/stadium/swim.png`
          : `https://frontend.gattogame.site/uploads/stadium/swim_eng.png`,
      callback: () => {
        openLoadingPopout({
          assets: SWIGAME_ASSETS,
          redirect: '/selectPet',
          state: { headerText: t(LOCALES.swim), gameType: GameType.Swim },
        })
      },
      grid: {
        row: 2,
        column: 1,
        width: 1,
        height: 2,
      },
    },
    {
      id: 2,
      title: t(LOCALES.race),
      imageUrl:
        APP_LANG === 'ru'
          ? `https://frontend.gattogame.site/uploads/stadium/run.png`
          : `https://frontend.gattogame.site/uploads/stadium/run_eng.png`,
      callback: () => {
        openLoadingPopout({
          assets: [RUNGAME_SPRITESHEET_PATH, REWARDS_SPRITESHEET_PATH],
          redirect: '/selectPet',
          state: { headerText: t(LOCALES.race), gameType: GameType.Race },
        })
      },
      grid: {
        row: 2,
        column: 2,
        width: 1,
        height: 2,
      },
    },
  ]

  useEffect(() => {
    if (MINI_APP === Platform.VK) {
      bridge.send('VKWebAppShowBannerAd', {
        banner_location: BannerAdLocation.BOTTOM,
        layout_type: BannerAdLayoutType.OVERLAY,
      })
    }
  }, [])

  return (
    <PageContainer>
      <PageHeader over={<Wallet mode="horizontal" />} before={<BackButton />}>
        <HeaderText>{t(LOCALES.onboarding_shop_3_title)}</HeaderText>
      </PageHeader>
      {/* <Placeholder
        title="Стадион скоро появится"
        subtitle="Достраиваем трибуны"
        img={worldMapPlaceholderIcon}
        buttonText={t(LOCALES.details)}
        isFullscreen={true}
      /> */}
      <ImageContainer>
        {news.map((item) => {
          const style = {
            gridArea: `${item.grid.row} / ${item.grid.column} / span ${item.grid.height} / span ${item.grid.width}`,
            gridColumn: `span ${item.grid.width}`,
            gridRow: `span ${item.grid.height}`,
            background: `url(${item.imageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }

          return (
            <TileCard
              style={style}
              // onClick={() => item.callback()}
              onClick={item.callback}
              key={item.id}
            />
          )
        })}
      </ImageContainer>
      <Spacing size={90} />
      <ToggleContainer>
        <Toggle items={tabbarItems} />
      </ToggleContainer>
    </PageContainer>
  )
}

export default StadiumPage
