import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'

import Arrow from 'assets/svg/arrowLeft.svg'

const Button = styled.img`
  width: 30px;
  min-height: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
`

interface BackButtonProps {
  onClick?: () => void
}

const BackButton: FC<BackButtonProps> = ({ onClick }) => {
  const navigate = useNavigate()
  Button.defaultProps = {
    src: Arrow,
  }
  return <Button onClick={onClick ? onClick : () => navigate(-1)} />
}

export default BackButton
