import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { Container } from '@pixi/react'
import bridge, { EAdsFormats } from '@vkontakte/vk-bridge'
import { Spinner } from '@vkontakte/vkui'
import axios from 'axios'
import { Types as MongooseTypes } from 'mongoose'
import * as PIXI from 'pixi.js'
import { Spritesheet } from 'pixi.js'
import { MINI_APP } from 'src'
import {
  BIOM_BACKGROUND_SPRITESHEET_PATH,
  OBJECTS_SPRITESHEET_PATH,
} from 'src/config/assets'
import { usePopout } from 'src/hooks'
import useLoadAsset from 'src/hooks/useLoadAsset'
import useScreenDimensions from 'src/hooks/useScreenDimensions'
import { makeRequest } from 'src/utils/api'

import { CloudsBackground } from 'components/Background'
import BiomDistrictBackground from 'components/Background/Cloud/CloudsBackground'
import { ResizableStage } from 'components/pixi'
import { RootState, useAppDispatch, useAppSelector } from 'store'
import { clearRegionState, fetchRegion } from 'store/region'
import { incrementBalance } from 'store/user'

import { PetStatus, Platform, PopulatedPet, PrizeResult } from '@gatto/shared'

import { BackgroundImage } from './BackgroundImage'
import RegionEgg from './RegionEgg'
import RegionEggEntity from './RegionEggEntity'
import RegionEmpty from './RegionEmpty'
import RegionPet from './RegionPet'
import RegionPetEntity from './RegionPetEntity'

const Root = styled.div<{ type: 'default' | 'halloween' }>`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: ${(prop) =>
    prop.type === 'default'
      ? 'linear-gradient(360deg, rgba(255,255,255,1) 0%,  rgba(0,232,255,0.8855917366946778) 100%)'
      : 'linear-gradient(180deg, rgba(176,41,132,1) 0%, rgba(246,100,13,1) 35%, rgba(253,217,196,1) 100%)'};
`

export const RegionPage: React.FC = () => {
  const dispatch = useAppDispatch()
  // Можем быть уверены, что regionId есть, так как это страница региона
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const regionId = useParams().regionId! as unknown as MongooseTypes.ObjectId
  const [hatchedPet, setHatchedPet] = useState<PopulatedPet | null>(null)
  const [isEggLoading, setIsEggLoading] = useState(false)
  const [isPetLevelShown, setIsPetLevelShown] = useState(true)
  const [prize, setPrize] = useState<PrizeResult | null>(null)
  const [isPrizeReady, setIsPrizeReady] = useState(false)

  const screenDimensions = useScreenDimensions()

  const region =
    useAppSelector((state: RootState) => state.region).data ?? undefined

  const baseRegion = region?.baseRegion

  const pet = region?.pet
  const egg = region?.egg

  const updateRegion = useCallback(() => {
    if (!regionId) return
    dispatch(fetchRegion(regionId))
  }, [dispatch, regionId])

  const petNamePopout = usePopout('petName', false, {
    regionId: region?._id.toString(),
  })

  let page: 'egg' | 'pet' = 'egg'

  if (pet && pet.status === PetStatus.Available) {
    page = 'pet'
  }

  const objectsSpritesheet = useLoadAsset<PIXI.Spritesheet>(
    OBJECTS_SPRITESHEET_PATH,
  )
  const backgroundSpritesheet = useLoadAsset<PIXI.Spritesheet>(
    BIOM_BACKGROUND_SPRITESHEET_PATH,
  )

  const bgTexture =
    baseRegion?.biome &&
    backgroundSpritesheet?.textures[`biome_${baseRegion?.biome}.png`]

  const showAds = async () => {
    try {
      const response = await bridge.send('VKWebAppShowNativeAds', {
        ad_format: EAdsFormats.REWARD,
      })
      return response.result
    } catch (err) {
      return false
    }
  }

  const handleEntityClick = () => {
    setIsPetLevelShown((prev) => !prev)
  }

  useEffect(() => {
    updateRegion()

    return () => {
      dispatch(clearRegionState())
    }
  }, [dispatch, updateRegion])

  useLayoutEffect(() => {
    if (region?._id.toString() !== String(regionId)) return
    if (pet?.name !== ' ') return

    if (!location.search.startsWith('?popout=petName')) {
      petNamePopout({ pet })
    }
  }, [pet, petNamePopout, region?._id, regionId])

  const handleOpenEgg = async () => {
    if (!egg || isEggLoading) return
    setIsEggLoading(true)
    try {
      const { pet, incrementUserBalance } = await makeRequest('egg.hatch', {
        id: egg._id.toString(),
      })
      if (incrementUserBalance) {
        if (MINI_APP === Platform.VK) {
          dispatch(incrementBalance({ hard: incrementUserBalance }))
        }

        if (MINI_APP === Platform.TG) {
          dispatch(incrementBalance({ soft: incrementUserBalance }))
        }
      }
      setHatchedPet(pet)
      setIsEggLoading(false)
    } catch (e) {
      setIsEggLoading(false)
      if (e instanceof axios.AxiosError) {
        if (!regionId) return
        dispatch(fetchRegion(regionId))
      }
    }
  }

  const renderContent = () => {
    if (
      !bgTexture ||
      !objectsSpritesheet ||
      !backgroundSpritesheet ||
      !region
    ) {
      return (
        <Root type="default">
          <Spinner size="medium" />
        </Root>
      )
    }

    let content: JSX.Element | undefined = undefined
    let entity: JSX.Element | undefined = undefined

    if (!region.egg && !region.pet) {
      content = <RegionEmpty region={region} />
      entity = undefined
    }

    if (page === 'egg' && egg) {
      content = (
        <RegionEgg
          egg={egg}
          showAds={showAds}
          region={region}
          handleOpenEgg={handleOpenEgg}
          hatchedPet={hatchedPet}
        />
      )
      entity = (
        <RegionEggEntity
          egg={egg}
          handleOpenEgg={handleOpenEgg}
          hatchedPet={hatchedPet}
          setHatchedPet={setHatchedPet}
          petNamePopout={petNamePopout}
        />
      )
    }

    if (page === 'pet' && pet) {
      if (!prize) {
        content = (
          <RegionPet
            pet={pet}
            bgTexture={bgTexture}
            showAds={showAds}
            region={region}
            regionId={regionId}
            isPetLevelShown={isPetLevelShown}
            prize={prize}
            setPrize={setPrize}
            setIsPrizeReady={setIsPrizeReady}
          />
        )
      }
      entity = (
        <RegionPetEntity
          basePet={pet.basePet}
          handleEntityClick={handleEntityClick}
          prize={prize}
          setPrize={setPrize}
          isPrizeReady={isPrizeReady}
        />
      )
    }

    return (
      <Root type="default">
        {content}
        <ResizableStage>
          <Container sortableChildren>
            <BackgroundImage texture={bgTexture} />
            {screenDimensions.width > 450 ? (
              <BiomDistrictBackground type="default" />
            ) : (
              ''
            )}
            {entity}
          </Container>
        </ResizableStage>
      </Root>
    )
  }

  return renderContent()
}
