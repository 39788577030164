import React, { FC } from 'react'
import { _ReactPixi, Sprite } from '@pixi/react'
import * as PIXI from 'pixi.js'

import { useSqueezeAnimation } from './useSqueezeAnimation'

type EntityEggProps = Omit<_ReactPixi.ISprite, 'scale'> & {
  getRef?: React.RefObject<PIXI.Sprite>
  squeeze?: boolean
  scale?: number
}

/**
 * Компонент сущности яйца
 * @component
 */
export const EntityEgg: FC<EntityEggProps> = (props) => {
  const { getRef, scale = 1, squeeze = true, ...rest } = props

  const { squeezeAnimation } = useSqueezeAnimation()

  return (
    <Sprite
      ref={getRef}
      anchor={[0.5, 1]}
      scale={
        squeeze ? [scale / squeezeAnimation, scale * squeezeAnimation] : scale
      }
      {...rest}
    />
  )
}
