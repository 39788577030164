import styled from '@emotion/styled'

const PageTitle = styled.h1`
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  padding-top: 17px;
  user-select: none;
`

export default PageTitle
