import { useState } from 'react'
import { useTick } from '@pixi/react'
import { SpringValue, useSpringValue } from '@react-spring/web'

type useSqueezeAnimation = () => {
  squeezeAnimation: number
  squeezeAnimationController: SpringValue<number>
}

export const useSqueezeAnimation: useSqueezeAnimation = () => {
  const squeezeAnimationController = useSpringValue(1, {
    config: { mass: 1, tension: 20000, friction: 1000 },
  })
  const [squeezeAnimation, setSqueezeAnimationValue] = useState(
    squeezeAnimationController.get(),
  )

  useTick(() => {
    setSqueezeAnimationValue(squeezeAnimationController.get())
  })

  return { squeezeAnimation, squeezeAnimationController }
}
