import React, { FC } from 'react'
import styled from '@emotion/styled'

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 10px;
  width: 100%;
  border-radius: 13px;
  background: rgba(0, 0, 0, 0.02);
`

const Block = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 31px;
`

const Wrapper = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
`

const Icon = styled.img<{ type: boolean; size?: number }>`
  width: ${(props) => (props.type ? `35px` : '15px')};
  height: ${(props) => (props.type ? `35px` : '15px')};
  border-radius: ${(props) => (props.type ? '10px' : '0px')};
`

const Title = styled.div`
  font-family: Nunito;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 11.103px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  //width: 145px;
  height: 100%;
  align-items: center;
  text-align: start;
  padding-top: 10px;
`

const Value = styled.div`
  font-family: Nunito;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 11.103px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.05);
  padding: 10px;
`

type InfoBlockProps = {
  items: Array<{
    icon?: string
    title?: string
    value?: string | number
  }>
  type: boolean
}

export const InfoBlocks: FC<InfoBlockProps> = ({ items, type }) => {
  return (
    <InfoWrapper>
      {items.map((items, index) => (
        <Block key={index}>
          <Wrapper>
            {items.icon && <Icon src={items.icon} type={type} />}
            {items.title && <Title>{items.title}</Title>}
          </Wrapper>
          {items.value && <Value>{items.value}</Value>}
        </Block>
      ))}
    </InfoWrapper>
  )
}
