/* eslint-disable no-console */
import { RunGame } from 'src/pixi/classes'

/**
 * Очищает память при удалении контейнера
 */
export function destroyRunGameApp(this: RunGame): void {
  this.view.removeEventListener?.('pointerdown', this.jumpAnchorPet)
  this.destroy()
}
