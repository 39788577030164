import React, { FC } from 'react'
import { Popouts } from 'const/router'
import { nowServerTime } from 'utils/nowServerTime'

import { PopulatedEgg, PopulatedPet } from '@gatto/shared'

import EggHatchedEntity from './Egg/EggHatchedEntity'
import EggHatchingEntity from './Egg/EggHatchingEntity'

type RegionEggEntityProps = {
  egg: PopulatedEgg
  handleOpenEgg: () => Promise<void>
  setHatchedPet: React.Dispatch<React.SetStateAction<PopulatedPet | null>>
  hatchedPet: PopulatedPet | null
  petNamePopout: (restProps?: Popouts['petName'] | undefined) => void
}

const RegionEggEntity: FC<RegionEggEntityProps> = ({
  egg,
  handleOpenEgg,
  hatchedPet,
  setHatchedPet,
  petNamePopout,
}) => {
  const isPlaced = Boolean(egg?.hatching)
  const isHatched = Boolean(
    egg?.hatching?.isHatched ||
      (egg?.hatching?.hatchingTimeout &&
        nowServerTime() >= egg?.hatching?.hatchingTimeout),
  )

  if (egg.hatching && !isHatched && isPlaced) {
    return <EggHatchingEntity egg={egg} />
  }

  if (isHatched) {
    return (
      <EggHatchedEntity
        egg={egg}
        handleOpenEgg={handleOpenEgg}
        hatchedPet={hatchedPet}
        petNamePopout={petNamePopout}
        setHatchedPet={setHatchedPet}
      />
    )
  }

  return null
}

export default RegionEggEntity
