import styled from '@emotion/styled'

import DotsBackgroundPath from 'assets/backgrounds/dots-background.png'

/** Фон с точками */
const DotsBackground = styled.div`
  width: 100%;
  height: 100%;

  background-image: url('${DotsBackgroundPath}');
  background-repeat: repeat;
  background-size: 15%;
`

export default DotsBackground
