import React, { FC, HTMLAttributes, ReactNode } from 'react'
import styled from '@emotion/styled'
import { Card } from '@vkontakte/vkui'

const Wrapper = styled(Card)<{
  isSelected: boolean
  withRadial?: boolean
  width?: string
  height?: string
}>`
  position: relative;
  display: flex;
  width: 113.667px;
  padding: 15px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-self: center;
  flex-shrink: 0;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.15);
  ${(props) =>
    props.isSelected &&
    `background: ${
      props.withRadial
        ? 'radial-gradient(99.45% 117.33% at 81.12% 13.99%, rgba(41, 187, 85, 0.20) 0%, rgba(41, 187, 84, 000) 65.33%),'
        : ''
    } linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), rgba(0, 0, 0, 0.05); border: 2px solid rgba(255, 255, 255, 0.20);`}
  cursor: pointer;
  margin-top: 10px;
  ${(props) => props.height && `height: ${props.height};`}
  ${(props) => props.width && `width: ${props.width};`}
  margin-bottom: 5px;
`

interface SelectCardProps extends HTMLAttributes<HTMLDivElement> {
  isSelected: boolean
  withRadial?: boolean
  children: ReactNode
  width?: string
  height?: string
}

const SelectCard: FC<SelectCardProps> = ({
  isSelected,
  withRadial,
  children,
  width,
  height,
  ...props
}) => {
  return (
    <Wrapper
      isSelected={isSelected}
      width={width}
      height={height}
      withRadial={withRadial}
      {...props}
    >
      {children}
    </Wrapper>
  )
}

export default SelectCard
