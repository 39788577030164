/* eslint-disable no-console */
import * as PIXI from 'pixi.js'
import { EngineClient } from 'src/engine/client'

import { BaseUnitEvents } from '@gatto/engine'

import {
  addTrack,
  barriersSetupCoords,
  destroyRunGameApp,
  getCurrentTrack,
  getCurrentTrackName,
  getObjectSecondCoord,
  getTexture,
  getTrackNameByNum,
  getTrackNameByX,
  getTrackSpriteByNum,
  moveAnchorPet,
  moveBackground,
  movePet,
  movePetY,
  moveTrack,
  setup,
  setupBackground,
  setupBarriers,
  setupBeforeEngine,
  setupPet,
  setupPets,
  setupTrack,
  sync,
} from '../rungame/methods'
import { OlympicGameOption } from '../types/baseOlympicGame'
import {
  RunGameBackgroundSprites,
  RunGameBackgroundSpritesWidth,
  RunGameTracksTextures,
} from '../types/rungame'

import { BaseOlympicGame } from '.'

export class RunGame extends BaseOlympicGame {
  backgroundSprites!: RunGameBackgroundSprites
  backgroundSpritesWidth!: RunGameBackgroundSpritesWidth

  // Трек
  trackContainer = new PIXI.Container()
  trackSprites: RunGameTracksTextures = {}
  fullTrack: number[] = []
  currentTrack = 0
  currentTrackOffset = 0

  constructor(runGameOptions: OlympicGameOption) {
    super(runGameOptions)
    this.setupBeforeEngine()

    this.engine = new EngineClient(this.lobbyUrl, this.currentUserAuth)

    this.makeSubscriptions()

    this.ready()

    this.view.addEventListener?.('pointerdown', this.jumpAnchorPet)
  }

  // Setup
  setupBeforeEngine = setupBeforeEngine
  setupBackground = setupBackground
  setupTrack = setupTrack
  setupPet = setupPet
  setupPets = setupPets
  barriersSetupCoords = barriersSetupCoords
  setupBarriers = setupBarriers

  // Socket
  sync = sync
  setup = setup

  // Move
  addTrack = addTrack
  moveAnchorPet = moveAnchorPet
  movePet = movePet
  movePetY = movePetY
  moveBackground = moveBackground
  moveTrack = moveTrack

  // Get Methods
  getTexture = getTexture
  getCurrentTrack = getCurrentTrack
  getCurrentTrackName = getCurrentTrackName
  getObjectSecondCoord = getObjectSecondCoord
  getTrackSpriteByNum = getTrackSpriteByNum
  getTrackNameByNum = getTrackNameByNum
  getTrackNameByX = getTrackNameByX

  //Render
  destroyApp = destroyRunGameApp

  /**
   * Прыжок anchor питомца
   */
  jumpAnchorPet = (): void => {
    this.engine?.sendWebsocketEvent(BaseUnitEvents.PetJump)
  }
}
