/**
 * Для чистоты и более лучшей читаемости компонента некоторый функционал перенесен в функции
 */

import { RarityBackgroundColors } from 'popouts/Skins/SkinsPopout'

import {
  EssenceType,
  PetRarity,
  PrizeItemType,
  SkinRarity,
} from '@gatto/shared'

const rarityBackgroundColors = {
  normal:
    'linear-gradient(180deg, #4386FE 0%, #3A6EDF 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.39), rgba(255, 255, 255, 0.39));',
  rare: 'linear-gradient(180deg, #FFAA45 0%, #FF7A33 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.39), rgba(255, 255, 255, 0.39));',
  mif: 'linear-gradient(180deg, #9969FF 0%, #7432FF 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.39), rgba(255, 255, 255, 0.39));',
  legendary:
    'linear-gradient(207.87deg, #FFEA2F 12.77%, #FA00FF 47.94%, #00F0FF 100%), ' +
    'linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), ' +
    'radial-gradient(93.84% 93.84% at 58.55% 7.95%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%),' +
    'linear-gradient(0deg, rgba(255, 255, 255, 0.39), rgba(255, 255, 255, 0.39));',
}

/**
 * essenceBackgroundColors и essenceBackgroundColorsPercent используются как раритет для эссенций,
 * т.к. раритеты у эссенций имеют другие названия в отличии от skins/eggs
 */

const essenceBackgroundColors = {
  [EssenceType.Small]:
    'linear-gradient(180deg, rgba(68, 104, 255, 0.10) 0%, rgba(59, 91, 224, 0.30) 100%), linear-gradient(0deg, #FFF 0%, #FFF 100%), #D9D9D9;',
  [EssenceType.Tiny]:
    'linear-gradient(180deg, rgba(68, 104, 255, 0.10) 0%, rgba(59, 91, 224, 0.30) 100%), linear-gradient(0deg, #FFF 0%, #FFF 100%), #D9D9D9;',
  [EssenceType.Big]:
    'linear-gradient(180deg, rgba(255, 198, 91, 0.10) 0%, rgba(255, 198, 91, 0.30) 100%), linear-gradient(0deg, #FFF 0%, #FFF 100%), #D9D9D9;',
  [EssenceType.Medium]:
    'linear-gradient(180deg, rgba(120, 56, 255, 0.10) 0%, rgba(120, 56, 255, 0.30) 100%), linear-gradient(0deg, #FFF 0%, #FFF 100%), #D9D9D9;',
  [EssenceType.Large]:
    'linear-gradient(207.87deg, #FFEA2F 12.77%, #FA00FF 47.94%, #00F0FF 100%), ' +
    'linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), ' +
    'radial-gradient(93.84% 93.84% at 58.55% 7.95%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%),' +
    'linear-gradient(0deg, rgba(255, 255, 255, 0.39), rgba(255, 255, 255, 0.39));',
}

const essenceBackgroundColorsPercent = {
  [EssenceType.Small]:
    'linear-gradient(180deg, #4386FE 0%, #3A6EDF 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.39), rgba(255, 255, 255, 0.39));',
  [EssenceType.Tiny]:
    'linear-gradient(180deg, #4386FE 0%, #3A6EDF 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.39), rgba(255, 255, 255, 0.39));',
  [EssenceType.Big]:
    'linear-gradient(180deg, #FFAA45 0%, #FF7A33 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.39), rgba(255, 255, 255, 0.39));',
  [EssenceType.Medium]:
    'linear-gradient(180deg, #9969FF 0%, #7432FF 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.39), rgba(255, 255, 255, 0.39));',
  [EssenceType.Large]:
    'linear-gradient(207.87deg, #FFEA2F 12.77%, #FA00FF 47.94%, #00F0FF 100%), ' +
    'linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), ' +
    'radial-gradient(93.84% 93.84% at 58.55% 7.95%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%),' +
    'linear-gradient(0deg, rgba(255, 255, 255, 0.39), rgba(255, 255, 255, 0.39));',
}

type Count = { min: number; max: number } | undefined

/**
 * Возвращает цвет заднего фона в зависимости от раритета
 */
export const getItemBackground = (
  rarity?: PetRarity | SkinRarity | EssenceType | undefined,
  type?: PrizeItemType,
): string => {
  if (type) {
    // FIXME: разобраться с градиентом для монеток
    if (type === PrizeItemType.Soft && !rarity) {
      return 'linear-gradient(180deg, rgba(13, 178, 255, 0.1) 0%, rgba(11, 152, 249, 0.1) 100%);\n'
    }

    if (type === PrizeItemType.Hard) {
      return 'linear-gradient(180deg, rgba(13, 178, 255, 0.1) 0%, rgba(11, 152, 249, 0.1) 100%)'
    }
  }

  if (!rarity) {
    return 'gray'
  }

  if (rarity in RarityBackgroundColors) {
    const color =
      RarityBackgroundColors[rarity as keyof typeof RarityBackgroundColors]

    // В RarityBackgroundColors значение в виде 'background: "цвет"', но нужно только значение
    return color.replace(/background:\s*/g, '')
  }

  if (rarity in essenceBackgroundColors) {
    return essenceBackgroundColors[
      rarity as keyof typeof essenceBackgroundColors
    ]
  }

  return 'gray'
}

/**
 * Возвращает цвет заднего фона для блока с процентами (шанс выпадения айтема)
 */
export const getPercentBackground = (
  rarity?: PetRarity | SkinRarity | EssenceType | undefined,
  type?: PrizeItemType,
): string => {
  if (type) {
    // FIXME: разобраться с градиентом для монеток
    if (type === PrizeItemType.Soft) {
      return 'linear-gradient(180deg, rgba(13, 178, 255, 0.1) 0%, rgba(11, 152, 249, 0.1) 100%);'
    }

    if (type === PrizeItemType.Hard) {
      return 'linear-gradient(180deg, rgba(13, 178, 255, 0.1) 0%, rgba(11, 152, 249, 0.1) 100%)'
    }
  }

  if (!rarity) {
    return 'gray'
  }

  if (rarity in rarityBackgroundColors) {
    return rarityBackgroundColors[rarity as keyof typeof rarityBackgroundColors]
  }

  if (rarity in essenceBackgroundColorsPercent) {
    return essenceBackgroundColorsPercent[
      rarity as keyof typeof essenceBackgroundColorsPercent
    ]
  }

  return 'gray'
}
