import Tile from '../types/Tile'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function debounce(f: (tile: Tile) => void | undefined, ms: number) {
  let isCooldown = false

  return function () {
    if (isCooldown) return

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    f.apply(this, arguments)

    isCooldown = true

    setTimeout(() => (isCooldown = false), ms)
  }
}

export default debounce
