import React, { FC } from 'react'
import styled from '@emotion/styled'

const FirstNumber = styled.span({
  color: '#FFF',
  textAlign: 'center',
  fontSize: '16px',
})

const Level = styled(FirstNumber)({
  fontSize: '28px',
  lineHeight: '23px',
})

const SecondNumber = styled.span({
  fontSize: '10px',
  fontWeight: 700,
  lineHeight: 'normal',
})

interface Props {
  level?: string | number
}

const PetLevelRatio: FC<Props> = ({ level }) => {
  if (!level) {
    return null
  }

  if (typeof level === 'number') {
    return <Level>{level}</Level>
  }

  const [firstPart, secondPart] = level.split('/')
  return (
    <>
      <FirstNumber>{firstPart}</FirstNumber>
      {firstPart !== 'MAX' && <SecondNumber>/{secondPart}</SecondNumber>}
    </>
  )
}

export default PetLevelRatio
