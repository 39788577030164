import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const Item = styled.div<{
  isActive: boolean
  position: 'first' | 'last' | undefined
}>`
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  height: 60px;
  padding: 14px 14px 17px 14px;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.position === 'first'
      ? 'border-radius: 10px 0px 0px 10px;'
      : props.position === 'last'
      ? 'border-radius: 0px 10px 10px 0px;'
      : ''}

  background: ${(props) =>
    props.isActive
      ? 'var(--gradient-success, linear-gradient(0deg, rgba(36, 156, 77, 0.69) 0%, rgba(36, 156, 77, 0.69) 100%), linear-gradient(180deg, #32FF6B 0%, #32FF5F 100%))'
      : '#8d98e3'};
  box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.1) inset;
  color: var(--white-white-100, #fff);
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: 'clig' off, 'liga' off;
  text-shadow: 0px 1.3274139165878296px 0px rgba(39, 34, 64, 0.35);

  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
  margin: -1px;
`

type TabbarProps = {
  items: Array<{ title: string; path: string }>
}

const Toggle: FC<TabbarProps> = ({ items }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Wrapper>
      {items.map(({ title, path }, index) => {
        let position: 'first' | 'last' | undefined

        if (index === 0) {
          position = 'first'
        }

        if (index === items.length - 1) {
          position = 'last'
        }

        return (
          <Item
            key={index}
            isActive={location.pathname === path}
            position={position}
            onClick={() => navigate(path, { replace: true })}
          >
            {t(title)}
          </Item>
        )
      })}
    </Wrapper>
  )
}

export default Toggle
