import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { combineReducers, configureStore } from '@reduxjs/toolkit'

import onboardingReducer from './onboarding'
import paymentReducer from './payment'
import regionReducer from './region'
import selectPetReducer from './selectPet'
import serverReducer from './server'
import storageReducer from './storage'
import userReducer from './user'
import waitLobbyReducer from './waitLobby'

const rootReducer = combineReducers({
  region: regionReducer,
  user: userReducer,
  server: serverReducer,
  storage: storageReducer,
  selectPet: selectPetReducer,
  onboarding: onboardingReducer,
  waitLobby: waitLobbyReducer,
  payment: paymentReducer,
})

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
})

export default store

export type RootDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof rootReducer>

type DispatchFunc = () => RootDispatch
export const useAppDispatch: DispatchFunc = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
