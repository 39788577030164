import { useLocation } from 'react-router-dom'
import { Modals, Params } from 'const/router'

// хук для извлечения GET параметров из URL страницы
type useGetParamResponse = {
  modalName: keyof Modals
}

const useGetModalParam = (name: Params): useGetParamResponse => {
  const { search } = useLocation()
  const query = new URLSearchParams(search)

  const modalName = query.get(name) as keyof Modals

  return { modalName }
}

export default useGetModalParam
