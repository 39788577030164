import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Popouts } from 'const/router'
import { HeadersWrapper } from 'popouts/Bonus/BonusPopout'
import { Wrapper } from 'popouts/ReferralSystem/ReferralSystemPopout'
import { makeRequest } from 'utils/api'

import ImageTon from 'assets/svg/other/Ton.svg'
import TonIconImage from 'assets/svg/other/TonIcon.svg'
import { Button, Header, Popout, StatusType, Subheader } from 'components/base'
import Input from 'components/base/Popout/Input'
import { useAppSelector } from 'store'

import { LOCALES } from '@gatto/locales'
import { Ton, TON_MIN_LIMIT, TonRegex, TonStatus } from '@gatto/shared'

import { usePopout } from '../../hooks'
import { useWalletCheck } from '../../hooks/useWalletCheck'

const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 321px;
  margin: 15px 16px 0 16px;
`

const HeaderBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const IconBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 11px;
  margin-bottom: 8px;
  background: var(
    --gradient-sky-10,
    linear-gradient(
      180deg,
      rgba(13, 178, 255, 0.1) 0%,
      rgba(11, 152, 249, 0.1) 100%
    ),
    #fff
  );
`
const TonImage = styled.img`
  align-self: center;
`
const BodyBlock = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  width: 100%;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.03);
  height: 70px;
`

const InputBlock = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
`

const SubheaderBlock = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  padding-left: 20px;
`

const BottomBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 327px;
  height: 190px;
  margin: 0 0 10px 0;
  gap: 12px;
  align-self: stretch;
  overflow-y: scroll;
  line-height: 15px;
`

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-height: 55px;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.05);
  padding: 16px;
`

const TonData = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
`

const TonIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`

const Text = styled.span`
  color: #000;

  leading-trim: both;

  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
`

const SubheaderContainer = styled.div<{ margin: string }>`
  display: flex;
  justify-content: start;
  align-items: start;
  width: 100%;
  margin: ${(props) => props.margin};
`

type ExchangePopoutProps = Popouts['tonExchange']

const getStatusWord = (status: TonStatus): string => {
  switch (status) {
    case TonStatus.wait:
      return 'Выведено'
    case TonStatus.Got:
      return 'Получено'
    case TonStatus.Error:
      return 'Ошибка'
    default:
      return 'Неизвестный статус'
  }
}

const transformDate = (date: number): string => {
  const now = new Date(date)
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short' as const,
    hour: 'numeric',
    minute: 'numeric',
  }

  const formattedDate = new Intl.DateTimeFormat('ru-RU', options).format(now)

  const [day, month, time] = formattedDate.split(' ')

  return `${day} ${month} ${time}`
}

const TonExchangePopout: FC<ExchangePopoutProps> = () => {
  const { t } = useTranslation()
  const [ton, setTon] = useState<string>(TON_MIN_LIMIT.toString())
  const [dataTon, setDataTon] = useState<Ton[]>([])
  const [status, setStatus] = useState<StatusType>()
  const { user } = useAppSelector((state) => state.user)
  const data = useWalletCheck()

  const openPopout = usePopout('tonAccept', false, { amount: ton })

  useEffect(() => {
    const isValid = TonRegex.test(ton)

    if (!isValid) {
      let newTon = ton?.replace(/[^0-9.]/g, '')

      const match = newTon.match(/^(\d+(\.\d{0,3})?)?$/)
      newTon = match ? match[1] || '' : newTon.toString().slice(0, -1)

      const limitedTon =
        parseFloat(newTon) < TON_MIN_LIMIT ? TON_MIN_LIMIT.toString() : newTon

      setTon(limitedTon.replace(/^0+(?=\d)/, ''))
    }
  }, [ton])

  useEffect(() => {
    const handleGetExchanges = async () => {
      const { data } = await makeRequest('ton.history', '')
      setDataTon(data)
    }

    handleGetExchanges()
  }, [])

  const isDisabled = (user && user.balance.hard < +ton) || +ton < TON_MIN_LIMIT

  return (
    <Popout
      background={true}
      isFullScreen={false}
      setStatus={setStatus}
      status={status}
      type="default"
      width="353px"
    >
      <Wrapper>
        <HeaderBlock>
          <IconBlock>
            <TonImage src={ImageTon} />
          </IconBlock>
          <HeadersWrapper>
            <Header>{t(LOCALES.wallet)}</Header>
            <Subheader>{t(LOCALES.minAmountTONWithdraw)}</Subheader>
          </HeadersWrapper>
        </HeaderBlock>
        <MainBlock>
          <BodyBlock>
            <InputBlock>
              <Input
                placeholder={t(LOCALES.enter)}
                disabled={true}
                setText={setTon}
                value={ton}
                style={{
                  textTransform: 'initial',
                  width: '200px',
                  background: 'transparent',
                  height: '15px',
                }}
              />
              <SubheaderBlock>
                <Subheader>TON</Subheader>
              </SubheaderBlock>
            </InputBlock>
            <Button
              variant={isDisabled || !data.length ? 'translucent' : 'success'}
              width="100px"
              onClick={() => {
                openPopout()
              }}
              disabled={isDisabled || !data.length}
            >
              {t(LOCALES.Withdraw)}
            </Button>
          </BodyBlock>
          {!dataTon.length ? (
            ''
          ) : (
            <SubheaderContainer margin={'15px 0 15px 2px'}>
              <Text>{t(LOCALES.history)}</Text>
            </SubheaderContainer>
          )}
          <BottomBlock>
            {dataTon &&
              dataTon.map((item) => (
                <BottomContainer key={item._id}>
                  <TonData>
                    <TonIcon src={TonIconImage} />
                    <HeadersWrapper>
                      <Header>
                        {item.status === TonStatus.wait ? '-' : '+'}$
                        {item.amount} TON
                      </Header>
                      <SubheaderContainer margin={'0'}>
                        <Subheader>{getStatusWord(item.status)}</Subheader>
                      </SubheaderContainer>
                    </HeadersWrapper>
                  </TonData>
                  <TonData>
                    <Subheader>{transformDate(item.date)}</Subheader>
                  </TonData>
                </BottomContainer>
              ))}
          </BottomBlock>
        </MainBlock>
      </Wrapper>
    </Popout>
  )
}

export default TonExchangePopout
