import { useNavigate } from 'react-router-dom'
import { Popouts } from 'src/const/router'

// Хук для открытия модальных окон
// принимает путь до модального окна
// возвращает функцию открытия модального окна

const usePopout = <T extends keyof Popouts>(
  path: T,
  replace: boolean,
  props?: Popouts[T],
): ((restProps?: Popouts[T]) => void) => {
  const navigate = useNavigate()

  return (restProps?: Popouts[T]) => {
    const queryParams = `?popout=${path}`

    navigate(queryParams, { state: { ...props, ...restProps }, replace })
  }
}

export default usePopout
