import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchingStatus, makeRequest } from 'src/utils/api'

export interface ServerState {
  timeDelta: number
  status: FetchingStatus
  currentRequestId?: string
  pixiLoadingProgress: number
}

export const initialState: ServerState = {
  timeDelta: 0,
  status: FetchingStatus.IDLE,
  currentRequestId: undefined,
  pixiLoadingProgress: 0,
}

export const fetchServerInfo = createAsyncThunk<
  { timeDelta: number } | undefined,
  void,
  { state: { server: ServerState } }
>('server.getInfo', async (_, { getState, requestId }) => {
  const { status, currentRequestId } = getState().server
  if (status !== FetchingStatus.PENDING || requestId !== currentRequestId) {
    return
  }

  const localTime = Date.now()
  const response = await makeRequest('server.getInfo', {})
  return {
    timeDelta: response.time - localTime,
  }
})

export const serverSlice = createSlice({
  name: 'server',
  initialState,
  reducers: {
    setPixiLoadingProgress: (state, action: PayloadAction<number>) => {
      state.pixiLoadingProgress = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServerInfo.pending, (state, action) => {
        if (state.status !== FetchingStatus.IDLE) {
          return
        }

        state.status = FetchingStatus.PENDING
        state.currentRequestId = action.meta.requestId
      })
      .addCase(fetchServerInfo.fulfilled, (state, action) => {
        const { requestId } = action.meta

        if (
          state.status !== FetchingStatus.PENDING ||
          state.currentRequestId !== requestId ||
          !action.payload
        ) {
          return
        }

        state.status = FetchingStatus.FULFILLED
        state.timeDelta = action.payload.timeDelta
        state.currentRequestId = undefined
      })
  },
})

export const { setPixiLoadingProgress } = serverSlice.actions
export default serverSlice.reducer
