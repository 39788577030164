/* eslint-disable no-console */
import * as PIXI from 'pixi.js'
import { Sprite } from 'pixi.js'
import { RunGame } from 'src/pixi/classes'
import { TEXTURE_NAMES, TRACKS_NAMES } from 'src/pixi/types/rungame'

import { RUNGAME_TEXTURES, TRACK_BY_NUM, TRACKS_COORDS } from '../constants'

/**
 * Возвращает трек по его номеру в объекте TRACK_BY_NUM
 * @param trackNum номер трека
 * @returns название трека
 */
export function getTrackNameByNum(
  this: RunGame,
  trackNum?: number,
): TRACKS_NAMES | null {
  if (!trackNum) return null
  return TRACK_BY_NUM[trackNum] || null
}

/**
 * Получение номер текущего трека в TRACK_BY_NUM
 * @returns номер трека или null
 */
export function getCurrentTrack(this: RunGame): number | null {
  return this.fullTrack[this.currentTrack] || null
}

/**
 * Получение имени текущего трека
 * @returns имя трека
 */
export function getCurrentTrackName(this: RunGame): TRACKS_NAMES {
  const track = this.getCurrentTrack()
  if (!track) return 'trackFinish'
  return TRACK_BY_NUM[track] || 'trackFinish'
}

export function getTrackNameByX(this: RunGame, x: number): TRACKS_NAMES {
  const track = this.fullTrack[Math.floor(x / 800)]
  if (!track) return 'trackFinish'
  return this.getTrackNameByNum(track) || 'trackFinish'
}

/**
 * Возвращает спрайт трека по его номеру в объекте TRACK_BY_NUM
 * @param trackNum номер трека
 * @param спрайт трека
 */
export function getTrackSpriteByNum(
  this: RunGame,
  trackNum?: number,
): Sprite | null {
  const trackName = this.getTrackNameByNum(trackNum)
  if (!trackName) return null
  const trackTexture = this.trackSprites[trackName]
  if (!trackTexture) return null
  return new Sprite(trackTexture) || null
}

/**
 * Получение координаты Y для питомца для объектов на дороге
 */
export function getObjectSecondCoord(
  this: RunGame,
  {
    row,
    trackName,
    x,
    objOffset,
  }: {
    row: number
    trackName: TRACKS_NAMES
    x: number
    objOffset: number
  },
): number {
  const coords = TRACKS_COORDS[trackName]
  const slot = Math.floor(x / 32)
  const nextPoint = coords[slot + 1] || 0
  const currentPoint = coords[slot] || 0
  const yOffset = currentPoint + ((nextPoint - currentPoint) / 32) * (x % 32)
  return 70 * row + yOffset + objOffset
}

/**
 * Получение текстуры по имени
 */
export function getTexture(this: RunGame, name: TEXTURE_NAMES): PIXI.Texture {
  return this.textures.textures[RUNGAME_TEXTURES[name]] || PIXI.Texture.from('')
}
