import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { VK_GROUP_FAQ_LINK } from 'src/config/constants'
import { openUrl } from 'utils/openURL'

import { BackButton, Placeholder, Wallet } from 'components/base'
import { Page, PageContainer, PageHeader } from 'components/page'
import { HeaderText } from 'components/typography'

// import worldMapPlaceholderIcon from 'assets/svg/worldMap/placeholder.svg'
import { LOCALES } from '@gatto/locales'

export const WorldMapPage: FC = () => {
  const { t } = useTranslation()
  return (
    <PageContainer>
      <PageHeader over={<Wallet mode="horizontal" />} before={<BackButton />}>
        <HeaderText>{t(LOCALES.faq)}</HeaderText>
      </PageHeader>
      <Placeholder
        title={t(LOCALES.faq_text)}
        // subtitle="Нанимаем художников, начинаем отрисовку"
        // img={worldMapPlaceholderIcon}
        buttonText={t(LOCALES.faq_btnMore)}
        isFullscreen={true}
        onClick={() => {
          openUrl(VK_GROUP_FAQ_LINK)
        }}
      />
    </PageContainer>
  )
}

WorldMapPage.displayName = 'WorldMapPage'

// import styled from '@emotion.svg/styled'
// import * as PIXI from 'pixi.js'
// import React, { FC } from 'react'
// import { MakeUnit } from 'src/pixi/pets/example'

// const Container = styled.div`
//   width: 100%;
//   height: 100%;

//   > canvas {
//     width: 100%;
//     height: 100%;
//   }
// `

// export const WorldMapPage: FC = () => {
//   const containerRef = React.useRef<HTMLDivElement>(null)
//   const [app, setApp] = React.useState<PIXI.Application>()
//   /** Монтируем канвас PIXI в контейнер */
//   React.useEffect(() => {
//     if (!containerRef.current) return
//     const container = containerRef.current
//     const canvas = document.createElement('canvas')
//     canvas.width = window.innerWidth
//     canvas.height = window.innerHeight
//     container.appendChild(canvas)
//     const app = new PIXI.Application({
//       width: window.innerWidth * window.devicePixelRatio,
//       height: window.innerHeight * window.devicePixelRatio,
//       backgroundColor: 0x9370db,
//       antialias: true,
//       view: canvas,
//     })
//     setApp(app)
//     return () => app.destroy(true)
//   }, [])
//   // Добавляем спрайт
//   React.useEffect(() => {
//     if (!app) return

//     async function addUnit() {
//       if (app) await MakeUnit(app)
//     }
//     addUnit()
//     // return () => sprite.destroy()
//   }, [app])
//   return <Container id="map" ref={containerRef} />
// }

// WorldMapPage.displayName = 'WorldMapPage'
