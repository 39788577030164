import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import searchIcon from 'assets/svg/other/search.svg'
import { Button } from 'components/base'

import { LOCALES } from '@gatto/locales'
import { PetSearchRes } from '@gatto/shared'

const SearchWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  margin: 0 20px;
`

const Input = styled.input`
  display: flex;
  height: 42px;
  padding: 10px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
  font-family: Nunito;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  border: none;
  outline: none;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-family: Nunito;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
  }
`

const ButtonIcon = styled.img``

type SearchProps = {
  pets: PetSearchRes[]
  setFilteredPets: React.Dispatch<React.SetStateAction<PetSearchRes[]>>
  setIsFirstLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const Search: FC<SearchProps> = ({
  pets,
  setFilteredPets,
  setIsFirstLoading,
}) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const handleSearch = (event: string) => {
    if (event === 'Button' || event === 'Enter') {
      const filteredPets = pets.filter(
        (pet) => pet.name.toLowerCase().search(search.toLowerCase()) !== -1,
      )
      setFilteredPets(filteredPets)
      setIsFirstLoading(false)
    }
  }

  return (
    <SearchWrapper>
      <Input
        placeholder={t(LOCALES.choosePet_search)}
        value={search}
        onChange={handleInput}
        onKeyDown={(e) => handleSearch(e.code)}
      />
      <Button
        variant="skyBlue"
        width="auto"
        style={{ padding: '10px 12px 13px' }}
        onClick={() => handleSearch('Button')}
      >
        <ButtonIcon src={searchIcon} />
      </Button>
    </SearchWrapper>
  )
}

export default Search
