import React, { FC, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'

import AddCoinsImage from 'assets/svg/other/add_coins.svg'
import TonImage from 'assets/svg/other/Withdraw.png'

import usePopout from '../../../hooks/router/usePopout'

const Button = styled.button`
  position: absolute;
  top: auto;
  right: -17px;
  display: flex;
  width: 34px;
  padding: 0.104px 0px 3.104px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;

  transition: all 120ms ease;

  &:not([disabled]):active {
    scale: 0.95;
    opacity: 0.9;
  }
`

const AddCoinsIcon = styled.img`
  width: 33.823px;
  height: 33.793px;
  flex-shrink: 0;
`

export enum WalletType {
  Ton = 'ton',
  Default = 'default',
}

interface Wallet {
  type: WalletType
}

const AddCoinsButton: FC<Wallet> = ({ type }) => {
  const navigate = useNavigate()

  const sortByInStore = 'donates'
  const location = useLocation()

  const openExchangePopout = usePopout('tonExchange', false)
  return (
    <Button
      onClick={() => {
        if (
          location.pathname !== '/store' &&
          location.search !== '?sortBy=donates' &&
          type === WalletType.Default
        ) {
          navigate(`/store?sortBy=${sortByInStore}`)
        }

        if (type === WalletType.Ton) {
          openExchangePopout()
        }
      }}
    >
      <AddCoinsIcon src={WalletType.Ton === type ? TonImage : AddCoinsImage} />
    </Button>
  )
}

export default AddCoinsButton
