export const PET_BODY_PARTS = [
  'hat_base',
  'eyes_stuff_base',
  'collar_base',
  'tail_stuff_base',
  'tail_end_stuff_base',
  'monocle_base',
]

export const CLOTHES_BONES_OBJECT = {
  panama_hat: 'hat_base',
  don_glasses: 'eyes_stuff_base',
  bell_collar: 'collar_base',
  witch_tail: 'tail_stuff_base',
  crown_hat: 'hat_base',
  bandage_tail: 'tail_stuff_base',
  bone_collar: 'collar_base',
  bow_tail: 'tail_stuff_base',
  brut_collar: 'collar_base',
  cap_hat: 'hat_base',
  hand_tail_end: 'tail_end_stuff_base',
  heart_collar: 'collar_base',
  leo_glasses: 'eyes_stuff_base',
  mik_glasses: 'eyes_stuff_base',
  one_glasses_monocle: 'monocle_base',
  raf_glasses: 'eyes_stuff_base',
  retro_glasses: 'eyes_stuff_base',
  ring_tail: 'tail_stuff_base',
  smart_glasses: 'eyes_stuff_base',
  sun_glasses: 'eyes_stuff_base',
  ushanka_hat: 'hat_base',
  witch_collar: 'collar_base',
  witch_hat: 'hat_base',
  bite_glasses: 'eyes_stuff_base',
  cat_collar: 'collar_base',
  hogwarts_hat: 'hat_base',
  pumpkin_dep_hat: 'hat_base',
  pumpkin_hap_hat: 'hat_base',
  pumpkin_nor_hat: 'hat_base',
  rope_collar: 'collar_base',
  scull_collar: 'collar_base',
  stars_hat: 'hat_base',
  owl_glasses: 'eyes_stuff_base',
  pumkin_glasses: 'eyes_stuff_base',
  zomby_tail: 'tail_stuff_base',
  bell_tail: 'tail_stuff_base',
  blue_hat: 'hat_base',
  bow_collar: 'collar_base',
  candy_collar: 'collar_base',
  candy_glasses: 'eyes_stuff_base',
  cinema_glasses: 'eyes_stuff_base',
  elf_hat: 'hat_base',
  ice_collar: 'collar_base',
  lights_glasses: 'eyes_stuff_base',
  lights_tail: 'tail_stuff_base',
  mandarin_hat: 'hat_base',
  red_hat: 'hat_base',
  scarf_collar: 'collar_base',
  scarf_tail: 'tail_stuff_base',
  star_glasses: 'eyes_stuff_base',
  tree_collar: 'collar_base',
  tree_hat: 'hat_base',
  winter_hat: 'hat_base',
}

export const CLOTHES_BONES = new Map(Object.entries(CLOTHES_BONES_OBJECT))
