import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import { Card } from '@vkontakte/vkui'

import { Button } from 'components/base'

type ContainerPorps = {
  isGrayBorder?: boolean
  isBackground?: boolean
}

const Container = styled(Card)<ContainerPorps>`
  padding: 10px 7px 7px 7px;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  width: 100%;
  ${(props) =>
    props.isGrayBorder &&
    'box-shadow: 0px 4.280207633972168px 0px 0px rgba(0, 0, 0, 0.10); backdrop-filter: blur(41.196998596191406px); margin-bottom: 5px;'}
`

const Img = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 10px;
`

const Titles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
`

const Title = styled.div`
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
`

const Subtitle = styled.div`
  color: #888;
  text-align: center;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 10.5px;
`

const PlaceholderButton = styled(Button)`
  width: 100%;
`

type PlaceholderProps = {
  img?: string
  title?: string
  subtitle?: string
  buttonText?: string
  isGrayBorder?: boolean
  isFullscreen?: boolean
  buttonIcon?: ReactNode
  onClick?: () => void
}

const FullscreenContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-self: center;
  gap: 15px;
  padding: 20px;
  flex: 1 0 0;
  width: 353px;
  height: 196px;
  position: absolute;
  top: 35%;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 7px;
  align-self: stretch;
  width: 313px;
`

const HeaderMain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #fff;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
`

const SubHeader = styled.div`
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 8px;
  width: 230px;
  white-space: pre-wrap;
`

function Placeholder({
  img,
  title,
  subtitle,
  buttonText,
  isGrayBorder,
  isFullscreen = false,
  buttonIcon,
  onClick = () => null,
}: PlaceholderProps): React.ReactElement {
  if (isFullscreen) {
    return (
      <FullscreenContainer>
        {img && <Img src={img} />}
        <Header>
          <HeaderMain>{title}</HeaderMain>
          <SubHeader>{subtitle}</SubHeader>
          {buttonText && (
            <Button variant="success" onClick={onClick}>
              {buttonText}
              {buttonIcon}
            </Button>
          )}
        </Header>
      </FullscreenContainer>
    )
  } else {
    return (
      <Container isGrayBorder={isGrayBorder}>
        <Img src={img} />
        <Titles>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Titles>
        <PlaceholderButton variant="success" onClick={onClick}>
          {buttonText}
          {buttonIcon}
        </PlaceholderButton>
      </Container>
    )
  }
}

export default Placeholder
