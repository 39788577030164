import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import * as PIXI from 'pixi.js'

const Container = styled.div`
  width: 100%;
  height: 100%;

  > canvas {
    width: 100%;
    height: 100%;
  }
`

export const GamePage: FC = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [app, setApp] = useState<PIXI.Application>()

  /** Монтируем канвас PIXI в контейнер */
  useEffect(() => {
    if (!containerRef.current) return
    const container = containerRef.current

    const canvas = document.createElement('canvas')
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight
    container.appendChild(canvas)

    const app = new PIXI.Application({
      width: window.innerWidth * window.devicePixelRatio,
      height: window.innerHeight * window.devicePixelRatio,
      backgroundColor: 0x9370db,
      antialias: true,
      view: canvas,
    })

    setApp(app)
    return () => app.destroy(true)
  }, [])

  // Добавляем спрайт
  useEffect(() => {
    if (!app) return

    // const imageUrl = 'https://cataas.com/cat/says/Типичный работник KotBread'
    // const sprite = PIXI.Sprite.from(imageUrl)

    // sprite.anchor.set(0.5)
    // sprite.x = app.screen.width / 2
    // sprite.y = app.screen.height / 2
    // sprite.scale = new PIXI.Point(1.2, 1.2)
    // app.stage.addChild(sprite)

    async function addUnit() {
      // if (app) await MakeBackground(app)
    }

    addUnit()

    // return () => sprite.destroy()
  }, [app])

  return <Container id="map" ref={containerRef} />
}

GamePage.displayName = 'GamePage'
