import { io, Socket } from 'socket.io-client'
import { getLocationSearch } from 'utils/getLocationSearch'

import {
  BaseEngineEvents,
  BaseUnitEvents,
  DisconnectEvent,
  Engine,
  GameStartedEvent,
  MapInfo,
  MapType,
  Pet,
  StateInfoEvent,
  StateUserInfo,
  UnitDive,
  UnitEmerge,
  UnitJump,
  UserModel,
  UserStatus,
} from '@gatto/engine'
import { Platform, SocketResponse } from '@gatto/shared'

import { MINI_APP } from '../index'

type callback<T> = (data: T) => Promise<void>

type EngineUser = {
  info: UserModel
  pet: Pet
}

interface Setup {
  users: Map<number, EngineUser>
  mapInfo: MapInfo
}

/*
  Использование:
  
  // Создание клиента, указание ссылки на комнату ожидания и подпись пользователя (или любой другой аутентифицирующий токен)
  const client = new Client('https://gatto.play/room/1', 'подпись пользователя')

  // Регистрация и обработка сокет-ивентов с сервера:
  client.onWebsocketEvent<SetupInput>(BaseEngineEvents.Setup, (data) => {
    // Например:
    // Пользователи точно будут в мапе, так как клиент самостоятельно также принимает setup ивент 
    // и обрабатывает его, парся пользователей и данные карты
    client.setup.users.forEach((user, i) => {
      console.info(user.info.id) // идентификатор пользователя
    }) 
  })

  // Обязательно создавайте enum'ы или константы для ивентов сообщений.
  const someEventName = 'test'

  // Регистрация и обработка пользовательский ивентов
  client.on<TestData>(someEventName, (data) => {
    console.log(test)
  })

  // Отправка пользовательского ивента
  client.sendEvent<TestData>(someEventName, data)

  // Подписываемся на прыжок пользователя.
  client.onWebsocketEvent<SetupEvent>(BaseUnitEvents.PetJump, (syncData) => {
    // some code
  })

  // Отправление вебсокет ивента на бекенд. Учтите, что вы не получите данные в onWebsocketEvent, туда они не дублируются.
  // Если вам необходимо это, то реализуйте собственный обработчик
  client.sendWebsocketEvent<TestData>(someEventName, data)
 
  // Старт обработки ивентов от вебсокета
  client.handleWebsocketUpdates()
*/
export class EngineClient extends Engine {
  readonly addr: string

  readonly userAccessToken: string

  gameTimeout = 0

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private websocketCallbacks: Map<string, Array<callback<any>>> = new Map()

  private readonly io: Socket | undefined

  setup: Setup | undefined

  constructor(addr: string, userAccessToken: string) {
    super()
    let token = getLocationSearch()

    if (MINI_APP === Platform.TG) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      token = window.Telegram.WebApp.initData
    }

    this.addr = addr

    this.userAccessToken = userAccessToken

    this.io = io(addr, {
      transports: ['websocket'],
      auth: {
        token: `Bearer ${
          MINI_APP === Platform.TG ? token : this.userAccessToken
        }`,
      },
    })

    this.setupHandlers()
  }

  onWebsocketEvent<T>(event: string, callback: callback<T>): void {
    const eventCallbacks = this.websocketCallbacks.get(event)

    if (!eventCallbacks) {
      this.websocketCallbacks.set(event, [callback])

      return
    }

    eventCallbacks.push(callback)
  }

  sendWebsocketEvent = <RQ, RS>(
    event: string,
    data?: RQ,
    callback?: (data: SocketResponse<RS>) => Promise<void>,
  ): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.io?.emit(event, data, (data: SocketResponse<RS>) => {
      if (callback === undefined) {
        return
      }

      callback(data)
    })
  }

  handleWebsocketUpdates(): void {
    this.io?.onAny((event: string, data) => {
      this.handleWebsocketEvents(event, data)
    })
  }

  userById(id: number): EngineUser | undefined {
    return this.setup?.users.get(id)
  }

  petById(id: string): Pet | undefined {
    let result = undefined
    this.setup?.users.forEach((user) => {
      if (user.pet.info.id === id) result = user.pet
      return
    })
    return result
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleWebsocketEvents(event: string, data: any): void {
    const eventCallbacks = this.websocketCallbacks.get(event)
    if (!eventCallbacks) {
      return
    }

    eventCallbacks.forEach((callback) => {
      callback(data)
    })
  }

  private createSetupUsers(
    gameType: MapType,
    mapInfo: MapInfo,
    users: { [key: number]: StateUserInfo },
  ) {
    const parsedUsers = new Map<number, EngineUser>()

    Object.values(users).forEach((user) => {
      const pet = new Pet(
        user.row,
        this.serverTimeDiff,
        gameType,
        user.petInfo,
        mapInfo,
      )

      pet.currentSpeed = user.petCurrentSpeed
      pet.speedY = user.petCurrentSpeedY
      pet.coordinates = user.petCoordinates
      pet.setNewLastUpdate(user.petLastUpdate)

      parsedUsers.set(user.userInfo.id, {
        info: user.userInfo,
        pet: pet,
      })
    })

    return parsedUsers
  }

  private createUser(gameType: MapType, user: StateUserInfo) {
    if (!this.setup) return
    const pet = new Pet(
      user.row,
      this.serverTimeDiff,
      gameType,
      user.petInfo,
      this.setup.mapInfo,
    )

    pet.currentSpeed = user.petCurrentSpeed
    pet.speedY = user.petCurrentSpeedY
    pet.coordinates = user.petCoordinates
    pet.setNewLastUpdate(user.petLastUpdate)

    this.setup.users.set(user.userInfo.id, {
      info: user.userInfo,
      pet: pet,
    })
  }

  private parseSetupUsers(
    gameType: MapType,
    users: {
      [key: number]: StateUserInfo
    },
  ): Map<number, EngineUser> {
    const parsedUsers = new Map<number, EngineUser>()

    Object.values(users).forEach((user) => {
      const u = this.userById(user.userInfo.id)
      if (!u) {
        this.createUser(gameType, user)

        const player = this.setup?.users.get(user.userInfo.id)
        if (!player) return

        this.addUnits(player.pet)
        this.userStartRace(player)

        return
      }

      u.pet.updateInfo(this.serverTimeDiff, user)
    })

    return parsedUsers
  }

  private setupHandlers(): void {
    this.onWebsocketEvent<UnitJump>(BaseUnitEvents.PetJump, async (data) =>
      this.handleJumps(data),
    )

    this.onWebsocketEvent<StateInfoEvent>(
      BaseEngineEvents.StateInfo,
      async (data) => this.refreshInfo(data),
    )

    this.onWebsocketEvent<UnitDive>(BaseUnitEvents.PetDive, async (data) =>
      this.handleDive(data),
    )

    this.onWebsocketEvent<UnitEmerge>(BaseUnitEvents.PetEmerge, async (data) =>
      this.handleEmerge(data),
    )

    this.onWebsocketEvent(BaseEngineEvents.GameEnded, async () =>
      this.handleGameEnd(),
    )
  }

  refreshInfo(data: StateInfoEvent): void {
    this.updateTimeDiff(data.serverTime)

    if (!this.setup) {
      const mapInfo = new MapInfo(data.mapInfo.mapType, data.mapInfo)

      this.setup = {
        mapInfo: mapInfo,
        users: this.createSetupUsers(data.mapInfo.mapType, mapInfo, data.users),
      }

      this.gameStartedAt = data.gameStartedAt
      this.gameTimeout = data.timeoutIn

      this.setup.users.forEach((user) => {
        this.addUnits(user.pet)

        this.userStartRace(user)
      })

      this.newLastUpdate(data.lastUpdateAt)

      this.update()

      return
    }

    this.parseSetupUsers(data.mapInfo.mapType, data.users)

    this.newLastUpdate(data.lastUpdateAt)

    this.update()
  }

  private handleJumps(data: UnitJump): void {
    const user = this.userById(data.userId)
    if (!user) {
      return
    }

    user.pet.currentSpeed = data.petCurrentSpeed
    user.pet.coordinates = data.petCoordinates
    user.pet.setNewLastUpdate(data.petLastUpdate)
    user.pet.speedY = data.petSpeedY

    this.newLastUpdate(data.petLastUpdate)
    this.update()

    return
  }

  private handleEmerge(data: UnitDive): void {
    const user = this.userById(data.userId)
    if (!user) {
      return
    }

    user.pet.isDiving = false
    user.pet.currentSpeed = data.petCurrentSpeed
    user.pet.coordinates = data.petCoordinates
    user.pet.setNewLastUpdate(data.petLastUpdate)
    user.pet.speedY = data.petSpeedY

    this.newLastUpdate(data.petLastUpdate)
    this.update()

    return
  }

  private handleDive(data: UnitDive): void {
    const user = this.userById(data.userId)
    if (!user) {
      return
    }

    user.pet.isDiving = true
    user.pet.currentSpeed = data.petCurrentSpeed
    user.pet.coordinates = data.petCoordinates
    user.pet.setNewLastUpdate(data.petLastUpdate)
    user.pet.speedY = data.petSpeedY

    this.newLastUpdate(data.petLastUpdate)
    this.update()

    return
  }

  private userStartRace(user: EngineUser): void {
    user.info.status = UserStatus.Playing

    this.startPetRun(user)
  }

  private async handleGameStart(data: GameStartedEvent) {
    this.updateTimeDiff(data.serverTime)

    this.gameTimeout = data.gameTimeoutIn

    this.setup?.users?.forEach((user) => {
      this.startPetRun(user)
    })
  }

  private async handleGameEnd() {
    this.setup?.users?.forEach((user) => {
      user.pet.gracefulShutdown()
    })
  }

  private startPetRun(user: EngineUser) {
    user.pet.startRun()
  }

  private handleDisconnects = async (data: DisconnectEvent): Promise<void> => {
    const user = this.userById(data.userId)
    if (!user) {
      return
    }

    user.info.status = UserStatus.Disconnected
  }
}
