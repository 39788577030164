import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import bridge from '@vkontakte/vk-bridge'
import {
  CoinIcon,
  GroupIcon,
  LinkIcon,
  NotificationIcon,
} from 'src/config/assets'
import { VK_GROUP_ID } from 'src/config/constants'
import { usePopout } from 'src/hooks'
import { makeRequest } from 'utils/api'

import check from 'assets/svg/other/check.svg'
import { Button, Header, Popout, StatusType, Subheader } from 'components/base'
import { useAppDispatch } from 'store/index'
import { incrementBalance } from 'store/user'

import { LOCALES } from '@gatto/locales'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const HeadersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`

const Icon = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 12px;
  margin-bottom: 12px;
`
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  margin-top: 15px;
  row-gap: 10px;
`

const Block = styled.div`
  min-height: 31px;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 13px;
  padding: 10px;
`

const BlockWrapper = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

const BlockIcon = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 8px;
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  background: var(
    --gradient-primary-10,
    linear-gradient(
      180deg,
      rgba(67, 134, 254, 0.1) 0%,
      rgba(58, 110, 223, 0.1) 100%
    ),
    #fff
  );
`

const LinkImg = styled.img`
  width: 16px;
  height: 16px;
`

const BlockTitle = styled.div`
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  max-width: 170px;
`

const ButtonIcon = styled.img`
  width: 16px;
  height: 16px;
`
const IconTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: 8px;
`

const BonusPopout: FC = () => {
  const { t } = useTranslation()
  const [status, setStatus] = useState<StatusType>()
  const dispatch = useAppDispatch()
  const [isSubscribeDisabled, setIsSubscribeDisabled] = useState(false)
  const [isNotificationDisabled, setIsNotificationDisabled] = useState(false)

  const referralPopout = usePopout('referralSystem', false)

  useEffect(() => {
    makeRequest('user.getBonusesInfo', {}).then(
      ({ groupNotification, groupSubscribe }) => {
        setIsNotificationDisabled(groupNotification)
        setIsSubscribeDisabled(groupSubscribe)
      },
    )
  }, [])

  const blocks: Array<{
    icon: ReactNode
    title: string
    buttonText?: string
    buttonIcon?: string
    onClick?: () => void
    isDisabled?: boolean
  }> = [
    {
      icon: <BlockIcon src={GroupIcon} />,
      title: t(LOCALES.bonus_Subscribe),
      buttonText: '+300',
      buttonIcon: !isSubscribeDisabled ? CoinIcon : check,
      onClick: () => {
        bridge
          .send('VKWebAppJoinGroup', {
            group_id: VK_GROUP_ID,
          })
          .then(async ({ result }) => {
            if (result) {
              await makeRequest('user.getBonus', { type: 'subscribe' }).then(
                () => {
                  dispatch(incrementBalance({ soft: 300 }))
                  setIsSubscribeDisabled(true)
                },
              )
            }
          })
      },
      isDisabled: isSubscribeDisabled,
    },
    {
      icon: (
        <IconWrapper>
          <LinkImg src={NotificationIcon} />
        </IconWrapper>
      ),
      title: t(LOCALES.bonus_Notification),
      buttonText: '+300',
      buttonIcon: !isNotificationDisabled ? CoinIcon : check,
      onClick: () => {
        bridge
          .send('VKWebAppAllowMessagesFromGroup', {
            group_id: VK_GROUP_ID,
          })
          .then(async ({ result }) => {
            if (result) {
              await makeRequest('user.getBonus', { type: 'notification' }).then(
                () => {
                  dispatch(incrementBalance({ soft: 300 }))
                  setIsNotificationDisabled(true)
                },
              )
            }
          })
      },
      isDisabled: isNotificationDisabled,
    },
    {
      icon: (
        <IconWrapper>
          <LinkImg src={LinkIcon} />
        </IconWrapper>
      ),
      title: t(LOCALES.referral_system),
      buttonText: t(LOCALES.open),
      onClick: () => referralPopout(),
    },
  ]

  return (
    <Popout
      background={true}
      isFullScreen={false}
      setStatus={setStatus}
      status={status}
      type="default"
      width="353px"
    >
      <Wrapper>
        <Icon src={CoinIcon} />
        <HeadersWrapper>
          <Header>{t(LOCALES.bonuses)}</Header>
          <Subheader>{t(LOCALES.activeBonusesList)}</Subheader>
        </HeadersWrapper>
        <InfoWrapper>
          {blocks.map(
            (
              { buttonText, icon, title, buttonIcon, onClick, isDisabled },
              index,
            ) => (
              <Block key={index}>
                <BlockWrapper>
                  <IconTitleWrapper>
                    {icon}
                    <BlockTitle>{title}</BlockTitle>
                  </IconTitleWrapper>
                  <Button
                    variant={isDisabled ? 'gray' : 'success'}
                    iconPosition="right"
                    width={'auto'}
                    onClick={onClick}
                    disabled={isDisabled}
                  >
                    {!isDisabled && buttonText}
                    {buttonIcon && <ButtonIcon src={buttonIcon} />}
                  </Button>
                </BlockWrapper>
              </Block>
            ),
          )}
        </InfoWrapper>
      </Wrapper>
    </Popout>
  )
}

export default BonusPopout
