import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { APP_LANG } from 'src/config/constants'
import { EVENT_ICONS } from 'src/config/eventSettings'
import { usePopout } from 'src/hooks'
import { fixEndingByNumber } from 'utils/i18/fixRussianWords'

import Ton from 'assets/png/ton/Ton.png'
import CurrencyIcon from 'assets/svg/coin.svg'
import DiamondPaw from 'assets/svg/diamond_paw.svg'
import { Item, ItemDesc, ItemImage } from 'components/base/Item'

import { LOCALES } from '@gatto/locales'
import {
  CurrencyType,
  DonateItemType,
  EggItemType,
  EssenceItemType,
  ItemType,
  Platform,
  SkinItemType,
  SkinRarity,
} from '@gatto/shared'

import { MINI_APP } from '../../../index'

import StoreWallet from './StoreWallet'

const CardText = styled.p<{ isVotes?: boolean }>`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 55%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const VotesText = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

const IconImage = styled.img`
  width: 15px;
`

const PriceText = styled.p`
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

interface MenuItemProps {
  name: string
  image: string
  price: number
  currency: string
  props: {
    name: string
    price: number
    image: string
    description: string
    currency: CurrencyType
    goodId: string
    itemType:
      | ItemType
      | EssenceItemType
      | DonateItemType
      | SkinItemType
      | EggItemType
    rarity?: SkinRarity
  }
}

const MenuItem: FC<MenuItemProps> = ({
  name,
  image,
  price,
  props,
  currency,
}) => {
  const { t } = useTranslation()
  const openPopout = usePopout('store', false, props)
  const widths =
    currency === 'votes'
      ? { textWidth: '60px', walletWidth: '100px' }
      : { textWidth: '100px', walletWidth: '70px' }
  return (
    <Item onClick={() => openPopout(props)} key={name}>
      <ItemImage src={image} />
      <ItemDesc>
        <CardText isVotes={currency === 'votes'}>{name}</CardText>
        <StoreWallet
          style={{
            width: widths.walletWidth,
            justifyContent: 'space-around',
            background: 'rgba(0, 0, 0, 0.05)',
          }}
          isVotes={currency === 'votes'}
        >
          <PriceText>{price}</PriceText>
          {currency === CurrencyType.Soft && <IconImage src={CurrencyIcon} />}
          {currency === CurrencyType.Hard && (
            <IconImage src={MINI_APP === Platform.VK ? DiamondPaw : Ton} />
          )}
          {currency === CurrencyType.Event && (
            <IconImage src={EVENT_ICONS.small} />
          )}
          {currency === CurrencyType.Votes &&
            (APP_LANG === 'ru'
              ? fixEndingByNumber(t(LOCALES.votes), price)
              : t(LOCALES.votes))}
        </StoreWallet>
      </ItemDesc>
    </Item>
  )
}

export default MenuItem
