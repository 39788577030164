import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Spinner as VKUISpinner } from '@vkontakte/vkui'
import { MINI_APP } from 'src'
import { AdSVG, FeedSVG, PlaySVG } from 'src/config/assets'
import { nowServerTime } from 'utils/nowServerTime'

import { Button, type ButtonColor, ProgressLevel } from 'components/base'
import { RootState, useAppDispatch, useAppSelector } from 'store/index'
import { fetchRegion } from 'store/region'

import { LOCALES } from '@gatto/locales'
import { PetFeelsAliases, Platform, PopulatedPet } from '@gatto/shared'

export type PetButtonProps = {
  title: string
  subtitle?: string
  icon: React.ReactElement
  progressTitle: string
  buttonVariant: ButtonColor
  progress?: ButtonProgress
  isAdsReady: boolean
}

const Root = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 12,
  alignItems: 'center',
  zIndex: 10,
  width: '100%',
  justifyContent: 'center',
  flexWrap: 'wrap',
})

const ButtonContainer = styled.div({
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: 15,
  padding: 5,
})

const StyledButton = styled(Button)({
  height: 50,
  position: 'relative',
})

const VerticalContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 0,
  lineHeight: '15px',
  alignItems: 'flex-start',
})

const Hint = styled.span({
  fontSize: 10,
  display: 'block',
})

const IconContainer = styled.div({
  position: 'relative',
  display: 'flex',
})

const Progress = styled(ProgressLevel)({
  position: 'absolute',
  color: 'white',
  left: -2,
  top: -2,
})

const AdProgress = styled(ProgressLevel)({
  position: 'absolute',
  color: '#43FF56',
  left: -2,
  top: -2,
})

const AdIcon = styled(AdSVG)({
  position: 'absolute',
  right: -14,
  top: -14,
})

const Spinner = styled(VKUISpinner)({
  width: 110,
  color: '#ffffff',
})

const PetButton: React.FC<
  PetButtonProps & { onClick: () => void; alias: PetFeelsAliases }
> = ({
  buttonVariant,
  progressTitle,
  progress,
  title,
  subtitle,
  icon,
  onClick,
  alias,
  isAdsReady,
}) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const region = useAppSelector((state: RootState) => state.region).data
  const pet = region?.pet

  const updateRegion = useCallback(() => {
    if (!region?._id) return
    dispatch(fetchRegion(region._id))
  }, [dispatch, region?._id])

  const calculateProgress = useCallback(() => {
    const defaultResponse = { percent: 1, timeLeft: '' }

    if (!pet || !progress) return defaultResponse
    const feelStats = pet[alias === 'feed' ? 'fullness' : 'happiness']
    const boost = feelStats.originalEnd - feelStats.end

    const now = nowServerTime() + boost
    if (progress.date < now) {
      updateRegion()
      return defaultResponse
    }

    const waitingTime = progress.date - progress.since
    const timeLeft = progress.date - now

    let diff = timeLeft
    const hours = Math.floor(diff / 60 / 60 / 1000)
    diff -= hours * 60 * 60 * 1000
    const minutes = Math.floor(diff / 60 / 1000)
    diff -= minutes * 60 * 1000
    const seconds = Math.floor(diff / 1000)

    const timeUnits = [
      ...(hours ? [`${('0' + hours).slice(-2)}${t(LOCALES.timerHours)}`] : []),
      ...(minutes || !hours
        ? [`${('0' + minutes).slice(-2)}${t(LOCALES.timerMinutes)}`]
        : []),
      `${('0' + seconds).slice(-2)}${t(LOCALES.timerSeconds)}`,
    ]

    return {
      percent: 1 - timeLeft / waitingTime,
      timeLeft: timeUnits.slice(0, 2).join(' '),
    }
  }, [progress, updateRegion, pet, alias])

  const initialValues = useMemo(calculateProgress, [calculateProgress])
  const [percent, setPercent] = useState(initialValues.percent)
  const [timeLeft, setTimeLeft] = useState(initialValues.timeLeft)

  useEffect(() => {
    setLoading(false)
  }, [region])

  useEffect(() => {
    if (!progress) return

    let interval: NodeJS.Timeout | number = 0
    const update = () => {
      const { percent, timeLeft } = calculateProgress()
      setPercent(percent)
      setTimeLeft(timeLeft)
      if (percent === 1) clearInterval(interval)
    }
    update()
    interval = setInterval(update, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [progress, calculateProgress])

  const handleClick = () => {
    setLoading(true)
    onClick?.()
  }

  const shouldDisableButton = (progress && !progress.adAvailable) || loading

  return (
    <ButtonContainer>
      <StyledButton
        onClick={handleClick}
        width="stretch"
        disabled={shouldDisableButton}
        variant={progress ? 'translucent' : buttonVariant}
      >
        {!loading && (
          <>
            {progress?.adAvailable &&
              isAdsReady &&
              MINI_APP === Platform.VK && <AdIcon />}
            <IconContainer>
              {icon}
              {progress && <Progress startAngle={0} progress={percent} />}
              {MINI_APP === Platform.VK &&
                progress?.adAvailable &&
                isAdsReady && (
                  <AdProgress
                    startAngle={0}
                    progress={(1 - percent) * 0.2}
                    startProgress={percent}
                  />
                )}
            </IconContainer>
            {progress ? (
              <VerticalContainer>
                <Hint>{progressTitle}</Hint>
                {timeLeft}
              </VerticalContainer>
            ) : (
              <VerticalContainer>
                {title}
                <Hint>{subtitle}</Hint>
              </VerticalContainer>
            )}
          </>
        )}

        {loading && <Spinner />}
      </StyledButton>
    </ButtonContainer>
  )
}

type ButtonProgress = {
  /** Дата окончания таймера */
  date: number
  /** Дата начала таймера */
  since: number
  /** Доступна ли реклама */
  adAvailable?: boolean
}

type Props = Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'> & {
  onClick?: (buttonAlias: PetFeelsAliases) => void
  pet: PopulatedPet
}

const PetButtons: React.FC<Props> = ({ onClick, pet, ...props }) => {
  const { t } = useTranslation()
  const now = nowServerTime()

  return (
    <Root {...props}>
      <PetButton
        title={t(LOCALES.petFeed)}
        subtitle={t(LOCALES.petFeedSubtitle)}
        icon={<FeedSVG />}
        progressTitle={t(LOCALES.petFeedTimeout)}
        buttonVariant="skyBlue"
        progress={
          (pet.fullness.end ?? 0) >= now
            ? {
                date: pet.fullness.originalEnd ?? 0,
                since: pet.fullness.since ?? 0,
                adAvailable: (pet?.fullness.adSkippedTimes ?? 0) < 3,
              }
            : undefined
        }
        isAdsReady={true}
        alias={PetFeelsAliases.Feed}
        onClick={() => onClick?.(PetFeelsAliases.Feed)}
      />
      <PetButton
        title={t(LOCALES.petPlay)}
        icon={<PlaySVG />}
        progressTitle={t(LOCALES.petPlayTimeout)}
        buttonVariant="warning"
        progress={
          (pet?.happiness.end ?? 0) >= now
            ? {
                date: pet?.happiness.originalEnd ?? 0,
                // eslint-disable-next-line prettier/prettier
                since: pet?.happiness.since ?? 0,
                adAvailable: (pet?.happiness.adSkippedTimes ?? 0) < 3,
              }
            : undefined
        }
        isAdsReady={true}
        alias={PetFeelsAliases.Play}
        onClick={() => onClick?.(PetFeelsAliases.Play)}
      />
    </Root>
  )
}

export default PetButtons
