import React from 'react'
import styled from '@emotion/styled'

interface InputProps {
  children?: React.ReactNode
  onClick?: () => void
  disabled?: boolean
  className?: string
  placeholder?: string
  value?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

const InputTextElement = styled.input`
  padding: 10px 20px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
  border: none;
  outline: none;

  color: #000000;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`

/**
 * Компонент текстового поля
 * @component
 */
function InputText({
  children,
  disabled = false,
  className,
  value = '',
  onChange,
  placeholder = '',
}: InputProps): React.ReactElement {
  return (
    <InputTextElement
      type="text"
      placeholder={placeholder}
      disabled={disabled}
      className={className}
      value={value}
      onChange={onChange}
    >
      {children}
    </InputTextElement>
  )
}

export default InputText
