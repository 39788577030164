import { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Types as MongooseTypes } from 'mongoose'
import { MINI_APP } from 'src'
import { useNow } from 'src/hooks/useNow'
import { getTimeFormatted } from 'utils/getTimeFormatted'

import Ton from 'assets/png/ton/Ton.png'
import diamondPaw from 'assets/svg/diamond_paw.svg'
import ad from 'assets/svg/egg/ad.svg'
import { Button } from 'components/base'
import { RootState, useAppDispatch, useAppSelector } from 'store'
import { fetchRegion } from 'store/region'

import { LOCALES } from '@gatto/locales'
import { Platform, PopulatedEgg } from '@gatto/shared'
import { usePopout } from '../../../hooks'

type EggButtonsWrapperProps = {
  entityDimension: {
    width: number
    height: number
  }
}

const EggButtonsWrapper = styled.div<EggButtonsWrapperProps>`
  display: flex;
  column-gap: 5px;
  width: 100%;
  z-index: 10;
  justify-content: center;
  position: absolute;
  top: calc(
    50% +
      ${(props) =>
        (props.entityDimension.height +
          (props.entityDimension.height / 100) * 53) /
        2}px + 50px
  );
`

const HatchingTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;
  border-radius: 11px;
  background: rgba(0, 0, 0, 0.15);
  padding: 20px;
`

const HatchingTitle = styled.div`
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 12.548px;
  text-transform: uppercase;

  @media (max-width: 360px) {
    font-size: 10px;
  }
`

const HatchingTime = styled.div`
  color: #fff;
  text-align: center;
  text-shadow: 0px 1.3274139165878296px 0px 0px rgba(39, 34, 64, 0.35);
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 11.103px;
  text-transform: uppercase;
`

const Buttons = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  border-radius: 11px;
  background: rgba(0, 0, 0, 0.15);
  pointer-events: all;
`

const AdImg = styled.img`
  width: 28px;
  height: 27px;
`

const Price = styled.div`
  color: #fff;
  font-family: Nunito;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 11.103px;
  text-transform: uppercase;
`

const PriceWrapper = styled.div<{ isMoreNine: boolean }>`
  position: absolute;
  top: 11px;
  right: -3px;
  display: flex;
  align-items: center;
  gap: 1px;
  ${(props) => !props.isMoreNine && 'top: 7px;'}
`

const Img = styled.img`
  width: 14px;
  height: 15px;
  padding-bottom: 1px;
`

const IconPolygon = styled.div<{ isMoreNine: boolean }>`
  background-color: transparent;
  width: 28px;
  height: 28px;
  ${(props) => props.isMoreNine && 'width: 35px; height: 35px;'}
`

const IconsWrapper = styled.div`
  position: relative;
  top: -2px;
  display: flex;
`

const TextWrapper = styled.div`
  color: #fff;
  text-shadow: 0px 1.3274139165878296px 0px 0px rgba(39, 34, 64, 0.35);
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: 11.103px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  text-align: left;
`

const SmallText = styled.div`
  font-size: 10px;
`

type EggButtonsProps = {
  egg: PopulatedEgg
  regionId: MongooseTypes.ObjectId
  entityDimension: {
    width: number
    height: number
  }
  isAdsReady: boolean
  onClick: (payed?: boolean) => void
  hatchingTime: number
  hatchingTimeout: number
  canPayedSkip?: boolean
  canFreeSkip?: boolean
  price: number
  timeLeft: number
}

const EggButtons: FC<EggButtonsProps> = ({
  entityDimension,
  regionId,
  isAdsReady,
  hatchingTime,
  hatchingTimeout,
  onClick,
  price,
  canFreeSkip,
  canPayedSkip,
  timeLeft,
}) => {
  const { t } = useTranslation()
  const now = useNow(1000)
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state) => state.user)

  const timeText = getTimeFormatted(t, timeLeft)
  // @TODO: Возможно стоит хранить на бэке не только кол-во доступных скипов, но и время одного скипа
  const skipTimeText = useMemo(
    () => getTimeFormatted(t, 3600000),
    [hatchingTime],
  )

  useEffect(() => {
    if (now >= hatchingTimeout) {
      dispatch(fetchRegion(regionId))
    }
  }, [dispatch, hatchingTimeout, now, regionId])

  return (
    <EggButtonsWrapper entityDimension={entityDimension}>
      <HatchingTimeWrapper>
        <HatchingTitle>{t(LOCALES.hatching_in)}</HatchingTitle>
        <HatchingTime>{timeText}</HatchingTime>
      </HatchingTimeWrapper>
      <Buttons>
        <Button
          onClick={async () => {
            if (
              MINI_APP === Platform.VK ||
              (MINI_APP === Platform.TG && user && user.balance.hard > price)
            ) {
              await onClick()
            } else {
              await onClick()
            }
          }}
          variant={canPayedSkip ? 'skyBlue' : 'translucent'}
          style={{ padding: 10 }}
          disabled={!canPayedSkip}
        >
          <IconsWrapper>
            <IconPolygon isMoreNine={price > 9} />
            <PriceWrapper isMoreNine={price > 9}>
              <Price>
                {MINI_APP === Platform.VK ? price : price.toFixed(2)}
              </Price>
              {MINI_APP === Platform.VK ? (
                <Img src={diamondPaw} />
              ) : (
                <Img src={Ton} />
              )}
            </PriceWrapper>
          </IconsWrapper>
          <TextWrapper>
            {t(LOCALES.open)}
            <SmallText>{t(LOCALES.instantly)}</SmallText>
          </TextWrapper>
        </Button>
        {canFreeSkip && isAdsReady && MINI_APP === Platform.VK && (
          <Button
            onClick={() => onClick(false)}
            variant="success"
            style={{ padding: '20px 40px 23px 10px' }}
          >
            <AdImg src={ad} />
            {skipTimeText}
          </Button>
        )}
      </Buttons>
    </EggButtonsWrapper>
  )
}

export default EggButtons
