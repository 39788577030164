import { FC } from 'react'

import { useAppSelector } from 'store/index'
import { getTempSelectedPet } from 'store/selectPet'

import { PetSearchRes } from '@gatto/shared'

import Card from './Card'

type CardListProps = {
  pets: PetSearchRes[]
}

const CardList: FC<CardListProps> = ({ pets }) => {
  const selectedPet = useAppSelector(getTempSelectedPet)

  return pets.map((pet, index) => {
    return (
      <Card
        key={index}
        isSelected={!!selectedPet && selectedPet === pet._id.toString()}
        pet={pet}
      />
    )
  })
}

export default CardList
