import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Stage } from '@pixi/react'
import { Spinner } from '@vkontakte/vkui'
import { createBrowserHistory } from 'history'
import { POPOUT_ANIMATION_MS } from 'src/const'
import useNetworkStatus from 'src/hooks/useNetworkStatus'
import { makeRequest } from 'utils/api'

import { Button } from 'components/base'
import { Input, Popout, StatusType } from 'components/base/Popout'
import { Entity, EntityPet } from 'components/pixi/index'
import { RootState, useAppDispatch, useAppSelector } from 'store'
import { clearEggState, fetchRegion, updateRegionPet } from 'store/region'
import { updateUserRegion } from 'store/user'

import { LOCALES } from '@gatto/locales'
import { BasePet, PetNameRegex } from '@gatto/shared'

const PopoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
`

const Title = styled.div`
  color: #000;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 11.103px;
  margin-bottom: 5px;
  text-align: center;
`

const Subtitle = styled.div`
  color: #9a9a9a;
  text-align: center;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; /* 107.143% */
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

const PetNamePopout: FC = () => {
  const { t } = useTranslation()
  const [status, setStatus] = useState<StatusType>()
  const [text, setText] = useState('')
  const [popoutClose, setPopoutClose] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const [request, setRequest] = useState(true)
  const temp = useLocation()
  const isOnline = useNetworkStatus()
  const { state } = temp
  const history = createBrowserHistory()

  const regionId = state?.regionId
  const basePet: BasePet = state?.pet?.basePet

  const pet = useAppSelector((state: RootState) => state.region)?.data?.pet

  useEffect(() => {
    if (!pet) {
      dispatch(fetchRegion())
    }
  }, [dispatch, pet])

  useEffect(() => {
    const blocker = !isOnline || (!!pet && pet.name === ' ')
    const unblock = history.block(() => !blocker)

    let timeout: ReturnType<typeof setTimeout>

    if (pet?.name !== ' ') {
      unblock()
      timeout = setTimeout(() => {
        if (location.search.startsWith('?popout=petName')) {
          setPopoutClose(true)
        }
      }, POPOUT_ANIMATION_MS)
    }
    return () => {
      unblock()
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [isOnline, pet?.name])

  useEffect(() => {
    let newText = text.replace(PetNameRegex, '')

    if (newText.length > 15) {
      newText = newText.slice(0, 15)
    }

    setText(newText)
  }, [text])

  const renamePet = useCallback(async () => {
    try {
      setRequest(false)
      const pet = await makeRequest('pet.rename', {
        id: state.pet._id,
        name: text.trim(),
      })
      dispatch(
        updateRegionPet({
          pet,
        }),
      )
      dispatch(clearEggState())
      dispatch(updateUserRegion({ pet, regionId, egg: null }))
    } finally {
      setRequest(true)
    }
  }, [dispatch, regionId, state?.pet?._id, text])

  if (!state?.pet) {
    return null
  }

  const isButtonDisabled = text.trim().length > 15 || text.trim().length === 0

  return (
    <Popout
      type="default"
      width={'290px'}
      background={true}
      isFullScreen={false}
      status={status}
      setStatus={setStatus}
      isClosable={popoutClose}
      close={popoutClose}
    >
      <PopoutWrapper>
        <Stage
          width={250}
          height={150}
          options={{
            backgroundAlpha: 0,
            antialias: true,
            resolution: window.devicePixelRatio || 1,
            autoDensity: true,
          }}
        >
          <Entity
            scale={0.7}
            x={125}
            y={150}
            anchor={0.5}
            shadow={false}
            entity={<EntityPet basePet={basePet} />}
          />
        </Stage>
        <div>
          <Title>{t(LOCALES.petHatched)}</Title>
          <Subtitle>{t(LOCALES.comeUpName)}</Subtitle>
        </div>
        <Wrapper>
          {request && (
            <Input
              placeholder={t(LOCALES.name)}
              setText={setText}
              value={text}
              style={{ textTransform: 'initial' }}
            />
          )}
          {!request && <Spinner size="large" style={{ margin: '20px 0' }} />}
          <Button
            onClick={renamePet}
            variant="success"
            disabled={isButtonDisabled}
            style={{ padding: '14px 14px 17px 14px' }}
          >
            {t(LOCALES.save)}
          </Button>
        </Wrapper>
      </PopoutWrapper>
    </Popout>
  )
}

export default PetNamePopout
