import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import { Card } from '@vkontakte/vkui'

import { Button } from 'components/base'

import { Biome } from '@gatto/shared'

import BiomeImg from './BiomeImg'

const CardWrapper = styled.div<{ isGrayBorder: boolean | undefined }>`
  margin-right: 7px;
  border-radius: 13px;
  ${(props) =>
    props.isGrayBorder &&
    'box-shadow: 0px 4.280207633972168px 0px 0px rgba(0, 0, 0, 0.10); backdrop-filter: blur(41.196998596191406px); margin-bottom: 5px;'}
`

const CardItem = styled(Card)`
  padding: 10px 7px 7px 7px;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  width: 119px;
`

const Img = styled.img`
  border-radius: 10px;
  flex-shrink: 0;
  width: 70%;
`

const Titles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
`

const Title = styled.div`
  color: rgba(0, 0, 0, 0.7);
  font-size: 12px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
`

const Subtitle = styled.div`
  color: #888;
  text-align: center;
  font-family: Nunito;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10.5px;
`
const CardButton = styled(Button)`
  width: 100%;
  font-size: 11px;
  padding: 9px 12px 12px 12px;
`

type CustomCardProps = {
  img?: string | ReactNode
  title?: string
  subtitle?: React.ReactNode
  biomeName?: Biome
  disabled?: boolean
  buttonText?: string
  isGrayBorder?: boolean
  buttonIcon?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  id?: string
}

const CustomCard: React.FC<CustomCardProps> = ({
  img,
  title,
  subtitle,
  disabled,
  biomeName,
  buttonText,
  isGrayBorder,
  buttonIcon,
  onClick,
  id,
}) => {
  return (
    <CardWrapper isGrayBorder={isGrayBorder} id={id}>
      <CardItem>
        {typeof img === 'string' ? <Img src={img} /> : img}
        {biomeName && <BiomeImg name={biomeName} />}
        <Titles>
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Titles>
        <CardButton
          variant={'success'}
          width="auto"
          onClick={onClick}
          disabled={disabled}
        >
          {buttonText}
          {buttonIcon}
        </CardButton>
      </CardItem>
    </CardWrapper>
  )
}

export default CustomCard
