import { PetKind } from '@gatto/shared'

type PetSpriteMapping = {
  [key in PetKind]: `${string}.json`
}

export const petSpriteMapping: PetSpriteMapping = {
  [PetKind.Axolotl]: 'axolotl.json',
  [PetKind.Bat]: 'zubrik.json',
  [PetKind.Bear]: 'bear.json',
  [PetKind.BirdYellow]: 'yellow_bird.json',
  [PetKind.Bull]: 'bikobull.json',
  [PetKind.Capybara]: 'capibara.json',
  [PetKind.CatBrown]: 'cat.json',
  [PetKind.DogCorgy]: 'corgy.json',
  [PetKind.Duck]: 'duck.json',
  [PetKind.Goose]: 'goose.json',
  [PetKind.Hippogriff]: 'hippogriff.json',
  [PetKind.IcePengwuin]: 'penguin.json',
  [PetKind.Lama]: 'lama.json',
  [PetKind.MountainWent]: 'goat.json',
  [PetKind.Narval]: 'narval.json',
  [PetKind.PigLeaf]: 'plant_piggy.json',
  [PetKind.SandFell]: 'peschany_mushec.json',
  [PetKind.Shark]: 'sharky.json',
  [PetKind.Squirrel]: 'chipmunk.json',
  [PetKind.Tiger]: 'tiger.json',
  [PetKind.Crab]: 'crab.json',
  [PetKind.Fox]: 'fox.json',
  [PetKind.Frog]: 'frog.json',
  [PetKind.HammerShark]: 'hammer_shark.json',
  [PetKind.HedgeHog]: 'hedgehog.json',
  [PetKind.Mushroom]: 'mushroom.json',
  [PetKind.Opossum]: 'opossum.json',
  [PetKind.Pigeon]: 'pigeon.json',
  [PetKind.Racoon]: 'racoon.json',
  [PetKind.RoboFish]: 'robo_fish.json',
  [PetKind.Salamandra]: 'salamandra.json',
  [PetKind.SeaCat]: 'sea_cat.json',
  [PetKind.Snejec]: 'snejec.json',
  [PetKind.Stingray]: 'stingray.json',
  [PetKind.Toucan]: 'toucan.json',
}
