import styled from '@emotion/styled'

const Scrollable = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }
  overflow-y: scroll;

  height: 100%;
  width: 100%;
`
export default Scrollable
