import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Placeholder } from 'src/components/base'

import { LOCALES } from '@gatto/locales'

import eggPlaceholder from '../../../assets/svg/popouts/egg-placeholder.svg'

const EggPlaceholder: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <Placeholder
      title={t(LOCALES.youDontHaveEggs)}
      subtitle={t(LOCALES.buyEggInShop)}
      img={eggPlaceholder}
      buttonText={t(LOCALES.goShop)}
      onClick={() => {
        navigate('/store')
      }}
      isGrayBorder={true}
    />
  )
}

export default EggPlaceholder
