import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import bridge from '@vkontakte/vk-bridge'
import { useCloudStorage } from '@vkruglikov/react-telegram-web-app'
import { GenIcon, LvlIcon, TileIcon } from 'src/config/assets'
import { PET_CHARS } from 'src/config/petChars'
import { Popouts } from 'src/const/router'

import { Button, PetImage } from 'components/base'
import { Header, Popout, StatusType } from 'components/base/Popout'
import { InfoBlocks } from 'components/base/Popout/InfoBlocks'
import { useAppDispatch } from 'store/index'
import { setSelectedPet } from 'store/selectPet'
import { setIsTabbarHidden } from 'store/storage'

import { LOCALES } from '@gatto/locales'
import { Platform } from '@gatto/shared'

import { MINI_APP } from '../../index'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 20px;
  align-self: stretch;
`

const InfoField = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Nunito;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 11.103px;
  white-space: nowrap;
  margin-top: -10px;
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  align-self: stretch;
  width: 100%;
  height: 150px;
`

const HeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  align-self: stretch;
`

const Row = styled.div`
  display: flex;

  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  justify-content: space-between;
`

const Attribute = styled.div`
  display: flex;
  padding: 5px 7px 7px 7px;
  max-width: 47px;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.02);
`

const StatsImage = styled.img`
  width: 27px;
  height: 29px;
`

const InfoAttributeText = styled.p`
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 19.103px; /* 159.189% */
  text-transform: uppercase;
`

type PetInfoPopoutProps = Popouts['petInfo']

const PetInfoPopout: FC<PetInfoPopoutProps> = ({
  basePet,
  avatar,
  owner,
  petId,
  localId,
  petChars,
  petLevel,
  petName,
  petEvolution,
  buttonText,
  skins,
  petBiome,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { setItem } = useCloudStorage()
  const [status, setStatus] = useState<StatusType>()
  const [popoutClose, setPopoutClose] = useState<boolean>(false)

  const items = [
    { icon: LvlIcon, title: t(LOCALES.lvl), value: petLevel || '0' },
    { icon: GenIcon, title: t(LOCALES.generation), value: petEvolution || '1' },
  ]

  if (petBiome) {
    items.push({
      icon: TileIcon,
      title: t(LOCALES.biome),
      value: t(`biomeType_${petBiome}`),
    })
  }
  const handleClick = () => {
    //TODO: раскомментить
    if (MINI_APP === Platform.VK) {
      bridge
        .send('VKWebAppStorageSet', {
          key: 'selectedPet',
          value: petId || '',
        })
        .then(() => {
          // dispatch(setSelectedPet(petId))
          // setPopoutClose(true)
        })
        .catch(() => setPopoutClose(true))
    }

    if (MINI_APP === Platform.VK) {
      setItem('selectedPet', petId || '').catch(() => {
        setPopoutClose(true)
      })
    }
    // dispatch(setSelectedPet(petId))
    // navigate(-1)
    dispatch(setSelectedPet(petId))
    setPopoutClose(true)
  }

  const placeOnMap = () => {
    if (localId && basePet) {
      dispatch(setIsTabbarHidden(true))
      navigate(
        `/?${createSearchParams({
          placing: 'pet',
          pet_id: localId,
          allowed_region: basePet.allowedRegion,
        })}`,
        { replace: true },
      )
    }
  }

  return (
    <Popout
      type="default"
      background={true}
      width="315px"
      isFullScreen={false}
      status={status}
      setStatus={setStatus}
      close={popoutClose}
    >
      <Container>
        <ImageContainer>
          <PetImage
            basePet={basePet}
            scale={0.8}
            width={150}
            height={150}
            skins={skins || []}
          />
        </ImageContainer>
        <HeaderWrap>
          <Header lineHeight={25}>{petName}</Header>
        </HeaderWrap>
        <InfoField>
          <Row>
            {petChars &&
              PET_CHARS.map((char, i) => (
                <Attribute key={i}>
                  <StatsImage src={char.icon} />
                  <InfoAttributeText>{petChars[char.type]}</InfoAttributeText>
                </Attribute>
              ))}
          </Row>
          {owner && avatar && (
            <InfoBlocks
              items={[{ icon: avatar, title: owner, value: t(LOCALES.owner) }]}
              type={true}
            />
          )}
          <InfoBlocks items={items} type={false} />
        </InfoField>
        {buttonText && (
          <Button variant="success" width="100%" onClick={handleClick}>
            {buttonText}
          </Button>
        )}
        {
          // PetBiome используется для определения, что наш питомец на складе
          petBiome && (
            <Button
              variant="success"
              width="100%"
              style={{ padding: '14px 14px 17px 14px' }}
              onClick={() => {
                placeOnMap()
              }}
            >
              {t(LOCALES.settle)}
            </Button>
          )
        }
      </Container>
    </Popout>
  )
}

export default PetInfoPopout
