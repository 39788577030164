import { FC, useState } from 'react'
import styled from '@emotion/styled'

import { Button, Header, Popout, StatusType, Subheader } from 'components/base'

import WaitCircleImage from '../../assets/svg/popouts/wait-circle.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`

const WaitImage = styled.img`
  width: 47.823px;
  height: 47.823px;
`

import { useTranslation } from 'react-i18next'

import { LOCALES } from '@gatto/locales'

import InfoCircleImage from '../../assets/svg/popouts/info-circle.svg'

const InfoBlockContainer = styled.div`
  display: flex;
  padding: 7px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  align-self: stretch;
  border-radius: 13px;
  background: rgba(0, 0, 0, 0.02);
`

const Text = styled.div`
  color: #888888;
  leading-trim: both;
  text-edge: cap;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 11.103px; /* 92.523% */
`

const InfoImageContainer = styled.div`
  display: flex;
  padding: 5px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.05);
`

const InfoImage = styled.img`
  width: 18px;
  height: 18px;
`

const MintingStarted: FC = () => {
  const { t } = useTranslation()
  const [status, setStatus] = useState<StatusType>()

  return (
    <Popout
      type="default"
      background={true}
      width="284px"
      isFullScreen={false}
      status={status}
      setStatus={setStatus}
    >
      <Container>
        <InfoContainer>
          <WaitImage src={WaitCircleImage} />
          <Header fontSize={18}>{t(LOCALES.mintStarted)}</Header>
          <Subheader>{t(LOCALES.afterComplite)}</Subheader>
        </InfoContainer>
        <InfoBlockContainer>
          <InfoImageContainer>
            <InfoImage src={InfoCircleImage} />
          </InfoImageContainer>
          <Text>{t(LOCALES.itsALongProcess)}</Text>
        </InfoBlockContainer>
        <Button
          variant="success"
          onClick={() => {
            setStatus('disappear')
          }}
        >
          {t(LOCALES.okay)}
        </Button>
      </Container>
    </Popout>
  )
}

export default MintingStarted
