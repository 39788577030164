import { Container, Sprite } from '@pixi/react'
import { Resource, Texture } from 'pixi.js'
import useScreenDimensions from 'src/hooks/useScreenDimensions'

export const BackgroundImage: React.FC<{
  texture: Texture<Resource> | undefined
}> = ({ texture }) => {
  const screenDimensions = useScreenDimensions()
  const containerX = screenDimensions.width / 2
  const scaleCoef = window.innerHeight / 960

  if (!texture) return

  return (
    <Container eventMode="static" x={containerX} scale={scaleCoef}>
      <Sprite anchor={[0.5, 0]} texture={texture} zIndex={4} />
    </Container>
  )
}
