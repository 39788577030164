// Включить/отключить ивент
export const EVENT_ACTIVE = true

// Иконки
// import HalloweenSmallTicket from 'assets/icons/ticket_halloween_small.png'
// import HalloweenDefaultTicket from 'assets/icons/ticket_halloween.png'
import NewYearDefaultTicket from '../assets/icons/ticket_new_year.png'
import NewYearSmallTicket from '../assets/icons/ticket_new_year_small.png'

export const EVENT_ICONS = {
  small: NewYearSmallTicket,
  default: NewYearDefaultTicket,
}
