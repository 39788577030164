import * as PIXI from 'pixi.js'
import { TileType } from 'src/types/Tile'

// Конфигурация платформ на странице MapPage
export const HIT_AREAS = {
  regular: new PIXI.Polygon([198, 34, 387, 165, 200, 300, 8, 165]),
  large: new PIXI.Polygon([375, 295, 720, 530, 375, 765, 28, 530]),
}

export const PLATFORM_OUTLINE_POLYGON = new PIXI.Polygon([
  { x: 400, y: 52 },
  { x: 812, y: 345 },
  { x: 400, y: 636 },
  { x: -12, y: 345 },
])

export const PLATFORMS_HIT_AREAS: Record<TileType, PIXI.Polygon> = {
  plain: HIT_AREAS.regular,
  forest: HIT_AREAS.regular,
  ice: HIT_AREAS.regular,
  mountain: HIT_AREAS.regular,
  desert: HIT_AREAS.regular,
  sea: HIT_AREAS.regular,
  store: HIT_AREAS.large,
  storage: HIT_AREAS.large,
  stadium: HIT_AREAS.large,
  empty: HIT_AREAS.regular,
  park: HIT_AREAS.large,
  festival: HIT_AREAS.large,
  random: HIT_AREAS.regular,
}
