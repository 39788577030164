import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import designTokens, { ButtonSize } from 'src/config/tokens'
import useSquircle from 'src/hooks/useSquircle'

import type { ButtonColor, ButtonWidth } from 'components/base'

type BaseToggleProps = {
  /**
   * Ширина переключателя
   * @default 'square'
   */
  width?: Omit<ButtonWidth, 'square'>

  /**
   * Вариант цвета переключателя
   * @default 'success'
   */
  variant?: ButtonColor

  /**
   * Размер переключателя
   * @default 'medium'
   */
  size?: ButtonSize

  /**
   * Текст левой метки
   */
  leftText?: string

  /**
   * Текст правой метки
   */
  rightText?: string

  /**
   * Событие изменения состояния переключателя
   * @event onChange
   */
  onStateChange?: (state: boolean) => void
}

type ToggleProps = BaseToggleProps &
  Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    keyof BaseToggleProps
  >

const ToggleRoot = styled.span<{
  svgPath: string
  width: NonNullable<ToggleProps['width']>
  size: NonNullable<ToggleProps['size']>
}>`
  min-width: 128px;
  height: ${({ size }) => designTokens.button[size].height};

  /* Растягиваем переключатель на всю ширину родителя */
  ${({ width }) => width === 'stretch' && 'width: 100%; flex: 1 0;'}

  /* Ширина переключателя указана в процентах или пикселях */
  ${({ width }) =>
    width.endsWith('px') || (width.endsWith('%') && `width: ${width};`)}

  /* Squircle */
  clip-path: ${({ svgPath }) => `path('${svgPath}')`};
`

const Label = styled.label`
  width: 100%;
  height: 100%;
  position: relative;

  display: inline-block;
  background: ${designTokens.color.grey.secondary};

  border-radius: 8px;
  box-shadow: ${designTokens.shadow.block};
  overflow: hidden;

  border: 2px solid ${designTokens.gradient.clothes.legendary};
`

const Slider = styled.span<{
  variant: NonNullable<ToggleProps['variant']>
  disabled: NonNullable<ToggleProps['disabled']>
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: grid;
  grid-template-columns: 1fr 1fr;

  background: transparent;
  transition: 0.3s;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 50%;
    left: 0;
    bottom: 0;

    /* Акцентный цвет переключателя */
    background: ${({ variant, disabled }) =>
      !disabled
        ? designTokens.gradient[variant]
        : designTokens.color.grey.secondary};

    box-shadow: ${designTokens.shadow.block};
    transition: 0.3s;
  }
`

const Input = styled.input<{
  variant: NonNullable<ToggleProps['variant']>
}>`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span:before {
    transform: translateX(100%);
  }
`

const Text = styled.span`
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${designTokens.color.white};
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;

  text-shadow: ${designTokens.shadow.text};
`

/**
 * Переключатель
 * @component
 *
 * @example
 * Пример получения состояния переключателя через `ref`
 * ```tsx
 * const toggleRef = useRef<HTMLInputElement>(null)
 * const currentState = toggleRef.current?.checked
 *
 * return <Toggle variant="success" inputRef={toggleRef} />
 * ```
 *
 * Пример получения состояния переключателя через `onChange`
 * ```tsx
 * const [currentState, setCurrentState] = useState(false)
 * const handleChange = useCallback((state) => setCurrentState(state), [])
 *
 * return <Toggle variant="success" onChange={handleChange} />
 * ```
 */
function Toggle({
  variant = 'success',
  width = 'square',
  size = 'mini',
  disabled = false,
  leftText,
  rightText,
  onStateChange,
  ...props
}: ToggleProps): React.ReactElement {
  const toggleRef = React.useRef<HTMLLabelElement>(null)
  const svgPath = useSquircle({
    ref: toggleRef,
    cornerRadius: 8,
  })

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      onStateChange?.(event.currentTarget.checked)
    },
    [onStateChange],
  )

  return (
    <ToggleRoot svgPath={svgPath} width={width} size={size} {...props}>
      <Label ref={toggleRef}>
        <Input
          type="checkbox"
          variant={variant}
          onChange={handleChange}
          disabled={disabled}
          {...props}
        />
        <Slider variant={variant} disabled={disabled}>
          <Text>{leftText}</Text>
          <Text>{rightText}</Text>
        </Slider>
      </Label>
    </ToggleRoot>
  )
}

export default Toggle
