import { TFunction } from 'i18next'

import { LOCALES } from '@gatto/locales'

export const getRarityName = (
  t: TFunction,
  rarity: 'mif' | 'legendary' | 'normal' | 'rare',
  ending: 'male' | 'female' | 'neuter',
): string => {
  const word = t(LOCALES[`rarity_${rarity}`]).slice(0, -2)
  if (ending === 'male' && word.at(-1) === 'к') return word + 'ий'
  if (ending === 'male') return word + 'ый'
  if (ending === 'female') return word + 'ая'
  return word + 'ое'
}
