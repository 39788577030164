export function openUrl(url: string): void {
  const a = document.createElement('a')
  Object.assign(a, { href: url, target: '_blank' })
  a.click()
}

export function paymentPath(
  wallet: string,
  amount: string,
  comment: string,
): string {
  return `https://app.tonkeeper.com/transfer/${wallet}?amount=${amount}&open=1&text=${comment}`
}

export function openTelegramLink(url: string): void {
  // eslint-disable-next-line no-console
  console.log(url)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.Telegram.WebApp.openLink(url)
}
