import { TonConnectUI, WalletInfo } from '@tonconnect/ui'

import { API_URL, APP_LANG } from '../../config/constants'

const allowedWallets = ['telegram-wallet', 'tonkeeper', 'tonhub']

class TCUI extends TonConnectUI {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  getWallets(): Promise<WalletInfo[]> {
    return super.getWallets().then((wallets) => {
      console.info('WALLETS', wallets)
      return wallets.filter((wallet) => allowedWallets.includes(wallet.appName))
    })
  }
}

export const connector = new TCUI({
  manifestUrl: 'https://frontend.gattogame.site/uploads/tonkeeper/tonconnect-manifest.json',
})

connector.uiOptions = {
  language: APP_LANG,
  // actionsConfiguration: {
  // twaReturnUrl: `${'https'}://${'t.me/stage_tamagochi_bot/Gatto'}`,
  // returnStrategy: `${'https'}://${'t.me/stage_tamagochi_bot/Gatto'}`,
  // },
  // actionsConfiguration: {
  //   returnStrategy: 'https://t.me/stage_tamagochi_bot/Gatto',
  // },
}
