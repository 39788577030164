import axios from 'axios'
import axiosRetry from 'axios-retry'
import { MINI_APP } from 'src'
import { API_URL } from 'src/config/constants'

import { ApiEndpoints, ApiMethods, Platform } from '@gatto/shared'

import { getLocationSearch } from './getLocationSearch'

axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1000
  },
})

export enum FetchingStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

export const makeRequest = async <Method extends ApiEndpoints>(
  method: Method,
  methodParams: ApiMethods[Method]['request'],
): Promise<ApiMethods[Method]['response']> => {
  let token = getLocationSearch()

  if (MINI_APP === Platform.TG) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    token = window.Telegram.WebApp.initData
  }

  const headers = {
    Authorization: `Bearer ${token}`,
  }
  const res = await axios.post(`${API_URL}/${method}`, methodParams, {
    headers,
  })

  return res.data
}
