import { useEffect, useRef, useState } from 'react'
import { POPOUT_ANIMATION_MS } from 'const/index'
import { Popouts } from 'const/router'

import useGetPopoutParam from './useGetPopoutParam'

// хук для определения открытого попаута
interface useGetPopoutResponse {
  mountedPopout: keyof Popouts | null
}

const useGetPopout = (): useGetPopoutResponse => {
  const { popoutName } = useGetPopoutParam('popout')
  const [mountedPopout, setMountedPopout] = useState(popoutName)
  const timeoutRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (popoutName) {
      timeoutRef.current && clearTimeout(timeoutRef.current)
      setMountedPopout(popoutName)
    } else {
      timeoutRef.current = setTimeout(() => {
        setMountedPopout(null)
      }, POPOUT_ANIMATION_MS)
    }
  }, [popoutName])

  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current)
    }
  }, [])

  return {
    mountedPopout,
  }
}

export default useGetPopout
