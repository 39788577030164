import { FC } from 'react'
import styled from '@emotion/styled'
import { API_URL, APP_LANG } from 'src/config/constants'

export const BannerImage = styled.img`
  height: 171px;
  width: 353px;
  border-radius: 26px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  align-self: center;
`

const RatingBanner: FC = () => {
  return (
    <BannerImage
      src={
        APP_LANG === 'ru'
          ? `https://frontend.gattogame.site/uploads/news/rating2.png`
          : `https://frontend.gattogame.site/uploads/news/best_pet_eng.png`
      }
    />
  )
}

export default RatingBanner
