import styled from '@emotion/styled'

export const ItemImage = styled.img`
  width: 149.114px;
  flex-shrink: 0;
  border-radius: 16px;
  background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(255, 255, 255, 0.11) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(0deg, #233d75 0%, #233d75 100%),
    lightgray -35.397px -26.732px / 146.54% 131.553% no-repeat;

  @media screen and (max-width: 375px) {
    width: 140px;
    max-height: 150px;
  }
`
