/* eslint-disable no-console */
import { Sprite, SwimGame, TilingSprite } from 'src/pixi/classes'
import { SwimGamePet } from 'src/pixi/classes/SwimGamePet'
import { SwimGameBackgroundSprites } from 'src/pixi/types/swimgame'

import {
  CONTAINER_SCALE_COEF,
  FINISH_LINE_X,
  POOL_WIDTH,
  SWIM_DEPTH,
  SwimStatus,
  TRACK_WIDTH,
} from '../constants'

/**
 * Переместить питомца
 * @param pet объект питомца
 */
export function movePet(this: SwimGame, pet: SwimGamePet): void {
  const xOffset = pet.getPetXOffset()
  const y = pet.getPetEngineY()

  if (!xOffset || y === undefined) return
  pet.x += xOffset
  this.movePetY(pet, y)

  if (pet.x <= 307 || pet.x >= this.fullTrack.length * POOL_WIDTH) {
    pet.setAnimation('run', true)
    this.canDive = false
    pet.swimStatus = SwimStatus.Run
  } else {
    pet.setAnimation('swim', true)
    this.canDive = true
    if (pet.swimStatus === SwimStatus.Run) {
      pet.swimStatus = SwimStatus.Surf
    }
  }

  if (pet.x >= this.fullTrack.length * POOL_WIDTH + FINISH_LINE_X) {
    pet.setAnimation('idle', true)
  }
}

export function movePetY(this: SwimGame, pet: SwimGamePet, y: number): void {
  const addedY =
    this.getObjectSecondCoord({
      row: pet.row,
      objOffset: -pet.petSpine.height / 5,
    }) + (pet.swimStatus !== SwimStatus.Run ? SWIM_DEPTH : 0)
  pet.setY(-y, addedY)
}

/**
 * Переместить anchor питомца
 */
export function moveAnchorPet(this: SwimGame): void {
  const xOffset = this.anchorPet.getPetXOffset()
  if (
    this.currentTrackOffset <
      this.fullTrack.length * POOL_WIDTH +
        307 -
        CONTAINER_SCALE_COEF * TRACK_WIDTH &&
    xOffset
  ) {
    this.moveTrack(xOffset)
  }
  this.movePet(this.anchorPet as SwimGamePet)
}

export function moveTrack(this: SwimGame, xOffset: number): void {
  this.currentTrackOffset += xOffset
  if (
    Math.floor((this.currentTrackOffset - 300) / POOL_WIDTH) > this.currentTrack
  ) {
    if (this.currentTrack < this.fullTrack.length - 3) {
      const curTrack = this.waveArray.shift()
      if (curTrack) {
        curTrack.x += POOL_WIDTH * 3
        this.waveArray.push(curTrack)
      }
    }

    this.currentTrack++
  }
  this.interractiveContainer.x = -this.currentTrackOffset

  this.moveBackground(xOffset)
}

export function moveBackground(this: SwimGame, xOffset: number): void {
  Object.entries(this.backgroundSprites).forEach(([name, sprite]) => {
    if (sprite instanceof Sprite) return
    if (sprite.tilePosition.x >= 0) return
    const width =
      this.backgroundSpritesWidth[name as keyof SwimGameBackgroundSprites]
    if (!width) return
    sprite.tilePosition.x += width * 5
  })

  this.backgroundSprites.windows.tilePosition.x -= xOffset * 0.6
  this.backgroundSprites.stands.tilePosition.x -= xOffset * 0.7
  this.backgroundSprites.floor.tilePosition.x -= xOffset
  this.backgroundSprites.floorDown.tilePosition.x -= xOffset

  this.moveTilingSprite(this.backgroundSprites.poolBack, xOffset)
  this.moveTilingSprite(this.backgroundSprites.lineDown, xOffset)
  this.moveTilingSprite(this.backgroundSprites.lineUp, xOffset)
  this.moveTilingSprite(this.backgroundSprites.backEndPoolDown, xOffset)
  this.moveTilingSprite(this.backgroundSprites.backEndPoolUp, xOffset)
  this.moveFilters(xOffset)
}

export function moveFilters(this: SwimGame, xOffset: number): void {
  this.filtersArray.forEach((filter, index) => {
    if (
      filter.x + 25 <= this.currentTrackOffset &&
      filter.x <
        this.fullTrack.length * POOL_WIDTH -
          filter.width -
          27 +
          (3 - index) * 26
    ) {
      filter.x += xOffset
      filter.skew.set(-0.28, 0)
    }
  })
}

export function moveTilingSprite(
  this: SwimGame,
  sprite: TilingSprite,
  xOffset: number,
): void {
  if (
    sprite.x <= this.currentTrackOffset &&
    sprite.x < this.fullTrack.length * POOL_WIDTH - sprite.width + 20
  ) {
    sprite.x += xOffset
    sprite.tilePosition.x -= xOffset
  }
}
