import styled from '@emotion/styled'

const Wallet = styled.div<{ isVotes: boolean }>`
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  padding: 5px;
  cursor: pointer;
  max-height: 30px;
  font-size: 12px;
  ${({ isVotes }) => (isVotes ? `max-width: 80px;` : `max-width: 60px;`)}
  flex-grow: 2;

  @media screen and (max-width: 400px) {
    width: 50px;
  }
`
export default Wallet
