/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import bridge from '@vkontakte/vk-bridge'
import * as PIXI from 'pixi.js'
import { MINI_APP } from 'src'
import { YM_COUNTER_ID } from 'src/config/constants'
import {
  RUNGAME_SPRITESHEET_PATH,
  SWIMGAME_SPRITESHEET_PATH,
} from 'src/config/assets'
import useLoadAsset, { loadAsset } from 'src/hooks/useLoadAsset'
import { BaseOlympicGame, GamePet, RunGame, SwimGame } from 'src/pixi/classes'
import { Pet } from 'src/pixi/classes/Pet'
import { SwimGamePet } from 'src/pixi/classes/SwimGamePet'
import {
  OlympicGameTextures,
  OtherTextures,
} from 'src/pixi/types/baseOlympicGame'
import { SpineAsset, SpineTexture } from 'src/pixi/types/spine'
import { makeRequest } from 'utils/api'
import { getVkUserId } from 'utils/getLocationSearch'
import { petSpriteMapping } from 'utils/petSpriteMapping'

import { RootDispatch } from 'store/index'
import { incrementBalance, updateCanWatchAds, updatePet } from 'store/user'

import { GameEndedEvent } from '@gatto/engine'
import {
  GameType,
  PetKind,
  Platform,
  UserPrize,
  WaitroomNewGameRes,
} from '@gatto/shared'

const PetJson = '/sprites/pets'
const TextureSprites = '/sprites/clothes/spritesheet.json'
const WaveTextures = '/sprites/swimgame/spine/wave.json'
const SwimSplashesTextures = '/sprites/swimgame/spine/swim_splashes.json'
const BarrierTextures = '/sprites/swimgame/spine/barrier.json'

const Container = styled.div`
  width: 100%;
  height: 100%;

  > canvas {
    width: 100%;
    height: 100%;
  }
`

const Gradient = styled.div((props) => ({
  width: '100%',
  height: '50%',
  position: 'absolute',
  top: '0',
  left: '0',
  zIndex: '-1',
  background: props.color
    ? '#2fe3ea'
    : 'linear-gradient(to bottom, #6ce4c2 0%, #f8ea76 37%)',
}))

interface OlympicGameLocationState {
  data: WaitroomNewGameRes
  anchorPetId: string
  lobbyId?: string
}

export const OlympicGamePage: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch: RootDispatch = useDispatch()

  const [locationGameState, setLocationGameState] =
    useState<OlympicGameLocationState | null>(null)

  const [backgroundTextures, setBackgroundTextures] =
    useState<OlympicGameTextures | null>(null)

  const [otherBackgroundTextures, setOtherBackgroundTextures] =
    useState<OtherTextures | null>(null)

  useEffect(() => {
    console.log(getVkUserId())
    console.log('rerender locationGameState')
    setLocationGameState(location.state)
  }, [location.state])

  useEffect(() => {
    if (!locationGameState) return
    const loadAssetFunc = async () => {
      let spritesheet
      let barrier
      let wave
      let splashes
      switch (locationGameState.data.game.type) {
        case GameType.Race:
          spritesheet = await loadAsset<PIXI.Spritesheet>(
            'main',
            RUNGAME_SPRITESHEET_PATH,
          )
          setBackgroundTextures({ main: spritesheet.data as PIXI.Spritesheet })
          break
        case GameType.Swim:
          spritesheet = await loadAsset<PIXI.Spritesheet>(
            'main',
            SWIMGAME_SPRITESHEET_PATH,
          )
          barrier = await loadAsset<{ spineData: SpineTexture }>(
            'barrier',
            BarrierTextures,
          )
          wave = await loadAsset<{ spineData: SpineTexture }>(
            'wave',
            WaveTextures,
          )
          splashes = await loadAsset<{ spineData: SpineTexture }>(
            'splashes',
            SwimSplashesTextures,
          )
          setBackgroundTextures({
            main: spritesheet.data as PIXI.Spritesheet,
            barrier: barrier.data?.spineData as SpineTexture,
            wave: wave.data?.spineData as SpineTexture,
          })
          setOtherBackgroundTextures({
            splashes: splashes.data?.spineData as SpineTexture,
          })
          break
        default:
          console.log('Unknown gameType')
      }
    }
    loadAssetFunc()
  }, [locationGameState])

  useEffect(() => {
    if (MINI_APP === Platform.VK) {
      bridge.send('VKWebAppHideBannerAd')
      bridge.send('VKWebAppCheckBannerAd').then((data) => {
        if (data.result) {
          bridge.send('VKWebAppHideBannerAd')
        }
      })
    }
  }, [])

  const navigateToRewarding = useCallback(
    async (data: GameEndedEvent) => {
      if (!locationGameState) return
      const usersPrizes = data.usersPrizes as UserPrize[]
      console.info(
        data,
        data.usersPrizes.find((user) => user.userId === Number(getVkUserId())),
      )

      await makeRequest('pet.getById', {
        id: locationGameState?.anchorPetId,
      }).then((res) => {
        console.log(res)
        dispatch(updatePet(res))
      })

      dispatch(
        incrementBalance({
          soft: Number(
            usersPrizes.find((user) => user.userId === Number(getVkUserId()))
              ?.prize.moneyAmount,
          ),
          hard: Number(
            usersPrizes
              .find((user) => user.userId === Number(getVkUserId()))
              ?.extraAwards.find((item) => item.type === 'ton')?.item,
          ),
          event: Number(
            usersPrizes
              .find((user) => user.userId === Number(getVkUserId()))
              ?.extraAwards.find((item) => item.type === 'event')?.item,
          ),
        }),
      )
      dispatch(updateCanWatchAds(true))
      navigate('/rewarding', {
        state: {
          gameType: locationGameState.data.game.type,
          lobbyId: locationGameState.lobbyId,
          ...data,
        },
        replace: true,
      })
    },
    [locationGameState],
  )

  const containerRef = useRef<HTMLDivElement>(null)
  const [app, setApp] = useState<BaseOlympicGame | null>(null)

  const clothesTexture = useLoadAsset<PIXI.Spritesheet>(TextureSprites)

  /** Монтируем канвас PIXI в контейнер */
  useEffect(() => {
    const AsyncFuncEffect = async () => {
      console.log('async callback')
      if (!backgroundTextures || !clothesTexture || !locationGameState) return
      const { data, anchorPetId } = locationGameState
      const gameType = data.game.type
      console.log('async callback get data ', data)
      console.log('background textures ', backgroundTextures)
      if (!containerRef.current) return
      const container = containerRef.current

      const arrayAssets = await Promise.all(
        data.pets.map((pet) => {
          return loadAsset<SpineAsset>(
            String(pet._id),
            `${PetJson}/${
              petSpriteMapping[pet.basePet.kind ?? PetKind.DogCorgy]
            }`,
          )
        }),
      )

      const petsAssets = new Map<string, SpineAsset | undefined>()
      arrayAssets.forEach((asset) => {
        petsAssets.set(asset.key, asset.data)
      })

      const canvas = document.createElement('canvas')
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
      container.appendChild(canvas)

      let anchorPet: Pet
      const otherPets: Map<string, Pet> = new Map()

      for await (const pet of data.pets) {
        const petTexture = petsAssets.get(String(pet._id))?.spineData

        const petSkins = await makeRequest('skin.getPetSkins', {
          petId: pet._id.toString(),
        })

        if (!petTexture) return
        if (String(pet._id) === anchorPetId) {
          anchorPet = new Pet({
            texture: petTexture,
            currentSkin: pet.basePet.rarity,
            clothesSpriteSheet: clothesTexture,
            currentClothes: petSkins,
          })
        } else {
          otherPets.set(
            String(pet._id),
            new Pet({
              texture: petTexture,
              currentSkin: pet.basePet.rarity,
              clothesSpriteSheet: clothesTexture,
              currentClothes: petSkins,
            }),
          )
        }
      }

      const gameProps = {
        lobbyUrl: data.game.lobbyUrl,
        gameId: data.game.id,
        finishGame: (data: GameEndedEvent) => {
          navigateToRewarding(data)
          if (MINI_APP === Platform.TG) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            ym(YM_COUNTER_ID, 'reachGoal', 'match_end')
          }
        },
        textures: backgroundTextures,
        gameType,
        width: window.innerWidth,
        height: window.innerHeight,
        resolution: window.devicePixelRatio,
        backgroundAlpha: 0,
        antialias: true,
        view: canvas,
      }

      if (gameType === GameType.Race) {
        const petsMap: Map<string, GamePet> = new Map()
        otherPets.forEach((anotherPet, key) => {
          petsMap.set(
            key,
            new GamePet({
              id: key,
              petSpine: anotherPet!,
              gameType,
            }),
          )
        })
        const app = new RunGame(
          Object.assign(gameProps, {
            anchorPet: new GamePet({
              id: anchorPetId,
              petSpine: anchorPet!,
              gameType,
            }),
            pets: petsMap,
          }),
        )
        setApp(app)
      } else if (gameType === GameType.Swim) {
        const petsMap: Map<string, SwimGamePet> = new Map()
        otherPets.forEach((anotherPet, key) => {
          petsMap.set(
            key,
            new SwimGamePet({
              id: key,
              petSpine: anotherPet!,
              gameType,
              splashes: otherBackgroundTextures?.splashes as SpineTexture,
            }),
          )
        })
        const app = new SwimGame(
          Object.assign(gameProps, {
            anchorPet: new SwimGamePet({
              id: anchorPetId,
              petSpine: anchorPet!,
              gameType,
              splashes: otherBackgroundTextures?.splashes as SpineTexture,
            }),
            pets: petsMap,
          }),
        )
        setApp(app)
      }
      if (MINI_APP === Platform.TG) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        ym(YM_COUNTER_ID, 'reachGoal', 'match_start')
      }
    }

    AsyncFuncEffect()
  }, [
    backgroundTextures,
    clothesTexture,
    locationGameState,
    otherBackgroundTextures,
  ])

  useEffect(() => {
    return () => {
      if (app) app.destroyApp()
      console.log('destroyed', app)
      setApp(null)
    }
  }, [])

  return (
    <Container id="map" ref={containerRef}>
      <Gradient
        color={locationGameState?.data.game.type === GameType.Swim ? '1' : ''}
      />
    </Container>
  )
}

OlympicGamePage.displayName = 'GamePage'
