import React, { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { ISpritesheetFrameData, Spritesheet } from 'pixi.js'
import { MAP_SPRITESHEET_PATH } from 'src/config/assets'
import useLoadAsset from 'src/hooks/useLoadAsset'
import texturePackerToCanvas from 'src/utils/texturePackerToCanvas'

const Img = styled.canvas`
  border-radius: 10px;
  flex-shrink: 0;
  width: 125px;
  height: auto;
`

interface BiomeImgProps {
  name: string
}

const BiomeImg: React.FC<BiomeImgProps> = ({ name }) => {
  const spritesheet = useLoadAsset<Spritesheet>(MAP_SPRITESHEET_PATH)
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const frames = spritesheet?.data.frames
    const imagePath = spritesheet?.baseTexture.resource.src
    const spriteName = [`platform_${name}.png`, `platform_${name}_1.png`].find(
      (spriteName) => frames && frames[spriteName],
    )

    if (!imagePath || !canvas || !spriteName) return

    const { rotated, frame } = frames?.[spriteName] as ISpritesheetFrameData
    const src = spritesheet.baseTexture.resource.src

    const context = canvas.getContext('2d')
    const image = new Image(frame.w, frame.h)
    image.src = src

    function onLoad() {
      if (!context || !canvas) return
      texturePackerToCanvas(canvas, image, frame, rotated)
    }

    image.addEventListener('load', onLoad)
    return () => image.removeEventListener('load', onLoad)
  }, [spritesheet, name])

  return <Img ref={canvasRef} />
}

export default BiomeImg
