import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { ALL_ASSETS } from 'src/config/assets'
import { LoadPage } from 'pages'

type AppSuspenseProps = {
  children: React.ReactNode
}

const LoadIndicatorStyled = styled.div`
  display: none;
`

type LoadIndicatorProps = {
  onLoad: () => void
}

const LoadIndicator: React.FC<LoadIndicatorProps> = ({ onLoad }) => {
  useEffect(() => {
    return () => onLoad()
  }, [])
  return <LoadIndicatorStyled />
}

function AppSuspense(props: AppSuspenseProps): JSX.Element {
  const [isReactLoaded, setIsReactLoaded] = useState(false)
  const [isSpritesLoaded, setIsSpritesLoaded] = useState(false)
  const navigate = useNavigate()

  const memorized = useCallback(() => {
    setIsSpritesLoaded(true)
  }, [])

  useEffect(() => {
    navigate('/', { replace: true })
  }, [])

  return (
    <React.Suspense
      fallback={<LoadIndicator onLoad={() => setIsReactLoaded(true)} />}
    >
      {!isReactLoaded || !isSpritesLoaded ? (
        <LoadPage
          mode="suspense"
          assets={ALL_ASSETS}
          onLoad={() => {
            memorized()
          }}
        />
      ) : (
        props.children
      )}
    </React.Suspense>
  )
}

export default AppSuspense
