import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@pixi/react'
import bridge, {
  BannerAdLayoutType,
  BannerAdLocation,
} from '@vkontakte/vk-bridge'
import { TFunction } from 'i18next'
import * as PIXI from 'pixi.js'
import { MINI_APP } from 'src'
import { GIFT_SPRITESHEET_PATH } from 'src/config/assets'
import { API_URL, APP_LANG } from 'src/config/constants'
import useLoadAsset from 'src/hooks/useLoadAsset'
import useScreenDimensions from 'src/hooks/useScreenDimensions'
import { OpenGiftAnimation } from 'src/pixi/gift-animation'
import { SpineTexture } from 'src/pixi/types/spine'
import { getRarityName } from 'utils/getRarityName'

import { LOCALES } from '@gatto/locales'
import {
  BaseEgg,
  BaseEssence,
  BaseSkin,
  Platform,
  PrizeResult,
} from '@gatto/shared'

const essenceGuard = (item: object): item is BaseEssence => {
  return 'experience' in item
}

const skinGuard = (item: object): item is BaseSkin => {
  return 'bodyPart' in item
}

const eggGuard = (item: object): item is BaseEgg => {
  return 'allowedRegion' in item
}

const getRarityCard = (
  rarity: 'mif' | 'legendary' | 'normal' | 'rare',
): string => {
  const src = `https://frontend.gattogame.site/uploads/giftCards/`

  switch (rarity) {
    case 'legendary':
      return src + 'obj_leg_card.png'
    case 'mif':
      return src + 'obj_mif_card.png'
    case 'rare':
      return src + 'obj_rare_card.png'
    default:
      return src + 'obj_reg_card.png'
  }
}

const generateExactItem = (
  t: TFunction,
  item: BaseEgg | BaseSkin | BaseEssence,
  setCardTextures: React.Dispatch<
    React.SetStateAction<Array<PIXI.Texture<PIXI.Resource>> | null>
  >,
) => {
  if (eggGuard(item)) {
    const rarity = item.rarity
    const photoUrl = item.photoUrl

    generateCard(
      t(LOCALES.gift_egg),
      getRarityCard(rarity),
      `${API_URL}${photoUrl}`,
      setCardTextures,
      APP_LANG === 'ru'
        ? getRarityName(t, rarity, 'neuter')
        : t(LOCALES[`rarity_${rarity}`]),
    )

    return
  }

  if (skinGuard(item)) {
    const rarity = item.rarity
    const photoUrl = item.photoUrl

    generateCard(
      t(LOCALES.gift_skin),
      getRarityCard(rarity),
      `${API_URL}${photoUrl}`,
      setCardTextures,
      APP_LANG === 'ru'
        ? getRarityName(t, rarity, 'female')
        : t(LOCALES[`rarity_${rarity}`]),
    )

    return
  }

  if (essenceGuard(item)) {
    const photoUrl = item.photoUrl
    const expCardSrc = `https://frontend.gattogame.site/uploads/giftCards/exp_card.png`

    generateCard(
      t(LOCALES.gift_essence),
      expCardSrc,
      `${API_URL}${photoUrl}`,
      setCardTextures,
      t(`essenceType_${item.type}`),
    )

    return
  }
}

const generateCard = (
  title: string,
  cardSrc: string,
  itemSrc: string,
  setTextures: React.Dispatch<
    React.SetStateAction<Array<PIXI.Texture<PIXI.Resource>> | null>
  >,
  description?: string | number | null,
) => {
  const width = 200
  const height = 200

  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height

  const context = canvas.getContext('2d')

  if (context) {
    context.imageSmoothingEnabled = true

    const img = new Image()
    const img2 = new Image()

    img.onload = () => {
      context.drawImage(img, 0, 0, width, height)
      img2.src = itemSrc
      context.font = `400 27pt Cartonsix_NC`

      img2.onload = () => {
        context.drawImage(
          img2,
          width / 8,
          height / 8,
          width * 0.75,
          height * 0.75,
        )
        context.fillStyle = 'white'
        context.textAlign = 'center'
        context.miterLimit = 2
        context.lineWidth = 5
        context.strokeText(title, width / 2, height / 4.5)
        context.lineWidth = 1
        context.fillText(title, width / 2, height / 4.5)

        if (typeof description === 'string') {
          context.font = `800 15pt Nunito`
        }

        if (typeof description === 'number') {
          context.font = `800 18pt Nunito`
        }

        if (description) {
          context.fillStyle = 'white'
          context.textAlign = 'center'
          context.miterLimit = 2
          context.lineWidth = 5
          context.strokeText(
            description.toString().toLocaleLowerCase(),
            width / 2,
            170,
          )
          context.fillText(
            description.toString().toLocaleLowerCase(),
            width / 2,
            170,
          )
        }

        const imageDataURL = canvas.toDataURL('image/png', 1.0)
        const texture = PIXI.Texture.from(imageDataURL)

        setTextures((prev) => {
          if (!prev) {
            return [texture]
          }

          return [...prev, texture]
        })
      }
    }

    img.src = cardSrc
    img2.crossOrigin = 'anonymous'
    img.crossOrigin = 'anonymous'
  }
}

const getPrizesArray = (
  prizes: PrizeResult,
): {
  prizesArray: Array<BaseEgg | BaseSkin | BaseEssence | number>
  prizesLength: number
} => {
  const prizesArray = Object.entries(prizes)
    .filter(([key, value]) => {
      if (key !== 'soft' && key !== 'experience') {
        if (
          (value instanceof Array && value.length) ||
          (value && !(value instanceof Array))
        ) {
          return true
        }
      }

      return false
    })
    .map(([_, value]) => {
      return value
    }) as Array<BaseEgg[] | BaseSkin[] | BaseEssence[] | number>

  const prizesWithoutArray: Array<BaseEgg | BaseSkin | BaseEssence | number> =
    []

  prizesArray.forEach((item) => {
    if (item instanceof Array && item) {
      item.forEach((subItem) => {
        prizesWithoutArray.push(subItem)
      })
    } else {
      prizesWithoutArray.push(item)
    }
  })

  return {
    prizesArray: prizesWithoutArray,
    prizesLength: 2 + prizesWithoutArray.length,
  }
}

type GiftAnimationContainerProps = {
  onEnd: () => void
  prizes: PrizeResult
}

export const GiftAnimationContainer: FC<GiftAnimationContainerProps> = ({
  onEnd,
  prizes,
}) => {
  const { t } = useTranslation()

  const giftRef = useRef<PIXI.Container>(null)
  const screenDimensions = useScreenDimensions()
  const containerX = screenDimensions.width / 2 + 15
  const containerY = screenDimensions.height / 2 + 97
  const spineGiftAnimation = useLoadAsset<{ spineData: SpineTexture }>(
    GIFT_SPRITESHEET_PATH,
  )
  const softCardSrc = `https://frontend.gattogame.site/uploads/giftCards/coins_card.png`
  const pawsCardSrc = `https://frontend.gattogame.site/uploads/giftCards/paws_card.png`
  const pawsSrc = `https://frontend.gattogame.site/uploads/store/paws_small.png`
  const expCardSrc = `https://frontend.gattogame.site/uploads/giftCards/exp_card.png`
  const expSrc = `https://frontend.gattogame.site/uploads/essences/exp_tiny.png`
  const coinsItem = `https://frontend.gattogame.site/uploads/store/coins_tiny.png`

  const { prizesArray, prizesLength } = getPrizesArray(prizes)

  const [cardTextures, setCardTextures] = useState<PIXI.Texture[] | null>(null)

  useEffect(() => {
    if (giftRef.current && spineGiftAnimation?.spineData) {
      const texture = spineGiftAnimation.spineData

      const addUnit = async (container: PIXI.Container) => {
        // const font = new FontFace(
        //   'Cartonsix_NC',
        //   'url(../../assets/fonts/Cartonsix_NC.ttf)',
        // )
        //
        // await font.load()

        if (!cardTextures?.length) {
          generateCard(
            t(LOCALES.gift_coins),
            softCardSrc,
            coinsItem,
            setCardTextures,
            prizes.soft,
          )
        }

        if (cardTextures?.length === 1) {
          generateCard(
            `${t(LOCALES.experience).toLowerCase()}`,
            expCardSrc,
            expSrc,
            setCardTextures,
            prizes.experience,
          )
        }

        if (cardTextures?.length === 2 && prizesLength > 2) {
          if (typeof prizesArray[0] === 'object' && prizesArray[0]) {
            generateExactItem(t, prizesArray[0], setCardTextures)
          } else {
            generateCard(
              MINI_APP === Platform.TG
                ? 'TON'
                : `${t(LOCALES.gift_paws).toLowerCase()}`,
              pawsCardSrc,
              pawsSrc,
              setCardTextures,
              prizes.hard,
            )
          }
        }

        if (cardTextures?.length === 3 && prizesLength > 3) {
          if (typeof prizesArray[1] === 'object' && prizesArray[1]) {
            generateExactItem(t, prizesArray[1], setCardTextures)
          } else {
            generateCard(
              MINI_APP === Platform.TG
                ? 'TON'
                : `${t(LOCALES.gift_paws).toLowerCase()}`,
              pawsCardSrc,
              pawsSrc,
              setCardTextures,
              prizes.hard,
            )
          }
        }

        if (cardTextures?.length === prizesLength) {
          try {
            if (MINI_APP === Platform.VK) {
              bridge.send('VKWebAppShowBannerAd', {
                banner_location: BannerAdLocation.BOTTOM,
                layout_type: BannerAdLayoutType.OVERLAY,
              })
            }
          } catch (error) {
            console.warn(error)
          }

          const animation = new OpenGiftAnimation({
            texture,
            cardTextures,
            onEnd,
          })

          container.removeChildren()

          container.addChild(animation)

          animation.run()
        }
      }

      addUnit(giftRef.current).catch(console.error)
    }
  }, [spineGiftAnimation, cardTextures?.length])

  return (
    <Container x={containerX} y={containerY}>
      <Container ref={giftRef} scale={0.3} />
    </Container>
  )
}
