import { FC } from 'react'
import styled from '@emotion/styled'

import { PetRatingCard, RatingCard } from './RatingCard'

const Container = styled.div`
  width: 100%;
  padding: 15px;

  &::-webkit-scrollbar {
    display: none;
  }
  border-radius: 26px;
  background: rgba(255, 255, 255, 0.1);
`

interface PlayersTopProps {
  petsList: PetRatingCard[]
}

export const PlayersTop: FC<PlayersTopProps> = ({ petsList }) => {
  return (
    <Container>
      {petsList?.map((pet, i) => {
        if (!pet) return

        return <RatingCard key={i} pet={pet} avatarBoxId={'petBox' + (i + 1)} />
      })}
    </Container>
  )
}
