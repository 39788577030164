import styled from '@emotion/styled'

export const HeaderText = styled.p<{
  textAlign?: string
  padding?: string
}>`
  color: #fff;
  text-align: ${({ textAlign }) => textAlign};
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  flex-grow: 2;
  ${({ padding }) => padding}
`

HeaderText.defaultProps = {
  textAlign: 'center',
  padding: '',
}
