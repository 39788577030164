export const DOMAIN = 'api.gattogame.site' //'https://localhost:5000'
export const MAIN_DOMAIN = 'gattogame.site'

export const WAITROOM_SUBDOMAIN = 'waitroom'

export const API_URL =
  import.meta.env.VITE_NODE_ENV === 'production'
    ? `https://${DOMAIN}`
    : 'http://127.0.0.1:5000'

export const WAITROOM_URL =
  import.meta.env.VITE_NODE_ENV === 'production'
    ? `https://${WAITROOM_SUBDOMAIN}.${MAIN_DOMAIN}`
    : 'ws://localhost:30001'

export const MODE = import.meta.env.MODE
export const ENABLE_MAP_TILES_DEBUG_OVERLAY =
  import.meta.env.VITE_ENABLE_MAP_TILES_DEBUG_OVERLAY === 'true'

export const PET_LEVELS = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
}

export const PET_LEVEL_WIDTH = 57
export const PET_LEVEL_HEIGHT = 56

export const GROUP_ID = 202759253
export const UNIQUE_APP_ID = 179
export const BACKGROUND_IMAGE_HEIGHT = 1920
export const BACKGROUND_IMAGE_WIDTH = 1600
export const BOWL_IMAGE_HEIGHT = 94
export const BOWL_IMAGE_WIDTH = 134
export const OBJECT_HEIGHT_MULTIPLIER = 0.0489

export const EXPERIENCE_TABLE: { [key: number]: number } = {
  1: 0,
  2: 10,
  3: 30,
  4: 110,
  5: 350,
  6: 700,
  7: 1000,
  8: 1500,
  9: 3000,
  10: 4500,
}

// Мой район
export const MAP_SIZE = 8
export const BASE_TILES = [
  'empty',
  'store',
  'park',
  'storage',
  'stadium',
  'festival',
] as const
export const ONE_MINUTE = 60 * 1000
export const ONE_HOUR = ONE_MINUTE * 60
export const ONE_DAY = ONE_HOUR * 24

export const allowedCryptoWallets = [
  {
    name: 'tonkeeper',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/Gram_cryptocurrency_logo.svg/225px-Gram_cryptocurrency_logo.svg.png',
  },
  {
    name: 'tonhub',
  },
  { name: 'telegram-wallet' },
]
export const TON_WALLET = 'UQBi01YXMYnPnv_rddXJeU4CD47lHyzlZ45JmKObIPL5V4cQ'
export const MAIN_ARTICLE_LINK = 'https://vk.com/@-202759253-o-proekte'
export const NFT_ARTICLE_LINK = 'https://vk.com/@gatto_game-nft'
export const VK_GROUP_LINK = 'https://vk.com/gatto_game'
export const VK_CHAT_LINK =
  'https://vk.me/join/mkf3hT4qCm4xa7UKO94pCVufBe11ZWAvgZw='
export const TG_GROUP_LINK = 'https://t.me/gatto_game'
export const TG_CHAT_LINK_RUS = 'https://t.me/chat_gatto'
export const TG_CHAT_LINK_ENG = 'https://t.me/chat_gatto_en'
export const VK_GROUP_FAQ_LINK = 'https://vk.com/@gatto_game-faq'
export const VK_GROUP_ID = 202759253
export const VK_MINI_APP_LINK =
  import.meta.env.VITE_NODE_ENV === 'production'
    ? 'https://vk.com/app51720506'
    : 'https://vk.com/app51730827' // нужно указать ID своего мини аппа для разработки

export const LOCATION_SEARCH = window.location.search.split('?')[1]
export const LOCATION_HASH = window.location.hash.replace('#', '')

export const APP_LANG =
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.Telegram?.WebApp?.initDataUnsafe?.user?.language_code ||
  LOCATION_SEARCH?.match(/vk_language=[a-z]{2}/)?.[0]?.slice(-2) ||
  ''
export const YM_COUNTER_ID = 95798148
