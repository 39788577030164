import { useEffect, useState } from 'react'
import { Assets, Cache, ProgressCallback } from 'pixi.js'

const useLoadAsset = <T>(
  key: string,
  onProgress?: ProgressCallback,
): T | undefined => {
  const [asset, setAsset] = useState<T>()

  useEffect(() => {
    const loadAsset = async () => {
      if (Cache.has(key)) {
        return setAsset(Cache.get(key))
      }

      const loadedAsset = await Assets.load<T>(key, onProgress)

      setAsset(loadedAsset)
    }

    loadAsset()
  }, [key, onProgress])

  return asset
}

export default useLoadAsset

type AssetType<T> = { key: string; data: T | undefined }

export const useLoadManyAssets = <T>(
  targets: Map<string, string>,
): Map<string, T | undefined> => {
  const [assets, setAssets] = useState<Map<string, T | undefined>>(
    new Map<string, T | undefined>(),
  )

  useEffect(() => {
    Promise.all(
      Array.from(targets.entries()).map(([key, value]) => {
        return loadAsset<T>(key, value)
      }),
    ).then((values) => {
      const m = new Map<string, T | undefined>()
      values.forEach((asset: AssetType<T>) => {
        m.set(asset.key, asset.data)
      })
      setAssets(m)
    })
  }, [targets])

  return assets
}

/** Для подгрузки аасетов без использования хука */
export const loadAsset = async <T>(
  key: string,
  path: string,
): Promise<AssetType<T>> => {
  if (Cache.has(path)) {
    return { key: key, data: Cache.get(path) }
  }
  const asset = await Assets.load<T>(path)
  return { key: key, data: asset }
}
