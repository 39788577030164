import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import useRoute from 'src/hooks/useRoute'

import { useAppSelector } from 'store/index'

import TabbarHighlight from './TabbarHighlight'
import { type IconType } from './TabbarIcon'
import TabbarItem from './TabbarItem'

const Root = styled.div<{ isHidden?: boolean }>`
  width: 100%;
  max-width: 441px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 18px;
  margin: auto;

  display: ${(props) => (props.isHidden ? 'none' : 'flex')};
  justify-content: center;

  z-index: 2;
`

const Container = styled.span`
  width: 100%;
  padding: 0 55px;

  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 61px;
`

type DestinationsType = Readonly<Array<`/${string}`>>

const LINKS = ['/news', '/', '/worldMap'] as const satisfies DestinationsType
type LinkType = (typeof LINKS)[number]

type LinkItemType = Record<LinkType, { title: string; icon: IconType }>

const tabbarItems: LinkItemType = {
  '/news': {
    title: 'news',
    icon: 'news',
  },
  '/': {
    title: 'district',
    icon: 'map',
  },
  '/worldMap': {
    title: 'faq',
    icon: 'hood',
  },
} as const

type BaseTabbarProps = {
  /**
   * Отображается ли таббар
   * @default true
   */
  isVisible?: boolean

  /**
   * Режим отображения таббара с одной кнопкой
   * @default false
   */
  isOneButtonMode?: boolean
}

type TabbarProps = BaseTabbarProps &
  Omit<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    keyof BaseTabbarProps
  >

/** Таббар */
function Tabbar({
  isVisible = true,
  isOneButtonMode = false,
  ...props
}: TabbarProps): React.ReactElement {
  const { t } = useTranslation()
  const { isTabbarHidden } = useAppSelector((state) => state.storage)

  // Получаем текущий путь
  const route = useRoute()
  const currentPath = (route?.path || '/') as LinkType
  const textColor = currentPath === '/' ? '#307276' : '#ffffffb2'

  // Получаем параметры из url, если там есть параметр mode=cross, то скрываем таббар
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const isTabbarHiddenFromUrl = searchParams.get('mode') === 'cross'

  // Не отображаем таббар, если необходимо
  if (!isVisible) {
    return <></>
  }

  return (
    <Root isHidden={isTabbarHidden || isTabbarHiddenFromUrl} {...props}>
      <Container>
        {isOneButtonMode && (
          <TabbarItem
            destination={currentPath}
            isSelected={true}
            icon={tabbarItems[currentPath].icon}
            color={textColor}
          >
            {t(tabbarItems[currentPath].title)}
          </TabbarItem>
        )}

        {!isOneButtonMode &&
          Object.keys(tabbarItems).map((destination) => {
            const item = tabbarItems[destination as LinkType]
            return (
              <TabbarItem
                key={destination}
                destination={destination as LinkType}
                isSelected={currentPath === destination}
                icon={item.icon}
                color={textColor}
              >
                {t(item.title)}
              </TabbarItem>
            )
          })}
      </Container>

      {!isOneButtonMode && (
        <TabbarHighlight
          activeTab={currentPath}
          mode={currentPath === '/' ? `dark` : `light`}
        />
      )}
    </Root>
  )
}

export default Tabbar
export type { LinkType }
