import { createContext } from 'react'

type ModalContextType = {
  activeModal?: string | null
  updateActiveModal: React.Dispatch<React.SetStateAction<string | null>>
}

export const ModalContext = createContext<ModalContextType>({
  updateActiveModal: () => {
    return
  },
})
