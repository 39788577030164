import React from 'react'
import styled from '@emotion/styled'
import { UsersStack } from '@vkontakte/vkui'

import { WaitroomUpdateList } from '@gatto/shared'

const Container = styled.div`
  display: flex;
  width: 87px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 6.833px;
  flex-shrink: 0;
`

const Label = styled.span`
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 15.032px; /* 93.952% */
`

interface OnlineStackProps extends React.HTMLAttributes<HTMLDivElement> {
  users: WaitroomUpdateList
  text: string
}

function OnlineStack({
  users,
  text,
  ...props
}: OnlineStackProps): React.ReactElement {
  return (
    <Container {...props}>
      <Label>{text}</Label>
      <UsersStack
        visibleCount={3}
        layout="vertical"
        size="m"
        photos={users.map((item) => item.avatar)}
      />
    </Container>
  )
}

export default React.memo(OnlineStack)
