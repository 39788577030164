import React, { FC } from 'react'
import styled from '@emotion/styled'
import { API_URL } from 'src/config/constants'

import { Header, Subheader } from 'components/base'
import Button from 'components/base/Button/Button'
import { InfoBlocks } from 'components/base/Popout/InfoBlocks'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 20px;
  align-self: stretch;
`
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;
`

const BigImage = styled.img`
  width: 99px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  align-self: center;
  border-radius: 10px;
`

const HeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  align-self: stretch;
`

type StorageContentProps = {
  photoUrl?: string
  image?: () => React.ReactNode
  header: string
  subheader: string
  items?: Array<{
    icon?: string | undefined
    title?: string | undefined
    value?: string | number | undefined
  }>
  children?: React.ReactNode
  handleClick?: () => void
  buttonText?: string
  category?: string
}

const StorageContent: FC<StorageContentProps> = ({
  photoUrl,
  image,
  header,
  subheader,
  items,
  children,
  handleClick,
  buttonText,
  category,
}) => {
  return (
    <>
      <Container>
        <ImageContainer>
          {photoUrl && (
            <BigImage
              src={category === 'nft' ? `${photoUrl}` : `${API_URL}${photoUrl}`}
            />
          )}
          {image && image()}
        </ImageContainer>
        <HeaderWrap>
          <Header lineHeight={20}>{header}</Header>
          <Subheader>{subheader}</Subheader>
        </HeaderWrap>
        {items && <InfoBlocks items={items} type={false} />}
        {children}
      </Container>
      {buttonText && (
        <Button variant="success" width="100%" onClick={handleClick}>
          {buttonText}
        </Button>
      )}
    </>
  )
}

export default StorageContent
