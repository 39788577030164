import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Card } from '@vkontakte/vkui'
import { Scroller } from 'popouts/EggsAndPets/EggAndPetCardList'
import { API_URL } from 'src/config/constants'
import { makeRequest } from 'utils/api'

import check from 'assets/svg/other/check.svg'
import { Popout, StatusType } from 'components/base/Popout'
import { useAppDispatch, useAppSelector } from 'store/index'
import {
  getRegionPetId,
  getRegionPetKind,
  updateRegionPetSkins,
} from 'store/region'
import { updateUserRegionPetSkins } from 'store/user'

import { LOCALES } from '@gatto/locales'
import { PetKind, SkinBodyPart, SkinGetRes } from '@gatto/shared'

export const RarityBackgroundColors = {
  normal:
    'background: linear-gradient(180deg, rgba(68, 104, 255, 0.10) 0%, rgba(59, 91, 224, 0.30) 100%), linear-gradient(0deg, #FFF 0%, #FFF 100%), #D9D9D9;',
  rare: 'background: linear-gradient(180deg, rgba(255, 198, 91, 0.10) 0%, rgba(255, 198, 91, 0.30) 100%), linear-gradient(0deg, #FFF 0%, #FFF 100%), #D9D9D9;',
  mif: 'background: linear-gradient(180deg, rgba(120, 56, 255, 0.10) 0%, rgba(120, 56, 255, 0.30) 100%), linear-gradient(0deg, #FFF 0%, #FFF 100%), #D9D9D9;',
  legendary:
    'background: linear-gradient(212deg, rgba(255, 234, 47, 0.16) 8.15%, rgba(250, 0, 255, 0.19) 45.18%, rgba(0, 240, 255, 0.30) 100%), linear-gradient(0deg, #FFF 0%, #FFF 100%), radial-gradient(94.60% 94.88% at 58.55% 7.95%, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.02) 100%), linear-gradient(180deg, rgba(68, 104, 255, 0.10) 0%, rgba(59, 91, 224, 0.10) 100%), linear-gradient(180deg, rgba(68, 143, 255, 0.05) 0%, rgba(59, 115, 224, 0.05) 100%);',
}

export const RarityColors = {
  normal:
    'background: linear-gradient(180deg, rgba(68, 104, 255, 0.62) 0%, #3B5BE0 100%);',
  rare: 'background: linear-gradient(180deg, rgba(255, 198, 91, 0.51) 0%, #FFC65B 100%);',
  mif: 'background: linear-gradient(180deg, rgba(120, 56, 255, 0.53) 0%, #7838FF 100%);',
  legendary:
    'background: radial-gradient(94.60% 94.88% at 58.55% 7.95%, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.02) 100%), linear-gradient(212deg, rgba(255, 234, 47, 0.73) 8.15%, rgba(250, 0, 255, 0.75) 45.18%, #00F0FF 100%), linear-gradient(180deg, rgba(120, 56, 255, 0.53) 0%, #7838FF 100%);',
}

const SkinCard = styled(Card)`
  position: relative;
  display: flex;
  width: 119px;
  padding: 5px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-right: 7px;
  border-radius: 13px;
  box-shadow: 0px 4.280207633972168px 0px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(41.196998596191406px);
  margin-bottom: 5px;
`

const SkinSelect = styled.div<{ isSelected: boolean }>`
  position: absolute;
  z-index: 1;
  top: 11px;
  right: 11px;
  width: 35px;
  height: 31px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.07);
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.isSelected &&
    'background: linear-gradient(0deg, rgba(36, 156, 77, 0.69) 0%, rgba(36, 156, 77, 0.69) 100%), linear-gradient(180deg, #32FF6B 0%, #32FF5F 100%); box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.15) inset; height: 35px;'}
`

const SelectIcon = styled.img``

export const SkinBackground = styled.div<{
  rarity?: keyof typeof RarityBackgroundColors
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 109px;
  height: 109px;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.03) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );

  ${(props) => {
    return props.rarity && RarityBackgroundColors[props.rarity]
  }}
`
export const SkinRarity = styled.div<{ rarity?: keyof typeof RarityColors }>`
  position: absolute;
  bottom: 7px;
  left: 47.5px;
  width: 15px;
  height: 5px;
  border-radius: 10px;
  ${(props) => {
    return props.rarity && RarityColors[props.rarity]
  }}
`

const SkinTitle = styled.div`
  color: rgba(0, 0, 0, 0.8);
  font-family: Nunito;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 109px;
  margin-bottom: 10px;
`

export const SkinImg = styled.img`
  width: 90px;
  height: 90px;
`

export const excludedBodyPart: { [key in PetKind | string]: string[] } = {
  frog: ['neck'],
  mushroom: ['tail'],
  capybara: ['tail'],
  hedgehog: ['tail'],
  crab: ['tail'],
  robo_fish: ['tail'],
  bull: ['face'],
}

const SkinsPopout: FC = () => {
  const { t } = useTranslation()
  const petKind = useAppSelector(getRegionPetKind)
  const [status, setStatus] = useState<StatusType>()
  const [activeTab, setActiveTab] = useState('head')
  const [tabItems, setTabItems] = useState([
    { name: t(LOCALES.head), type: 'head' },
    { name: t(LOCALES.neck), type: 'neck' },
    { name: t(LOCALES.face), type: 'face' },
    { name: t(LOCALES.tail), type: 'tail' },
  ])

  const dispatch = useAppDispatch()
  const [skins, setSkins] = useState<SkinGetRes>()
  const petId = useAppSelector(getRegionPetId)?.toString()
  const [isRemoved, setIsRemoved] = useState(false)
  const [selectedSkin, setSelectedSkin] = useState<string | undefined>(
    undefined,
  )

  useEffect(() => {
    if (petKind && excludedBodyPart[petKind]) {
      setTabItems(() => {
        const tabs = tabItems.filter((item) => {
          let flag = true

          excludedBodyPart[petKind]?.forEach((bodyPart) => {
            if (item.type === bodyPart) {
              flag = false
            }
          })

          return flag
        })

        return tabs
      })
    }
  }, [])

  useEffect(() => {
    setSkins([])
    if (petId) {
      makeRequest('skin.get', {
        bodyPart: activeTab as SkinBodyPart,
        petId: petId,
      }).then((res) => {
        setSkins(res)
        setSelectedSkin(undefined)
        setIsRemoved(false)
      })
    }
  }, [activeTab])

  const handleRemove = async (
    petId: string | undefined,
    selectedSkin: string | undefined,
  ) => {
    if (selectedSkin && petId) {
      try {
        const skins = await makeRequest('skin.undress', {
          petId,
          userSkinPetId: selectedSkin,
        })

        setSelectedSkin(undefined)
        setIsRemoved(true)
        dispatch(updateRegionPetSkins(skins))
        dispatch(updateUserRegionPetSkins({ petId, skins }))
      } catch (error) {
        setSelectedSkin(undefined)
        setIsRemoved(true)
        dispatch(updateRegionPetSkins([]))
        dispatch(updateUserRegionPetSkins({ petId, skins: [] }))
      }
    }
  }

  const handleSelect = async (
    petId: string | undefined,
    prevUserSkinPetId: string | undefined,
    userSkinPetId: string,
  ) => {
    if (petId) {
      const skins = await makeRequest('skin.dress', {
        petId,
        prevUserSkinPetId,
        userSkinPetId,
      })

      setSelectedSkin(userSkinPetId)
      setIsRemoved(false)
      dispatch(updateRegionPetSkins(skins))
      dispatch(updateUserRegionPetSkins({ petId, skins }))
    }
  }

  return (
    <Popout
      type="tabs"
      tabItems={tabItems}
      header={tabItems}
      isFullScreen={false}
      setStatus={setStatus}
      status={status}
      background={false}
      position="bottom"
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      width={'100%'}
      maxWidth={'373px'}
      headerPosition={'left'}
    >
      <Scroller id="cardScroll">
        <SkinCard>
          <SkinSelect
            isSelected={selectedSkin === undefined}
            onClick={() => handleRemove(petId, selectedSkin)}
          >
            {selectedSkin === undefined && <SelectIcon src={check} />}
          </SkinSelect>
          <SkinBackground>
            <SkinRarity />
          </SkinBackground>
          <SkinTitle>{t(LOCALES.notWearing)}</SkinTitle>
        </SkinCard>

        {skins &&
          skins.map((skin, index) => {
            const userSkinPetId = skin.userSkinPetId.toString()

            if (skin.isSelected && selectedSkin === undefined && !isRemoved) {
              setSelectedSkin(userSkinPetId)
            }

            return (
              <SkinCard key={index}>
                <SkinSelect
                  isSelected={selectedSkin === userSkinPetId}
                  onClick={() =>
                    handleSelect(petId, selectedSkin, userSkinPetId)
                  }
                >
                  {selectedSkin === userSkinPetId && <SelectIcon src={check} />}
                </SkinSelect>
                <SkinBackground rarity={skin.rarity}>
                  <SkinImg src={`${API_URL}${skin.photoUrl}`} />
                  <SkinRarity rarity={skin.rarity} />
                </SkinBackground>
                <SkinTitle>{t('skin_' + skin.itemName)}</SkinTitle>
              </SkinCard>
            )
          })}
      </Scroller>
    </Popout>
  )
}

export default SkinsPopout
