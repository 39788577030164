import { StatusAlertPopout } from 'const/router'
import { makeRequest } from 'utils/api'

import { TonGetPaymentRes } from '@gatto/shared'
import { connector } from 'utils/ton/connector'
import { PayloadAction } from '@reduxjs/toolkit'
import { setPaymentProcess } from 'store/payment'

interface PaymentProps {
  uniqueId: string
  intervalValue: { current: NodeJS.Timeout | null }
  isReqNeeded: React.MutableRefObject<boolean>
  successPopout: (restProps?: StatusAlertPopout | undefined) => void
  errorPopout: (restProps?: StatusAlertPopout | undefined) => void
  waitPopout: (restProps?: StatusAlertPopout | undefined) => void
  stopInterval: () => void
  startInterval: () => void
  // dispatch: (action: PayloadAction<boolean>) => void
}

export const handleGetTonPayments = async (
  data: PaymentProps,
): Promise<TonGetPaymentRes | void> => {
  const { modal } = connector
  const { status } = modal.state

  if (status !== 'opened') {
    data.startInterval()
    const res = await makeRequest('ton.getPayment', {
      data: data.uniqueId,
    })

    if (res.status === 'got' || res.status === 'error') {
      data.stopInterval()
      clearInterval(data.intervalValue.current as NodeJS.Timeout)
      data.isReqNeeded.current = false
    }

    if (res.status === 'got') {
      // data.successPopout()
    }
    if (res.status === 'error') {
      // data.errorPopout()
    }
    if (res.status === 'wait') {
      // data.waitPopout()
    }

    return res
  }
  return
}
