import * as PIXI from 'pixi.js'

import { SpriteOptions } from '../types/sprite'

import { optionInstaller } from './../utils/optionInstaller'

export class TilingSprite extends PIXI.TilingSprite {
  optionInstaller = optionInstaller

  constructor(
    texture: PIXI.Texture,
    width: number,
    height: number,
    options: SpriteOptions = {},
  ) {
    super(texture, width, height)
    this.optionInstaller(options)
  }
}
