import { FC, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Container, Stage } from '@pixi/react'
import { Spinner } from '@vkontakte/vkui'
import * as PIXI from 'pixi.js'
import { useRating } from 'src/hooks'

import {
  BackButton,
  type PetRatingCard,
  PlayersTop,
  RatingCard,
  Row,
  Wallet,
} from 'components/base'
import RatingBanner from 'components/base/Rating/RatingBanner'
import { Page, PageContainer, PageHeader } from 'components/page'
import { Entity, EntityPet } from 'components/pixi'
import { HeaderText } from 'components/typography'
import { useAppSelector } from 'store/index'

import { LOCALES } from '@gatto/locales'
import { BasePet } from '@gatto/shared'

const PlayerInfoWrapper = styled.div`
  display: flex;
  max-width: 353px;
  max-height: 95px;
  width: 100%;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  border-radius: 26px;
  background: rgba(255, 255, 255, 0.1);
  align-self: center;
  margin-bottom: 20px;
`

const PlayersTopWrapper = styled(Row)`
  display: flex;
  max-width: 353px;
  height: 100%;
  width: 100%;
  align-self: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const TopText = styled.div`
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-style: normal;
  margin-bottom: 15px;
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 353px;
  width: 100%;
  align-self: center;

  @media screen and (max-width: 360px) {
    padding-left: 5px;
    padding-right: 5px;
  }
`

const Scroller = styled.div`
  display: flex;
  padding-top: 24px;
  flex-direction: column;
  overflow-x: hidden;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  gap: 20px;
  mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(62, 112, 242, 0.99) 2%
  );
`

export const RatingPage: FC = () => {
  const { t } = useTranslation()
  const { user } = useAppSelector((state) => state.user)

  const { ratingList } = useRating()

  const navigate = useNavigate()

  const scroller = useRef<HTMLDivElement>(null)
  const stageRef = useRef<PIXI.Container>(null)
  const myPet = useMemo(() => {
    return ratingList && user
      ? ratingList.find((p) => p.owner === user._id)
      : null
  }, [ratingList, user])

  // useEffect(() => {
  //   if (!scroller.current || !stageRef.current) return
  //   function handleScroll() {
  //     if (!scroller.current || !stageRef.current) return
  //     stageRef.current.y = -scroller.current.scrollTop
  //   }
  //   scroller.current.addEventListener('scroll', handleScroll)
  //   return () => {
  //     if (!scroller.current) return
  //     scroller.current.removeEventListener('scroll', handleScroll)
  //   }
  // }, [scroller.current, stageRef.current])

  return useMemo(
    () => (
      <PageContainer>
        <PageHeader
          over={<Wallet mode="horizontal" />}
          before={<BackButton onClick={() => navigate(-1)} />}
        >
          <HeaderText>{t(LOCALES.news)}</HeaderText>
        </PageHeader>
        <Scroller ref={scroller} id={'petContainer'}>
          <InfoWrapper style={{ marginTop: '-20px' }}>
            <Stage
              id="stage"
              width={window.innerWidth}
              height={window.innerHeight}
              options={{
                backgroundAlpha: 0,
                antialias: true,
                resolution: window.devicePixelRatio || 1,
                autoDensity: true,
              }}
              style={{
                position: 'absolute',
                left: `calc(50% - 118px)`,
                pointerEvents: 'none',
              }}
            >
              <Container ref={stageRef}>
                {ratingList &&
                  user &&
                  [myPet, ...ratingList]?.map(
                    (pet, i) =>
                      pet && (
                        <Entity
                          key={i}
                          reactTracking={{
                            elementId: 'petBox' + i,
                            scrollableElementId: 'petContainer',
                            stageId: 'stage',
                          }}
                          entity={
                            pet.basePet && (
                              <EntityPet
                                basePet={pet.basePet}
                                squeeze={false}
                                y={50}
                                x={28}
                                scale={0.3}
                                skins={pet.skins}
                              />
                            )
                          }
                          shadow={false}
                        />
                      ),
                  )}
              </Container>
            </Stage>
          </InfoWrapper>

          <Row style={{ justifyContent: 'center' }}>
            <RatingBanner />
          </Row>

          {ratingList && user ? (
            ratingList.length ? (
              <InfoWrapper>
                {myPet && (
                  <PlayerInfoWrapper>
                    {ratingList.find((p) => p.owner === user._id) ? (
                      <RatingCard pet={myPet} avatarBoxId={'petBox0'} />
                    ) : (
                      ''
                    )}
                  </PlayerInfoWrapper>
                )}
                <TopText>{t(LOCALES.top100)}</TopText>
                <PlayersTopWrapper>
                  <PlayersTop petsList={ratingList} />
                </PlayersTopWrapper>
              </InfoWrapper>
            ) : ratingList[0] ? (
              <div style={{ alignSelf: 'center' }}>
                {t(LOCALES.top100NoPets)}
              </div>
            ) : (
              <Spinner />
            )
          ) : (
            <Spinner />
          )}
        </Scroller>
      </PageContainer>
    ),
    [ratingList, user],
  )
}
