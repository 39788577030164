/* eslint-disable no-console */
import { BaseOlympicGame } from 'src/pixi/classes'

import { CountdownStatus } from '../constants'

/**
 * requestAnimationFrame цикл
 */
export function renderFunction(this: BaseOlympicGame): void {
  this.engine.update()
  this.update()

  this.loopRequestId = requestAnimationFrame(() => this.renderFunction())
}

/**
 * Функция обновления экрана и позиций питомцев
 */
export function update(this: BaseOlympicGame): void {
  this.moveAnchorPet()
  this.pets.forEach((pet) => {
    this.movePet(pet)
  })
  this.updateTimer()
  if (this.countdownStatus !== CountdownStatus.Hidden) {
    this.updateCountdown()
  }
}

/**
 * Остановка цикла перерисовки
 */
export function cancelRenderLoop(this: BaseOlympicGame): void {
  console.log('cancel render loop ', this.loopRequestId)
  if (!this.loopRequestId) return
  cancelAnimationFrame(this.loopRequestId)
}

export function updateTimer(this: BaseOlympicGame): void {
  if (this.timerCountSprite) {
    this.timerCountSprite.text = this.getTimerString()
  }
}

export function updateCountdown(this: BaseOlympicGame): void {
  if (this.countdownStatus === CountdownStatus.Hidden) return

  let timeLeft: number | null
  if (!this.startGameAt) {
    timeLeft = null
  } else {
    timeLeft = Math.ceil((this.startGameAt - this.engine.localTime()) / 1000)
    // console.info(
    //   'countdown timeLeft; startGameAt; localTime; Date.now() ',
    //   timeLeft,
    //   this.startGameAt,
    //   this.engine.localTime(),
    //   Date.now(),
    // )
  }

  if (timeLeft === null) {
    this.countdownStatus = CountdownStatus.Wait
  } else if (timeLeft <= 0) {
    this.countdownStatus = CountdownStatus.Hidden
    this.interfaceContainer.removeChild(this.countdownContainer)
    this.anchorPet.setAnimation(this.mainPetAnimation, true)
    this.pets.forEach((pet) => pet.setAnimation(this.mainPetAnimation, true))
    this.timerContainer.alpha = 1
    return
  }

  if (
    !this.countdownText ||
    !this.countdownCountGlow ||
    !this.countdownWait ||
    !timeLeft
  )
    return

  if (this.countdownStatus === CountdownStatus.Count) {
    this.countdownText.text = timeLeft === 1 ? 'GO' : timeLeft - 1
    this.countdownCountGlow.rotation += 0.01
    return
  }

  this.countdownContainer.removeChild(this.countdownWait)
  this.countdownStatus = CountdownStatus.Count
  this.countdownText.text = timeLeft
  this.countdownContainer.addChild(this.countdownCountGlow, this.countdownText)
}
