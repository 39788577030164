import { FC } from 'react'
import styled from '@emotion/styled'
import { EVENT_ACTIVE, EVENT_ICONS } from 'src/config/eventSettings'

import CoinImg from 'assets/svg/coin.svg'
import Diamond from 'assets/svg/diamond.png'
import DiamondPaw from 'assets/svg/diamond_paw.svg'
import { useAppSelector } from 'store'

import { Platform } from '@gatto/shared'

import { MINI_APP } from '../../../index'

import AddCoinsButton, { WalletType } from './AddCoinsButton'

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`

const BaseContainer = styled.div<{ isTG: boolean }>`
  width: fit-content;
  position: relative;
  justify-content: flex-start;
  display: flex;
  border-radius: 8px;
  background: linear-gradient(
    355deg,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  box-shadow: 0px -4px 0px 0px #7eaff2 inset;
  align-self: flex-start;
  ${(props) => props.isTG && 'right: 0;'}
  margin: ${(props) => (props.isTG ? '12px 40px 0 20px' : '12px 0 0 20px')};
  min-height: 45px;
`

const CoinsPawsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
`

const Coin = styled.img`
  height: 17px;
  align-self: center;
`

const AmountNumber = styled.p`
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
`

const verticalStyle = {
  padding: '10px 13px 13px 13px',
  flexDirection: 'column' as const, // Зачем так нужно и описание проблемы - https://github.com/cssinjs/jss/issues/1344
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '7px',
  paddingRight: '17px',
}

const horizontalStyle = {
  height: '45px',
  padding: '10px 13px 13px 13px',
  flexDirection: 'row' as const,
  alignItems: 'center',
  gap: '10px',
  paddingRight: 'calc(17px + 6.34px)',
}

type WalletProps = {
  mode: 'vertical' | 'horizontal'
  customStyle?: string
}

const Wallet: FC<WalletProps> = ({ mode, customStyle }) => {
  const { user } = useAppSelector((state) => state.user)

  let StyledContainer

  if (mode === 'vertical') {
    StyledContainer = styled(BaseContainer)`
      ${verticalStyle}
    `
  } else {
    StyledContainer = styled(BaseContainer)`
      ${horizontalStyle}
    `
  }

  let Container = StyledContainer

  if (customStyle) {
    Container = styled(StyledContainer)`
      ${customStyle}
    `
  }

  return (
    <Content>
      {MINI_APP === Platform.VK ? (
        <Container isTG={false}>
          <CoinsPawsContainer>
            <Coin src={CoinImg} />
            <AmountNumber>{user?.balance.soft}</AmountNumber>
          </CoinsPawsContainer>
          <CoinsPawsContainer>
            <Coin src={DiamondPaw} />
            <AmountNumber>{user?.balance.hard}</AmountNumber>
          </CoinsPawsContainer>
          {EVENT_ACTIVE ? (
            <CoinsPawsContainer>
              <Coin src={EVENT_ICONS.small} />
              <AmountNumber>{user?.balance.event}</AmountNumber>
            </CoinsPawsContainer>
          ) : (
            ''
          )}
          <AddCoinsButton type={WalletType.Default} />
        </Container>
      ) : (
        <>
          <Container isTG={false}>
            <CoinsPawsContainer>
              <Coin src={CoinImg} />
              <AmountNumber>{user?.balance.soft}</AmountNumber>
            </CoinsPawsContainer>
            {EVENT_ACTIVE ? (
              <CoinsPawsContainer>
                <Coin src={EVENT_ICONS.small} />
                <AmountNumber>{user?.balance.event}</AmountNumber>
              </CoinsPawsContainer>
            ) : (
              ''
            )}
            <AddCoinsButton type={WalletType.Default} />
          </Container>
          <Container isTG={true}>
            <CoinsPawsContainer>
              <Coin src={Diamond} />
              <AmountNumber>{user?.balance.hard?.toFixed(3)}</AmountNumber>
            </CoinsPawsContainer>
            <AddCoinsButton type={WalletType.Ton} />
          </Container>
        </>
      )}
    </Content>
  )
}

export default Wallet
