import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { POPOUTS, Popouts } from 'src/const/router'
import { useGetPopout } from 'src/hooks'

// компонент, возвращающий обёрнутый попаут по GET параметрам

const GetParameterPopouts: FC = () => {
  const { mountedPopout } = useGetPopout()

  const Component = mountedPopout && POPOUTS[mountedPopout as keyof Popouts]

  const { state } = useLocation()

  if (!Component) return null

  return <Component {...state} />
}

export default GetParameterPopouts
