import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { GenIconWhite, LvlIconWhite } from 'src/config/assets'
import { usePopout } from 'src/hooks'

import check from 'assets/svg/other/check.svg'
import SelectCard from 'components/SelectCard'

import { LOCALES } from '@gatto/locales'
import { PetSearchRes } from '@gatto/shared'

const PetName = styled.div`
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
`

const IconsWrapper = styled.div`
  display: flex;
  padding: 3px;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.05);
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: Nunito;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 11.103px;
`
const Icon = styled.img``

const Count = styled.div`
  margin-top: 2px;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 2px;
`

const Selected = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  width: 25px;
  height: 25px;
  padding: 14px 14px 14px 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
  flex-shrink: 0;
  border-radius: 7px;
  background: linear-gradient(
      0deg,
      rgba(36, 156, 77, 0.69) 0%,
      rgba(36, 156, 77, 0.69) 100%
    ),
    linear-gradient(180deg, #32ff6b 0%, #32ff5f 100%),
    linear-gradient(
      180deg,
      rgba(68, 104, 255, 0.2) 0%,
      rgba(59, 91, 224, 0.2) 100%
    ),
    linear-gradient(180deg, #448fff 0%, #3b73e0 100%);
  box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.15) inset;
`

const PetBox = styled.div`
  width: 100px;
  height: 100px;
`

type CardProps = {
  isSelected: boolean
  pet: PetSearchRes & { biomeName: string }
}

const Card: FC<CardProps> = ({ isSelected, pet }) => {
  const { t } = useTranslation()
  const props = {
    basePet: pet.basePet,
    petId: pet._id.toString(),
    petChars: pet.chars,
    petLevel: pet.level,
    petName: pet.name,
    petEvolution: pet.evolution,
    buttonText: isSelected ? undefined : t(LOCALES.choose),
    skins: pet.userSkinPets[0]?.skinData,
  }

  const openPopout = usePopout('petInfo', false, props)

  return (
    <SelectCard
      isSelected={isSelected}
      withRadial={true}
      onClick={() => openPopout()}
    >
      {isSelected && (
        <Selected>
          <Icon src={check} />
        </Selected>
      )}
      <PetBox id={'pet_' + String(pet._id)} />

      <PetName>{pet.name}</PetName>
      <IconsWrapper>
        <IconContainer>
          <Icon src={LvlIconWhite} />
          <Count>{pet.level}</Count>
        </IconContainer>
        <IconContainer>
          <Icon src={GenIconWhite} />
          <Count>{pet.evolution}</Count>
        </IconContainer>
      </IconsWrapper>
    </SelectCard>
  )
}

export default Card
