import { useCallback, useReducer, useRef } from 'react'

type MapActions<K, V> = {
  set: Map<K, V>['set']
  remove: Map<K, V>['delete']
  clear: Map<K, V>['clear']
}

const useMap = <K, V>(): [Map<K, V>, MapActions<K, V>] => {
  // Нужно обновлять компонент ручками, так как изменение Set не вызовет ререндер
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, forceUpdate] = useReducer((x) => x + 1, 0)

  const map = useRef(new Map<K, V>())

  const set = useCallback((key: K, value: V) => {
    if (map.current.has(key)) return map.current
    map.current.set(key, value)
    forceUpdate()
    return map.current
  }, [])

  const remove = useCallback((key: K) => {
    if (!map.current.has(key)) return false
    map.current.delete(key)
    forceUpdate()
    return true
  }, [])

  const clear = useCallback(() => {
    map.current.clear()
    forceUpdate()
  }, [])

  return [map.current, { set, remove, clear }]
}

export default useMap
