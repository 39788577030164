import React, { FC } from 'react'
import { API_URL } from 'src/config/constants'
import useScreenDimensions from 'src/hooks/useScreenDimensions'

import { Entity, EntityEgg } from 'components/pixi'

import { PopulatedEgg } from '@gatto/shared'

type EggHatchingEntityProps = {
  egg: PopulatedEgg
}

const EggHatchingEntity: FC<EggHatchingEntityProps> = ({ egg }) => {
  const eggSrc = egg ? `${API_URL}${egg.baseEgg.photoUrl}` : ''
  const screenDimensions = useScreenDimensions()

  return (
    <Entity
      x={screenDimensions.width / 2}
      y={screenDimensions.height / 2}
      visible={!location.search.startsWith('?popout=petName')}
      entity={<EntityEgg image={eggSrc} scale={0.5} anchor={[0.5, 0.5]} />}
      shadow={false}
      shadowProps={{
        y: 90,
      }}
    />
  )
}

export default EggHatchingEntity
