import { FC, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { MODAL_ANIMATION_MS } from 'const/index'
import { MODALS } from 'const/router'
import useGetModalParam from 'src/hooks/router/useGetModalParam'
import { ModalContext } from 'src/utils/ModalContext'

import { ModalController } from 'components/modals/ModalController'

// компонент, возвращающий обёрнутый попаут по GET параметрам

const GetParameterModals: FC = () => {
  const { modalName } = useGetModalParam('modal')
  const Component = modalName && MODALS[modalName]
  const { activeModal, updateActiveModal } = useContext(ModalContext)
  const { state } = useLocation()

  const navigate = useNavigate()

  if (!Component) return null

  const onModalClose = (goBack: boolean) => {
    if (goBack) setTimeout(() => navigate(-1), MODAL_ANIMATION_MS)
    updateActiveModal(null)
  }
  console.info({ activeModal })
  return (
    <ModalController activeModal={activeModal} onClose={onModalClose}>
      <Component {...state} />
    </ModalController>
  )
}

export default GetParameterModals
