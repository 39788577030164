import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import WalletRoot from 'popouts/Settings/WalletRoot'
import usePopout from 'src/hooks/router/usePopout'

import { Button, Header, Popout, StatusType, Subheader } from 'components/base'

import { LOCALES } from '@gatto/locales'
import { MINI_APP } from '../../index'
import { Platform } from '@gatto/shared'
const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`

const Promocodes = styled.div`
  display: flex;
  height: 30px;
  flex-direction: column;
  justify-content: center;
  > span {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 11.103px;
  }
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const Link = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  > a {
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 11.103px; /* 79.305% */
    text-decoration-line: underline;
  }
`

const SoundContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  align-self: stretch;
  margin-bottom: -7px;
`
const SoundMain = styled.div`
  display: flex;
  flex-direction: column;
`

const SoundText = styled.div`
  flex: 1 0 0;
  > span {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 11.103px; /* 79.305% */
  }
`

const SoundPercent = styled.div`
  display: flex;
  padding: 10px 0px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  > span {
    color: #000;
    text-align: right;
    leading-trim: both;
    text-edge: cap;
    font-family: Nunito;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 11.103px; /* 85.405% */
  }
`

const Settings: FC = () => {
  const { t } = useTranslation()
  const [status, setStatus] = useState<StatusType>()
  const openPopout = usePopout('promoInput', true, {})

  const [closePopout, setClosePopout] = useState(false)

  return (
    <Popout
      background={true}
      isFullScreen={false}
      type="default"
      width="330px"
      status={status}
      setStatus={setStatus}
      close={closePopout}
    >
      <Main>
        <Head>
          <Header fontSize={18} lineHeight={15}>
            {t(LOCALES.settings)}
          </Header>
          <Subheader>{t(LOCALES.basic_settings)}</Subheader>
        </Head>
        {/* <SoundMain>
         <SoundContainer>
          <SoundText>
            <span>Звук</span>
          </SoundText>
          <SoundPercent>
            <span>{value}%</span>
          </SoundPercent>
        </SoundContainer>
        <CustomSlider onChange={SetSoundValue} value={value} />
      </SoundMain> */}
        <WalletRoot
          closeModal={() => {
            setClosePopout(true)
          }}
        />
        <Bottom>
          <Promocodes>
            <span>{t(LOCALES.promo_codes)}</span>
          </Promocodes>
          <Button
            onClick={() => openPopout()}
            variant="success"
            style={{ fontSize: 14 }}
          >
            {t(LOCALES.enter_a_promo_code)}
          </Button>
        </Bottom>
        {/* <Link>
        <a>Мы в Вконтакте</a>
      </Link> */}
      </Main>
    </Popout>
  )
}

export default Settings
