import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Types as MongooseTypes } from 'mongoose'
import { PetClothes } from 'src/pixi/types/spine'
import { FetchingStatus, makeRequest } from 'utils/api'

import {
  Override,
  PopulatedEgg,
  PopulatedPet,
  PopulatedUser,
  PopulatedUserRegion,
  UserBalance,
} from '@gatto/shared'

import { RootState } from '.'

export interface UserState {
  user?: Override<PopulatedUser, { regions: PopulatedUserRegion[] }>
  status: FetchingStatus
  currentRequestId?: string
  canWatchAds: boolean
  walletId: string
}

export const initialState: UserState = {
  user: undefined,
  status: FetchingStatus.IDLE,
  currentRequestId: undefined,
  canWatchAds: true,
  walletId: '',
}

export interface PetOrEgg {
  pet?: PopulatedPet | null
  egg?: PopulatedEgg | null
  regionId?: MongooseTypes.ObjectId
}

export const fetchSelf = createAsyncThunk<
  PopulatedUser | undefined,
  void,
  { state: { user: UserState } }
>('user.getSelf', async (_, { getState, requestId }) => {
  const { status, currentRequestId } = getState().user
  if (status !== FetchingStatus.PENDING || requestId !== currentRequestId) {
    return
  }
  const response = await makeRequest('user.getSelf', {})
  console.info('response from store', response)
  return response.user
})

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setWallet: (state, action) => {
      state.walletId = action.payload
    },
    setBalance: (state, action: PayloadAction<UserBalance>) => {
      if (state.user) {
        state.user.balance = action.payload
      }
    },
    incrementBalance: (state, action: PayloadAction<Partial<UserBalance>>) => {
      if (state.user) {
        if (action.payload.soft) state.user.balance.soft += action.payload.soft
        if (action.payload.hard) state.user.balance.hard += action.payload.hard
        if (action.payload.ton) state.user.balance.ton += action.payload.ton
        if (action.payload.event)
          state.user.balance.event += action.payload.event
      }
    },
    decrementBalance: (state, action: PayloadAction<Partial<UserBalance>>) => {
      if (state.user) {
        if (action.payload.soft) state.user.balance.soft -= action.payload.soft
        if (action.payload.hard) state.user.balance.hard -= action.payload.hard
        if (action.payload.ton) state.user.balance.ton -= action.payload.ton
        if (action.payload.event)
          state.user.balance.event -= action.payload.event
      }
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
    setRegion: (state, action) => {
      if (state.user) {
        const thisRegionIndex = state.user.regions.findIndex(
          (region) => region._id === action.payload._id,
        )
        if (thisRegionIndex !== -1) {
          state.user.regions[thisRegionIndex] = action.payload
        } else {
          state.user.regions.push(action.payload)
        }
      }
    },
    deletePet: (state, action) => {
      state.user &&
        state?.user.regions.map((region) => {
          if (region?.pet?._id === action.payload) {
            region.pet = null
          }
          return region
        })
    },
    updatePet: (state, action) => {
      state.user &&
        state.user.regions.map((region) => {
          if (region?.pet?._id === action.payload._id) {
            region.pet = action.payload
          }
          return region.pet
        })
    },
    updateRegions: (state, action: PayloadAction<PopulatedUserRegion[]>) => {
      if (state.user) {
        state.user.regions = action.payload
      }
    },
    updateUserRegion: (state, action: PayloadAction<PetOrEgg>) => {
      if (state.user) {
        const thisRegionIndex = state.user.regions.findIndex(
          (region) => region._id === action.payload.regionId,
        )

        if (thisRegionIndex !== -1) {
          if (action.payload.pet !== undefined) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            state.user.regions[thisRegionIndex]!.pet = action.payload.pet
          }

          if (action.payload.egg !== undefined) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            state.user.regions[thisRegionIndex]!.egg = action.payload.egg
          }
          return
        }
      }
    },
    updateCanWatchAds: (state, action: PayloadAction<boolean>) => {
      if (state.user) {
        state.canWatchAds = action.payload
      }
    },
    updateUserRegionPetSkins: (
      state,
      action: PayloadAction<{ skins: PetClothes[]; petId: string }>,
    ) => {
      state.user?.regions.forEach((region) => {
        if (region.pet?._id.toString() === action.payload.petId && region.pet) {
          region.pet.skins = action.payload.skins
        }
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSelf.pending, (state, action) => {
        if (state.status !== FetchingStatus.IDLE) {
          return
        }

        state.status = FetchingStatus.PENDING
        state.currentRequestId = action.meta.requestId
      })
      .addCase(fetchSelf.fulfilled, (state, action) => {
        const { requestId } = action.meta

        if (
          state.status !== FetchingStatus.PENDING ||
          state.currentRequestId !== requestId ||
          !action.payload
        ) {
          return
        }

        state.status = FetchingStatus.FULFILLED
        state.user = action.payload
        state.currentRequestId = undefined
      })
  },
})

export const {
  setUser,
  setRegion,
  setBalance,
  incrementBalance,
  decrementBalance,
  updateUserRegion,
  updateCanWatchAds,
  deletePet,
  updateUserRegionPetSkins,
  updatePet,
  updateRegions,
  setWallet,
} = userSlice.actions

export default userSlice.reducer

export const userIdSelector = (state: RootState): number | undefined =>
  state.user.user?._id
