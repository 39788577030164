import React, { FC, useCallback } from 'react'
import { API_URL } from 'src/config/constants'
import useScreenDimensions from 'src/hooks/useScreenDimensions'

import { Entity, EntityEgg, EntityRays } from 'components/pixi'

import { PopulatedEgg, PopulatedPet } from '@gatto/shared'

import { EggAnimationContainer } from './EggAnimationContainer'

export type propsTypes = {
  [key: string]: unknown
}

type EggHatchedEntityProps = {
  egg: PopulatedEgg
  petNamePopout: (restProps?: propsTypes | undefined) => void
  setHatchedPet: React.Dispatch<React.SetStateAction<PopulatedPet | null>>
  hatchedPet: PopulatedPet | null
  handleOpenEgg: () => Promise<void>
}

const EggHatchedEntity: FC<EggHatchedEntityProps> = ({
  egg,
  petNamePopout,
  hatchedPet,
  setHatchedPet,
  handleOpenEgg,
}) => {
  const screenDimensions = useScreenDimensions()
  const eggSrc = egg ? `${API_URL}${egg.baseEgg.photoUrl}` : ''

  const onOpenAnimationEnd = useCallback(() => {
    if (navigator.onLine) {
      petNamePopout({ pet: hatchedPet })
    }

    setHatchedPet(null)
  }, [hatchedPet, petNamePopout, setHatchedPet])

  if (!hatchedPet) {
    return (
      <Entity
        onClick={handleOpenEgg}
        x={screenDimensions.width / 2}
        y={screenDimensions.height / 2}
        visible={!location.search.startsWith('?popout=petName')}
        entity={<EntityEgg image={eggSrc} scale={0.5} anchor={[0.5, 0.5]} />}
        rays={<EntityRays />}
        shadow={false}
        shadowProps={{
          y: 90,
        }}
      />
    )
  }
  if (hatchedPet) {
    return (
      <EggAnimationContainer
        eggSrc={eggSrc}
        hatchedPet={hatchedPet.basePet}
        onEnd={onOpenAnimationEnd}
      />
    )
  }

  return null
}

export default EggHatchedEntity
