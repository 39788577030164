import ImageCross1 from 'assets/onboarding/cross/1.png'
import ImageCross2 from 'assets/onboarding/cross/2.png'
import ImageCross3 from 'assets/onboarding/cross/3.png'
import ImagePool1 from 'assets/onboarding/Pool/onboarding_swim_1.png'
import ImagePool2 from 'assets/onboarding/Pool/onboarding_swim_2.png'
import ImageStadium1 from 'assets/onboarding/stadium/onboarding_run_1.png'
import ImageStadium2 from 'assets/onboarding/stadium/onboarding_run_2.png'
import ImageStore1 from 'assets/onboarding/store/onboarding_shop_1.png'
import ImageStore2 from 'assets/onboarding/store/onboarding_shop_2.png'
import ImageStore3 from 'assets/onboarding/store/onboarding_shop_3.png'
import ImageWelcome1 from 'assets/onboarding/welcome/1.png'
import ImageWelcome2 from 'assets/onboarding/welcome/2.png'
import ImageWelcome3 from 'assets/onboarding/welcome/3.png'
import { ShowSlidesSheetRequest } from 'components/OnBoarding/types'

import { LOCALES } from '@gatto/locales'

export const OnboardingWelcome: ShowSlidesSheetRequest = {
  slides: [
    {
      title: LOCALES.onboarding_welcome_1_title,
      subtitle: LOCALES.onboarding_welcome_1_text,
      button: LOCALES.onboarding_welcome_1_btn,
      media: {
        type: 'image',
        url: ImageWelcome1,
      },
    },
    {
      title: LOCALES.onboarding_welcome_2_title,
      subtitle: LOCALES.onboarding_welcome_2_text,
      button: LOCALES.onboarding_welcome_2_btn,
      media: {
        type: 'image',
        url: ImageWelcome2,
      },
    },
    {
      title: LOCALES.onboarding_welcome_3_title,
      subtitle: LOCALES.onboarding_welcome_3_text,
      button: LOCALES.onboarding_welcome_3_btn,
      media: {
        type: 'image',
        url: ImageWelcome3,
      },
    },
  ],
}

export const OnboardingStadium: ShowSlidesSheetRequest = {
  slides: [
    {
      title: LOCALES.onboarding_race_1_title,
      subtitle: LOCALES.onboarding_race_1_text,
      button: LOCALES.onboarding_race_1_btn,
      media: {
        type: 'image',
        url: ImageStadium1,
      },
    },
    {
      title: LOCALES.onboarding_race_2_title,
      subtitle: LOCALES.onboarding_race_2_text,
      button: LOCALES.onboarding_race_2_btn,
      media: {
        type: 'image',
        url: ImageStadium2,
      },
    },
  ],
}

export const OnboardingPool: ShowSlidesSheetRequest = {
  slides: [
    {
      title: LOCALES.onboarding_swim_1_title,
      subtitle: LOCALES.onboarding_swim_1_text,
      button: LOCALES.onboarding_swim_1_btn,
      media: {
        type: 'image',
        url: ImagePool1,
      },
    },
    {
      title: LOCALES.onboarding_swim_2_title,
      subtitle: LOCALES.onboarding_swim_2_text,
      button: LOCALES.onboarding_swim_2_btn,
      media: {
        type: 'image',
        url: ImagePool2,
      },
    },
  ],
}

export const OnboardingStore: ShowSlidesSheetRequest = {
  slides: [
    {
      title: LOCALES.onboarding_shop_1_title,
      subtitle: LOCALES.onboarding_shop_1_text,
      button: LOCALES.onboarding_shop_1_btn,
      media: {
        type: 'image',
        url: ImageStore1,
      },
    },
    {
      title: LOCALES.onboarding_shop_2_title,
      subtitle: LOCALES.onboarding_shop_2_text,
      button: LOCALES.onboarding_shop_2_btn,
      media: {
        type: 'image',
        url: ImageStore2,
      },
    },
    {
      title: LOCALES.onboarding_shop_3_title,
      subtitle: LOCALES.onboarding_shop_3_text,
      button: LOCALES.onboarding_shop_3_btn,
      media: {
        type: 'image',
        url: ImageStore3,
      },
    },
  ],
}

export const OnboardingCross: ShowSlidesSheetRequest = {
  slides: [
    {
      title: LOCALES.onboarding_breeding_1_title,
      subtitle: LOCALES.onboarding_breeding_1_text,
      button: LOCALES.onboarding_breeding_1_btn,
      media: {
        type: 'image',
        url: ImageCross1,
      },
    },
    {
      title: LOCALES.onboarding_breeding_2_title,
      subtitle: LOCALES.onboarding_breeding_2_text,
      button: LOCALES.onboarding_breeding_2_btn,
      media: {
        type: 'image',
        url: ImageCross2,
      },
    },
    {
      title: LOCALES.onboarding_breeding_3_title,
      subtitle: LOCALES.onboarding_breeding_3_text,
      button: LOCALES.onboarding_breeding_3_btn,
      media: {
        type: 'image',
        url: ImageCross3,
      },
    },
  ],
}

export const OnboardingList = {
  welcome: OnboardingWelcome,
  stadium: OnboardingStadium,
  pool: OnboardingPool,
  store: OnboardingStore,
  cross: OnboardingCross,
}

export type OnboardingListNames = keyof typeof OnboardingList
