import React, { FC, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import { SkinBackground, SkinImg } from 'popouts/Skins/SkinsPopout'

import { BackButton, Item, Menu, Wallet } from 'components/base'
import { Container, Page, PageHeader } from 'components/page'
import { HeaderText } from 'components/typography'

import {
  EssenceType,
  PetRarity,
  PrizeItemType,
  SkinRarity,
} from '@gatto/shared'

import { API_URL } from '../../config/constants'

import { getItemBackground, getPercentBackground } from './utils'

const StyledSkinImg = styled.img`
  width: 136px;
  height: 110px;
  object-fit: contain;

  @media screen and (max-width: 375px) {
    width: 140px;
    max-height: 150px;
  }
`

const StyledItem = styled(Item)`
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  min-width: 150px;
  cursor: default;
`

const StyledSkinBackground = styled(SkinBackground)<{ color: string }>`
  position: relative;
  width: 150px;
  height: 150px;

  background: ${({ color }) => color};

  @media screen and (max-width: 375px) {
    width: 140px;
    max-height: 150px;
  }
`

const PercentBlock = styled.div<{
  color: string
}>`
  font-size: 10px;

  position: absolute;
  top: 8px;
  right: 8px;
  padding: 8px;
  border-radius: 10px;

  background: ${({ color }) => color};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid rgba(255, 255, 255, 0.39);
    border-radius: 10px;
    z-index: 1;
  }
`

const Rarity = styled.div<{ color: string }>`
  position: absolute;
  bottom: 7px;
  width: 15px;
  height: 5px;
  border-radius: 10px;

  background: ${({ color }) => color};
`

export type PrizeInfo = {
  itemTypeRarity?: PetRarity | SkinRarity | EssenceType | undefined
  itemType?: PrizeItemType
  percent?: number
  photoUrl: string
  name: string
}

export const renderListItems = (prizes: PrizeInfo[]): ReactNode | null => {
  return prizes.map((item, index) => {
    const { itemTypeRarity, itemType, percent, photoUrl, name } = item
    console.warn(item)

    const backgroundPercentColor = getPercentBackground(
      itemTypeRarity,
      itemType,
    )

    const backgroundColor = getItemBackground(itemTypeRarity, itemType)

    return (
      <StyledItem key={index}>
        <StyledSkinBackground color={backgroundColor}>
          <PercentBlock color={backgroundPercentColor}>{percent}%</PercentBlock>
          <StyledSkinImg src={`${API_URL}${photoUrl}`} />
          <Rarity color={backgroundPercentColor} />
        </StyledSkinBackground>
        <p>{name}</p>
      </StyledItem>
    )
  })
}

export const PrizeInfoPage: FC = () => {
  const location = useLocation()
  const body = location.state?.body
  const title = location.state?.title

  return (
    <Container>
      <Page>
        <Container>
          <PageHeader
            over={<Wallet mode="horizontal" />}
            before={<BackButton />}
          >
            <HeaderText>{title}</HeaderText>
          </PageHeader>
          <Menu ltRow={false}>{renderListItems(body)}</Menu>
        </Container>
      </Page>
    </Container>
  )
}
