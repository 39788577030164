/* eslint-disable no-console */
import * as PIXI from 'pixi.js'

import { UnitCoordinates } from '@gatto/engine'
import { Pet as PetEngine } from '@gatto/engine/src/units/pet/pet'
import { GameType } from '@gatto/shared'

import {
  PET_ANIMATION,
  SHOULD_BE_ANCHOR_PET_ROW,
} from '../baseOlympicGame/constants'
import { GamePetProps } from '../types/rungame'

import { Pet as PetSpine } from '.'

export class GamePet {
  petContainer = new PIXI.Container()
  petSpine: PetSpine
  _petEngine?: PetEngine
  mapAnchorRowTo: number | null = null // Дорожка пета, который меняется с anchor петом
  animation: PET_ANIMATION = ''
  animationLoop = false
  _isHidden = false
  originalRow = 0

  readonly id: string
  readonly gameType: GameType
  protected _row: number
  protected _x: number
  protected _y: number

  constructor({ id, petSpine, petEngine, x, y, gameType }: GamePetProps) {
    this.id = id
    this.petSpine = petSpine
    this.petEngine = petEngine
    this.gameType = gameType
    this._row = 0
    this._x = x || 0
    this._y = y || 0
    this.isHidden = true
    this.petSpine.zIndex = 2
    this.petContainer.addChild(this.petSpine)
    this.petContainer.sortableChildren = true
  }

  get x(): number {
    return this._x
  }

  set x(x: number) {
    this._x = x
    this.petContainer.x = x
  }

  get y(): number {
    return this._y
  }

  set y(y: number) {
    this._y = y
    this.petSpine.y = y
  }

  get row(): number {
    return this._row
  }

  set row(row: number) {
    this.originalRow = row
    if (row === SHOULD_BE_ANCHOR_PET_ROW && this.mapAnchorRowTo !== null) {
      this._row = this.mapAnchorRowTo
    } else if (row === this.mapAnchorRowTo) {
      this._row = SHOULD_BE_ANCHOR_PET_ROW
    } else {
      this._row = row
    }
    this.isHidden = false
  }

  get petEngine(): PetEngine | undefined {
    return this._petEngine
  }

  set petEngine(petEngine: PetEngine | undefined) {
    this._petEngine = petEngine
    if (petEngine) {
      this.row = petEngine.row
      this.petContainer.zIndex =
        this.gameType === GameType.Race ? this._row * 2 : this._row * 3 + 2
      console.log(this.petContainer.zIndex, this._row)
    }
  }

  setAnimation(animation: PET_ANIMATION, loop: boolean): void {
    if (this.animation !== animation || loop !== this.animationLoop) {
      this.animation = animation
      this.animationLoop = loop
      this.petSpine.setAnimation(animation, loop)
    }
  }

  getPetEngineCoords(): UnitCoordinates | undefined {
    if (!this.petEngine) return
    return this.petEngine.coordinates
  }

  getPetEngineY(): number | undefined {
    if (!this.petEngine) return
    return this.petEngine.coordinates.y
  }

  getPetCoordDifference(): UnitCoordinates | undefined {
    if (!this.petEngine) return
    return {
      x: this.petEngine.coordinates.x - this.x,
      y: this.petEngine.coordinates.y - this.y,
    }
  }
  getPetXOffset(): number | undefined {
    if (!this.petEngine) return
    return this.petEngine.coordinates.x - this.x
  }

  get isHidden(): boolean {
    return this._isHidden
  }

  set isHidden(value: boolean) {
    if (this._isHidden === value) return
    this._isHidden = value
    if (this._isHidden) {
      this.petSpine.alpha = 0
      console.log('set isHidden ', this.id, this._isHidden, this.petSpine.alpha)
    } else {
      this.petSpine.alpha = 1
    }
  }
}
