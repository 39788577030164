import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Stage } from '@pixi/react'
import bridge from '@vkontakte/vk-bridge'
import { useCloudStorage } from '@vkruglikov/react-telegram-web-app'
import { MINI_APP } from 'src'
import { API_URL } from 'src/config/constants'
import { Popouts } from 'src/const/router'
import { usePopout } from 'src/hooks'
import { makeRequest } from 'utils/api'
import { sendPayment } from 'utils/ton/connectWallet'
import { handleGetTonPayments } from 'utils/ton/GetPayments'

import Ton from 'assets/png/ton/Ton.png'
import CurrencyIcon from 'assets/svg/coin.svg'
import DiamondPaw from 'assets/svg/diamond_paw.svg'
import CrossIcon from 'assets/svg/popouts/cross.svg'
import Gen from 'assets/svg/popouts/gen.png'
import { Button, ButtonColor, Popout, StatusType } from 'components/base'
import { Entity, EntityPet } from 'components/pixi'
import { useAppDispatch, useAppSelector } from 'store/index'
import { getPaymentProcess, setPaymentProcess } from 'store/payment'
import { setIsPetCrossed } from 'store/region'
import { getSelectedPet } from 'store/selectPet'
import { decrementBalance, deletePet } from 'store/user'

import { LOCALES } from '@gatto/locales'
import {
  CurrencyType,
  evolvePaymentAmounts,
  evolvePaymentAmountsTG,
  Platform,
} from '@gatto/shared'

import { useWalletCheckWithUpdate } from '../../hooks/useWalletCheck'

const ContainerBlock = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.span`
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
`

const Block = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  margin: 15px 20px 10px 20px;
`

const PetsBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: center;
  width: 181px;
  height: 145px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.02);
`

const PetContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const BackImageBlock = styled.div<{ url: string }>`
  width: 78px;
  height: 100px;
  border-radius: 0.625rem;
  box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.15) inset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0.44rem;
  font-weight: bold;
  z-index: 1;

  &:before {
    content: ' ';

    position: absolute;
    left: 0;
    z-index: -1;

    width: 78px;
    height: 100px;
    border-radius: inherit;

    background-image: url(${(props) => props.url});
    background-repeat: no-repeat;
    background-size: 300%;
    background-position: center;

    filter: opacity(0.25);
  }
`

const PetBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 78px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 10px;
  margin: 5px 5px 10px 0;
  background: rgba(0, 0, 0, 0.05);
  box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.15) inset;
  z-index: 100;
`

const AwardBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  text-align: center;
  width: 78px;
  height: 125px;
  margin-bottom: 22px;
  margin-left: 20px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.02);
`

const TopTitle = styled.span`
  color: #000;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 6px;
`

export const BlockTitle = styled.span<{ fs?: string }>`
  color: #000;
  font-family: Nunito;
  font-size: ${(props) => (props.fs ? props.fs : '12px')};
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  width: 70px;
  text-overflow: ellipsis;
`

const ButtonBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

export const Cross = styled.img`
  width: 25px;
  height: 25px;
`

export const Currency = styled.img<{ mt?: string }>`
  width: 13px;
  height: 15px;
  margin-left: 2px;
  margin-top: ${(props) => props.mt && props.mt};
`

export const PriceInfo = styled.div<{ fs?: string }>`
  display: flex;
  padding: 3px 5px;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => (props.fs ? props.fs : '14px')};
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
`

export const GenInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Nunito;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 11.103px;
`

const PawInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
`

export const PolygonPaw = styled.img`
  width: 28.017px;
  height: 27.487px;
`

const EggImage = styled.img`
  width: 35px;
  height: 40.701px;
  flex-shrink: 0;
  z-index: 200;
`

const getImagePath = (
  rarity: string | undefined,
  region: string | undefined,
) => {
  return `https://frontend.gattogame.site/uploads/eggs/${region}_${rarity}.png`
}

const petRarities = {
  normal: 1,
  rare: 2,
  mif: 3,
  legendary: 4,
}

type PetCrossPopoutProps = Popouts['petsCross']

const PetCrossPopout: FC<PetCrossPopoutProps> = ({
  firstPet,
  firstPetID,
  secondPet,
  secondPetID,
}) => {
  const { t } = useTranslation()
  const [status, setStatus] = useState<StatusType>()
  const [closePopout, setClosePopout] = useState(false)

  const navigate = useNavigate()
  const { setItem } = useCloudStorage()

  const [isLoad, setIsLoad] = useState<boolean>(false)
  const successPopout = usePopout('statusAlert', true, {
    subheader: t(LOCALES.breedingSuccess),
    type: 'success',
    navigatePath: '/',
  })
  const errorPopout = usePopout('statusAlert', true, {
    header: t(LOCALES.breedingError),
    type: 'error',
    navigatePath: '/',
  })
  const walletExistPopout = usePopout('walletNotExist', false)
  const [data, updateData] = useWalletCheckWithUpdate(false)

  const tonPaymentPopout = usePopout('statusAlert', true, {
    type: 'success',
    header: t(LOCALES.failure_cross),
    navigatePath: '/',
  })
  const paymentProcessPopout = usePopout('statusAlert', true, {
    type: 'error',
    subheader: `${t(LOCALES.error_paymentProcess)}`,
  })

  const waitPopout = usePopout('statusAlert', true, {
    type: 'custom',
    subheader: t(LOCALES.process_of_purchase),
  })

  const { user } = useAppSelector((state) => state.user)
  const selectedPet = useAppSelector(getSelectedPet)
  const paymentProcess = useAppSelector(getPaymentProcess)
  const firstPetRarity = firstPet?.basePet?.rarity
  const secondPetRarity = secondPet?.basePet?.rarity

  const isReqNeeded = useRef<boolean>(false)
  const intervalValue: { current: NodeJS.Timeout | null } = useRef(null)

  const higherRarity =
    firstPetRarity &&
    secondPetRarity &&
    petRarities[firstPetRarity] >= petRarities[secondPetRarity]
      ? firstPetRarity
      : secondPetRarity

  const selectedRarityImage = getImagePath(
    higherRarity,
    firstPet?.basePet.allowedRegion,
  )

  const dispatch = useAppDispatch()

  const lowestEvolution = Math.min(
    firstPet?.evolution || 0,
    secondPet?.evolution || 0,
  )

  useEffect(() => {
    const handlePopstate = () => {
      navigate('?mode=cross', { replace: true })
    }

    window.addEventListener('popstate', handlePopstate)

    return () => {
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [])

  const evolutionCost =
    MINI_APP === Platform.VK
      ? evolvePaymentAmounts[lowestEvolution + 1]
      : evolvePaymentAmountsTG[lowestEvolution + 1]

  if (!evolutionCost) return

  const handleTonCross = async (currency: CurrencyType) => {
    if (paymentProcess) return paymentProcessPopout()
    if (MINI_APP === Platform.TG && currency === CurrencyType.Hard) {
      const userWallets = data.length ? data : await updateData()
      if (!userWallets.length) {
        walletExistPopout()
      } else {
        isReqNeeded.current = true
        setClosePopout(true)
        const uniqueId = await sendPayment(
          evolutionCost?.hard,
          `${'evolve'}:${firstPetID}:${secondPetID}`,
          tonPaymentPopout,
        )
        if (!uniqueId) return
        // dispatch(setPaymentProcess(true))
        intervalValue.current = setInterval(async () => {
          const res = await handleGetTonPayments({
            uniqueId,
            intervalValue,
            isReqNeeded,
            successPopout,
            errorPopout,
            waitPopout,
            stopInterval: () => {
              dispatch(setPaymentProcess(false))
            },
            startInterval: () => {
              dispatch(setPaymentProcess(true))
            },
          })

          if (res?.status === 'got') {
            dispatch(deletePet(firstPetID))
            dispatch(deletePet(secondPetID))

            if (selectedPet === firstPetID || selectedPet === secondPet) {
              setItem('selectedPet', '')
            }
          }
        }, 5000)
      }
    }
  }

  const handleCross = async (currency: CurrencyType) => {
    try {
      setIsLoad(true)

      await makeRequest('pet.evolve', {
        parents: [firstPetID, secondPetID],
        currency: currency,
      })

      if (currency === CurrencyType.Soft && user) {
        dispatch(decrementBalance({ soft: evolutionCost?.soft }))
      }

      if (currency === CurrencyType.Hard && user) {
        dispatch(decrementBalance({ hard: evolutionCost?.hard }))
      }

      if (selectedPet === firstPetID || selectedPet === secondPet) {
        if (MINI_APP === Platform.VK) {
          bridge.send('VKWebAppStorageSet', {
            key: 'selectedPet',
            value: '',
          })
        }

        if (MINI_APP === Platform.TG) {
          setItem('selectedPet', '')
        }
      }

      successPopout()
      dispatch(deletePet(firstPetID))
      dispatch(deletePet(secondPetID))
      setIsLoad(false)
    } catch (e) {
      errorPopout()
      dispatch(setIsPetCrossed(true))
      setTimeout(() => {
        dispatch(setIsPetCrossed(false))
      }, 1000)
    }
  }

  const createCrossButton = (
    currencyType: CurrencyType,
    balanceKey: 'soft' | 'hard',
    color?: ButtonColor,
  ) => (
    <Button
      onClick={async () => {
        if (
          MINI_APP === Platform.TG &&
          currencyType === CurrencyType.Hard &&
          user &&
          user.balance.hard < evolutionCost?.hard
        ) {
          await handleTonCross(currencyType)
        } else {
          await handleCross(currencyType)
        }
        return
      }}
      variant={
        MINI_APP === Platform.TG && currencyType === CurrencyType.Hard
          ? color
          : user &&
            user.balance['soft'] <
              (evolvePaymentAmounts[lowestEvolution + 1]?.['soft'] ?? 0)
          ? 'translucent'
          : color
      }
      width={balanceKey === 'hard' ? '50px' : '235px'}
      disabled={
        isLoad ||
        (user &&
          user.balance['soft'] < (evolutionCost?.['soft'] ?? 0) &&
          currencyType === CurrencyType.Soft)
      }
    >
      {balanceKey === 'soft' ? (
        <>
          <Cross src={CrossIcon} />
          Скрестить
          <PriceInfo>
            {evolutionCost?.[balanceKey] ?? 0} <Currency src={CurrencyIcon} />
          </PriceInfo>
        </>
      ) : (
        <PawInfo>
          <PriceInfo fs={'10px'}>
            {(MINI_APP === Platform.VK && evolutionCost?.[balanceKey]) ?? 0}
            {(MINI_APP === Platform.TG && evolutionCost?.[balanceKey]) ?? 0}
            {MINI_APP === Platform.VK ? (
              <Currency src={DiamondPaw} />
            ) : (
              <Currency src={Ton} />
            )}
          </PriceInfo>
        </PawInfo>
      )}
    </Button>
  )

  return (
    <Popout
      header={t(LOCALES.breeding)}
      headerPosition="center"
      type="default"
      background={false}
      isFullScreen={false}
      width="330px"
      position="center"
      status={status}
      setStatus={setStatus}
      navigatePath={'/?mode=cross'}
      close={closePopout}
    >
      <ContainerBlock>
        <Title>{t(LOCALES.breedingConfirm)}</Title>
        <Block>
          <PetsBlock>
            <BlockTitle>{t(LOCALES.breed)}</BlockTitle>
            <PetContainer>
              <PetBlock>
                <Stage
                  width={40}
                  height={40}
                  options={{
                    backgroundAlpha: 0,
                    antialias: true,
                    resolution: window.devicePixelRatio || 1,
                    autoDensity: true,
                  }}
                >
                  <Entity
                    shadow={false}
                    entity={
                      firstPet && firstPet.basePet ? (
                        <EntityPet
                          basePet={firstPet.basePet}
                          squeeze={false}
                          x={17}
                          y={35}
                          scale={0.25}
                          skins={firstPet.skins}
                        />
                      ) : (
                        ''
                      )
                    }
                  />
                </Stage>
                <BlockTitle>{firstPet?.name}</BlockTitle>
                <GenInfo>
                  {firstPet?.evolution} <Currency src={Gen} />
                </GenInfo>
              </PetBlock>
              <PetBlock>
                <Stage
                  width={40}
                  height={40}
                  options={{
                    backgroundAlpha: 0,
                    antialias: true,
                    resolution: window.devicePixelRatio || 1,
                    autoDensity: true,
                  }}
                >
                  <Entity
                    shadow={false}
                    entity={
                      secondPet && secondPet.basePet ? (
                        <EntityPet
                          basePet={secondPet.basePet}
                          squeeze={false}
                          x={17}
                          y={35}
                          scale={0.25}
                          skins={secondPet.skins}
                        />
                      ) : (
                        ''
                      )
                    }
                  />
                </Stage>
                <BlockTitle>{secondPet?.name}</BlockTitle>
                <GenInfo>
                  {secondPet?.evolution} <Currency src={Gen} />
                </GenInfo>
              </PetBlock>
            </PetContainer>
          </PetsBlock>
          <AwardBlock>
            <TopTitle>{t(LOCALES.youGet)}</TopTitle>
            <BackImageBlock url={selectedRarityImage}>
              <EggImage src={selectedRarityImage} />
              <BlockTitle>{t(LOCALES.egg)}</BlockTitle>
              <GenInfo>
                {lowestEvolution + 1} <Currency src={Gen} />
              </GenInfo>
            </BackImageBlock>
          </AwardBlock>
        </Block>
        <ButtonBlock>
          {createCrossButton(CurrencyType.Soft, 'soft', 'success')}
          {createCrossButton(CurrencyType.Hard, 'hard', 'skyBlue')}
        </ButtonBlock>
      </ContainerBlock>
    </Popout>
  )
}

export default PetCrossPopout
