import { useCallback, useEffect, useState } from 'react'
import { Assets, Cache } from 'pixi.js'

import 'pixi-spine'

// Фикс блокировки nginx запросов пикси
Assets.setPreferences({
  crossOrigin: 'anonymous',
})

type UseLoadAllAssetsReturnType = {
  percent: number
  isLoaded: boolean
}

/** Загружает все ассеты и возвращает процент загрузки
 * @param {string[]} assets - Массив ассетов
 * @return {UseLoadAllAssetsReturnType} - Процент загрузки и флаг загрузки
 * @example
 * ```tsx
 * const [percent, isLoaded] = useLoadAllAssets(['assets/1.png', 'assets/2.png'])
 * if (isLoaded) {
 *  return <App />
 * }
 * return <Loader percent={percent} />
 * ```
 **/
const useLoadAllAssets = (assets: string[]): UseLoadAllAssetsReturnType => {
  const [percent, setPercent] = useState(0)

  // Сбрасываем процент на каждое изменение ассетов
  useEffect(() => {
    setPercent(0)
  }, [assets])

  // Загружаем ассет
  const loadAsset = useCallback(async (asset: string) => {
    await Assets.load(asset, (progress) =>
      setPercent((lastValue) => lastValue + progress * 100),
    )
  }, [])

  // Загружаем все ассеты
  useEffect(() => {
    for (const asset of assets) {
      if (Cache.has(asset)) {
        setPercent((lastValue) => lastValue + 100)
      } else {
        loadAsset(asset).catch((error) => console.error(error))
      }
    }
  }, [assets, loadAsset])

  return {
    percent: percent / assets.length,
    isLoaded: percent / assets.length >= 100,
  }
}

export default useLoadAllAssets
