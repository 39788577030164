import { matchPath, useLocation } from 'react-router-dom'
import { Route, routes } from 'src/config/routes'

const useRoute = (): Route | undefined => {
  const location = useLocation()
  const path = getRoutePrefix(location.pathname)

  const route = routes.find((e) =>
    matchPath(
      // Фикс необходимый для деплоя на хост
      path.includes('index.html') ? `/${path.split('index.html')[1]}` : path,
      getRoutePrefix(e.path),
    ),
  )

  return route
}

const getRoutePrefix = (path: string) => `/${path.split('/')[1]}`

export default useRoute
