import { petSpriteMapping } from 'utils/petSpriteMapping'

import GenIcon from 'assets/icons/black/gen.png'
import LvlIcon from 'assets/icons/black/lvl.png'
import TileIcon from 'assets/icons/black/tile.png'
import TimeIcon from 'assets/icons/black/time.png'
import CoinIcon from 'assets/icons/coin_2.png'
import ExpIcon from 'assets/icons/exp.png'
import GenIconGray from 'assets/icons/gray/gen.png'
import GroupIcon from 'assets/icons/groupIcon.png'
import LinkIcon from 'assets/icons/link.png'
import NotificationIcon from 'assets/icons/notification.png'
import GenIconWhite from 'assets/icons/white/gen.png'
import LvlIconWhite from 'assets/icons/white/lvl.png'
import AgilityIcon from 'assets/png/pet/agility.png'
import EmotionIconBg from 'assets/png/pet/emotion.png'
import FishIconBg from 'assets/png/pet/feed.png'
import FlyIcon from 'assets/png/pet/fly.png'
import Prize from 'assets/png/pet/prize.png'
import PrizeSmall from 'assets/png/pet/prize_small.png'
import PrizeOpacity from 'assets/png/pet/prizeOpacity.png'
import StaminaIcon from 'assets/png/pet/stamina.png'
import StrengthIcon from 'assets/png/pet/strength.png'
import SwimIcon from 'assets/png/pet/swim.png'
import { ReactComponent as AdSVG } from 'assets/svg/other/ad.svg'
import { ReactComponent as ClothingSVG } from 'assets/svg/other/clothing.svg'
import { ReactComponent as FeedSVG } from 'assets/svg/other/feed.svg'
import { ReactComponent as HouseSVG } from 'assets/svg/other/house.svg'
import { ReactComponent as NFTStarSVG } from 'assets/svg/other/nft_star.svg'
import { ReactComponent as NoticeSVG } from 'assets/svg/other/notice.svg'
import { ReactComponent as PlaySVG } from 'assets/svg/other/play.svg'
import { ReactComponent as StockSVG } from 'assets/svg/other/stock.svg'
import { ReactComponent as EmotionIcon } from 'assets/svg/pet/emotion.svg'
// import { ReactComponent as EmotionIconBg } from 'assets/svg/pet/emotionSvgBg.svg'
import { ReactComponent as Evolution } from 'assets/svg/pet/evolution.svg'
import { ReactComponent as FishIcon } from 'assets/svg/pet/fish.svg'

// import { ReactComponent as FishIconBg } from 'assets/svg/pet/fishSvgBg.svg'
import font from '../assets/fonts/Cartonsix_NC.ttf'

export const MAP_SPRITESHEET_PATH = '/sprites/map/platforms.json'
export const PETS_SPRITESHEET_PATH = '/sprites/pets/'
export const OBJECTS_SPRITESHEET_PATH = '/sprites/objects/sprites.json'
export const CLOUD_SPRITESHEET_PATH = '/sprites/backgrounds/cloud_sprites.json'
export const HALLOWEEN_CLOUD_SPRITESHEET_PATH =
  '/sprites/backgrounds/halloween_cloud_sprites.json'
export const BIOM_BACKGROUND_SPRITESHEET_PATH =
  '/sprites/backgrounds/regions.json'
export const RUNGAME_SPRITESHEET_PATH = '/sprites/rungame/spritesheet.json'

export const SWIMGAME_SPRITESHEET_PATH = '/sprites/swimgame/spritesheet.json'
export const SWIGAME_ASSETS = [
  SWIMGAME_SPRITESHEET_PATH,
  '/sprites/swimgame/spine',
]

export const REWARDS_SPRITESHEET_PATH = '/sprites/objects/rewards.json'

export const OPEN_EGG_SPRITESHEET_PATH = '/sprites/eggs/eggs_ainmation.json'

export const GIFT_SPRITESHEET_PATH = '/sprites/gift/gift_2.json'

export const ALL_ASSETS = [
  // Все питомцы
  ...Object.values(petSpriteMapping).flatMap(
    (pet) => `${PETS_SPRITESHEET_PATH}${pet}`,
  ),
  font,
  MAP_SPRITESHEET_PATH,
  OBJECTS_SPRITESHEET_PATH,
  CLOUD_SPRITESHEET_PATH,
  HALLOWEEN_CLOUD_SPRITESHEET_PATH,
  BIOM_BACKGROUND_SPRITESHEET_PATH,
  OPEN_EGG_SPRITESHEET_PATH,
  GIFT_SPRITESHEET_PATH,
]

export {
  AdSVG,
  AgilityIcon,
  ClothingSVG,
  CoinIcon,
  EmotionIcon,
  EmotionIconBg,
  Evolution,
  ExpIcon,
  FeedSVG,
  FishIcon,
  FishIconBg,
  FlyIcon,
  GenIcon,
  GenIconGray,
  GenIconWhite,
  GroupIcon,
  HouseSVG,
  LinkIcon,
  LvlIcon,
  LvlIconWhite,
  NFTStarSVG,
  NoticeSVG,
  NotificationIcon,
  PlaySVG,
  Prize,
  PrizeOpacity,
  PrizeSmall,
  StaminaIcon,
  StockSVG,
  StrengthIcon,
  SwimIcon,
  TileIcon,
  TimeIcon,
}
