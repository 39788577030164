import { ITrackEntry, Spine } from 'pixi-spine'

import { AnimatedOpenEggProps } from '../types/spine'

export class OpenEggAnimation extends Spine {
  animation: null | ITrackEntry

  onCrack?: () => void
  onEnd?: () => void

  constructor({ texture, eggTexture }: AnimatedOpenEggProps) {
    super(texture)
    this.animation = null

    this.state.addListener({
      start: () => {
        this.hackTextureBySlotName('eggs_base', eggTexture)
      },
      complete: () => {
        if (this.onEnd) {
          setTimeout(this.onEnd, 2_000)
        }
      },
    })
  }

  run(): void {
    this.animation = this.state.setAnimation(0, 'rack', false)

    setTimeout(() => {
      this.hackTextureBySlotName('eggs_base')
      if (this.onCrack) {
        this.onCrack()
      }
    }, 3900)
  }
}
