import { StatusAlertPopout } from 'const/router'
import TonWeb from 'tonweb'
import { makeRequest } from 'utils/api'
import { v4 as uuidv4 } from 'uuid'

import { WalletType } from '@gatto/shared'

import { TON_WALLET, UNIQUE_APP_ID } from '../../config/constants'

import { connector } from './connector'
import axios from 'axios'

async function getNonce() {
  const response = await makeRequest('nft.getConnectHash', {})
  return response.hash
}

export const sendPayment = async (
  amount: number,
  comment: string,
  tonRes?: (restProps?: StatusAlertPopout | undefined) => void,
): Promise<undefined | string> => {
  console.info('Start payment', tonRes)
  // const tonkeeperWallet = await getTonKeeperWallet()
  // if (!tonkeeperWallet) return
  const uniqueId = uuidv4()

  const nanoTons = TonWeb.utils.toNano(amount.toFixed(3).toString()).toString()

  comment = `${comment}:${uniqueId}`

  if (!connector.connected) {
    await openTonConnectModal()
  }

  const cell = new TonWeb.boc.Cell()
  cell.bits.writeUint(0, 32)
  cell.bits.writeString(comment)
  const boc = await cell.toBoc(false)
  const payload = TonWeb.utils.bytesToBase64(boc)
  // eslint-disable-next-line no-console
  // console.log(uniqueId)
  const transaction = {
    validUntil: Math.floor(Date.now() / 1000) + 60,
    messages: [
      {
        address: TON_WALLET,
        amount: nanoTons,
        payload: payload,
      },
    ],
  }
  await connector.sendTransaction(transaction, {
    modals: ['before', 'success'],
    notifications: [],
    skipRedirectToWallet: 'never',
  })
  return uniqueId
}

export async function openTonConnectModal(
  successedConnection?: () => void,
): Promise<void> {
  try {
    console.info('start init conn to crypto wallet')

    const nonce = await getNonce()

    connector.setConnectRequestParameters({
      state: 'ready',
      value: {
        tonProof: nonce,
      },
    })

    if (connector.wallet) {
      console.info('Wallet already exists, disconnecting...', connector.wallet)
      await connector.disconnect()
    }
    await connector.openModal()

    connector.onStatusChange(async (wallet) => {
      console.info('status changed', wallet)

      if (!wallet) {
        return
      }

      const tonProof = wallet.connectItems?.tonProof

      if (!tonProof) return

      if (!('proof' in tonProof)) {
        console.error('Not validated: ', tonProof.error)

        return
      }

      const response = await makeRequest('nft.connectUserWallet', {
        proof: JSON.stringify(tonProof.proof),
        walletAddress: wallet.account.address,
        walletType: wallet.appName as WalletType,
      })

      const tgUserId =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (window.Telegram?.WebApp?.initDataUnsafe?.user?.id as number) ||
        undefined
      if (!tgUserId) {
        return
      }

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      await axios.post(
        'https://tappscenterapi.org/wallet/connect',
        {
          user_id: tgUserId,
          app_id: UNIQUE_APP_ID,
          wallet_address: response.walletAddress,
        },
        config,
      )
      if (response) {
        if (successedConnection) successedConnection()
      }

      return
    })
  } catch (e) {
    console.info(e)
  }
}

export async function disconnectTonWallet(): Promise<void> {
  if (!connector.connected) {
    await openTonConnectModal()
  }
}
